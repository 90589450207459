import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable, Subscription, catchError, debounceTime, finalize, firstValueFrom, forkJoin, map, merge, of, switchMap, throwError } from 'rxjs';
import { CfgQuestionarioComponent } from 'src/app/components/cfg-questionario/cfg-questionario.component';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ListaTabellareComponent, Filtri, Colonna, BottoniListaEvent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { DialogAssociaFiguraAziendaleComponent } from 'src/app/components/selezione-elementi-questionario/dialog/dialog-associa-figura-aziendale/dialog-associa-figura-aziendale.component';
import { SelezioneAzioniBuonepraticheBilancioComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-azioni-buonepratiche-bilancio/selezione-azioni-buonepratiche-bilancio.component';
import { SelezioneImpattiBilancioComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-impatti-bilancio/selezione-impatti-bilancio.component';
import { SelezioneImpegniBilancioComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-impegni-bilancio/selezione-impegni-bilancio.component';
import { SelezioneKpiBilancioComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-kpi-bilancio/selezione-kpi-bilancio.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogCreaMaterialitaComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-materialita/dialog-crea-materialita.component';
import { DialogCreaTemplateMailComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-template-mail/dialog-crea-template-mail.component';
import { DialogAssegnazioniComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-assegnazioni/dialog-assegnazioni.component';
import { DialogCompilazioneFigureComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-compilazione-figure/dialog-compilazione-figure.component';
import { DialogAnteprimaCompilazioneInfoAzComponent } from 'src/app/page/configurazioni/cfg-informativa-aziendale/dialog/dialog-anteprima-compilazione-info-az/dialog-anteprima-compilazione-info-az.component';
import { DialogCreaSintesiSostenibilitaComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-crea-sintesi-sostenibilita/dialog-crea-sintesi-sostenibilita.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { BilancioService, BilancioSostenibilita, ConfigBilanci } from 'src/app/services/bilancio/bilancio.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { AnalisiMaterialita, ElementiCruscotto, SintesiService, SintesiSostenibilita } from 'src/app/services/sintesi/sintesi.service';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AutovalutazioneCompilata, AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { SelezioneCertificazioniBilancioComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-certificazioni-bilancio/selezione-certificazioni-bilancio.component';
import { ImportazioniService } from 'src/app/services/importazioni/importazioni.service';
import { PolicyService, PolicySostenibilita } from 'src/app/services/policy/policy.service';
import { DialogCreaPolicySostenibilitaComponent } from 'src/app/page/policy-sostenibilita/dialog/dialog-crea-policy-sostenibilita/dialog-crea-policy-sostenibilita.component';
import { GruppoCfgStampa } from 'src/app/services/config-stampe/config-stampe.service';
import { CfgStampaComponent } from 'src/app/components/cfg-stampa/cfg-stampa.component';
import { PianoService, PianoSostenibilita } from 'src/app/services/piano/piano.service';
import { DialogCreaPianoSostenibilitaComponent } from 'src/app/page/piano-sostenibilita/dialog/dialog-crea-piano-sostenibilita/dialog-crea-piano-sostenibilita.component';
import { TemaService } from 'src/app/services/tema/tema.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { Padre } from 'src/app/components/select-custom/select-custom.component';
import { StandardRendicontazioneService, STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';

@Component({
    selector: 'app-dialog-crea-bilancio-sostenibilita',
    templateUrl: './dialog-crea-bilancio-sostenibilita.component.html',
    styleUrls: ['./dialog-crea-bilancio-sostenibilita.component.scss'],
    standalone: false
})
export class DialogCreaBilancioSostenibilitaComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('btnAvanti') btnAvanti!: MatButton;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(CfgQuestionarioComponent) componenteSurvey!: CfgQuestionarioComponent;
  @ViewChild(SelezioneImpattiBilancioComponent) componenteSelImpattiBilancio!: SelezioneImpattiBilancioComponent;
  @ViewChild(SelezioneImpegniBilancioComponent) componenteSelImpegniBilancio!: SelezioneImpegniBilancioComponent;
  @ViewChild(SelezioneAzioniBuonepraticheBilancioComponent) componenteSelAzioneBPBilancio!: SelezioneAzioniBuonepraticheBilancioComponent;
  @ViewChild(SelezioneCertificazioniBilancioComponent) componenteCertificazioniBilancio!: SelezioneCertificazioniBilancioComponent;
  @ViewChild(SelezioneKpiBilancioComponent) componenteSelKPIBilancio!: SelezioneKpiBilancioComponent;
  @ViewChild('scrollConfStampa') scrollConfStampa!: NgScrollbar;
  @ViewChild(CfgStampaComponent) cfgStampaComponent!: CfgStampaComponent;

  public STATO: 'BOZZA' | 'PUBBLICATO' | undefined = undefined;

  public settoriAzienda: Settore[] = [];

  private _bilancio: any = {};

  get bilancio() {
    return this._bilancio;
  }

  public isPermessoSoloRead: boolean = false;
  public isPermessoInvioMail: boolean = this.utenteService.isPermessoAttivo('INVIO_MAIL');

  private numeroDipendetiAzienda: number = 0;
  private numeroFatturatoAzienda: number = 0;

  public mappaAnnoSettore: { [anno: string]: { [settore: string]: boolean } } = {};
  public isMaterialitaSelezionata: boolean = false;
  public arrayAnni: string[] = [];
  public guidaDescrizione: string | undefined = undefined;
  public superataSoglia: boolean = false;
  public mostraDescrizione: boolean = false;
  public descrizioneErrore: string | undefined = undefined;
  public isIconaGuida: boolean = false;
  public selectedSTDR: string[] = [];
  public arraySTDR: STDR[] = [];
  public selectedSintesiSostenibilita: string[] = [];
  public arrayAnalisiMaterialita: AnalisiMaterialita[] = [];
  public arraySintesiSostenibilita: SintesiSostenibilita[] = [];
  public arrayAnalisiAutovalutazione: AutovalutazioneCompilata[] = [];
  public arrayPolicySostenibilita: PolicySostenibilita[] = [];
  public arrayPianoSostenibilita: PianoSostenibilita[] = [];

  public selectedAnalisiMaterialita: string[] = [];

  public mostraBottoneAssegnazioni: boolean = false;
  public valueToggleAnni: boolean = false;
  public objQuestionarioImpatti = [];
  public objQuestionarioImpegni = [];
  public objQuestionarioAzioniBP = [];
  public objQuestionarioCertificazioni = [];
  public objQuestionarioKPI = [];


  public idQuestionario: string | undefined = undefined;
  public indicePanelStampa: number | undefined = undefined;
  //**step3
  public displayedColumns: string[] = ["figura", "nome", "email", "stato", 'azioni'];
  public arrayInformativa: any[] = [];

  //**step6

  //KPI 
  public kpiNonCompilati: number = 0;
  public kpiCompilati: number = 0;
  //AZIONI
  public azioneCompilati: number = 0;
  public azioneNonCompilati: number = 0;
  //IMPEGNI
  public impegniCompilati: number = 0;
  public impegniNonCompilati: number = 0;

  public objQuestionario = [];

  public isUtenteGo: boolean = false;
  public bilancioOrReport: string = '';

  /***************************** CREAZIONE BILANCIO SOSTENIBILITA **************************/
  public formCreazioneBilancio = new FormGroup({

    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    idAzienda: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    anno: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    nrDipendenti: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(999999999999)],
    }),

    fatturato: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),
    idMaterialita: new FormControl<string | AnalisiMaterialita>('', {
      nonNullable: true,
      validators: [],
    }),
    idSintesiSostenibilita: new FormControl<string | SintesiSostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),
    idAutovalutazioneCompilata: new FormControl<string | AutovalutazioneCompilata>('', {
      nonNullable: true,
      validators: [],
    }),

    idPolicySostenibilita: new FormControl<string | PolicySostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    idPianoSostenibilita: new FormControl<string | PianoSostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    tipoSettore: new FormControl<'GENERALE' | 'SPECIFICO' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settori: new FormControl<Settore | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    sottoSettori: new FormControl<SottoSettore[]>([], {
      nonNullable: true
    }),
    tipo: new FormControl<string>('BILANCIO', {
      nonNullable: true,
      validators: [],
    }),

    stdr: new FormControl<Padre[]>([], {
      nonNullable: true,
      validators: [],
    }),
    compliance: new FormControl<Padre[]>([], {
      nonNullable: true,
      validators: [],
    }),

  });
  /*************************************************************** CREAZIONE SURVEY **************************************/
  private readonly _subImpatti: Subscription | undefined;

  public arrayAmbiti: Ambito[] = [];
  public arraySottoSettori: SottoSettore[] = [];
  public settoreGenerale: Settore | undefined = undefined;
  public arrayTematicheSel: { [key: string]: any } = {};
  public arrayTematicheDisponibili: { [key: string]: any } = {};
  public loadingTemDisp: { [key: string]: any } = {};


  /******************************************** CRUSCOTTO SURVEY *************************************/
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  public filtri: Filtri[] = [
    {
      titolo: 'Figure Aziendali',
      forControlName: 'figureAziendali',
      input: 'text',
    },
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Cognome',
      forControlName: 'cognome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    stakeholder: {
      title: 'Figure Aziendali',
      value: 'figura'
    },
    tipologia: {
      title: 'Nome',
      value: 'nome'
    },
    email: {
      title: 'Email',
      value: 'email'
    },
    stato: {
      title: 'Stato',
      value: (record: any) => {
        if (record?.stato === 'ROSSO') { // non compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'GIALLO') { // ha inviato la mail ma non è stato compilato (valore solo per gli stakeholder con modalita compilazione "INVIAMAIL")
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'VERDE') { // compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else {
          return '';
        }
      }
    },
    history: {
      type: 'button',
      buttonIcon: this.isPermessoSoloReadFunx() ? 'visibility' : 'history_edu',
      title: this.isPermessoSoloReadFunx() ? 'Visualizza' : 'Compila',
      buttonId: 'compila',
      buttonMostraSempre: true,
      sortable: false,
    },

    modifica: {
      type: 'button',
      buttonIcon: 'person_edit',
      title: 'Modifica',
      buttonId: 'modifica',
      nascondiButton: (record) => (record?.stato === 'VERDE' || this.isPermessoSoloReadFunx()),
      buttonMostraSempre: true
    },
  };

  private _subValueChangeSettore: Subscription | undefined = undefined;
  private _subValueChangeTipoSettore: Subscription | undefined = undefined;
  private _subCambioValoriSettoreSottoSettore: Subscription | undefined = undefined;

  private _subTema: Subscription | undefined;

  constructor(
    private readonly importazioniService: ImportazioniService,
    public dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<DialogCreaBilancioSostenibilitaComponent>,
    private readonly materialitaService: MaterialitaService,
    private readonly utilityService: UtilityService,
    private readonly aziendaService: AziendaService,
    private readonly settoriService: SettoriService,
    private readonly sintesiService: SintesiService,
    private readonly bilancioService: BilancioService,
    private readonly tematicaService: TematicheService,
    private readonly autovalutazioniService: AutovalutazioniService,
    private readonly policyService: PolicyService,
    private readonly pianoService: PianoService,
    private readonly temaService: TemaService,
    private readonly utenteService: UtenteService,
    private readonly stdrService: StandardRendicontazioneService,
    @Inject(MAT_DIALOG_DATA) public data: {
      bilancio: BilancioSostenibilita,
      configBilancio: ConfigBilanci,
    }
  ) { }

  ngOnInit(): void {
    const annoCorrente = new Date().getFullYear();
    // Aggiungi gli anni indietro di 5 anni e in avanti di 1 anno all'array
    for (let i = annoCorrente - 5; i <= annoCorrente + 1; i++) {
      this.arrayAnni.push(i.toString());
    }

    this.aziendaService.azienda.then((azienda) => {
      if (azienda) {

        this.formCreazioneBilancio.get('idAzienda')?.setValue(azienda.id);

        this.aziendaService.getAziendaByIdAzienda(azienda.id).subscribe((risp) => {

          this.numeroDipendetiAzienda = risp.dipendenti;
          this.numeroFatturatoAzienda = risp.fatturato;

          if (!this.data?.bilancio?.id) {
            this.formCreazioneBilancio.get('nrDipendenti')?.setValue(this.numeroDipendetiAzienda);
            this.formCreazioneBilancio.get('fatturato')?.setValue(this.numeroFatturatoAzienda);
          }

        });
      }
    });

    this.ctrIsUtenteGo();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 0);


    this.gestioneStepperBilancio();

    this.isPermessoSoloRead = this.isPermessoSoloReadFunx();

    if (this.isPermessoSoloRead) {
      this.formCreazioneBilancio.disable();
    }
  }

  isPermessoSoloReadFunx() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_BILANCIO');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_BILANCIO')
    return (!isEdit && !isCreate)
  }

  /**
   * Questionario pubblicato 
   * @returns true === PUBBLICATO
   */
  public isPubblicato(): boolean {
    return (this._bilancio?.stato === 'PUBBLICATO')
  }

  async init() {

    this.spinnerOver.show();

    if (this.isUtenteGo) {
      if (this.data.bilancio) {
        await this._setDatiBilancioGo(this.data.bilancio);

        if (this.data?.bilancio?.stato === 'PUBBLICATO') {
          this._getStepTematiche();
        }

      } else {
        this.formCreazioneBilancio.get('settori')?.setValue(this.data.configBilancio.settore); // setto il settore qui perchè mi arriva sempre dal esterno 

        if (this.data?.configBilancio?.settore?.id) {
          await firstValueFrom(this.getAutovalutazioni(this.data?.configBilancio?.settore?.id))
        }
      }

      this.spinnerOver.hide();
      return;
    }

    await this._caricaSettori();

    // Ad ogni cambio di settore o sottosettore bisogna refreshare materialità e autovalutazioni (assessment).
    // Si usa un merge per evitare che partano chiamate multiple parallele quando cambiano settori e/o sottoSettori.
    this._subCambioValoriSettoreSottoSettore = merge(
      this.formCreazioneBilancio.get('settori')!.valueChanges,
      this.formCreazioneBilancio.get('sottoSettori')!.valueChanges
    ).pipe(
      debounceTime(500)
    ).subscribe((value) => {
      if (this.formCreazioneBilancio.get('settori')?.value && !this.formCreazioneBilancio.get('id')?.value) {

        if (this.formCreazioneBilancio.get('settori')?.value) {
          this._cambioSelSettore();
        }
      }
    });


    this._subValueChangeSettore = this.formCreazioneBilancio.get('settori')!.valueChanges.subscribe((value) => {

      if (value) {
        this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === value.id)?.sottoSettori || [];
      }

      this._updateSottoSettoriSelezionati();

    });


    this._subValueChangeTipoSettore = this.formCreazioneBilancio.get('tipoSettore')?.valueChanges.subscribe((value) => {
      if (value === 'GENERALE') {
        this.formCreazioneBilancio.get('settori')?.setValue(this.settoreGenerale);
      } else if (value === 'SPECIFICO') {
        this.formCreazioneBilancio.get('settori')?.setValue(this.settoriAzienda?.length ? this.settoriAzienda[0] : undefined);
      }
    });

    if (this.data.bilancio) {
      await this._setDatiBilancio(this.data.bilancio);
    } else {

      // Preselezione settore

      if (this.settoriAzienda?.length) {
        this.formCreazioneBilancio.get('tipoSettore')?.setValue('SPECIFICO');
        this.formCreazioneBilancio.get('settori')?.setValue(this.settoriAzienda[0]);
      } else {
        this.formCreazioneBilancio.get('tipoSettore')?.setValue('GENERALE');
      }

      this.bilancioService.controlloCompilazioneKpi.soglieSuperate = false;
      this.bilancioService.controlloCompilazioneKpi.stdrBilancio = [];
    }

    this.formCreazioneBilancio.get('idSintesiSostenibilita')?.disable();
    this.formCreazioneBilancio.get('idPolicySostenibilita')?.disable();
    this.formCreazioneBilancio.get('idPianoSostenibilita')?.disable();
    this.formCreazioneBilancio.get('idMaterialita')?.disable();
    this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.disable();
    this.spinnerOver.hide();

    if (this.data?.bilancio?.stato === 'PUBBLICATO') {
      this._getStepTematiche();
    }

    if (!this.data.bilancio) {
      this.controlloSullaSoglia();
    }

  }

  ngOnDestroy(): void {
    this._subImpatti?.unsubscribe();
    this._subValueChangeSettore?.unsubscribe();
    this._subValueChangeTipoSettore?.unsubscribe();
    this._subCambioValoriSettoreSottoSettore?.unsubscribe();
    this._subTema?.unsubscribe();

  }

  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  onKeyDownDip(event: KeyboardEvent): void {
    if (event.key === ',' || event.key === '.') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  keyUpSoglie() {
    this.controlloSullaSoglia();
    this.controlloSuStdr()
    this.controlloSuCompliance()
  }

  compareSTDR(stdr1: AnalisiMaterialita, stdr2: AnalisiMaterialita): boolean {
    return stdr1.idMaterialita === stdr2.idMaterialita; // Sostituisci "id" con la proprietà univoca dei stdr
  }
  compareSintesiSostenibilita(sintesi1: SintesiSostenibilita, sintesi2: SintesiSostenibilita): boolean {
    return sintesi1.id === sintesi2.id; // Sostituisci "id" con la proprietà univoca delle sintesi
  }
  compareAnalisiMaterialita(materialita1: AnalisiMaterialita, materialita2: AnalisiMaterialita): boolean {
    return materialita1.idMaterialita === materialita2.idMaterialita; // Sostituisci "id" con la proprietà univoca delle materialità
  }
  compareAnalisiAutovalutazione(autovalutazione1: AutovalutazioneCompilata, autovalutazione2: AutovalutazioneCompilata): boolean {
    return autovalutazione1.id === autovalutazione2.id; // Sostituisci "id" con la proprietà univoca delle materialità
  }
  compareById(elemento1: any, elemento2: any): boolean {
    return elemento1?.id === elemento2?.id; // Sostituisci "id" con la proprietà univoca degli ambiti
  }
  comparePolicySostenibilita(policy1: PolicySostenibilita, policy2: PolicySostenibilita): boolean {
    return policy1.id === policy2.id; // Sostituisci "id" con la proprietà univoca delle policy
  }
  comparePianoSostenibilita(piano1: PianoSostenibilita, piano2: PianoSostenibilita): boolean {
    return piano1.id === piano2.id; // Sostituisci "id" con la proprietà univoca delle piano
  }

  /**
   * Viene disabilitato per non spammare il bottone avanti mentre stanno caricando i dati 
   */
  disabilitaBottoneAvanti() {
    setTimeout(() => {
      this.btnAvanti.disabled = true;
    }, 0);
  }

  cambioStep(event: StepperSelectionEvent) {
    this.mostraBottoneAssegnazioni = false;
    //Creazione sistesi di sostenibilità
    if (event.selectedIndex === 0) {
      // step1
    } else if (event.selectedIndex === 1) {

      this._getInformativa();

    } else if (event.selectedIndex === 2) {

      this.disabilitaBottoneAvanti();
      return this._getStepTematiche();

    } else if (event.selectedIndex === 3) {
      this.objQuestionario = [];
      this.mostraBottoneAssegnazioni = false;

      this.disabilitaBottoneAvanti();
      this._getImpattiBilancio();

    } else if (event.selectedIndex === 4) {
      this.objQuestionario = [];
      this.mostraBottoneAssegnazioni = true;

      this.disabilitaBottoneAvanti();
      this._getImpegniBilancio();

    } else if (event.selectedIndex === 5) {
      this.objQuestionario = [];
      this.mostraBottoneAssegnazioni = true;

      this.disabilitaBottoneAvanti();
      this._getAzioniBPBilancio();

    } else if (event.selectedIndex === 6) {
      this.objQuestionario = [];
      this.mostraBottoneAssegnazioni = true;

      this.disabilitaBottoneAvanti();
      this._getKpiBilancio();

    } else if (event.selectedIndex === 7) {
      this.objQuestionario = [];
      this.mostraBottoneAssegnazioni = true;

      this.disabilitaBottoneAvanti();
      this._getCertificazioniBilancio();

    } else if (event.selectedIndex === 8) {
      this.spinnerOver.show();
      this.tabella.caricaDati();

      const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value
      if (idBilancioSostenibilita) {
        this.caricaTotaliCruscotto(idBilancioSostenibilita);
      }


    } else if (event.selectedIndex === 9) {

      return this.cfgStampaComponent.caricaStampa();

    }
  }


  /**
   * Bottone indietro 
   * @returns step precedente 
   */
  indietro() {
    switch (this.stepper.selectedIndex) {
      case 0:
        return true;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        this.stepper.previous();
        return true;
      default:
        return null;
    }
  }

  /**
   * Bottone avanti
   * @returns metodo in base allo step attuale
   */
  public async avanti() {

    let result: boolean = false;
    this.spinnerOver.show();

    switch (this.stepper.selectedIndex) {
      case 0:
        result = await this.salvaStepBialncio();
        break;
      case 1:
        result = true;
        break;
      case 2:
        result = await this.salvaStepTematiche();
        break;
      case 3:
        result = await this.salvaStepImpatti();
        break;
      case 4:
        result = await this.salvaStepImpegni();
        break;
      case 5:
        result = await this.salvaStepAzioniBP();
        break;
      case 6:
        result = await this.salvaStepKPI();
        break;
      case 7:
        result = await this.salvaStepCertificazioni();
        break;
      case 8:
        return true;
      default:
        return;
    }
    this.spinnerOver.hide();

    return result;
  }

  public async avantiHTML() {
    if (this.isPermessoSoloRead || await this.avanti()) {
      return this.stepper.next();
    }
  }

  async ctrDipendentiFatturato(): Promise<boolean> {
    const id = this.formCreazioneBilancio.get('id')?.value;
    const nrDipendenti = this.formCreazioneBilancio.get('nrDipendenti')?.value;
    const fatturato = this.formCreazioneBilancio.get('fatturato')?.value;
    if (!id && (nrDipendenti !== this.numeroDipendetiAzienda || fatturato !== this.numeroFatturatoAzienda)) {
      /* IMPLEMENTARE LA DIALOG DI VARIAIZONE FATTUARTO E DIPENDENTI */
      const dialog = await this.utilityService.openDialog({
        titolo: 'Variazione Dipendenti e Fatturato',
        descrizione: 'I valori inseriti per il <strong>fatturato</strong> e il <strong>numero di dipendenti</strong> possono essere <strong>aggiornati</strong> anche nell\'<strong>Azienda</strong>.<br>Vuoi procedere con  l\'<strong>aggiornamento</strong>?',
        fontWeight: 'normal',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return false;
      }
      return true;
    }
    return false;
  }


  /**
   * Metodo salvataggio STEP 1 Bilancio
   * @returns 
   */
  public async salvaStepBialncio(): Promise<boolean> {

    const idBilancio = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancio) {
      return true;
    }

    if (this.controlloSullaSoglia() && !this.isUtenteGo) {
      this.utilityService.opneSnackBar('Per proseguire, è obbligatorio indicare l\'Analisi di Materialità di riferimento.', '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
      return false;
    };



    if (this.formCreazioneBilancio.valid) {

      const aggiornaDatiAzienda = await this.ctrDipendentiFatturato();
      const dialog = await this.utilityService.openDialog({

        titolo: `Creazione ${this.bilancioOrReport} di Sostenibilità`,
        descrizione: 'Continuando non sarà più possibile modificare queste informazioni.',
        bottoni: [
          {
            nome_btn: 'Annulla',
            id_btn: 'N'
          },
          {
            nome_btn: 'Avanti',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return false;
      }

      let stdrMerged;
      let complianceMerged;

      this.stdrService.mergeSelectedStdr(this.formCreazioneBilancio.get('stdr')?.getRawValue() as any, this.arraySTDR, true).subscribe(mergedResult => {
        stdrMerged = mergedResult.filter(padre => !padre.compliance)
      });

      this.stdrService.mergeSelectedStdr(this.formCreazioneBilancio.get('compliance')?.getRawValue() as any, this.arraySTDR, true).subscribe(mergedResult => {
        complianceMerged = mergedResult.filter(padre => padre.compliance)
      });

      let formCrtSostenibilita = this.formCreazioneBilancio.getRawValue();

      const nuovoBilancio = {
        ...formCrtSostenibilita,
        idMaterialita: (formCrtSostenibilita.idMaterialita as AnalisiMaterialita)?.idMaterialita || '',
        idSintesiSostenibilita: (formCrtSostenibilita.idSintesiSostenibilita as SintesiSostenibilita)?.id || '',
        idAutovalutazioneCompilata: (formCrtSostenibilita.idAutovalutazioneCompilata as AutovalutazioneCompilata)?.id || '',
        idPolicySostenibilita: (formCrtSostenibilita.idPolicySostenibilita as PolicySostenibilita)?.id || '',
        idPianoSostenibilita: (formCrtSostenibilita.idPianoSostenibilita as PianoSostenibilita)?.id || '',
        stdr: stdrMerged,
        compliance: complianceMerged,
        aggiornaDatiAzienda: aggiornaDatiAzienda
      };

      return new Promise((resolve) => {
        this.bilancioService.postCreazioneBilancioStep1(nuovoBilancio).subscribe({
          next: (esito => {
            this.idQuestionario = esito?.idQuestionario;
            this.formCreazioneBilancio.get('id')?.setValue(esito.id)

            this.formCreazioneBilancio.get('anno')?.disable();
            this.formCreazioneBilancio.get('settori')?.disable();
            this.formCreazioneBilancio.get('sottoSettori')?.disable();
            this.formCreazioneBilancio.get('stdr')?.disable();
            this.formCreazioneBilancio.get('compliance')?.disable();
            this.formCreazioneBilancio.get('nrDipendenti')?.disable();
            this.formCreazioneBilancio.get('fatturato')?.disable();
            this.formCreazioneBilancio.get('idSintesiSostenibilita')?.disable();
            this.formCreazioneBilancio.get('idPolicySostenibilita')?.disable();
            this.formCreazioneBilancio.get('idPianoSostenibilita')?.disable();
            this.formCreazioneBilancio.get('idMaterialita')?.disable();
            this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.disable();

            resolve(true);
          }),
          error: (err => {
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err.error.message,
              bottoni: [{ nome_btn: 'Ok' }]
            })
            resolve(false)
          })
        })
      })

    } else {
      Object.values(this.formCreazioneBilancio.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return false;
  }

  /**
    * Metodo salvataggio STEP  TEMATICHE
    * @returns 
    */
  public salvaStepTematiche(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        resolve(true);
        return;
      }

      this.componenteSurvey.salva().subscribe({
        next: (esito) => {
          resolve(esito);
        }
      });

    });
  }



  /**
    * Metodo salvataggio STEP 5 IMPATTI
    * @returns 
    */
  public salvaStepImpatti(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteSelImpattiBilancio.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });

    });
  }
  /**
    * Metodo salvataggio STEP 6 IMPEGNI
    * @returns 
    */
  public salvaStepImpegni(): Promise<boolean> {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteSelImpegniBilancio.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }

  /**
    * Metodo salvataggio STEP 7 AZIONI E BUONE PRATICHE
    * @returns 
    */
  public salvaStepAzioniBP() {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteSelAzioneBPBilancio.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }

  /**
    * Metodo salvataggio Certificazioni
    * @returns 
    */
  public salvaStepCertificazioni() {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteCertificazioniBilancio.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }

  /**
    * Metodo salvataggio STEP 8 KPI
    * @returns 
    */
  public salvaStepKPI() {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteSelKPIBilancio.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }

  /**
   * Metodo che mi gestisce controlli se il fatturato è maggiore della soglia 
   */
  public controlloSullaSoglia(): boolean {
    const idMaterialitaControl = this.formCreazioneBilancio?.get('idMaterialita');

    if (idMaterialitaControl) {
      if (this.isSogliaSuperata()) {

        this.superataSoglia = true;
        this.mostraDescrizione = true;
        idMaterialitaControl.setValidators(Validators.required);
        idMaterialitaControl.updateValueAndValidity();
      } else {
        this.superataSoglia = false;
        this.mostraDescrizione = false;
        idMaterialitaControl.clearValidators();
        idMaterialitaControl.updateValueAndValidity();

      }

      //Informazione sulla materialita      
      if (idMaterialitaControl.hasValidator(Validators.required) && !idMaterialitaControl.value) {
        this.guidaDescrizione = `Per proseguire, è obbligatorio indicare l'Analisi di Materialità di riferimento ( L'elenco include solo le analisi di materialità "Consolidate" ).`;
        return true;
      } else if (idMaterialitaControl.value && (idMaterialitaControl.value as AnalisiMaterialita).anno) {
        this.cambiaInfoBoxDesc((idMaterialitaControl.value as AnalisiMaterialita).anno)
        return false;
      } else {

        this.guidaDescrizione = "";
        return false;
      }

    }
    return false;
  }
  /**
   * Controllo soglia
   * @returns return true se soglia superata
   */
  public isSogliaSuperata(): boolean {

    const fatturatoControl = this.formCreazioneBilancio?.get('fatturato');
    const nrDipendentiControl = this.formCreazioneBilancio?.get('nrDipendenti');

    const fatturatoValue = fatturatoControl?.value;
    const nrDipendentiValue = nrDipendentiControl?.value;
    if (fatturatoValue && nrDipendentiValue) {
      if (fatturatoValue >= this.aziendaService.sogliaFatturato && nrDipendentiValue >= this.aziendaService.sogliaNrDipendenti) {
        return true;
      }
    }
    return false;
  }


  onSelectionChangeMaterialita(event: MatSelectChange) {
    this.cambiaInfoBoxDesc(event.value.anno)

    if (!this.isMaterialitaSelezionata) {
      this.utilityService.openDialog({
        titolo: 'Inclusione Analisi di Materialità',
        descrizione: 'Collegando la tua <strong>Analisi di Materialità</strong> al Bilancio di Sostenibilità, le <strong>tematiche materiali</strong> <br>non saranno <strong>deselezionabili</strong> nello step <strong>"Tematiche"</strong> e quindi saranno da trattare <strong>obbligatoriamente</strong>.',
        bottoni: [{ nome_btn: 'Ok' }],
        fontWeight: '500'
      });
    }

    this.isMaterialitaSelezionata = true;
  }

  onSelectionChangeAutovalutazione(event: MatSelectChange) {
  }

  onSelectionChangeSTDR(event: MatSelectChange) {

  }

  onSelectionChangeAnno(event: MatSelectChange) {

    const anno = this.formCreazioneBilancio.get('anno')?.value;
    const annoMaterialita = (this.formCreazioneBilancio.get('idMaterialita')?.value as AnalisiMaterialita)?.anno;
    const idSettore = this.formCreazioneBilancio.get('settori')?.value?.id;
    const idSottoSettori: string[] = (this.formCreazioneBilancio.get('sottoSettori')?.value?.map(sottoSet => sottoSet.id).filter(val => val !== undefined) || []) as string[];

    if (annoMaterialita) {
      this.cambiaInfoBoxDesc(annoMaterialita)
    }

  }

  onSelectionChangeSintesi(event: MatSelectChange) {
  }
  onSelectionChangePolicy(event: MatSelectChange) {
  }
  onSelectionChangePiano(event: MatSelectChange) {
  }


  private cambiaInfoBoxDesc(annoMaterialita: string) {
    /* Controllo se anno della materialità ha anzianità >= 2 anni   */

    const annoControl = this.formCreazioneBilancio.get('anno');
    if (annoControl) {

      const annoBilancio = parseInt(annoControl.value, 10);
      const annoMaterialitaNum: number = parseInt(annoMaterialita, 10);

      const diffAnno: number = annoBilancio - annoMaterialitaNum;
      if (diffAnno >= 2) {
        this.guidaDescrizione = "Sono passati 2 anni dall'Analisi di Materialità selezionata";
        this.mostraDescrizione = true;
      } else {
        this.mostraDescrizione = false;
      }
    }
  }

  addMaterialita() {
    let idSettore = this.formCreazioneBilancio.get('settori')?.value?.id;

    if (idSettore) {

      const dialogCreaMaterialita = this.dialog.open(DialogCreaMaterialitaComponent, {
        data: {
          /*    materialita: record, */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaMaterialita.afterClosed().subscribe(() => {
        if (idSettore) {
          this._getAnalisiMaterialitaObservable(idSettore).subscribe();
        }

      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }

  }

  addSintesi() {
    if (this.formCreazioneBilancio.get('settori')?.value?.id) {

      let idSettore = this.formCreazioneBilancio.get('settori')?.value?.id;

      const dialogCreaSintesi = this.dialog.open(DialogCreaSintesiSostenibilitaComponent, {
        data: {
          /* sintesi: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaSintesi.afterClosed().subscribe((result) => {
        this._getSintesiSostenibilitaObservable(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  addPolicy() {
    if (this.formCreazioneBilancio.get('settori')?.value?.id) {

      let idSettore = this.formCreazioneBilancio.get('settori')?.value?.id;

      const dialogCreaPolicy = this.dialog.open(DialogCreaPolicySostenibilitaComponent, {
        data: {
          /* policy: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaPolicy.afterClosed().subscribe((result) => {
        this._getPolicySostenibilitaObservable(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  addPiano() {
    if (this.formCreazioneBilancio.get('settori')?.value?.id) {

      let idSettore = this.formCreazioneBilancio.get('settori')?.value?.id;

      const dialogCreaPiano = this.dialog.open(DialogCreaPianoSostenibilitaComponent, {
        data: {
          /* piano: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaPiano.afterClosed().subscribe((result) => {
        this._getPianoSostenibilitaObservable(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  private _getAnalisiMaterialitaObservable(idSettore: string) {

    const sottoSettori = (this.formCreazioneBilancio.get('sottoSettori')?.value || []).map(s => s.id).filter(id => id !== undefined) as string[];

    return this.materialitaService.getMaterialitaPubblicate(idSettore, sottoSettori).pipe(
      map((esito: any) => esito.map((item: any) => ({
        idMaterialita: item.id,
        nome: 'Analisi Materialità ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento materialità pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  private _getSintesiSostenibilitaObservable(idSettore?: string, idSottoSettore?: string[]) {

    return this.sintesiService.getSintesiPubblicate(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Sintesi Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento sintesi pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  private _getPolicySostenibilitaObservable(idSettore?: string, idSottoSettore?: string[]) {

    return this.policyService.getPolicyPubblicate(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Policy Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento policy pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  private _getPianoSostenibilitaObservable(idSettore?: string, idSottoSettore?: string[]) {

    return this.pianoService.getPianiPubblicate(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Piano Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento piano pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  private _getStdrObservable(idSettore: string): Observable<any> {


    const idBilancio = this.data?.bilancio?.id || null;
    const isCreazione = !idBilancio;

    return this.bilancioService.getStdr(idBilancio, idSettore, isCreazione).pipe(
      map((esiti) => {
        return esiti
      }),
      catchError(() => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei stdr',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false)
      })
    )
  }



  gestioneStdr() {
    this.formCreazioneBilancio.get('compliance')?.setValue(this.stdrService.setOggettoSelectionCompliance(this.arraySTDR, true))
    this.formCreazioneBilancio.get('stdr')?.setValue(this.stdrService.setOggettoSelectionStdr(this.arraySTDR, true));

    // Faccio il controllo solamente se sono in creazione di un nuovo bilancio
    if (!this.data?.bilancio?.id) {
      this.controlloSuStdr();
      this.controlloSuCompliance()
    }
  }

  public getForkDatiIniziali(idSettore: string, idSottoSettori?: string[]): Observable<any> {

    if (idSettore) {
      return forkJoin({
        autovalutazione: this.getAutovalutazioni(idSettore, idSottoSettori),
        analisiMaterialita: this._getAnalisiMaterialitaObservable(idSettore),
        sintesiSostenibilita: this._getSintesiSostenibilitaObservable(idSettore, idSottoSettori),
        policySostenibilita: this._getPolicySostenibilitaObservable(idSettore, idSottoSettori),
        pianoSostenibilita: this._getPianoSostenibilitaObservable(idSettore, idSottoSettori),
        stdr: this._getStdrObservable(idSettore)
      }).pipe(
        switchMap((result) => {
          this.arrayAnalisiMaterialita = result.analisiMaterialita;
          this.arraySintesiSostenibilita = result.sintesiSostenibilita;
          this.arrayPolicySostenibilita = result.policySostenibilita;
          this.arrayPianoSostenibilita = result.pianoSostenibilita;
          this.arraySTDR = result.stdr;
          this.gestioneStdr();

          this.spinnerOver.hide();
          return of(true);
        }
        ),
        catchError((err) => {
          console.error(err);
          this.spinnerOver.hide();
          return of(false)
        })
      )
    } else {
      console.error('Nessun settore selezionato');
      this.spinnerOver.hide();
      return of(false)
    }
  }

  /**
* Setta nel formGroup i dati del bilancio ti Tipo GO
* @param bilancio 
*/
  private async _setDatiBilancioGo(bilancio: BilancioSostenibilita) {

    let settoreSel: Settore | undefined = bilancio.settori?.length ? bilancio.settori[0] : undefined;
    let anno: string | undefined = bilancio.anno;

    const idSottoSettori: string[] = ((bilancio.sottoSettori || []).map((sottoSet: SottoSettore) => sottoSet.id).filter((val: string | undefined) => val !== undefined) || []) as string[];

    /*  if (settoreSel) {
       await firstValueFrom(this.getForkDatiIniziali(settoreSel.id, idSottoSettori))
     } */

    if (settoreSel) {
      await firstValueFrom(this.getAutovalutazioni(settoreSel.id, idSottoSettori))
    }

    Object.keys(bilancio).forEach((value) => {

      if (value === 'settori') {
        this.formCreazioneBilancio.get('settori')?.setValue((bilancio as any)[value][0]);
      } else {
        this.formCreazioneBilancio.get(value)?.setValue((bilancio as any)[value]);
      }
    });

    if (bilancio.idAutovalutazioneCompilata) {
      // cerco il nome dell autovalutazione in base all id
      const autovalutazioneCompilata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === bilancio.idAutovalutazioneCompilata)
      if (autovalutazioneCompilata) {
        this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.setValue(autovalutazioneCompilata);
      }
    }
    //Disabilito l'anno in modifica 
    if (this.formCreazioneBilancio.get('id')?.value) {
      this.formCreazioneBilancio.get('anno')?.disable();
      this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.disable();
    }


  }

  /**
* Setta nel formGroup i dati del bilancio
* @param bilancio 
*/
  private async _setDatiBilancio(bilancio: BilancioSostenibilita) {

    let settoreSel: Settore | undefined = bilancio.settori?.length ? bilancio.settori[0] : undefined;

    const idSottoSettori: string[] = ((bilancio.sottoSettori || []).map((sottoSet: SottoSettore) => sottoSet.id).filter((val: string | undefined) => val !== undefined) || []) as string[];

    if (settoreSel) {
      await firstValueFrom(this.getForkDatiIniziali(settoreSel.id, idSottoSettori));

      /* await firstValueFrom(this.getAutovalutazioni(settoreSel.id, idSottoSettori)); */
    }



    Object.keys(bilancio).forEach((value) => {

      if (value === 'compliance' || value === 'stdr') {
        /**
         * Valorizzo con lista vuota perchè vengono già valorizzati con l'oggetto corretto nel metodo gestioneStdr()
         * perchè questi due oggetti provenienti dalla lista (this.data.bilancio.stdr/compliance) non hanno il campo selected. 
         */
        this.formCreazioneBilancio.get(value)?.setValue([]);

      } else if (value === 'settori') {
        // Settore si imposta per ultimo, per non far scattare il change quando non è ancora settato l'array dei sottosettori
        settoreSel = (bilancio as any)[value][0];
      } else {
        this.formCreazioneBilancio.get(value)?.setValue((bilancio as any)[value]);
      }

    });

    if (settoreSel) {

      if (!this.settoriAzienda.find((set) => set.id === settoreSel!.id)) {
        // Il settore del bilancio non è negli array dei settori azienda.
        // Potrebbe essere stato tolto a posteriori, dopo la creazione del bilancio.
        // Per non lasciare il campo vuoto, si setta nell'array il settore che viene dal bilancio.
        this.settoriAzienda = [settoreSel];
      }

      this.formCreazioneBilancio.get('settori')?.setValue(settoreSel);
    }

    if (bilancio.idMaterialita) {
      // cerco il nome della materilita in base all id
      const analisiMaterialita = this.arrayAnalisiMaterialita.find(analisi => analisi.idMaterialita === bilancio.idMaterialita)
      if (analisiMaterialita) {
        this.formCreazioneBilancio.get('idMaterialita')?.setValue(analisiMaterialita);
        this.cambiaInfoBoxDesc(analisiMaterialita.anno);
        this.isMaterialitaSelezionata = true;
      }
    }
    if (bilancio.idSintesiSostenibilita) {
      // cerco il nome della materilita in base all id
      const sintesiSostenibilita = this.arraySintesiSostenibilita.find(sintesi => sintesi.id === bilancio.idSintesiSostenibilita)
      if (sintesiSostenibilita) {
        this.formCreazioneBilancio.get('idSintesiSostenibilita')?.setValue(sintesiSostenibilita);
      }
    }
    if (bilancio.idPolicySostenibilita) {
      // cerco il nome della materilita in base all id
      const policySostenibilita = this.arrayPolicySostenibilita.find(policy => policy.id === bilancio.idPolicySostenibilita)
      if (policySostenibilita) {
        this.formCreazioneBilancio.get('idPolicySostenibilita')?.setValue(policySostenibilita);
      }
    }

    if (bilancio.idPianoSostenibilita) {
      // cerco il nome della materilita in base all id
      const pianoSostenibilita = this.arrayPianoSostenibilita.find(piano => piano.id === bilancio.idPianoSostenibilita)
      if (pianoSostenibilita) {
        this.formCreazioneBilancio.get('idPianoSostenibilita')?.setValue(pianoSostenibilita);
      }
    }

    if (bilancio.idAutovalutazioneCompilata) {
      // cerco il nome dell autovalutazione in base all id
      const autovalutazioneCompilata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === bilancio.idAutovalutazioneCompilata)
      if (autovalutazioneCompilata) {
        this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.setValue(autovalutazioneCompilata);
      }
    }

    //Disabilito l'anno in modifica 
    if (this.formCreazioneBilancio.get('id')?.value) {
      this.formCreazioneBilancio.get('anno')?.disable();
      this.formCreazioneBilancio.get('settori')?.disable();
      this.formCreazioneBilancio.get('sottoSettori')?.disable();
      this.formCreazioneBilancio.get('nrDipendenti')?.disable();
      this.formCreazioneBilancio.get('fatturato')?.disable();
      this.formCreazioneBilancio.get('stdr')?.disable();
      this.formCreazioneBilancio.get('compliance')?.disable();
    }

  }


  /**
   * Controllo compliance per soglia
   * Se le soglie sono superate allora devo prendere il compliance e lo devo checkare e poi bloccare. 
   */
  public controlloSuCompliance(): boolean {
    const complianceControl = this.formCreazioneBilancio?.get('compliance');
    let isUpdated = false;

    try {
      const complianceObbligatori = this.arraySTDR
        .filter(compliance => compliance.obbligatorio && compliance.compliance)
        .map(compliance => compliance.codice);


      if (complianceObbligatori.length) {
        const currentValues = complianceControl?.value || [];

        // Modifico la selected del GRI
        of(currentValues)
          .pipe(
            map(valore =>
              valore.map(compliance => {
                if (complianceObbligatori.includes(compliance.nomePadre)) {
                  isUpdated = true; // Setto isUpdated a true se trova un compliance obbligaotrio
                  return {
                    ...compliance,
                    selected: this.isSogliaSuperata() ? true : compliance.selected, // Se la soglia non è superata assengo il valore di prima, cosi non mi sporca il dato. 
                    disabledPadre: this.isSogliaSuperata() // disabilito lo standard per l'esito delle soglie. 
                  }; // Modifica lo standard
                }
                return compliance; // Mantieni l'oggetto invariato
              })
            )
          )
          .subscribe(updatedValues => {
            if (isUpdated) {  // Se almeno un elemento è stato aggiornato
              this.formCreazioneBilancio?.get('compliance')?.setValue(updatedValues);
            }
          });
      } else {
        return false
      }
    } catch (error) {
      console.error(error);
      return false; // Se c'è un errore, ritorna false
    }

    return isUpdated; // Ritorna true se è stato aggiornato, altrimenti false
  }

  /**
   * Controllo Stdr per soglia
   * Se le soglie sono superate allora devo prendere lo stdr GRI e lo devo checkare e poi bloccare. 
   */
  public controlloSuStdr(): boolean {
    const stdrControl = this.formCreazioneBilancio?.get('stdr');
    let isUpdated = false;

    try {
      const stdrObbligatori = this.arraySTDR
        .filter(stdr => stdr.obbligatorio && !stdr.compliance)
        .map(stdr => stdr.codice);


      if (stdrObbligatori.length) {
        const currentValues = stdrControl?.value || [];

        // Modifico la selected del GRI
        of(currentValues)
          .pipe(
            map(valore =>
              valore.map(stdr => {
                if (stdrObbligatori.includes(stdr.nomePadre)) {
                  isUpdated = true; // Setto isUpdated a true se trova un stdr obbligaotrio
                  return {
                    ...stdr,
                    selected: this.isSogliaSuperata() ? true : stdr.selected, // Se la soglia non è superata assengo il valore di prima, cosi non mi sporca il dato. 
                    disabledPadre: this.isSogliaSuperata() // disabilito lo standard per l'esito delle soglie. 
                  }; // Modifica lo standard
                }
                return stdr; // Mantieni l'oggetto invariato
              })
            )
          )
          .subscribe(updatedValues => {
            if (isUpdated) {  // Se almeno un elemento è stato aggiornato
              this.formCreazioneBilancio?.get('stdr')?.setValue(updatedValues);
            }
          });
      } else {
        return false
      }
    } catch (error) {
      console.error(error);
      return false; // Se c'è un errore, ritorna false
    }

    return isUpdated; // Ritorna true se è stato aggiornato, altrimenti false
  }
  /**
   * Controllo Stdr per soglia
   * Se le soglie sono superate allora devo prendere lo stdr/compliance GRI e lo devo checkare e poi bloccare. 
   */
  /*  public controlloSuStdr(): boolean {
     const stdrControl = this.formCreazioneBilancio?.get('stdr');
     const complianceControl = this.formCreazioneBilancio?.get('compliance');
     let isUpdated = false;
 
     try {
       const isGriPresente = this.arraySTDR.find(stdr => stdr.codice === 'GRI');
 
       if (isGriPresente) {
         const currentValues = (isGriPresente?.compliance ? complianceControl?.value : stdrControl?.value) || [];
 
         // Modifico la selected del GRI
         of(currentValues)
           .pipe(
             map(valore =>
               valore.map(stdr => {
                 if (stdr.nomePadre === 'GRI') {
                   isUpdated = true; // Setto isUpdated a true se trova un GRI
                   return {
                     ...stdr,
                     selected: this.isSogliaSuperata() ? true : stdr.selected, // Se la soglia non è superata assengo il valore di prima, cosi non mi sporca il dato. 
                     disabledPadre: this.isSogliaSuperata() // disabilito il GRI per l'esito delle soglie. 
                   }; // Modifica lo standard
                 }
                 return stdr; // Mantieni l'oggetto invariato
               })
             )
           )
           .subscribe(updatedValues => {
             if (isUpdated) {  // Se almeno un elemento è stato aggiornato
               if (isGriPresente?.compliance) {
                 this.formCreazioneBilancio?.get('compliance')?.setValue(updatedValues);
               } else {
                 this.formCreazioneBilancio?.get('stdr')?.setValue(updatedValues);
               }
             }
           });
       } else {
         return false
       }
     } catch (error) {
       console.error(error);
       return false; // Se c'è un errore, ritorna false
     }
 
     return isUpdated; // Ritorna true se è stato aggiornato, altrimenti false
   }
  */
  /**
   * Metodo che mi fa la get del questionario di bilancio
   * @param id id Bilancio
   * @returns 
   */
  private _getQuestionarioBilancio(id: string) {

    return this.bilancioService.getQuestionarioBilancio(id).pipe(catchError((err) => {
      console.error(err);
      if (err.status === 404) {
        return of(false);
      } else {
        return throwError(() => err);
      }
    })).pipe(map((bilancio) => {
      if (bilancio) {
        this._bilancio = bilancio;
      }
      return bilancio;
    }));
  }

  /**
   * Metodo che mi carica i settori dentro l'array
   * da una chiamata WS
   */
  private async _caricaSettori() {

    this.spinnerOver.show();

    try {

      const risp = await firstValueFrom(
        forkJoin({
          generale: this.settoriService.getSettoreGenerale().pipe(
            catchError((err) => {
              console.error('ERRORE GET SETTORE GENERALE : ', err);
              // Errore recupero settore generale, potrebbe non esistere. Si torna undefined
              return of(undefined);
            })
          ),
          specifici: this.aziendaService.getSettoriAzienda(),
          bilanciCreate: this.bilancioService.getBilancio(0, 1000, '', []).pipe(
            catchError((err) => {
              console.error('ERRORE GET BILANCI CREATI : ', err);
              return of(undefined);
            })
          ),
        })
      );



      this.settoriAzienda = risp?.specifici || [];
      this.settoreGenerale = risp?.generale;

      for (const bilanci of risp?.bilanciCreate?.content) {
        const anno = bilanci.anno;
        const settore = bilanci.settori[0]?.id;

        if (!this.mappaAnnoSettore[anno]) {
          this.mappaAnnoSettore[anno] = {};
        }

        this.mappaAnnoSettore[anno][settore] = true;
      }

      const settoreSel = this.formCreazioneBilancio.get('settori')?.value;
      this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === settoreSel?.id)?.sottoSettori || [];

      this.spinnerOver.hide();

    } catch (error) {

      console.error(error);

      this.spinnerOver.hide();

      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Errore nel recupero dei settori',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      this.dialogRef.close();
    }

  }

  private _cambioSelSettore() {
    this.spinnerOver.show()

    this.formCreazioneBilancio.get('idSintesiSostenibilita')?.enable();
    this.formCreazioneBilancio.get('idPolicySostenibilita')?.enable();
    this.formCreazioneBilancio.get('idPianoSostenibilita')?.enable();
    this.formCreazioneBilancio.get('idMaterialita')?.enable();
    this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.enable();



    const anno = this.formCreazioneBilancio.get('anno')?.value;
    const idSettore = this.formCreazioneBilancio.get('settori')?.value?.id;
    const idSottoSettori: string[] = (this.formCreazioneBilancio.get('sottoSettori')?.value?.map(sottoSet => sottoSet.id).filter(val => val !== undefined) || []) as string[];

    if (idSettore) {
      firstValueFrom(this.getForkDatiIniziali(idSettore, idSottoSettori));

      /* firstValueFrom(this.getAutovalutazioni(idSettore, idSottoSettori)); */
    }

  }

  /*************************************************** S T E P   2 ************************/

  private _getInformativa() {
    const id = this.formCreazioneBilancio.get('id')?.value;
    if (id) {
      this.spinnerOver.show();
      this.bilancioService.getInformativa(id)
        .pipe(finalize(() => this.spinnerOver.hide()))
        .subscribe({
          next: (risp) => {
            this.arrayInformativa = [risp];
          },
          error: (err) => {
            console.error(err);
          }
        })
    }
  }


  compilazione(elemento: any) {
    const id = this.formCreazioneBilancio.get('id')?.value;
    if (id) {

      this.spinnerOver.show();
      this.bilancioService.getCompilazioneInformativa(id)
        .pipe(finalize(() => this.spinnerOver.hide()))
        .subscribe({
          next: (compila) => {

            const dialogCreaTemplateMail = this.dialog.open(DialogAnteprimaCompilazioneInfoAzComponent, {
              data: {
                infoAz: compila,
                inConf: (elemento.stato === 'VERDE' || this.isPermessoSoloRead)
              },
              panelClass: ['dialog-container' ],
              disableClose: false,
              width: '90%',
              height: '90%',
              maxWidth: '90%',
              autoFocus: false,
            });

            dialogCreaTemplateMail.afterClosed().subscribe((event) => {

              const statoCompilatoInChiusura = event.info;
              const isBozza = event.isBozza;

              if (statoCompilatoInChiusura) {

                // Sono in bozza ovvero non ho compilato tutte le domande 
                if (isBozza) {
                  this.spinnerOver.show();

                  this.bilancioService.putSalvaCompilazioneInformativaBozza(id, statoCompilatoInChiusura)
                    .pipe(finalize(() => this.spinnerOver.hide()))
                    .subscribe({
                      next: () => {
                        this._getInformativa();
                      },
                      error: (err) => {
                        this.utilityService.openDialog({
                          titolo: 'Attenzione',
                          descrizione: err?.error?.message || 'Errore salva compilazione',
                          bottoni: [{ nome_btn: 'Ok' }]
                        })
                        console.error(err);
                      }
                    });

                  // Sono in compilazione intera quindi pubblico 
                } else {

                  this.spinnerOver.show();

                  this.bilancioService.putSalvaCompilazioneInformativa(id, statoCompilatoInChiusura)
                    .pipe(finalize(() => this.spinnerOver.hide()))
                    .subscribe({
                      next: () => {
                        this._getInformativa();
                      },
                      error: (err) => {
                        this.utilityService.openDialog({
                          titolo: 'Attenzione',
                          descrizione: err?.error?.message || 'Errore salva compilazione',
                          bottoni: [{ nome_btn: 'Ok' }]
                        })
                        console.error(err);
                      }
                    });
                }
              }
            });
          },
          error: (err) => {
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore Generico',
              bottoni: [{ nome_btn: 'Ok' }]
            })
            console.error(err);
          }
        })

    }
  }

  figura(elemento: any) {
    const dialogAssociaFiguraAziendale = this.dialog.open(DialogAssociaFiguraAziendaleComponent, {
      data: {
        figura: { id: elemento.id }
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogAssociaFiguraAziendale.afterClosed().subscribe((idFiguraNuova) => {

      const idFiguraPrecedente = elemento?.id;

      const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

      if (idBilancioSostenibilita && idFiguraPrecedente && idFiguraNuova) {

        this.bilancioService.putCambiaFiguraInformativa(
          idBilancioSostenibilita,
          idFiguraNuova
        ).subscribe({
          next: () => {
            this._getInformativa();
          },
          error: (err) => {
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore Generico',
              bottoni: [{ nome_btn: 'Ok' }]
            })
            this._getInformativa();
            console.error(err);
          }
        });
      }

    });

  }

  inviaMailInformativa(elemento: any) {

    const id = this.formCreazioneBilancio.get('id')?.value;
    if (id) {

      this.spinnerOver.show();

      this.bilancioService.inviaMailInformativa(id).subscribe({
        next: () => {
          this.spinnerOver.hide();
          this._getInformativa();
        },
        error: (err) => {
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore Generico',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      })
    }
  }

  inviaNotifInformativa(elemento: any) {

    const id = this.formCreazioneBilancio.get('id')?.value;
    if (id) {

      this.spinnerOver.show();

      this.bilancioService.inviaNotificaInformativa(id).subscribe({
        next: () => {
          this.spinnerOver.hide();
          this._getInformativa();
        },
        error: (err) => {
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore Generico',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      })
    }
  }



  //?? ************************************************** EDN S T E P   3 ************************/


  private _getStepTematiche() {

    const id = this.formCreazioneBilancio.get('id')?.value;

    const idSettore = (this.formCreazioneBilancio.get('settori')?.value as Settore).id
    this.componenteSurvey.datiInizialiCfgQuestionario(idSettore);

    if (!id) {
      return;
    }

    this.spinnerOver.show();

    this._getQuestionarioBilancio(id).subscribe(
      {
        next: (bilancio: any) => {

          if (bilancio) {

            this._bilancio = bilancio;
            this.completaStep();

          }
          this.btnAvanti.disabled = false;
          this.spinnerOver.hide();
        },
        error: (err: any) => {
          console.error(err);
          this.btnAvanti.disabled = false;

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero della bilancio',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          this.spinnerOver.hide();
        }
      }
    )
  }


  /**
 * Get ws Impatti bilancio 
 * @returns 
 */
  private _getImpattiBilancio(): Promise<boolean> {

    const id = this.formCreazioneBilancio.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }

    this.spinnerOver.show();
    return firstValueFrom(this.bilancioService.getImpattiBilancio(id)
      .pipe(
        map((esito) => {
          this.objQuestionarioImpatti = esito;
          this.componenteSelImpattiBilancio.objQuestionario = esito;
          this.btnAvanti.disabled = false;
          return true;
        }),
        catchError(err => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero degli impatti',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return of(false);
        }),
        finalize(() => this.spinnerOver.hide())
      ))
  }

  /**
 * Get ws impegni bilancio 
 * @returns 
 */
  private _getImpegniBilancio(): Promise<boolean> {
    const id = this.formCreazioneBilancio.get('id')?.value;

    if (!id) {
      return Promise.resolve(false)
    }
    this.spinnerOver.show();
    return firstValueFrom(this.bilancioService.getImpegniBilancio(id)
      .pipe(
        map(esito => {
          this.objQuestionarioImpegni = esito
          this.componenteSelImpegniBilancio.objQuestionario = esito;
          this.btnAvanti.disabled = false;
          return true;
        }),
        catchError(err => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero degli impegni',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return of(false)
        }),
        finalize(() => this.spinnerOver.hide())
      ))
  }
  /**
 * Get ws azioni buone pratiche bilancio 
 * @returns 
 */
  private _getAzioniBPBilancio(): Promise<boolean> {
    const id = this.formCreazioneBilancio.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();
    return firstValueFrom(this.bilancioService.getAzioniBPBilancio(id)
      .pipe(
        map(esito => {
          this.objQuestionarioAzioniBP = esito;
          this.componenteSelAzioneBPBilancio.objQuestionario = esito;
          this.btnAvanti.disabled = false;
          return true
        }),
        catchError(err => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero delle azioni buone pratiche',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return of(false)
        }),
        finalize(() => this.spinnerOver.hide())
      ))
  }
  /**
 * Get ws kpi bilancio 
 * @returns 
 */
  private _getKpiBilancio(): Promise<boolean> {
    const id = this.formCreazioneBilancio.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();

    return firstValueFrom(
      forkJoin({
        anni: this.bilancioService.getAnniPrecKpi(id),
        kpi: this.bilancioService.getKpiBilancio(id)
      })
        .pipe(
          map(esito => {
            if (esito.kpi) {
              this.objQuestionarioKPI = esito.kpi;
              this.componenteSelKPIBilancio.objQuestionario = esito.kpi;
            }

            if (esito.anni) {
              this.valueToggleAnni = esito.anni.includiAnniPrecedenti || false;
            }

            this.btnAvanti.disabled = false;
            return true;
          }),
          catchError(err => {
            console.error(err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel recupero dei KPI',
              bottoni: [{ nome_btn: 'Ok' }]
            })
            return of(false)
          }),
          finalize(() => this.spinnerOver.hide())
        ))
  }
  /**
   * Get Certificazioni bilancio
   * @returns 
   */
  private _getCertificazioniBilancio(): Promise<boolean> {
    const id = this.formCreazioneBilancio.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();
    return firstValueFrom(this.bilancioService.getCertificazioniBilancio(id)
      .pipe(
        map(esito => {
          this.objQuestionarioCertificazioni = esito
          this.btnAvanti.disabled = false;
          return true;
        }),
        catchError(err => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero delle certificazioni',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return of(false)
        }),
        finalize(() => this.spinnerOver.hide())
      ))
  }

  salvataggioSelezioni(stepperIndex: number): Observable<boolean> {
    if (this.isPubblicato() || this.isPermessoSoloRead) {
      return of(true);
    }

    switch (stepperIndex) {
      case 3: //Impatti
        this.spinnerOver.show();
        return this.componenteSelImpattiBilancio.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 4: //Impegni
        this.spinnerOver.show();
        return this.componenteSelImpegniBilancio.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 5: //Azioni e buone pratiche
        this.spinnerOver.show();
        return this.componenteSelAzioneBPBilancio.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 6: //KPI
        this.spinnerOver.show();
        return this.componenteSelKPIBilancio.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 7: //Certificazioni
        this.spinnerOver.show();
        return this.componenteCertificazioniBilancio.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      default:
        return of();
    }
  }


  /**
   * Lista di tutte le assegnazioni delle figure 
   * @param stepperIndex index dello stepper in qui sono 
   */
  assegnazioni(stepperIndex: number) {
    this.salvataggioSelezioni(stepperIndex)
      .pipe(
        switchMap(() => {
          const dialogAssegnazioniFigAzz = this.dialog.open(DialogAssegnazioniComponent, {
            data: {
              idContesto: this.formCreazioneBilancio.get('id')?.value,
              stato: this.bilancio?.stato,
              contesto: 'bilancio',
              isPermessoSoloRead: this.isPermessoSoloRead
            },
            panelClass: 'dialog-container',
            disableClose: false,
            width: '75%',
            maxHeight: '95%',
            autoFocus: false,
          });

          return dialogAssegnazioniFigAzz.afterClosed();
        })
      )
      .subscribe(() => {
        if (this._bilancio?.stato === 'PUBBLICATO') {
          return;
        }

        switch (stepperIndex) {
          case 3: //Impatti
            this._getImpattiBilancio();
            break;
          case 4: //Impegni
            this._getImpegniBilancio();
            break;
          case 5: //Azioni e buone pratiche
            this._getAzioniBPBilancio();
            break;
          case 6: //KPI
            this._getKpiBilancio();
            break;
          case 7: //KPI
            this._getCertificazioniBilancio();
            break;
          default:
            break;
        }

      });
  }
  /* ----------------- STEP 9 CRUSCOTTO ------------- */
  public fnCaricamentoDatiCruscotto: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value

    if (idBilancioSostenibilita) {
      return this.bilancioService.getCruscotti(idBilancioSostenibilita, page, pageSize, ricerca, filters, sortBy);
    } else {
      return of(false);
    }

  };


  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.modificaFigura(event.data);
        break;
      case 'compila':
        this.eleCompilazioneFigura(event.data.elementi, event.data.nome, event.data.figura, event.data.id);

        break;
      case 'azione':
        if (event.data) {
          if (event.data.ultimoInvio) {

            this._inviaNotificaSingola(event.data);
          } else {

            this._inviaMailSingola(event.data);
          }
        }
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Metodo che mi invia una notifica singola 
   * @param cruscotto 
   * @returns 
   */
  private async _inviaNotificaSingola(cruscotto: any) {
    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancioSostenibilita) {

      const testiMail = await firstValueFrom(this.bilancioService.getTemplateMail(idBilancioSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica alla figura selezionata',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }


      try {
        this.spinnerOver.show();

        await firstValueFrom(this.bilancioService.inviaNotifica(idBilancioSostenibilita, cruscotto.id));
        await firstValueFrom(this._getQuestionarioBilancio(idBilancioSostenibilita));

        this.tabella.caricaDati();

        this.utilityService.opneSnackBar('Notifica inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {

        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio notifica',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }

    }
  }
  async notificaTutti() {

    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancioSostenibilita) {

      const testiMail = await firstValueFrom(this.bilancioService.getTemplateMail(idBilancioSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica a tutte le figure che hanno ricevuto la mail',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      try {
        this.spinnerOver.show();
        await firstValueFrom(this.bilancioService.inviaNotificaMultiple(idBilancioSostenibilita));
        await firstValueFrom(this._getQuestionarioBilancio(idBilancioSostenibilita))

        this.tabella.caricaDati();

        this.utilityService.opneSnackBar('Notifica inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio notifiche',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }

    }

  }

  private async _inviaMailSingola(cruscotto: any) {

    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancioSostenibilita) {

      const testiMail = await firstValueFrom(this.bilancioService.getTemplateMail(idBilancioSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Invia mail ',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      try {
        this.spinnerOver.show();
        await firstValueFrom(this.bilancioService.inviaMail(idBilancioSostenibilita, cruscotto.id));
        await firstValueFrom(this._getQuestionarioBilancio(idBilancioSostenibilita));
        this.tabella.caricaDati();

        this.utilityService.opneSnackBar('Mail inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio mail',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }

    }
  }

  async inviaTutti() {
    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancioSostenibilita) {

      const testiMail = await firstValueFrom(this.bilancioService.getTemplateMail(idBilancioSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }

      if (this._bilancio.stato !== 'PUBBLICATO') {
        const risultatoObservable = await this._pubblicazioneBilancio('con l\'invio mail');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
        }
        this.spinnerOver.hide();
      }

      try {
        this.spinnerOver.show();
        await firstValueFrom(this.bilancioService.inviaMailMultiple(idBilancioSostenibilita));
        await firstValueFrom(this._getQuestionarioBilancio(idBilancioSostenibilita));
        this.tabella.caricaDati();
        this.utilityService.opneSnackBar('Mail inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio mail',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }
    }
  }

  /**
   * Modifica figura su cruscotto
   * @param dati 
   */
  modificaFigura(dati: any) {

    const dialogAssociaFiguraAziendale = this.dialog.open(DialogAssociaFiguraAziendaleComponent, {
      data: {
        figura: { id: dati.id }
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogAssociaFiguraAziendale.afterClosed().subscribe((idFiguraNuova) => {

      const idFiguraPrecedente = dati?.id;

      const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

      if (idBilancioSostenibilita && idFiguraPrecedente && idFiguraNuova) {

        this.bilancioService.putCambiaFigura(
          idBilancioSostenibilita,
          idFiguraPrecedente,
          idFiguraNuova
        ).subscribe({
          next: () => {
            this.tabella.caricaDati();
          },
          error: (err) => {
            this.tabella.caricaDati();
            console.error(err);
          }
        });
      }

    });
  }

  //! DA RIVEDERE L'IMPLEMENTAZIONE E L'ADATTAMENTO 
  template() {
    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value

    if (idBilancioSostenibilita) {

      const dialogCreaTemplateMail = this.dialog.open(DialogCreaTemplateMailComponent, {
        data: {
          idBilancioSostenibilita: idBilancioSostenibilita,
          isPermessoSoloRead: this.isPermessoSoloRead
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '95%',
        autoFocus: false,
      });

      dialogCreaTemplateMail.afterClosed().subscribe();

    }
  }

  private async _pubblicazioneBilancio(desc?: string): Promise<Observable<boolean> | undefined> {
    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

    let titolo: string = `Pubblicazione ${this.bilancioOrReport} di Sostenibilità`;
    let descrizione: string = `
      <div>
        <p>
          <strong>Si prega di notare che questa operazione pubblicherà il ${this.bilancioOrReport} di Sostenibilità.</strong>
        </p>
        <ul>
          <li>La pubblicazione è definitiva e <strong>NON</strong> potrà essere annullata.</li>
          <li>Inoltre non sarà più possibile <strong>aggiungere</strong> o <strong>modificare</strong> le informazioni precedentemente configurate.</li>
        </ul>
        <p><strong>Vuoi procedere ${desc ? desc : ' '}?</strong></p>
      </div>
    `;

    const dialog = await this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      fontWeight: '500',
      bottoni: [
        { nome_btn: 'No', id_btn: 'N' },
        { nome_btn: 'Si', id_btn: 'S' }
      ]
    });

    const valDialog = await firstValueFrom(dialog.beforeClosed());
    if (valDialog === 'N') {
      return of(false);
    }

    // PUBBLICO IL BILANCIO E ASPETTO LA RISPOSTA 
    this.spinnerOver.show();
    await firstValueFrom(this.bilancioService.pubblicaBilancio(idBilancioSostenibilita!));
    return of(true);
  }

  /**
   * Get cruscotto filtrato per figura
   * @param idFigura 
   * @param idBilancioSostenibilita 
   * @returns 
   */
  getFiguraCruscotto(idFigura: string, idBilancioSostenibilita: string) {
    return new Promise((resolve, reject) => {

      let filtroFiguraSelezionata: Filters = {
        values: [idFigura],
        operator: 'eq',
        chiave: 'id'
      };

      this.bilancioService.getCruscotti(idBilancioSostenibilita ?? '', 0, 1000, '', [filtroFiguraSelezionata])
        .subscribe({
          next: data => resolve(data),
          error: err => reject(new Error(err))
        });
    });
  }

  /**
   *
   * @param elementiCruscotto 
   * @param nome 
   * @param figura 
   */
  public async eleCompilazioneFigura(elementiCruscotto: ElementiCruscotto[], nome: string, figura: string, idFigura: string) {

    const idBilancioSostenibilita = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancioSostenibilita) {

      if (!this.isPubblicato() && !this.isPermessoSoloRead) {

        /* --- Modifica richiesta: Se ci sono elementi precompilati lo posso sapere solamente se pubblico 
          il questionario, una volta pubblicato, il BE calcola le precompilazioni, mi rifaccio la get del cruscotto filtrato
          per idFigura selezionata e mi reperisco gli elementi aggiornati e precompilati. --- */

        const risultatoObservable = await this._pubblicazioneBilancio('con la compilazione');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
          try {
            // CHIAMO LA FIGURA FILTRTA E ASPETTO RISPOSTA 
            const figura: any = await this.getFiguraCruscotto(idFigura!, idBilancioSostenibilita!);
            elementiCruscotto = figura?.content[0]?.elementi;
          } catch (err) {
            console.error(err);
          }
        }
        this.spinnerOver.hide();
      }

      let stdrBilancio: STDR[] = [];
      let complianceBilancio: STDR[] = [];

      this.stdrService.mergeSelectedStdr(this.formCreazioneBilancio.get('stdr')?.getRawValue() as any, this.arraySTDR, true).subscribe(mergedResult => {
        stdrBilancio = mergedResult.filter(padre => !padre.compliance)
      });
      this.stdrService.mergeSelectedStdr(this.formCreazioneBilancio.get('compliance')?.getRawValue() as any, this.arraySTDR, true).subscribe(mergedResult => {
        complianceBilancio = mergedResult.filter(padre => padre.compliance)
      });

      this.bilancioService.controlloCompilazioneKpi.stdrBilancio = stdrBilancio;
      this.bilancioService.controlloCompilazioneKpi.complianceBilancio = complianceBilancio;
      this.bilancioService.controlloCompilazioneKpi.soglieSuperate = this.isSogliaSuperata();


      const dialogCompilazioneFigure = this.dialog.open(DialogCompilazioneFigureComponent, {
        data: {
          elementiCruscotto,
          idBilancioSostenibilita,
          nome,
          figura,
          isPermessoSoloRead: this.isPermessoSoloRead
        },
        panelClass: ['dialog-container' ],
        disableClose: false,
        width: '75%',
        maxHeight: '95%',
        maxWidth: '90%',
        autoFocus: false,
      });


      dialogCompilazioneFigure.afterClosed().pipe(
        switchMap(() => {
          this.spinnerOver.show();
          return this._getQuestionarioBilancio(idBilancioSostenibilita)
        })
      ).subscribe(() => {
        this.tabella.caricaDati();
        this.caricaTotaliCruscotto(idBilancioSostenibilita);
      })
    }
  }

  /**
   * TOTALI CRUSCOTTO STEP 9
   * @param idBilancioSostenibilita 
   */
  caricaTotaliCruscotto(idBilancioSostenibilita: string): Promise<boolean> {

    return firstValueFrom(this.bilancioService.getTotaliCruscotto(idBilancioSostenibilita).pipe(
      map((totaliCruscotto: any) => {
        this.kpiCompilati = totaliCruscotto.nrKpiCompilati || 0;
        this.kpiNonCompilati = (totaliCruscotto.nrKpi || 0) - this.kpiCompilati;
        this.azioneCompilati = totaliCruscotto.nrAzioniCompilate || 0;
        this.azioneNonCompilati = (totaliCruscotto.nrAzioni || 0) - this.azioneCompilati;
        this.impegniCompilati = totaliCruscotto.nrImpegniCompilati || 0;
        this.impegniNonCompilati = (totaliCruscotto.nrImpegni || 0) - this.impegniCompilati;
        return true;
      }),
      catchError(err => {
        console.error(err);
        return of(false)
      }),
      finalize(() => this.spinnerOver.hide())

    )
    )
  }

  cambioToggle(event: any) {

    const id = this.formCreazioneBilancio.get('id')?.value;

    if (!id) {
      return;
    }

    this.spinnerOver.show();

    if (event.checked) {

      this.bilancioService.postAnniPrecKpi(id).subscribe({
        next: (esito) => {
          this.valueToggleAnni = true;
          this.spinnerOver.hide();

        },
        error: (err) => {
          this.valueToggleAnni = false;

          this.spinnerOver.hide();
          console.error(err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei KPI',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      });

    } else {

      this.bilancioService.delAnniPrecKpi(id).subscribe({
        next: () => {
          this.valueToggleAnni = false;
          this.spinnerOver.hide();
        },
        error: (err) => {
          this.valueToggleAnni = true;
          this.spinnerOver.hide();
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei KPI',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    }
  }

  /* ------------------------- STAMPA -------------------------*/

  public async stampa() {
    const idBilancio = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancio) {
      await this.caricaTotaliCruscotto(idBilancio)
    }

    if (this.kpiNonCompilati || this.azioneNonCompilati || this.impegniNonCompilati) {
      let titolo: string = 'Stampa';
      let descrizione: string = `
      <div>
        <p>
          Non sono state <strong>completate</strong> tutte le <strong>compilazioni</strong>.
        </p>
        <p><strong>Vuoi procedere?</strong></p>
      </div>`;

      const dialog = await this.utilityService.openDialog({
        titolo: titolo,
        descrizione: descrizione,
        fontWeight: '500',
        bottoni: [
          { nome_btn: 'No', id_btn: 'N' },
          { nome_btn: 'Si', id_btn: 'S' }
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return of(false);
      }
    }

    if (!this.cfgStampaComponent.cfgStampa) {
      console.error('NESSUNA CFG STAMPA DA SALVARE');
      return;
    }

    this.spinnerOver.show()

    if (idBilancio && this.cfgStampaComponent.cfgStampa) {

      this.fnSalvaCfgStampa(this.cfgStampaComponent.cfgStampa).pipe(
        switchMap((result: any) => {
          if (result) {
            return this.bilancioService.postStampaBilancio(idBilancio);
          } else {
            return of()
          }
        })
      ).subscribe(
        {
          next: (urlStampa) => {
            window.open(urlStampa?.body, '_blank');

            this.spinnerOver.hide();
          },
          error: (err) => {
            console.error(err);

            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Stampa non riuscita. Verificare le configurazioni e riprovare.',
              bottoni: [{ nome_btn: 'Ok' }]
            });
          }
        }
      )
    }
    return;
  }


  /**
   * Lancia il salvataggio della configurazione, passando dal componente.
   */
  public salvaCfgStampa() {

    if (!this.cfgStampaComponent.cfgStampa) {
      console.error('NESSUNA CFG STAMPA DA STAMPARE');
      return;
    }

    this.spinnerOver.show();

    this.fnSalvaCfgStampa(this.cfgStampaComponent.cfgStampa).subscribe((esito) => {

      if (!esito) {
        // Salvataggio fallito
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Salvataggio configurazione non riuscito. Verificare le informazioni e riprovare.',
          bottoni: [{ nome_btn: 'Ok' }]
        });
      }

      this.spinnerOver.hide();

    })
  }

  /**
   * 
   * @returns Funzione di get cfg stampa da impostare sul componente cfgStampaComponent
   */
  public fnGetConfigStampa = () => {
    const idBilancio = this.formCreazioneBilancio.get('id')?.value;
    if (idBilancio) {
      return this.bilancioService.getConfigStampa(idBilancio);
    } else {
      return throwError(() => "ID bilancio non valorizzato");
    }
  }

  /**
   * Funzione di salvataggio cfg stampa da impostare sul componente cfgStampaComponent
   * @param grpCfgStampa 
   * @returns 
   */
  public fnSalvaCfgStampa = (grpCfgStampa: GruppoCfgStampa) => {
    const idBilancio = this.formCreazioneBilancio.get('id')?.value;

    if (idBilancio) {

      if (this.isPermessoSoloRead) {
        return of(true);
      }

      return this.bilancioService.postSalvaElementiStampa(idBilancio, grpCfgStampa)
    } else {
      return throwError(() => "ID bilancio non valorizzato");
    }
  }

  /**
   * Metodo che mi fa l'importazione del file immagine 
   * @param elemStampa 
   */
  public fnImportaImmagine = (file: File, chiaveCfg: string) => {

    if (!this.formCreazioneBilancio.get('idAzienda')?.value ||
      !this.formCreazioneBilancio.get('id')?.value) {
      return throwError(() => "DATI INSUFFICIENTI PER ELIMINAZIONE IMMAGINE");
    }

    const listaId = {
      idAzienda: this.formCreazioneBilancio.get('idAzienda')!.value,
      idBilancioSostenibilita: this.formCreazioneBilancio.get('id')!.value!,
      chiaveCfg
    }

    return this.importazioniService.importImgStampaBilancio(file, {}, listaId);

  }

  /**
   * Metodo per eliminazione immagine in cfg stampa 
   * Viene rimosso da google cloud storage 
   */
  public fnEliminaImg = (chiaveCfg: string) => {

    if (!this.formCreazioneBilancio.get('idAzienda')?.value ||
      !this.formCreazioneBilancio.get('id')?.value) {
      return throwError(() => "DATI INSUFFICIENTI PER ELIMINAZIONE IMMAGINE");
    }

    const listaId = {
      idAzienda: this.formCreazioneBilancio.get('idAzienda')!.value,
      idBilancioSostenibilita: this.formCreazioneBilancio.get('id')!.value!,
      chiaveCfg
    }

    return this.importazioniService.deleteImgStampaBilancio(listaId);
  }

  public toggleLoading(mostra: boolean) {
    if (mostra) {
      this.spinnerOver.show();
    } else {
      this.spinnerOver.hide();
    }
  }

  /**
   * Metodo che mi richiama la lista delle autovalutazioni compilate
   */
  getAutovalutazioni(idSettore: string, idSottoSettori?: string[]): Observable<any> {

    this.spinnerOver.show()

    return this.autovalutazioniService.getAutovalutazioniCompilatePerSettore(idSettore, idSottoSettori).pipe(
      switchMap((result) => {
        this.arrayAnalisiAutovalutazione = result;

        // Controlla il valore già presente nella form. Se non esiste nell'array, si azzera
        const autovalPreSelez = this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.value;
        let autovalutazioneTrovata: AutovalutazioneCompilata | undefined = undefined;
        if (autovalPreSelez && typeof autovalPreSelez !== 'string') {
          autovalutazioneTrovata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === autovalPreSelez.id);
        }

        if (!autovalutazioneTrovata) {
          // valore preselezionato non trovato, si azzera
          this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.setValue('');
        }

        this.formCreazioneBilancio.get('idAutovalutazioneCompilata')?.updateValueAndValidity();

        this.spinnerOver.hide();
        return of(true)
      }),
      catchError((err) => {
        console.error(err);
        this.spinnerOver.hide();
        return of(false)
      })
    )
  }

  // Gestione cancellazione in mat select 
  hasValue(formControlName: string) {
    if (this.data.bilancio || !this.formCreazioneBilancio.get(formControlName)?.value || this.formCreazioneBilancio.get(formControlName)?.status === 'DISABLED') {
      return true;
    }
    return false;
  }

  clearInput(formControlName: string, event?: Event): void {
    const field = this.formCreazioneBilancio.get(formControlName);

    if (formControlName === 'idMaterialita') {
      this.isMaterialitaSelezionata = false;
    }

    if (!field?.disabled) {
      field?.reset();
      field?.markAsDirty();
      field?.markAsTouched();
    }
    if (event) event.stopPropagation();

    if (!this.formCreazioneBilancio.get('idMaterialita')?.value) {
      this.mostraDescrizione = false;
    }
  }

  mostraDescrizioneAssessment() {
    return this.arrayAnalisiAutovalutazione.length ? 'Assessment di Sostenibilità' : 'Assessment non selezionato'
  }
  mostraDescrizionMaterialita() {
    return this.arrayAnalisiMaterialita.length ? 'Analisi Materialità' : 'Materialità non selezionata'
  }
  mostraDescrizionSintesi() {
    return this.arraySintesiSostenibilita.length ? 'Sintesi di Sostenibilità' : 'Sintesi non selezionata'
  }
  mostraDescrizionPolicy() {
    return this.arrayPolicySostenibilita.length ? 'Policy di Sostenibilità' : 'Policy non selezionata'
  }
  mostraDescrizionPiano() {
    return this.arrayPianoSostenibilita.length ? 'Piano di Sostenibilità' : 'Piano non selezionata'
  }

  private _updateSottoSettoriSelezionati() {
    const sottoSettoriSel = this.formCreazioneBilancio.get('sottoSettori')?.value || [];

    const sottoSettoriaggiornati = sottoSettoriSel.filter(
      (sottoSettSel) => {
        return this.arraySottoSettori && sottoSettSel.id && !!this.arraySottoSettori.find(
          (sottoSett) => {
            return sottoSett.id === sottoSettSel.id;
          });
      }
    );

    this.formCreazioneBilancio.get('sottoSettori')?.setValue(sottoSettoriaggiornati || []);
  }

  ctrAnnoBilanciUtilizzati(anno: string) {
    return this.isAnnoSettoreValid(anno, this.formCreazioneBilancio.get('settori')?.value?.id);
  }

  ctrSettoreBilanciUtilizzati(settore: Settore) {
    const anno = this.formCreazioneBilancio.get('anno')?.value;
    return this.isAnnoSettoreValid(anno, settore?.id);
  }

  private isAnnoSettoreValid(anno: string | undefined, settoreId: string | undefined) {
    return anno && settoreId && this.mappaAnnoSettore[anno]?.[settoreId] ? true : false;
  }

  private ctrIsUtenteGo() {
    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
        this.bilancioOrReport = 'Report'
        this.formCreazioneBilancio.get('nrDipendenti')?.clearValidators();
        this.formCreazioneBilancio.get('fatturato')?.clearValidators();
        this.formCreazioneBilancio.get('tipoSettore')?.clearValidators();
        this.formCreazioneBilancio.get('settori')?.clearValidators();
        this.formCreazioneBilancio.get('stdr')?.clearValidators();
      } else {
        this.bilancioOrReport = 'Bilancio'
        this.isUtenteGo = false;
      }
      this.formCreazioneBilancio.updateValueAndValidity();
    })
  }


  saltaCtrStepper(idx: number) {

    // Se i miei permessi sono solo in read 
    if (this.isPermessoSoloRead) {
      return true;
    }

    // Se pubblicato 
    if ((this.isPubblicato() || this.data?.bilancio?.stato === 'PUBBLICATO')) {
      return true
    }

    // Se torno indietro non faccio ne controlli ne salvataggi
    if (idx < this.stepper.selectedIndex) {
      return true
    }
    return false;
  }

  /**
   * Metodo che gestisce la logica della navigazione sugli stepper 
   */
  gestioneStepperBilancio() {

    setTimeout(() => {

      this.stepper.steps.forEach((step, idx) => {
        step.select = async () => {

          // Salto controllo stepper 
          if (this.saltaCtrStepper(idx)) {
            return this.stepper.selectedIndex = idx;
          }

          /* Se step selezionato è completed devo controllare gli step in mezzo
           Esempio (sono su STEP 1 seleziono STEP 6 , devo controllare STEP 2-3-4-5) */
          if (this.stepper.steps.get(idx)?.completed) {
            return this.stepper.selectedIndex = await this.ctrStep(idx);
          }


          if (idx === this.stepper.selectedIndex + 1) { // linear
            const isSalvato = await this.avanti();

            if (isSalvato) {
              return this.stepper.selectedIndex = idx;
            }
          }
          return;
        };
      });

    }, 0);

  }

  /**
   * gestione controlli step selezionati
   * @param stepperSelezionato 
   * @returns 
   */
  private async ctrStep(stepperSelezionato: number): Promise<number> {
    const stepperCorrente = this.stepper.selectedIndex;

    if (stepperSelezionato > stepperCorrente) {
      for (let index = stepperCorrente; index < stepperSelezionato; index++) {

        // Salvo step corrente
        if (stepperCorrente === index) {
          const isSalvato = await this.avanti();
          if (!isSalvato) {
            return stepperCorrente;
          } else {
            continue;
          }
        }

        // Controllo step by step e ritorno l'index dove da errore
        if (await this.ritornoControllo(index)) {
          return index;
        }

      }
    }

    return stepperSelezionato;
  }

  /**
   * Controlli degli stepper
   * @param stepperDaControllare 
   * @returns 
   */
  private async ritornoControllo(stepperDaControllare: number): Promise<boolean> {
    let isCtrFallito: boolean = false;;
    switch (stepperDaControllare) {
      case 2:
        isCtrFallito = this.componenteSurvey.ctrSalva();
        break;

      case 3: {
        const esito = await this._getImpattiBilancio();
        if (esito) {
          isCtrFallito = this.componenteSelImpattiBilancio.ctrStepper('impatto');
        }
        break;
      }
      case 4: {
        const esito = await this._getImpegniBilancio();
        if (esito) {
          isCtrFallito = this.componenteSelImpegniBilancio.ctrStepper('impegno');
        }
        break;
      }

      case 5: {
        const esito = await this._getAzioniBPBilancio();
        if (esito) {
          isCtrFallito = this.componenteSelAzioneBPBilancio.ctrStepper('azione o buona pratica');
        }
        break;
      }

      case 6: {
        const esito = await this._getKpiBilancio();
        if (esito) {
          isCtrFallito = this.componenteSelKPIBilancio.ctrStepper('kpi');
        }
        break;
      }

      default:
        break;
    }
    return isCtrFallito

  }

  /**
    * Viene richiamato nel momento in cui si caricano le tamatiche (questionario)
    */
  public completaStep() {
    if (this.isPubblicato()) {
      this.stepper.steps.forEach((step, idx) => {
        step.completed = true;
      });
    }
  }

}

