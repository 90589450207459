<app-spinner-overlay></app-spinner-overlay>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Sintesi di Sostenibilità"
            subTitolo="Redigi un documento di Sintesi di Sostenibilità per comunicare gli impegni, le buone pratiche, gli impatti e le certificazioni aziendali di sostenibilità. <br>
            <br>
            Puoi creare la tua Sintesi in seguito alla redazione del Bilancio di Sostenibilità, per definire le informazioni più importanti da comunicare all'esterno, o prima di redigere il tuo Bilancio di Sostenibilità, per documentare le prestazioni di sostenibilità dell'organizzazione attraverso un documento più semplice e intuitivo. <br>
            <br>
            Collegando la tua Sintesi di Sostenibilità agli altri moduli potrai usufruire delle informazioni inserite precedentemente.
            "></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri"
        (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="crea()"
                *ngIf="this.utenteService.isPermessoAttivo('CREATE_SINTESI')">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>