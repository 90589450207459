import { trigger, transition, style, animate } from '@angular/animations';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { KPIService } from 'src/app/services/KPI/kpi.service';
import { FigliStdr, StandardRendicontazioneService, STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
    selector: 'app-dialog-targhet-stdr',
    templateUrl: './dialog-targhet-stdr.component.html',
    styleUrls: ['./dialog-targhet-stdr.component.scss'],
    standalone: false
})
export class DialogTarghetStdrComponent implements AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild('accordionStdr') accordionStdr!: MatAccordion;
  @ViewChild('accordionCompliance') accordionCompliance!: MatAccordion;
  public checked = false;

  public arrayStrCheck: STDR[] = [];
  public arrayStdr: STDR[] = [];
  public arrayCompliance: STDR[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogTarghetStdrComponent>,
    private readonly utilityService: UtilityService,
    private readonly tematicaService: TematicheService,
    private readonly kpiService: KPIService,
    private readonly stdrService: StandardRendicontazioneService,
    @Inject(MAT_DIALOG_DATA) public data: { idKpi: string, stdr: STDR[], idsSettori: string[] }
  ) { }

  ngAfterViewInit(): void {
    this.getStdr();
    this.accordionStdr.openAll;
    this.accordionCompliance.openAll;
  }

  salva() {

    let stdrMerged = JSON.parse(JSON.stringify(this.arrayStdr)).filter((padre: any) => {
      if (padre.selected) {
        padre.figli = []; // Se padre è selezionato, imposta figli come array vuoto
      } else if (padre.figli && padre.figli.length > 0) {
        padre.figli = padre.figli.filter((figlio: any) => figlio.selected);
      }
      return (padre.figli && padre.figli.length > 0) || padre.selected;
    });

    let complianceMerged = JSON.parse(JSON.stringify(this.arrayCompliance)).filter((padre: any) => {
      if (padre.selected) {
        padre.figli = []; // Se padre è selezionato, imposta figli come array vuoto
      } else if (padre.figli && padre.figli.length > 0) {
        padre.figli = padre.figli.filter((figlio: any) => figlio.selected);
      }
      return (padre.figli && padre.figli.length > 0) || padre.selected;
    });


    console.log(stdrMerged)
    console.log(complianceMerged)

    const objSalvaStdr = {
      stdr: stdrMerged,
      compliance: complianceMerged
    }
    this.spinnerOver.show();

    this.kpiService.putStdr(this.data.idKpi, objSalvaStdr).subscribe({
      next: (result) => {

        this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });

        this.dialogRef.close(result || []);
        this.spinnerOver.hide();
      },
      error: (err) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel salvataggio del kpi',
          bottoni: [{ nome_btn: 'Chiudi' }]
        })
        console.error(err);
        this.spinnerOver.hide();
      }
    });

  }

  allCompleteStdr(event: any, stdr: STDR) {

    this.allComplete[stdr.id]

  }

  toggleCheckbox(event: any, stdr: STDR) {
    if (!event.checked) {
      stdr.target = false;

      stdr.figli.map(figlio => {
        figlio.target = false;
      })
    }
  }

  toggleCheckboxFigli(event: any, figlio: FigliStdr) {
    if (!event.checked) {
      figlio.target = false;
    }
  }

  public nrSelezionati: number = 0;
  allComplete: { [key: string]: boolean } = {};

  /**
   * 
   * @param stdr 
   * @returns 
   */
  public someComplete(stdr: STDR): boolean {
    if (!stdr.figli) {
      return false;
    }
    this.nrSelezionati = stdr.figli.filter(t => t.selected).length;
    return !!this.nrSelezionati && this.nrSelezionati != stdr.figli.length;
  }

  /**
   * 
   * @param selected 
   * @param stdr 
   * @returns 
   */
  public setAll(selected: boolean, stdr: STDR) {
    if (stdr.figli == null) {
      return;
    }
    if (selected) {
      stdr.figli.forEach(t => (t.selected = selected));
    }
  }

  /**
   * 
   * @param stdr 
   */
  public updateAllComplete(stdr: STDR, figli: FigliStdr) {
    /*   setTimeout(() => {
        this.allComplete[stdr.id] = stdr.figli?.length > 0 && stdr.figli.every(t => t.selected);
  
        if (!this.isStdrPadreDisabled(stdr)) {
          stdr.selected = this.allComplete[stdr.id];
          if (!stdr.selected) {
            stdr.target = false;
          }
        }
  
      }, 0); */

    /*   if (!figli.selected) {
        figli.target = false;
      } */
  }

  slideTarget(stdr: STDR) {
    if (stdr.selected) {

      if (stdr.target) {
        stdr.figli.map(figlio => {
          figlio.target = true;
        })
      } else {
        stdr.figli.map(figlio => {
          figlio.target = false;
        })
      }
    }
  }



  isStdrPadreDisabled(stdr: STDR) {
    return !stdr.generale && stdr.settoriale
  }

  private getStdr() {
    const idKpi = this.data?.idKpi ? this.data?.idKpi : null;
    const isCreazione = !idKpi
    const idsSettori = this.data?.idsSettori ? this.data?.idsSettori : [];

    this.spinnerOver.show();

    this.kpiService.getStdr(idKpi, idsSettori, isCreazione,).subscribe({

      next: ((result: STDR[]) => {
        this.arrayStdr = result.filter(stdr => !stdr.compliance);
        this.arrayCompliance = result.filter(stdr => stdr.compliance);
        this.spinnerOver.hide();
      }),

      error: (err: any) => {
        console.error('Errore durante il caricamento dei stdr:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei stdr',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        this.spinnerOver.hide();
      }
    })
  }
}
