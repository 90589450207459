import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Colonna, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { MatDialog } from '@angular/material/dialog';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { InfoUtente } from 'src/app/services/utente/utente-web.service';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TemaService } from 'src/app/services/tema/tema.service';
import { finalize, Subscription } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { UtilityService } from 'src/app/services/utility/utility.service';


interface DatiBarChart {
  titolo: string,
  utilizzati: number,
  nonUtilizzati: number
}

interface RiepilogoGenerale {
  nomeAzienda: string,
  datiRiepilogoPerSettore: RiepilogoPerSettore[],
}

interface RiepilogoPerSettore {
  nomeSettore: string,
  anno: number,
  statoAutovalutazione: string,
  statoPolicy: string,
  statoBilancio: string,
  statoSintesi: string,
  statoMaterialita: string
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',],
  standalone: false
})

export class DashboardComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('doughnutChartCanvas') doughnutChart: BaseChartDirective<'doughnut'> | undefined;
  @ViewChild('barChartCanvas') barChart: BaseChartDirective<'bar'> | undefined;
  @ViewChildren(BaseChartDirective) charts!: QueryList<BaseChartDirective>;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;


  @ViewChild('tabella') tabellaRiepilogo!: ListaTabellareComponent;

  public infoUtente: InfoUtente | undefined;
  private isUtenteGo: boolean = false;
  public spinnerDoughnutChart: boolean = false;


  public spazioTotaleDisponibile: number = 0
  public spazioTotaleUtilizzato: number = 0

  public colonneRiepilogo: { [key: string]: Colonna } | undefined = undefined;

  public datiBarChart: DatiBarChart[] | undefined = undefined;
  public datiRiepilogoGenerale: RiepilogoGenerale[] | undefined;
  private readonly _subTema: Subscription | undefined;

  constructor(
    public dialog: MatDialog,
    private readonly utenteService: UtenteService,
    private readonly dashboardService: DashboardService,
    private readonly temaService: TemaService,
    private readonly utilityService: UtilityService,
  ) {

    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
      } else {
        this.isUtenteGo = false;
      }
    })

    this.infoUtente = this.utenteService.infoUtente;
  }

  ngOnInit(): void {

    let colonne: any = {
      nomeAzienda: {
        title: 'Azienda',
        value: 'nomeAzienda',
        sortable: true,
      },
      anno: {
        title: 'Anno',
        value: 'anno',
        sortable: true,
      },
      nomeSettore: {
        title: 'Settore',
        value: 'nomeSettore',
        sortable: true,
      },
      statoAutovalutazione: {
        title: 'Assessment',
        value: (record: any) => {
          if (record?.statoAutovalutazione === 'rosso') {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
          } else if (record?.statoAutovalutazione === 'giallo') {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
          } else if (record?.statoAutovalutazione === 'verde') {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
          } else {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#CCDBCD;width:90px;text-align: center;border-radius: 15px;height:27px"><p style="color:#729373;font-size:13px">' + record?.statoAutovalutazione + '</p></div>'
          }
        },
        sortable: true
      },
    };

    if (!this.isUtenteGo) {
      colonne.statoMaterialita = {
        title: 'Materialità',
        value: (record: any) => this.ctrRiepilogoColore(record.statoMaterialita),
        sortable: true
      };

      colonne.statoPolicy = {
        title: 'Policy',
        value: (record: any) => this.ctrRiepilogoColore(record.statoPolicy),
        sortable: true
      };

      colonne.piano = {
        title: 'Piano',
        value: (record: any) => this.ctrRiepilogoColore(record.statoPiano),
        sortable: true
      };

      colonne.statoSintesi = {
        title: 'Sintesi',
        value: (record: any) => this.ctrRiepilogoColore(record.statoSintesi),
        sortable: true
      };
    }

    colonne.statoBilancio = {
      title: this.isUtenteGo ? 'Report Tematico' : 'Bilancio',
      value: (record: any) => this.ctrRiepilogoColore(record.statoBilancio),
      sortable: true
    }

    this.colonneRiepilogo = colonne;
  };


  ngAfterViewInit(): void {

    this.getInfoLicenze();
    this.getDoughnutChart();

  }
  ngOnDestroy(): void {
    this._subTema?.unsubscribe();
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.dashboardService.getRiepilogoGenerale(page, pageSize, ricerca, filters, sortBy);
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          color: 'black',
          font: {
            weight: 'bold'
          }
        }
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 1,
          color: 'black',
          font: {
            weight: 'bold'
          }
        }
      },

    },
    plugins: {
      legend: {
        display: true,
        align: 'end',
      },

    },
  };
  public barChartType = 'bar' as const;

  public barChartData: ChartData<'bar'> = {

    labels: [],
    datasets: [
      {
        data: [],
        label: 'Non Utilizzati',
        backgroundColor: '#A5DFC6',
        borderColor: '#77CF7A',
        borderWidth: 1

      },
      {
        data: [],
        label: 'Utilizzati',
        backgroundColor: '#FFB1C1',
        borderColor: '#FF6384',
        borderWidth: 1

      }
    ],
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void { }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
  }

  updateChart(chartType: string): void {
    const chart = this.charts.find((chart: any) => chart.chart?.config.type === chartType);
    chart?.update();
  }

  /**
   * Metodo che setta i dati iniziali del grafico per Disponibilità Pacchetti 
   */
  private _setDatiBarChart() {
    if (this.datiBarChart) {
      this.datiBarChart.forEach((data: DatiBarChart | null) => {
        if (data) {
          this.barChartData.labels?.push(data.titolo);
          (this.barChartData.datasets[1].data as number[]).push(data.utilizzati);
          (this.barChartData.datasets[0].data as number[]).push(data.nonUtilizzati);
        }
      });
      this.updateChart(this.barChartType)
    }
  }

  private getInfoLicenze() {
    if (this.infoUtente?.utente.id) {
      this.dashboardService.getInfoLicenze(this.infoUtente?.utente.id).subscribe({
        next: (esito) => {
          this.datiBarChart = esito.datiBarCharts;
          this._setDatiBarChart();
        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }
  convertiBytesToGigabytes(bytes: number) {
    const gigabytes: number = bytes / (1024 ** 3); // Converti byte in gigabyte
    return parseFloat(gigabytes.toFixed(1));

  }

  ctrRiepilogoColore(statoContesto: string) {
    if (statoContesto === 'Completata') {
      return '<div style="font-weight: bold; color: var(--mat-color-primary)">' + statoContesto + '</div>';
    }
    if (statoContesto === 'Iniziata') {
      return '<div style="font-weight: bold; color:var(--mat-color-accent)">' + statoContesto + '</div>';
    } else {
      return statoContesto;
    }

  }

  /**
   * Metodo che mi converte in base alla quantita di bytes nell'unita di misura corrispondente 
   * @param bytes 
   * @returns 
   */
  formatBytes(bytes: number): string {
    if (bytes <= 0) return '0 Bytes'; // Controllo per valori negativi o zero

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(1024)); // Trova l'indice dell'unità giusta

    // Dividi i bytes per 1024 elevato all'indice trovato
    let value = bytes / Math.pow(1024, i);

    // Per i Gigabytes torno un decimale altrimenti no 
    if (sizes[i] === 'GB') {
      return `${value.toFixed(1)} ${sizes[i]}`;
    } else {
      return `${value.toFixed(0)} ${sizes[i]}`;
    }
  }

  ctrSpazioEsaurito(esito: any) {
    const ruoliDaControllare = ['utente_premium_go', 'utente_premium', 'professionista', 'collaboratore', 'collaboratore_go']

    if (esito.fullUsage >= esito.fullStorage && ruoliDaControllare.includes(this.infoUtente?.utente?.ruolo?.roleName as string)) {

      const descrizioneElimina = '<div> <p><strong>Spazio di archiviazione in esaurimento. Contatta il tuo fornitore.</strong></p>'
      this.utilityService.openDialog({
        titolo: 'Spazio di Archiviazione Esaurito',
        descrizione: descrizioneElimina,
        fontWeight: '500',
        bottoni: [
          {
            nome_btn: 'Chiudi', handler: () => {
              this.utilityService.closeDialog();
            }
          }]
      })

    }

  }

  /**
   * Mi prende i valori del ultimo aggiornamento fatto o del ultima volta che lo
   * schedulatore è stato lanciato.
   * Dati non del tutto aggiornati, verrano aggioranti al prossimo lancio dello
   * schedulatore o del aggiornamento grafico manuale 
   * Modifica richiesta per una gestione più fluida e veloce del grafico [ vedi javadoc del metodo di aggiornamento grafico aggiornaDoughnutChart() ] 
   */
  private getDoughnutChart() {

    if (this.infoUtente?.utente.id) {
      this.dashboardService.getDatiDoughnutChart(this.infoUtente?.utente.id).subscribe({
        next: (esito) => {

          this.ctrSpazioEsaurito(esito)

          // Setto i dati del grafico 
          this.setDatiDoughnutChart(esito);

        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }

  /**
   * Metodo che aggiorna il grafico di spazio di archiviazione
   * Farebbe quello che fa lo schedulatore per ricalcolare lo spazio,
   * Dato che fa delle logiche di calcolo abbastanza pesanti (lettura di n* collection)
   * Si è optato di costruire uno schedulatore lato BE, inoltre dare la possibilità di eseguire la 
   * stessa logica di calcolo richiamando un EP lato FE.
   */
  public aggiornaDoughnutChart() {
    this.spinnerDoughnutChart = true
    if (this.infoUtente?.utente.id) {
      this.dashboardService.aggiornaDatiDoughnutChart(this.infoUtente?.utente.id)
        .pipe(finalize(() => this.spinnerDoughnutChart = false))
        .subscribe({
          next: (esito) => {

            // Setto i dati del grafico 
            this.setDatiDoughnutChart(esito);

          },
          error: (err) => {
            console.error(err);
          }
        })
    }

  }

  /**
   * Metodo che mi setta i valori per la visualizzaizone del chart doughnut
   */
  private setDatiDoughnutChart(esito: any) {

    // Mi valorizzo i dati con il valore in bytes, per avere la possibilità di convertirli in tutte le unità di misura 
    this.datiDoughnutChart.availableUsage.valore = esito.availableUsage;
    this.datiDoughnutChart.databaseUsage.valore = esito.databaseUsage;
    this.datiDoughnutChart.storageUsage.valore = esito.storageUsage;
    this.datiDoughnutChart.fullStorage.valore = esito.fullStorage;

    // Mi valorizzo il disponibile e il totale utilizzato in Gigabytes per mostrarli nel centro dell grafico 
    this.spazioTotaleDisponibile = this.convertiBytesToGigabytes(esito.fullStorage);
    this.spazioTotaleUtilizzato = this.convertiBytesToGigabytes(esito.fullUsage);

    // Mi creo il plugin per la visualizzazione dei dati utilizzato/disponibile nel centro del grafico 
    this.doughnutChartPlugins = [this.returnObjectDoughnutChartPlugins(this.spazioTotaleUtilizzato, this.spazioTotaleDisponibile)];

    // Inizio a settare i dati sul oggetto del grafico per la visualizzazione corretta 
    if (this.datiDoughnutChart) {
      // Reset doughnutChartData per evitare duplicazioni
      this.doughnutChartData.labels = [];
      const data: number[] = [];
      const backgroundColors: string[] = [];

      // Specifico esplicitamente le chiavi valide di datiDoughnutChart
      const usageTypes: Array<'availableUsage' | 'storageUsage' | 'databaseUsage'> = [
        'availableUsage',
        'storageUsage',
        'databaseUsage',
      ];

      // Mi valorizzo l'oggetto per la visualizzazioine ( il valore di ogni oggetto in Gigabytes )
      usageTypes.forEach((usageType) => {
        const usage = this.datiDoughnutChart[usageType];
        if (usage) {
          this.doughnutChartData.labels?.push(usage.titolo);
          data.push(this.convertiBytesToGigabytes(usage.valore));
          backgroundColors.push(usage.colore);
        }
      });

      // Aggiorna i datasets e il colore del grafico
      this.doughnutChartData.datasets[0].data = data;
      this.doughnutChartData.datasets[0].backgroundColor = backgroundColors;

      // Aggiorna il grafico
      this.updateChart(this.doughnutChartType)
    }
  }

  public doughnutChartType = 'doughnut' as const;



  /*  Dati json per mappare la legenda e valori di doughnutChartData. 
      Il campo valore viene valorizzato dal esterno  */
  public datiDoughnutChart = {
    availableUsage: { // disponibilità
      titolo: 'Disponibilà',
      valore: 0,
      colore: '#A5DFC6'
    },
    /*   fullUsage: {
        titolo: 'Totale Utilizzato',
        valore: 0,
        colore: '#FF6384'
      }, */
    databaseUsage: {
      titolo: 'Utilizzo Database',
      valore: 0,
      colore: '#70D4FF'
    },
    storageUsage: {
      titolo: 'Utilizzo Cloud',
      valore: 0,
      colore: '#FF6384'
    },
    fullStorage: {
      titolo: 'Totale',
      valore: 0,
      colore: '#CF5B61'
    },
  }

  // Oggetto json per mappare il grafico doughnut
  public doughnutChartData: ChartData<'doughnut'> = {
    labels: [

    ],
    datasets: [{
      label: 'Gigabytes',
      data: [
      ],
      backgroundColor: [

      ],
      hoverOffset: 4
    }]
  };


  /* Plugin per Doughnut chart */
  public doughnutChartPlugins = [this.returnObjectDoughnutChartPlugins(this.spazioTotaleUtilizzato, this.spazioTotaleDisponibile)];

  /**
   * Metodo che mi crea un oggetto plugin per il grafico Doughnut
   * Gestito in questa maniera solamente per poter inserire i valori al centro del grafico
   * @param datiDoughnutChart 
   * @returns 
   */
  public returnObjectDoughnutChartPlugins(spazioTotaleUtilizzato: number, spazioTotaleDisponibile: number) {
    return {
      id: 'textCenter',
      afterDraw(chart: any) {
        const txt1 = 'Spazio Utilizzato'
        const txt2 = spazioTotaleUtilizzato + '/' + spazioTotaleDisponibile + ' Gb';

        const ctx = chart.ctx;

        // Posizioni gli elementi al centro del grafico.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

        // Configuro il font per la visualizzazione.
        const fontSizeToUse = 14;
        ctx.font = fontSizeToUse + 'px Sora';
        ctx.fillStyle = 'black';

        // Creo il testo nella posizione centrale 
        ctx.fillText(txt2, centerX, centerY - 10);
        const fontSizeToUse1 = 10;
        ctx.font = fontSizeToUse1 + 'px Sora';
        ctx.fillText(txt1, centerX, centerY + 10);

      }
    }
  }
}

