import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class RadioGroupComponent implements ControlValueAccessor {
  @Input() options: { label: string; value: any }[] = [];
  @Input() label: string = '';
  @Input() disabled: boolean = false;

  radioControl = new FormControl();
  
  onChange = (value: any) => { };
  onTouched = () => { };

  constructor() {
    // Monitoraggio dei cambiamenti per aggiornare il form esterno
    this.radioControl.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  // Implementazione di writeValue
  writeValue(value: any): void {
    this.radioControl.setValue(value, { emitEvent: false });
  }

  // Implementazione di registerOnChange
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Implementazione di registerOnTouched
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Abilita/disabilita l'input
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.radioControl.disable();
    } else {
      this.radioControl.enable();
    }
  }

  // Gestione della selezione manuale
  selectValue(value: any) {
    this.radioControl.setValue(value);
  }
}
