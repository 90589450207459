import { Component } from '@angular/core';
import { SelezioneElementiQuestionarioComponent } from '../../selezione-elementi-questionario.component';
import { DialogCreaCfgAzioneEBuonaPraticaComponent } from 'src/app/page/configurazioni/cfg-azione-e-buona-pratica/dialog/dialog-crea-cfg-azione-e-buona-pratica/dialog-crea-cfg-azione-e-buona-pratica.component';
import { of, Observable, map, catchError, tap, firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-selezione-azioni-buonepratiche-sintesi',
    templateUrl: '../../selezione-elementi-questionario.component.html',
    styleUrls: ['../../selezione-elementi-questionario.component.scss'],
    standalone: false
})
export class SelezioneAzioniBuonepraticheSintesiComponent extends SelezioneElementiQuestionarioComponent {



  override editElementoListaChk(idAmbito: string, idTematica: string, azioneBP?: any): void {
    const dialogCreaAzioneBP = this.dialog.open(DialogCreaCfgAzioneEBuonaPraticaComponent, {
      data: {
        azioneBP: azioneBP,
        idContest: this.idContest,
        idAmbito: idAmbito,
        idTematica: idTematica,
        contesto: 'sintesi'
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '75%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaAzioneBP.afterClosed().subscribe((result) => {
       
      if (result) {
        this.spinnerOver.show();
        this.salvataggio(idAmbito, idTematica, azioneBP?.id);
      }
    });
  }

  async salvataggio(idAmbito: string, idTematica: string, idAzioneBP: string) {
    try {
      // Salvo i dati modificati
      await firstValueFrom(this.salva(true));

      // Get dei dati aggiornati 
      await firstValueFrom(this._getAzioniBPSintesi());

      // Scroll all'elemento modificato 
      setTimeout(() => {
        this.scrollToElement(idAmbito, idTematica, idAzioneBP);
      }, 0);
    } catch (error) {
      console.error(error);
    }
  }

  override addElementoListaChk(idAmbito: string, idTematica: string): void {
    // posso passare la stessa funzione poichè la gestione è identica, Cambia solameanete il passaggio del azioneBP 
    // a vuoto nella creazione.
    this.editElementoListaChk(idAmbito, idTematica);
  }

  override salvaFigAz(idAmbito: string, idTematica: string, idAzioneBP: string, idFigAziendale: string): void {
    this.spinnerOver.show();
    this.sintesiService.putAssegnaFiguraAzioniBPSintesi(this.idContest, idAmbito, idTematica, idAzioneBP, idFigAziendale).subscribe({
      next: (esito) => {
        this.salvataggio(idAmbito, idTematica, idAzioneBP)
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }


  private _getAzioniBPSintesi() {
    this.spinnerOver.show();

    return this.sintesiService.getAzioneBPSintesi(this.idContest).pipe(
      tap((result) => {
        this.objQuestionario = result;
      }),
      catchError(() => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle azioni e buone pratiche',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(null);
      }),
      tap({
        finalize: () => this.spinnerOver.hide()
      })
    );
  }

  salva(noCtr?: boolean) {

    let azioneBPSelezionate = this.oggettoElementiSelezionati();

    if (!noCtr) {
      //Controllo se è stato selezionato almeno un azione buona pratica per tematica 
      if (this.ctrElemChecked(azioneBPSelezionate, 'azione o buona pratica')) {
        return of(false);
      }
    }

    return this._salvaSurveyMaterialita(azioneBPSelezionate);
  }

  private _salvaSurveyMaterialita(azioneBPSelezionate: any): Observable<boolean> {

    if (this.idContest) {

      return this.sintesiService.putAzioneBPSintesiSelezionati(
        this.idContest, azioneBPSelezionate).pipe(
          map((result: any) => {
             

            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();

            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore salvataggio (sconosciuto)',
              bottoni: [{ nome_btn: 'Ok' }]
            });
            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }


}
