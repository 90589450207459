import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import is from 'date-fns/esm/locale/is/index.js';
import { distinctUntilChanged, Subscription } from 'rxjs';


export interface Padre {
  id: string
  selected: boolean;
  nomePadre: string,
  descrizionePadre: string
  figli: Figli[]
  disabledPadre?: boolean;
}

export interface Figli {
  id: string
  selected: boolean;
  nomeFiglio: string,
  descrizioneFiglio: string
}


@Component({
    selector: 'app-select-custom',
    templateUrl: './select-custom.component.html',
    styleUrls: ['./select-custom.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectCustomComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class SelectCustomComponent implements ControlValueAccessor, AfterViewInit {

  @Input() titolo: string = '';
  @Input() nascondiFigli: boolean = false;

  @Input()
  set disabled(disabled) {
    if (disabled) {
      this.selectFormControl.disable();
    } else {
      this.selectFormControl.enable();
    }
  }

  get disabled() {
    return this.selectFormControl.disabled;
  }

  public padri = new FormArray<any>([])
  selectFormControl = new FormControl<string[]>([]);

  isFigliExpand: boolean[] = [];

  private _subValueChangePadre: Subscription | undefined = undefined;
  private _subValueChangeFiglio: Subscription | undefined = undefined;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdRef: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges(); // Forza il rilevamento delle modifiche
  }
  ngOnDestroy(): void {
    this._subValueChangePadre?.unsubscribe();
    this._subValueChangeFiglio?.unsubscribe();

  }

  onChange = (value: any) => { };
  onTouched = () => { };


  // Implementazione di writeValue
  writeValue(value: Padre[]): void {
    this.padri = this.creaformArray(value)

    if (value.length) {
      this._init(value);
    }

  }

  // Implementazione di registerOnChange
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Implementazione di registerOnTouched
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Metodo che mi espande i figli del padre 
   * @param padre 
   */
  expandFigli(padre: any) {
    this.isFigliExpand[padre.nomePadre] = !this.isFigliExpand[padre.nomePadre];
  }


  /**
   *  Metodo che mi riempie la input field con gli elementi selezionati.
   * @param value 
   */
  private _init(value: Padre[]) {
    this.selectFormControl.reset();

    value.forEach(padre => {
      this.riempiInput(padre.nomePadre, padre.selected)
      if (padre.figli.length && !this.nascondiFigli) {
        padre.figli.forEach(figlio => {
          this.riempiInput(figlio.nomeFiglio, figlio.selected)
        })
      }
    })
  }

  /**
   * Metodo che mi crea un form array di padri, ogni padre sarà un form group
   * @param oggettoSelect Oggetto che arriva dal esterno
   * @returns 
   */
  creaformArray(oggettoSelect: Padre[]): FormArray {

    let formArr: any[] = [];

    oggettoSelect.forEach(padre => {
      // Aggiungo un nuovo FormGroup (creato con createPadre) al FormArray
      formArr.push(this.createPadre(padre));
    });

    return this.fb.array(formArr)
  }

  /**
   * Metodo che mi crea un form group per ogni padre
   * @param padre 
   * @returns 
   */
  createPadre(padre: any): FormGroup {

    const groupConfig: { [key: string]: any } = {};

    Object.keys(padre).forEach(key => {


      // Se la chiave è 'figli', costruisco un FormGroup del figlio per aggiungerlo al Form Array
      if (key === 'figli') {
        groupConfig[key] = this.fb.array(padre[key].map((t: any) => this.createFigli(t, padre?.selected)));

      } else if (key === 'selected') {

        groupConfig[key] = new FormControl({ value: padre[key as keyof typeof padre], disabled: padre.disabledPadre });

        // Monitoraggio dei cambiamenti per aggiornare il form esterno
        this._subValueChangePadre = groupConfig[key].valueChanges
          .pipe(
            distinctUntilChanged()
          ).subscribe((isSelected: boolean) => {
            this.aggiornaValoreCheck();
            this.riempiInput(padre.nomePadre, isSelected);

            /* Seleziono o deseleziono tutti i figli solamente se sono tutti deselezionati o selezionati
            se sono indeterminate allora non faccio nulla  */
            /*  if (!this.someComplete(groupConfig[key].parent)) { */
            this.allComplete(groupConfig[key].parent, isSelected);
            /*  } */
          });

      } else {
        // Altrimenti, creo un FormControl per gli altri campi
        groupConfig[key] = new FormControl(padre[key as keyof typeof padre]);
      }
    });

    return this.fb.group(groupConfig);

  }

  /**
   * Metodo che mi crea un form group per ogni figlio
   * @param figlio 
   * @returns 
   */
  createFigli(figlio: any, padreSelezionato: any): FormGroup {
    const groupConfig: { [key: string]: any } = {};
    console.log(padreSelezionato)
    Object.keys(figlio).forEach(key => {

      /*     groupConfig[key] = new FormControl(figlio[key]); */
      groupConfig[key] = new FormControl({ value: figlio[key], disabled: padreSelezionato }); // se il padre è selezionato allora disabilito i figli 

      if (key === 'selected') {
        // Monitoraggio dei cambiamenti per aggiornare il form esterno
        this._subValueChangeFiglio = groupConfig[key].valueChanges
          .pipe(
            distinctUntilChanged()
          )
          .subscribe((isSelected: boolean) => {
            this.aggiornaValoreCheck();
            if (!this.nascondiFigli) {
              this.riempiInput(figlio.nomeFiglio, isSelected);
            }

            const padre = groupConfig[key].parent.parent.parent;
            if (!padre.get('disabledPadre')?.value) {

              /* Aggiugnere un parametro per differenziare i controlli generali con quello espliciti dove viene usato il componente
                Per adesso non p richiesto che selezionati tutti i figli anche il padre debba essere selezionato in automatico
              */
              /*  setTimeout(() => {
                 padre.get('selected')?.setValue(this.checkFigliAllComplete(padre));
               }, 0); */

            }
          });
      }

    });

    return this.fb.group(groupConfig);
  }

  /**
   * Monitoraggio dei cambiamenti per aggiornare il form esterno
   */
  aggiornaValoreCheck() {

    this.onChange(this.padri.getRawValue());
    this.onTouched();
  }

  /**
   * Metodo che mi riempie l'input field da visualizzare gli elementi selezionati nelle checkbox
   * @param codice codice del padre o figlio da visualizzare 
   * @param isSelected se è stato selezionato o meno (checkatto)
   * @returns 
   */
  riempiInput(codice?: string, isSelected?: boolean): void {
    if (!codice) {
      return;
    }

    const currentValues = this.selectFormControl.value || [];

    if (isSelected) {
      // Aggiungo il codice se il padre o il figlio è stato selezionato e non è già presente
      if (!currentValues.includes(codice)) {
        this.selectFormControl.setValue([...currentValues, codice]);
      }
    } else {
      // Rimuovo il codice se il padre o il figlio è stato deselezionato 
      this.selectFormControl.setValue(currentValues.filter(elemento => elemento !== codice));
    }
  }

  /**
   * Metodo che mi seleziona o deseleziona tutti i figli del padre
   * @param padre 
   * @param isSelected seleziona o deseleziona 
   * @returns 
   */
  allComplete(padre: FormGroup, isSelected: boolean) {

    (padre.get('figli') as FormArray)?.controls.forEach(figlio => {
      if (isSelected) {
        figlio.get('selected')?.setValue(isSelected)
        figlio.get('selected')?.disable();
      } else {
        figlio.get('selected')?.enable();
      }

    });
  }

  someComplete(padre: FormGroup): boolean {
    const figliControls = ((padre.get('figli') as FormArray).controls as FormGroup[])

    const isIndeterminate = figliControls.filter((t: FormGroup) => (t.get('selected') as FormControl)?.value).length > 0 && !this.checkFigliAllComplete(padre);

    return isIndeterminate
  }

  checkFigliAllComplete(padre: FormGroup) {
    const figliControls = ((padre.get('figli') as FormArray).controls as FormGroup[])
    const isFigliAllComplete = figliControls.every((t: FormGroup) => (t.get('selected') as FormControl).value);

    return isFigliAllComplete;
  }


  onKeyDown(event: KeyboardEvent): void {
    event.preventDefault(); // Non faccio scrivere dentro l'input
  }

}
