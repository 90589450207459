import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { StandardRendicontazioneService } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';
import { of } from 'rxjs';
import { DialogCreaStandardRendicontazioneComponent } from './dialog/dialog-crea-standard-rendicontazione/dialog-crea-standard-rendicontazione.component';

@Component({
    selector: 'app-cfg-standard-rendicontazione',
    templateUrl: './cfg-standard-rendicontazione.component.html',
    styleUrls: ['./cfg-standard-rendicontazione.component.scss'],
    standalone: false
})
export class CfgStandardRendicontazioneComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Codice',
      forControlName: 'codice',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    codice: {
      title: 'Codice',
      value: 'codice',
      sortable: true
    },
    descrizione: {
      title: 'Descrizione',
      value: 'descrizione',
      sortable: true
    },
    compliance: {
      title: 'Compliance Normativi',
      value: (record: any) => (record?.compliance ? 'Si' : 'No'),
      sortable: true
    },
    generale: {
      title: 'Generale',
      value: (record: any) => (record?.generale ? 'Si' : 'No'),
      sortable: true
    },
    settoriale: {
      title: 'Settoriale',
      value: (record: any) => (record?.settoriale ? 'Si' : 'No'),
      sortable: true
    },
    obbligatorio: {
      title: 'Obbligatorio',
      value: (record: any) => (record?.obbligatorio ? 'Si' : 'No'),
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };
  constructor(
    public dialog: MatDialog,
    private readonly stdrService: StandardRendicontazioneService,
    private readonly utilityService: UtilityService,
    public utenteService: UtenteService
  ) { }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  creaNuovoStdr(recordStdr?: any) {
    const dialogCreaStdr = this.dialog.open(DialogCreaStandardRendicontazioneComponent, {
      data: {
        stdr: recordStdr,
      },
      panelClass: ['dialog-container' ],
      disableClose: false,
      width: '80%',
      height: '95%',
      autoFocus: false,
    });

    dialogCreaStdr.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [
        {
          chiave: 'index',
          sort: 'asc',
        },
        {
          chiave: 'titoloBreve',
          sort: 'asc'
        }];
    }

    return this.stdrService.getStdr(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovoStdr(event.data);
        break;
      case 'elimina':
        this._eliminaStdr(event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare 
   * @param idRiga id della riga da eliminare 
   */
  private _eliminaStdr(idRiga: any) {
    const descrizioneElimina = '<div> <p><strong>Confermi di voler eliminare lo standard di rendicontazione?</strong></p> <p>Una volta eliminato, lo standard verrà rimosso da tutti i contesti in cui è stato utilizzato, ad eccezione dei moduli già consolidati.</p></div>';

    this.utilityService.openDialog({
      descrizione: descrizioneElimina,
      fontWeight: '500',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.stdrService.deleteStdr(idRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err?.error?.message || 'Eliminazione non riuscita',
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }

}
