import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
    selector: 'app-utenti',
    templateUrl: './utenti.component.html',
    styleUrls: ['./utenti.component.scss'],
    standalone: false
})
export class UtentiComponent {

  constructor(
    public dialog: MatDialog,
  ) { }

}
