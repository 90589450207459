<app-spinner-overlay></app-spinner-overlay>
<div class="contenitore">

    <div class="titolo-pagina">

        <app-titolo-subtitolo titolo="Figure Aziendali"
            subTitolo="Crea e configura le figure aziendali coinvolte nella gestione delle tematiche di sostenibilità delle aziende.<br>
            Nei moduli di Sostenibile.cloud potrai assegnare attività di compilazione specifiche ad ognuna delle figure individuate."></app-titolo-subtitolo>

    </div>

    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [maxNrButton]="1"
        *ngIf="idAzienda" [filtri]="filtri" (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button mat-flat-button class="primary-button" color="primary" (click)="creaNuovo()" *ngIf="this.utenteService.isPermessoAttivo('CREATE_FIGURA_AZ')">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>