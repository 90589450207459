import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { Utente } from 'src/app/services/utente/utente.service';

@Component({
    selector: 'app-dialog-vis-aziende',
    templateUrl: './dialog-vis-aziende.component.html', 
    styleUrls: ['./dialog-vis-aziende.component.scss'],
    standalone: false
})
export class DialogVisAziendeComponent {

  public id_azienda: string[] | undefined = undefined;
  public utente: Utente | undefined = undefined;

  constructor(
    public aziendaService: AziendaService,
    public dialogRef: MatDialogRef<DialogVisAziendeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idAzienda: string[], utente: Utente }
  ) {
   
    this.id_azienda = data.idAzienda;
    this.utente = data.utente;
  }
  ngOnInit(): void {
    this.aziendaService.setNrChiamataDialogAzd(1);
}
}
