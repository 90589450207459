import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-widget-dashboard-chart',
    templateUrl: './widget-dashboard-chart.component.html',
    styleUrls: ['./widget-dashboard-chart.component.scss'],
    standalone: false
})
export class WidgetDashboardChartComponent {
  private _titolo: string = '';
  private _colore: string = '';
  private _maxValue: number | undefined;
  private _value: number | undefined;
  public coloreRGB: string = '';
  public coloreFreccia: string = '';
  public iconaFreccia: string = '';
  public _valoreFreccia: number | undefined;
  public isMaxValue: boolean = true;

  /**
   * Funzione che controlla il valore della freccia e valorizza i parametri da passare
   * in widget-dashboard-chart.component.html / scss
   * @param valoreFreccia il valore della freccia
   * @returns colore se positivo o negativo e freccia su o giu
   */
  parametriFreccia(valoreFreccia: number) {
    let risultato = { colore: 'red', icona: 'arrow_downward' };

    if (valoreFreccia > 50) {
      risultato.colore = 'green';
      risultato.icona = 'arrow_upward';
    }
    return risultato;
  }

  /**
   * Funzione che transforma un colore in esadecimale in rgb
   * @param hex colore in esadecimale
   * @returns colore in rgb
   */
  hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? parseInt(result[1], 16) +
          ',' +
          parseInt(result[2], 16) +
          ',' +
          parseInt(result[3], 16)
      : '255, 255, 255';
  }

  @Input()
  /* TITOLO */
  set titolo(titolo: string) {
    this._titolo = titolo;
  }
  get titolo() {
    return this._titolo;
  }

  @Input()
  /* COLORE */
  set colore(colore: string) {
    this._colore = colore;
    this.coloreRGB = this.hexToRgb(this._colore);
  }
  get colore() {
    return this._colore;
  }

  @Input()
  /* MAX-VALUE */
  set maxValue(maxValue: number) {
    this._maxValue = maxValue;
    if(this._maxValue < 100){
      this.isMaxValue = false;
    }
  }
  get maxValue(): number | undefined {
    return this._maxValue;
  }

  @Input()
  /* VALUE */
  set value(value: number) {
    this._value = value;
  }
  get value(): number | undefined {
    return this._value;
  }

  @Input()
  /* Valore freccia */
  set valoreFreccia(valoreFreccia: number) {
    this._valoreFreccia = valoreFreccia;
    const risultatoParametriFreccia = this.parametriFreccia(
      this._valoreFreccia
    );
    this.coloreFreccia = risultatoParametriFreccia.colore;
    this.iconaFreccia = risultatoParametriFreccia.icona;
  }

  get valoreFreccia(): number | undefined {
    return this._valoreFreccia;
  }
}
