import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { HomeComponent } from './page/home/home.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { AutovalutazioniComponent } from './page/autovalutazioni/autovalutazioni.component';
import { StatisticheComponent } from './page/statistiche/statistiche.component';
import { AziendeComponent } from './page/aziende/aziende.component';
import { authGuard } from './guards/auth.guard';
import { DashboardAziendaComponent } from './page/dashboard-azienda/dashboard-azienda.component';
import { CfgAutovalutazioniComponent } from './page/configurazioni/cfg-autovalutazioni/cfg-autovalutazioni.component';
import { UtentiComponent } from './page/utenti/utenti.component';
import { CfgAmbitiComponent } from './page/configurazioni/cfg-ambiti/cfg-ambiti.component';
import { CfgTematicheComponent } from './page/configurazioni/cfg-tematiche/cfg-tematiche.component';
import { MaterialitaComponent } from './page/analisi-materialita/materialita/materialita.component';
import { StakeholderComponent } from './page/analisi-materialita/stakeholder/stakeholder.component';
import { CfgCategorieStakeholderComponent } from './page/configurazioni/cfg-categorie-stakeholder/cfg-categorie-stakeholder.component';
import { aziendaGuard } from './guards/azienda.guard';
import { CfgScritturaImpattoComponent } from './page/configurazioni/cfg-scrittura-impatto/cfg-scrittura-impatto/cfg-scrittura-impatto.component';
import { CfgImpattiComponent } from './page/configurazioni/cfg-impatti/cfg-impatti.component';
import { CfgSettoriComponent } from './page/configurazioni/cfg-settori/cfg-settori.component';
import { SurveyEsternoComponent } from './page/survey-esterno/survey-esterno.component';
import { FigureAziendeComponent } from './page/aziende/figure-aziende/figure-aziende.component';
import { CfgImpegniComponent } from './page/configurazioni/cfg-impegni/cfg-impegni.component';
import { CfgAzioneEBuonaPraticaComponent } from './page/configurazioni/cfg-azione-e-buona-pratica/cfg-azione-e-buona-pratica.component';
import { BilancioSostebilitaComponent } from './page/bilancio-sostebilita/bilancio-sostebilita.component';
import { SintesiSostenibilitaComponent } from './page/sintesi-sostenibilita/sintesi-sostenibilita.component';
import { AutovalutazioneEsternoComponent } from './page/autovalutazione-esterno/autovalutazione-esterno.component';
import { CfgUnitaDiMisuraComponent } from './page/configurazioni/cfg-unita-di-misura/cfg-unita-di-misura.component';
import { CfgKPIComponent } from './page/configurazioni/cfg-kpi/cfg-kpi.component';
import { PasswordDimenticataComponent } from './page/password-dimenticata/password-dimenticata.component';
import { SintesiSostenibilitaEsternaComponent } from './page/sintesi-sostenibilita-esterna/sintesi-sostenibilita-esterna.component';
import { CfgInformativaAziendaleComponent } from './page/configurazioni/cfg-informativa-aziendale/cfg-informativa-aziendale.component';
import { InformativaAziendaleEsternaComponent } from './page/informativa-aziendale-esterna/informativa-aziendale-esterna.component';
import { CfgCertificazioniComponent } from './page/configurazioni/cfg-certificazioni/cfg-certificazioni.component';
import { BilancioSostenibilitaEsternaComponent } from './page/bilancio-sostenibilita-esterna/bilancio-sostenibilita-esterna.component';
import { PolicySostenibilitaComponent } from './page/policy-sostenibilita/policy-sostenibilita.component';
import { CfgComitatiComponent } from './page/configurazioni/cfg-comitati/cfg-comitati.component';
import { CfgDestinatariPolicyComponent } from './page/configurazioni/cfg-destinatari-policy/cfg-destinatari-policy.component';
import { CfgPerimetroApplicazionePolicyComponent } from './page/configurazioni/cfg-perimetro-applicazione-policy/cfg-perimetro-applicazione-policy.component';
import { PolicySostenibilitaEsternaComponent } from './page/policy-sostenibilita-esterna/policy-sostenibilita-esterna.component';
import { CfgLineeGuidaStampeComponent } from './page/configurazioni/cfg-linee-guida-stampe/cfg-linee-guida-stampe.component';
import { PianoSostenibilitaComponent } from './page/piano-sostenibilita/piano-sostenibilita.component';
import { CfgObiettiviPianoComponent } from './page/configurazioni/cfg-obiettivi-piano/cfg-obiettivi-piano.component';
import { PianoSostenibilitaEsternaComponent } from './page/piano-sostenibilita-esterna/piano-sostenibilita-esterna.component';
import { CfgObiettiviAziendaliComponent } from './page/configurazioni/cfg-obiettivi-aziendali/cfg-obiettivi-aziendali.component';
import { CfgModelliSviluppoPianoComponent } from './page/configurazioni/cfg-modelli-sviluppo-piano/cfg-modelli-sviluppo-piano.component';
import { CfgStandardRendicontazioneComponent } from './page/configurazioni/cfg-standard-rendicontazione/cfg-standard-rendicontazione.component';
import { MainPageComponent } from './page/main-page/main-page.component';
import { ResetPasswordComponent } from './page/reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'password-dimenticata', component: PasswordDimenticataComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'attiva-account', component: ResetPasswordComponent },
  { path: 'compilazione-survey', component: SurveyEsternoComponent },
  { path: 'compilazione-autovalutazione', component: AutovalutazioneEsternoComponent },
  { path: 'compilazione-sintesi-sostenibilita', component: SintesiSostenibilitaEsternaComponent },
  { path: 'compilazione-bilancio-sostenibilita', component: BilancioSostenibilitaEsternaComponent },
  { path: 'compilazione-policy-sostenibilita', component: PolicySostenibilitaEsternaComponent },
  { path: 'compilazione-piano-sostenibilita', component: PianoSostenibilitaEsternaComponent },
  { path: 'compilazione-informativa-aziendale', component: InformativaAziendaleEsternaComponent },
  { path: 'main-page', component: MainPageComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'dashboard-azienda', component: DashboardAziendaComponent },
      { path: 'materialita', component: MaterialitaComponent, canActivate: [aziendaGuard] },
      { path: 'stakeholder', component: StakeholderComponent, canActivate: [aziendaGuard] },
      { path: 'bilanci', component: BilancioSostebilitaComponent, canActivate: [aziendaGuard] },
      { path: 'sostenibilita', component: SintesiSostenibilitaComponent, canActivate: [aziendaGuard] },
      { path: 'statistiche', component: StatisticheComponent },
      { path: 'assessment', component: AutovalutazioniComponent, canActivate: [aziendaGuard] },
      { path: 'aziende', component: AziendeComponent },
      { path: 'policy-sostenibilita', component: PolicySostenibilitaComponent, canActivate: [aziendaGuard] },
      { path: 'piano-sostenibilita', component: PianoSostenibilitaComponent, canActivate: [aziendaGuard] },
      { path: 'utenti', component: UtentiComponent },
      /* { path: 'riepilogo-licenze', component: RiepilogoLicenzeComponent, canActivate: [aziendaGuard]}, */
      { path: 'figure-aziendali', component: FigureAziendeComponent, canActivate: [aziendaGuard] },
      { path: 'comitati', component: CfgComitatiComponent, data: { perAzienda: true }, canActivate: [aziendaGuard] },
      { path: 'configurazioni-ambiti', component: CfgAmbitiComponent },
      { path: 'configurazioni-tematiche', component: CfgTematicheComponent },
      { path: 'configurazioni-impatti', component: CfgImpattiComponent },
      { path: 'configurazioni-standard-rendicontazione', component: CfgStandardRendicontazioneComponent},
      { path: 'configurazioni-autovalutazioni', component: CfgAutovalutazioniComponent },
      { path: 'configurazioni-cate-stakeholder', component: CfgCategorieStakeholderComponent },
      { path: 'configurazioni-scrittura-impatto', component: CfgScritturaImpattoComponent },
      { path: 'configurazioni-settori', component: CfgSettoriComponent },
      { path: 'configurazioni-informativa-aziendale', component: CfgInformativaAziendaleComponent },
      { path: 'configurazioni-impegni', component: CfgImpegniComponent },
      { path: 'configurazioni-KPI', component: CfgKPIComponent },
      { path: 'configurazioni-azione-e-buona-pratica', component: CfgAzioneEBuonaPraticaComponent },
      { path: 'configurazioni-unita-di-misura', component: CfgUnitaDiMisuraComponent },
      { path: 'configurazioni-certificazioni', component: CfgCertificazioniComponent },
      { path: 'configurazioni-comitati', component: CfgComitatiComponent, data: { perAzienda: false } },
      { path: 'configurazioni-destinatari-policy', component: CfgDestinatariPolicyComponent },
      { path: 'configurazioni-perimetro-app-policy', component: CfgPerimetroApplicazionePolicyComponent },
      { path: 'configurazioni-linee-guida-stampe', component: CfgLineeGuidaStampeComponent },
      { path: 'configurazioni-obiettivi-piano', component: CfgObiettiviPianoComponent },
      { path: 'configurazioni-obiettivi-aziendali-piano', component: CfgObiettiviAziendaliComponent },
      { path: 'configurazioni-modelli-sviluppo-piano', component: CfgModelliSviluppoPianoComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      errorHandler: (error) => {
        console.error('Errore di navigazione', error);
        // Puoi aggiungere logiche di gestione errori personalizzate qui
      }
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
