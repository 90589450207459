import { Ateco, JsonFileService, Localita } from 'src/app/services/json-file/json-file.service';
import { Azienda, AziendaService, TipoInstallazione } from 'src/app/services/azienda/azienda.service';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { EMPTY, Observable, Subscription, catchError, distinctUntilChanged, filter, finalize, firstValueFrom, forkJoin, map, of, startWith, switchMap, tap, throwError } from 'rxjs';
import { Utente, UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { ImportazioniService } from 'src/app/services/importazioni/importazioni.service';
import { MatSelectChange } from '@angular/material/select';
import { TemaService } from 'src/app/services/tema/tema.service';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';


@Component({
    selector: 'app-dialog-crea-azienda',
    templateUrl: './dialog-crea-azienda.component.html',
    styleUrls: ['./dialog-crea-azienda.component.scss'],
    standalone: false
})
export class DialogCreaAziendaComponent implements OnInit, AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public isPermessoSoloRead: boolean = false;

  public arrayUtentiAutoCompelte: Utente[] = [];

  public descrizioneErrore: string | undefined = undefined;
  public isIconaGuida: boolean = false;
  public urlLogo: string | undefined = undefined;
  private codiciAtecoArray: Ateco[] | undefined = undefined;
  private regionePreSelect: Localita | undefined = undefined;
  private provinciaPreSelect: Localita | undefined = undefined;
  private comunePreSelect: Localita | undefined = undefined;
  private atecoPreSelect: Ateco | undefined = undefined;
  private nomeImpresa: string | undefined = undefined;
  public isModifica = false;
  public showAltriRecordDisponibili: boolean = false;

  public regioni: Localita[] = [];
  public filteredRegioni: Observable<Localita[]> | undefined;
  public province: Localita[] = [];
  public filteredProvince: Observable<Localita[]> | undefined;
  public comuni: Localita[] = [];
  public filteredComuni: Observable<Localita[]> | undefined;
  public ateco: Ateco[] = [];
  public filteredAteco: Observable<Ateco[]> | undefined;

  public canSelectTipoInstallazione = this.utenteService.isPermessoAttivo('SELECT_INST_AZIENDA');

  public listaTipiInstallazione: TipoInstallazione[] = [];
  public isUtenteGo: boolean = false;
  private _subTema: Subscription | undefined;

  public imagePreview: string | ArrayBuffer | null = 'assets/images/logo/logo-sostenibile.png';
  public logoDaSalvare: File | undefined = undefined;
  public loadingUtente: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DialogCreaAziendaComponent>,
    public aziendaService: AziendaService,
    private readonly utilityService: UtilityService,
    private readonly jsonFileService: JsonFileService,
    public utenteService: UtenteService,
    private readonly importazioniService: ImportazioniService,
    private readonly temaService: TemaService,
    private cdRef: ChangeDetectorRef,
    private licenzeService: LicenzeService,

    @Inject(MAT_DIALOG_DATA) public data: {
      azienda: Azienda,
      utente: Utente,
    }
  ) {

    this.ctrTipoTema();

    this.formAzienda.get('idUtente')?.valueChanges.pipe(
      filter(utente => !!utente?.id), // Filtra solo valori validi
      tap(() => this.spinnerOver.show()),
      switchMap(utente =>
        this.licenzeService.getUserHasLicenseIdUtente(utente!.id, 'Azienda').pipe(
          map(() => utente), // Ritorna l'utente originale se il servizio ha successo
          catchError(error => {
            // Gestione dell'errore nel pipe
            const errorMessage = error?.error?.message || 'Si è verificato un errore.';
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: errorMessage,
              bottoni: [{ nome_btn: 'Ok' }]
            });
            this.formAzienda.get('idUtente')?.setValue(undefined);

            return EMPTY;
          }),
          finalize(() => this.spinnerOver.hide())
        )
      )
    ).subscribe({
      next: utente => {
        const ruolo = utente?.ruolo?.roleName;
        this.gestioneTipoInstallazione(ruolo);
      },
      error: () => {
      }
    });


  }

  ngOnDestroy(): void {
    this._subTema?.unsubscribe();

  }


  ngOnInit() {

    if (this.utenteService.infoUtente?.utente?.ruolo?.roleName === 'configuratore') {
      this.aziendaService.getListaTipiInstallazione().subscribe({
        next: (listaTipiInstallazione) => {
          this.listaTipiInstallazione = listaTipiInstallazione || [];
        },
        error: (err) => {
          console.error('ERRORE CARICAMENTO TIPI INSTALLAZIONE : ', err);
        }
      });
    }


  }

  ngAfterViewInit(): void {
    this.spinnerOver.show();
    this.valoriIniziali().subscribe({
      next: (result) => {
        if (result) {
          this.atecoAutoComplete();
          this.regioniAutoComplete();

          this.isModifica = !!this.formAzienda.get('id')?.value;
          if (!this.isModifica) {
            this.formAzienda.get('nome')?.addValidators([Validators.required, UtilityService.blankValidator]);
            this.formAzienda.get('email')?.addValidators([Validators.email, UtilityService.emailValidator, Validators.required]);
          } else {
            this.formAzienda.get('nome')?.clearValidators();
            this.formAzienda.get('email')?.clearValidators();
          }

          this.formAzienda.get('nome')?.updateValueAndValidity();
          this.formAzienda.get('email')?.updateValueAndValidity();

        }
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error('Errore nella subscribe:', err);
        this.spinnerOver.hide();
      }
    }
    );

    const isEdit = this.utenteService.isPermessoAttivo('EDIT_AZIENDA');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_AZIENDA')
    this.isPermessoSoloRead = (!isEdit && !isCreate)

    if (this.isPermessoSoloRead) {
      this.formAzienda.disable();
    }

    if (!this.data?.azienda?.id && this.data?.utente?.id) {

      this.gestioneTipoInstallazione(this.data?.utente?.ruolo.roleName);
    }

    if (this.utenteService.infoUtente?.utente?.ruolo?.roleName === 'configuratore' &&
      !this.data?.azienda && !this.data?.utente?.id) {

      this.loadingUtente = true;
      this.utenteService.getUtenti(0, 11)
        .pipe(finalize(() => this.loadingUtente = false))
        .subscribe({
          next: (esito => {
            this.arrayUtentiAutoCompelte = esito.content;

          }),
          error: (err => {
            console.error(err);
          })
        })
    } else {
      this.formAzienda.get('idUtente')?.disable()
    }

    this.cdRef.detectChanges();
  }

  private gestioneTipoInstallazione(ruolo?: string) {
    const codiciAteco = this.formAzienda.get('codiciAteco');

    if (ruolo?.includes('_go')) {
      this.formAzienda.get('tipoInstallazione')?.setValue('SOSTENIBILE_GO')
      codiciAteco?.setValue('');
      codiciAteco?.clearValidators();
    } else {
      this.formAzienda.get('tipoInstallazione')?.setValue('SOSTENIBILE_CLOUD')
      codiciAteco?.addValidators(Validators.required);
    }
    this.formAzienda.get('tipoInstallazione')?.disable()
    codiciAteco?.updateValueAndValidity();
    this.formAzienda.get('tipoInstallazione')?.disable()

  }

  /* Form Controls */
  public formAzienda = new FormGroup({

    /* ragione sociale */
    ragioneSociale: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    /* piva */
    piva: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(11), Validators.minLength(11), Validators.pattern('^[0-9]*$')],
    }),
    /* codice ateco */
    codiciAteco: new FormControl<string | Ateco>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* indirizzo */
    indirizzo: new FormControl<string>('', {
      nonNullable: true,
    }),
    /* Regione */
    regione: new FormControl<string | Localita>('', {
      nonNullable: true,
    }),

    /* Comune */
    comune: new FormControl<string | Localita>('', {
      nonNullable: true,
    }),
    /* provincia */
    provincia: new FormControl<string | Localita>('', {
      nonNullable: true,
    }),
    /* cap */
    cap: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.pattern('^[0-9]*$'), Validators.maxLength(5), Validators.minLength(5)]
    }),
    /* sito web */
    sitoWeb: new FormControl<string>('', {
      nonNullable: true,
    }),
    /* descrizione */
    descrizione: new FormControl<string>('', {
      nonNullable: true,
    }),
    /* Id */
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* Dipendenti */
    dipendenti: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.pattern('^[0-9]*$'), Validators.min(0)],
    }),
    /* Fatturato */
    fatturato: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),
    /* CMSId */
    CMSId: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* attivo */
    attivo: new FormControl<boolean>(true, {
      nonNullable: true,
    }),

    nome: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    email: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.email, UtilityService.emailValidator],
    }),
    tipoInstallazione: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    idUtente: new FormControl<Utente | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator]
    }),
  });

  compareById(elem1: any, elem2: any): boolean {
    return elem1.id === elem2.id;

  }


  /*GESTIONE AUTOCOMPLETE DEI KPI NELLA TAB RISPOSTE  */
  getUtentiAutoComplete = (id: any, txtRicerca: string): Observable<Utente[]> => {
    if (id) {
      const records = this.arrayUtentiAutoCompelte.filter(option => option.id?.toLowerCase() === id);
      return of(records);
    } else if (txtRicerca) {
      this.loadingUtente = true;

      return this.utenteService.getUtenti(0, 11, txtRicerca, [], []).pipe(
        finalize(() => this.loadingUtente = false),
        map(esito => {
          this.arrayUtentiAutoCompelte = esito.content;
          return this._filterUtenti(txtRicerca as string); // Applica il filtro
        }),
        catchError(err => {
          console.error(err);
          return of([]); // Ritorna un array vuoto in caso di errore
        })
      );
    } else {

      const records = this.arrayUtentiAutoCompelte.slice();
      return of(records);
    }
  };

  displayFnUtente(utente: Utente): string {
    return utente && ((utente.firstName + ' ' + utente.lastName).toUpperCase() + ' - ' + utente.username);
  }


  private _filterUtenti(ricerca: string): Utente[] {
    const filterValue = ricerca.toLowerCase();
    return this.arrayUtentiAutoCompelte.filter(
      option => option.firstName?.toLowerCase().includes(filterValue)
        || option.lastName?.toLowerCase().includes(filterValue)
        || option.username?.toLowerCase().includes(filterValue));
  }


  onSelectionChangeTipoInstallazione(event: MatSelectChange) {
    const codiciAteco = this.formAzienda.get('codiciAteco');

    if (event?.value === 'SOSTENIBILE_GO') {
      codiciAteco?.setValue('');
      codiciAteco?.clearValidators();
    } else {
      codiciAteco?.addValidators(Validators.required);
    }
    codiciAteco?.updateValueAndValidity();
  }

  onKeyDownDip(event: KeyboardEvent): void {
    if (event.key === ',' || event.key === '.') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }


  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  ctrElem(event: any, input: string) {
    const valoreInput = event.target.value;

    switch (input) {
      case 'regione':
        if (!this.regioni.includes(valoreInput)) {
          this.formAzienda.controls.regione.setValue('');
        }
        break;
      case 'provincia':
        if (!this.province.includes(valoreInput)) {
          this.formAzienda.controls.provincia.setValue('');
        }
        break;
      case 'comune':
        if (!this.comuni.includes(valoreInput)) {
          this.formAzienda.controls.comune.setValue('');
        }
        break;

      default:
        break;
    }

  }

  /* displayfn e filter per regione-province--comuni */
  displayFn(localita: Localita): string {
    return localita && localita.nome ? localita.nome : '';
  }

  /* displayfn e filter per codice ateco */
  displayFnAteco(ateco: Ateco): string {
    return ateco && ateco._id ? ateco._id + ' - ' + ateco.desc : '';
  }

  private _filterAteco(name: string, options: Ateco[]): Ateco[] {
    const filterValue = name.toLowerCase();
    return this.ateco.filter(option => option.desc.toLowerCase().includes(filterValue) || option._id.toLowerCase().includes(filterValue));
  }


  compareLocalita(localita1: Localita, localita2: Localita): boolean {
    return localita1.id === localita2.id; // Sostituisci "id" con la proprietà univoca delle localita
  }

  /* REGIONI */
  getRegioni = (id: any, txtRicerca: string) => {

    let records: Localita[] = [];

    if (id) {
      records = this.regioni.filter(option => option.id?.toLowerCase() === id);
    } else if (txtRicerca) {
      records = this._filterRegioni(txtRicerca as string);
    } else {
      records = this.regioni.slice();
    }

    return of(records);
  }

  /* Province */
  getProvince = (id: any, txtRicerca: string) => {

    let records: Localita[] = [];

    if (id) {
      records = this.province.filter(option => option.id?.toLowerCase() === id);
    } else if (txtRicerca) {
      records = this._filterProvince(txtRicerca as string);
    } else {
      records = this.province.slice();
    }

    return of(records);
  }
  /* Comune */
  getComune = (id: any, txtRicerca: string) => {

    let records: Localita[] = [];

    if (id) {
      records = this.comuni.filter(option => option.id?.toLowerCase() === id);
    } else if (txtRicerca) {
      records = this._filterComune(txtRicerca as string);
    } else {
      records = this.comuni.slice();
    }

    return of(records);
  }

  private _filterRegioni(regione: string): Localita[] {
    const filterValue = regione.toLowerCase();
    return this.regioni.filter(option => option.nome?.toLowerCase().includes(filterValue));
  }

  private _filterProvince(province: string): Localita[] {
    const filterValue = province.toLowerCase();
    return this.province.filter(option => option.nome?.toLowerCase().includes(filterValue));
  }

  private _filterComune(comune: string): Localita[] {
    const filterValue = comune.toLowerCase();
    return this.comuni.filter(option => option.nome?.toLowerCase().includes(filterValue));
  }

  /**
    * Metodo che prende le unità di misura 
    */
  public getProvinciaAutoComplete(record: Localita) {


    if (record.id) {
      this.provinceAutoComplete(record.id);
    }
  }
  /**
    * Metodo che prende le unità di misura 
    */
  public getComuneAutoComplete(record: Localita) {


    if (record.id) {
      this.comuniAutoComplete(record.id);
    }
  }
  /* ------------------------------------------------------------------------------------ */

  /**
   * Metodo che mi ritorna l'id dell'utente a cui assocciare l'azienda
   * @returns 
   */
  getIdUtente() {
    if (this.data?.utente?.id) {
      return this.data?.utente?.id
    } else {
      return (this.formAzienda.get('idUtente')?.value)?.id
    }
  }

  /**
   * Funzione di salvataggio di una nuova Azienda
   * @returns 
   */
  public async salva(): Promise<boolean> {

    if (this.formAzienda.valid) {

      let formAz = this.formAzienda.getRawValue();
      const codAtString = (formAz.codiciAteco as Ateco)._id
      

      const datiMappati = {
        id: formAz.id,
        cmsId: formAz.CMSId,
        ragioneSociale: formAz.ragioneSociale,
        piva: formAz.piva,
        codiciAteco: [(formAz.codiciAteco as Ateco)._id],
        indirizzo: formAz.indirizzo,
        regione: (formAz.regione as Localita)?.nome,
        comune: (formAz.comune as Localita)?.nome,
        provincia: (formAz.provincia as Localita)?.nome,
        cap: formAz.cap,
        dipendenti: formAz.dipendenti,
        fatturato: formAz.fatturato,
        descrizione: formAz.descrizione,
        sitoWeb: formAz.sitoWeb,
        attivo: formAz.attivo,
        urlLogo: this.urlLogo,
        tipoInstallazione: formAz.tipoInstallazione,
      }


      if (typeof datiMappati?.codiciAteco === 'string') {
        datiMappati.codiciAteco = [(datiMappati.codiciAteco as string).trim()];
      }

      let obsSalvataggio: Observable<boolean>;

      const id = this.formAzienda.get('id')?.value;

      const oggettoSalvataggioAzienda = {
        azienda: datiMappati,
        idUtente: this.getIdUtente(),
      }

      if (id) {

        obsSalvataggio = this.aziendaService.putAzienda(id, datiMappati).pipe(map(() => true)); // Se non dà errore tutto ok, si torna true

      } else {

        obsSalvataggio = this.aziendaService.postAzienda(oggettoSalvataggioAzienda).pipe(
          switchMap((azienda) => {
            if (azienda?.id) {
              this.formAzienda.get('id')?.setValue(azienda.id);
              return of(azienda.id); // Restituisci direttamente l'idAzienda
            } else {
              return throwError(() => 'Nessuna informazione ricevuta dal server');
            }
          }),
          switchMap((idAzienda) => {
            return this.aziendaService.postFigureAz(
              idAzienda,
              {
                nome: this.formAzienda.get('nome')?.value,
                email: this.formAzienda.get('email')?.value,
                figura: 'Referente',
                idAzienda
              }
            );
          })
        );

      }


      return firstValueFrom(obsSalvataggio.pipe(
        map((result: any) => {
          this.aziendaService.caricaAziende();

          this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });


          return true;
        }),
        catchError(err => {
          let msg: string;
          if (err.status === 403 && this.isUtenteGo) {
            msg = "Non puoi creare un\'altra azienda Sostenibile GO"
          } else {
            msg = err?.error?.message
          }

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: msg,
            bottoni: [{ nome_btn: 'Chiudi' }]
          })

          return of(false);
        })
      ))
    } else {
      Object.values(this.formAzienda.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }

    return false;
  }


  /**
     * Metodo che mi ritorna lista delle regioni
     */
  public regioniAutoComplete() {
    this.formAzienda.controls.provincia.disable();
    this.formAzienda.controls.comune.disable();
    this.jsonFileService.getRegioni().subscribe(
      {
        next: (regioni: Localita[]) => {
          this.regioni = regioni;

          const regioneControl = this.formAzienda.get('regione');

          if (this.regionePreSelect) {
            regioneControl?.setValue(this.regionePreSelect);
            this.regionePreSelect = undefined;
          }
        },
        error: (err: any) => {
          console.error('errore get regioni', err);
        }
      }
    )
  }

  /**
    * Metodo che ritorna lista di province filtrate per id regione 
    * @param id id della regione
    */
  public provinceAutoComplete(id: string) {
    this.formAzienda.controls.provincia.enable();
    this.formAzienda.controls.provincia.setValue('');
    this.formAzienda.controls.comune.setValue('');
    this.jsonFileService.getProvince(id).subscribe(
      {
        next: (province: Localita[]) => {
          this.province = province;
          const provinciaControl = this.formAzienda.get('provincia');

          if (this.provinciaPreSelect) {
            provinciaControl?.setValue(this.provinciaPreSelect);
            this.provinciaPreSelect = undefined;
          }
        },
        error: (err: any) => {
          console.error('errore get province', err);
        }
      }
    )
  }

  /**
  * Metodo che ritorna lista dei comuni filtrate per id provincia 
  * @param id id provincia 
  */
  public comuniAutoComplete(id: string) {
    this.formAzienda.controls.comune.enable();
    this.formAzienda.controls.comune.setValue('');
    this.jsonFileService.getComuni(id).subscribe(
      {
        next: (comuni: Localita[]) => {
          this.comuni = comuni;
          const comuneControl = this.formAzienda.get('comune');
          if (this.comunePreSelect) {
            comuneControl?.setValue(this.comunePreSelect);
            this.comunePreSelect = undefined;
          }
        },
        error: (err: any) => {
          console.error('errore get comuni', err);
        }
      }
    )
  }


  public valoriIniziali(): Observable<boolean> {
    /* valori iniziali in modifica per autocomplete */
    let regioneIniziale: string = '';
    let provinciaIniziale: string = '';
    let comuneIniziale: string = '';
    let atecoIniziale: string = '';


    if (this.data?.azienda) {
      Object.keys(this.data.azienda).forEach((value, index, array) => {
        if (value === 'codiciAteco') {
          const record = this.data.azienda as any;
          const valoreAteco = (record?.codiciAteco?.length ? record?.codiciAteco[0] : '');
          /* this.formAzienda.get('codiciAteco')?.setValue(valoreAteco); */
          atecoIniziale = valoreAteco;

        } else if (value === 'regione') {
          regioneIniziale = this.data.azienda.regione;

        } else if (value === 'provincia') {
          provinciaIniziale = this.data.azienda.provincia;

        } else if (value === 'comune') {
          comuneIniziale = this.data.azienda.comune;
        } else if (value === 'urlLogo' && this.data.azienda.urlLogo) {
          this.urlLogo = this.data.azienda.urlLogo;
        } else {
          this.formAzienda.get(value)?.setValue((this.data.azienda as any)[value]);
        }
      });

      this.nomeImpresa = this.formAzienda.get('ragioneSociale')?.value;

      /*  Definisco un oggetto che contiene un gruppo di Observalbe. Questo oggetto mi servirà per
      poi fare una forkJoin.
      Ogni observable prende una richiesta (regioni - province - comune)*/

      const obsPerForkJoin: {
        regione: Observable<Localita[]>,
        provincia: Observable<Localita[]>,
        comune: Observable<Localita[]>,
        ateco: Observable<Ateco[]>
        /*
        of([]) --> instanza un observable che ritorna un array vuoto (rxjs)
         */
      } = {
        regione: of([]),
        provincia: of([]),
        comune: of([]),
        ateco: of([])
      };

      if (regioneIniziale) {
        obsPerForkJoin.regione = this.jsonFileService.getRegioniByNome(regioneIniziale);

        if (provinciaIniziale) {
          obsPerForkJoin.provincia = this.jsonFileService.getProvinceByNome(provinciaIniziale);

          if (comuneIniziale) {
            obsPerForkJoin.comune = this.jsonFileService.getComuniByNome(comuneIniziale);
          }
        }

      }
      if (atecoIniziale) {
        obsPerForkJoin.ateco = this.jsonFileService.getAtecoById([atecoIniziale]);
      }
      /* 
      La forkJoin è un operatore RXJS che viene usato quando bisogna eseguire un gruppo di Observable
      e quindi ricevere risposta da tutti gli observable del gruppo.
      In questo caso mi esegue tutti gli Observable del gruppo obsPerForkJoin, dove mi ritorna il risultato della richiesta che 
      ho assegnato nel gruppo ad ogni Observable. 
       */
      return forkJoin(obsPerForkJoin).pipe(
        map(
          (result) => {
            this.regionePreSelect = result.regione[0] || undefined;
            this.provinciaPreSelect = result.provincia[0] || undefined;
            this.comunePreSelect = result.comune[0] || undefined;
            this.atecoPreSelect = result.ateco[0] || undefined;
            return true;
          }
        ),
        catchError((err) => {
          console.error(err);
          return of(false)
        })
      );

    } else {

      return of(true);

    }
  }
  /**
   * Metodo che mi riempie l'autocompelte dei codici ateco 
   */
  public atecoAutoComplete() {
    this.codiciAtecoArray = this.aziendaService.getCodiciAteco();
    if (this.codiciAtecoArray) {
      this.ateco = this.codiciAtecoArray;

      const atecoControl = this.formAzienda.get('codiciAteco');
      if (atecoControl) {
        this.filteredAteco = atecoControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.desc;
            return name ? this._filterAteco(name as string, this.ateco) : this.ateco.slice();
          }),
          tap((esito: any) => {

            if (esito.length > 10) {
              this.showAltriRecordDisponibili = true;
            } else {
              this.showAltriRecordDisponibili = false;
            }
          })
        );
        if (this.atecoPreSelect) {
          atecoControl.setValue(this.atecoPreSelect);
          this.atecoPreSelect = undefined;
        }
      }
    }
  }

  /**
   * Importazione di un logo
   */
  public async fileSelezionato(file: File[]) {

    // Controllo se il file > 1mb
    if (file[0].size > 1048576) {
      const dialog = await this.utilityService.openDialog({
        titolo: 'Dimensione file eccessiva',
        descrizione: 'La dimensione del file supera 1 Mb, la vuoi ridimensionare',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }
    }

    if (file[0].type === "image/jpeg" || file[0].type === "image/jpg" || file[0].type === "image/png" || file[0].type === "image/gif" || file[0].type === "image/svg+xml") {
      this.isIconaGuida = false;


      this.logoDaSalvare = file[0];
      // Creo un'anteprima dell'immagine
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
        this.urlLogo = undefined;
      };
      reader.readAsDataURL(file[0]);

    } else {
      this.utilityService.opneSnackBar(" Attenzione! Il file d'importazione deve essere un file immagine (.png .jpeg .jpg .gif .svg)", '', {
        duration: 5000,
        panelClass: ['red-snackbar']
      });
      this.descrizioneErrore = " Attenzione! Il file d'importazione deve essere un file immagine (.png, .jpeg, .jpg, .gif, .svg)";
      this.isIconaGuida = true;
    }
  }

  /**
   * Metodo per eliminazione del logo aziendale.
   * Viene rimosso da google cloud storage 
   */
  public eliminaLogo() {

    if (this.logoDaSalvare) {
      this.imagePreview = 'assets/images/logo/logo-sostenibile.png';
      this.logoDaSalvare = undefined;

    } else {
      const idAzienda = this.formAzienda.get('id')?.value;
      if (idAzienda) {
        this.spinnerOver.show();

        this.importazioniService.deleteLogoAzienda(idAzienda).subscribe({
          next: (esito) => {

            this.spinnerOver.hide();
            this.urlLogo = undefined;
            this.utilityService.opneSnackBar("Logo rimosso", '', {
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          },
          error: (err) => {
            console.error(err);
            this.spinnerOver.hide();
          }
        })
      }
    }
  }
  /**
   * Controlla il tipo di tema. Se è un go devo disabilitare i validatori su ateco
   */
  ctrTipoTema() {
    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {

      this.imagePreview = this.temaService.logoPath;

      const tipoAziendaEdit = this.data?.azienda?.tipoInstallazione; // se sono  utente cfg e voglio modificare un azienda go


      if (!this.utenteService.isPermessoAttivo('SELECT_INST_AZIENDA')) {
        this.formAzienda.get('tipoInstallazione')?.clearValidators();
      }

      if (tipoInstallazione === 'SOSTENIBILE_GO' || tipoAziendaEdit === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
        this.formAzienda.get('codiciAteco')?.clearValidators();
      } else {
        this.isUtenteGo = false;
        this.formAzienda.get('codiciAteco')?.addValidators(Validators.required);

      }
      this.formAzienda.get('codiciAteco')?.updateValueAndValidity();
      this.formAzienda.get('tipoInstallazione')?.updateValueAndValidity();
    })

  }

  public async salvaAzienda() {
    try {
      this.spinnerOver.show();

      const resultAzienda = await this.salva();
      if (!resultAzienda) {
        return;
      }
      if (this.logoDaSalvare) {
        const resultLogo = await this.importaLogo();
        if (resultLogo) {
          this.dialogRef.close(this.formAzienda.get('id')?.value);
        }
      } else {
        this.dialogRef.close(this.formAzienda.get('id')?.value)
      }
    } catch (error) {
      console.error("Errore durante il salvataggio dell'azienda o l'importazione del logo", error);
    } finally {
      this.spinnerOver.hide();
    }
  }

  async importaLogo(): Promise<boolean> {

    const idAzienda = this.formAzienda.get('id')?.value;
    if (idAzienda && this.logoDaSalvare) {
      return firstValueFrom(this.importazioniService.importLogo(this.logoDaSalvare, {}, idAzienda)
        .pipe(filter((res) => res?.type === 4),
          map((result: any) => {
            console.warn(result);

            if (result.body) {
              this.urlLogo = result.body.urlLogo;
            }
            return true;
          }),
          catchError(err => {
            console.error(err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || "Errore nell\'importazione",
              bottoni: [{ nome_btn: 'Chiudi' }]
            });
            return of(false)
          })))
    } else {
      return false;
    }
  }



}
