import { Injectable } from '@angular/core';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Filtri } from 'src/app/components/lista-tabellare/lista-tabellare.component';

@Injectable({
  providedIn: 'root'
})
export class FiltriService {

  constructor() { }

  costruisciJsonFiltri(datiFiltri: { [key: string]: any }, filtriApplicati: Filtri[]) {

    const filters: Filters[] = [];

    Object.keys(datiFiltri).forEach((chiave, index, array) => {

      let valoreFiltro = datiFiltri[chiave];

      if (valoreFiltro !== null && valoreFiltro !== undefined) {
        if (!Array.isArray(valoreFiltro)) {
          valoreFiltro = [valoreFiltro];
        }

        if (valoreFiltro.filter((val: any) => val !== null).length <= 0) {
          // Filtro vuoto
          return;
        }

        /* Gestisco il tipo di operatore in base al tipo di input  */
        let operatore: 'eq' | 'bet' | 'lt' | 'gt' | 'lte' | 'gte' | 'ne' | 'lk' | 'is' = 'lk';

        const filtroCorrente = filtriApplicati.find(f => f.forControlName === chiave);

        switch (filtroCorrente?.input) {
          case 'option':
          case 'number':
          case 'multiple-option':
          case 'multiple-option-chip':
            
          if (filtroCorrente?.input === 'multiple-option-chip') {
              console.log(valoreFiltro);
              valoreFiltro = [...valoreFiltro.map((valore: any) => valore[filtroCorrente.idValueOption ?? 'id'])]

            }
          
            if (typeof valoreFiltro[0] === 'boolean') {
              operatore = 'is';
            } else {
              operatore = 'eq';
            }
            break;
          case 'date':
            operatore = 'bet';
            break;
          default:
            operatore = 'lk';
        }

        filters.push({
          chiave,
          operator: operatore,
          values: valoreFiltro
        });
      }
    })


    return filters;
  }
}
