import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { catchError, of, throwError, Subscription, Observable, finalize, switchMap, map, firstValueFrom, forkJoin, merge, debounceTime } from 'rxjs';
import { CfgQuestionarioComponent } from 'src/app/components/cfg-questionario/cfg-questionario.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogCreaMaterialitaComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-materialita/dialog-crea-materialita.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { AnalisiMaterialita, ElementiCruscotto, PianoSelection, PolicySelection, SintesiService, SintesiSostenibilita } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaTemplateMailComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-template-mail/dialog-crea-template-mail.component';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { DialogCompilazioneFigureComponent } from '../dialog-compilazione-figure/dialog-compilazione-figure.component';
import { DialogAssegnazioniComponent } from '../dialog-assegnazioni/dialog-assegnazioni.component';
import { DialogAssociaFiguraAziendaleComponent } from 'src/app/components/selezione-elementi-questionario/dialog/dialog-associa-figura-aziendale/dialog-associa-figura-aziendale.component';
import { SelezioneImpegniSintesiComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-impegni-sintesi/selezione-impegni-sintesi.component';
import { SelezioneAzioniBuonepraticheSintesiComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-azioni-buonepratiche-sintesi/selezione-azioni-buonepratiche-sintesi.component';
import { SelezioneKPISintesiComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-kpi-sintesi/selezione-kpi-sintesi.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { AutovalutazioneCompilata, AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { SelezioneCertificazioniSintesiComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-certificazioni-sintesi/selezione-certificazioni-sintesi.component';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { DialogCreaPolicySostenibilitaComponent } from 'src/app/page/policy-sostenibilita/dialog/dialog-crea-policy-sostenibilita/dialog-crea-policy-sostenibilita.component';
import { ImportazioniService } from 'src/app/services/importazioni/importazioni.service';
import { GruppoCfgStampa } from 'src/app/services/config-stampe/config-stampe.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { DialogCreaPianoSostenibilitaComponent } from 'src/app/page/piano-sostenibilita/dialog/dialog-crea-piano-sostenibilita/dialog-crea-piano-sostenibilita.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { CfgStampaComponent } from 'src/app/components/cfg-stampa/cfg-stampa.component';

@Component({
    selector: 'app-dialog-crea-sintesi-sostenibilita',
    templateUrl: './dialog-crea-sintesi-sostenibilita.component.html',
    styleUrls: ['./dialog-crea-sintesi-sostenibilita.component.scss'],
    providers: [{
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        }],
    standalone: false
})
export class DialogCreaSintesiSostenibilitaComponent implements OnDestroy, AfterViewInit, OnInit, OnDestroy {
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('btnAvanti') btnAvanti!: MatButton;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(CfgQuestionarioComponent) componenteSurvey!: CfgQuestionarioComponent;
  @ViewChild(SelezioneImpegniSintesiComponent) componenteSelImpegniSintesi!: SelezioneImpegniSintesiComponent;
  @ViewChild(SelezioneAzioniBuonepraticheSintesiComponent) componenteSelAzioneBPSintesi!: SelezioneAzioniBuonepraticheSintesiComponent;
  @ViewChild(SelezioneKPISintesiComponent) componenteSelKPISintesi!: SelezioneKPISintesiComponent;
  @ViewChild(SelezioneCertificazioniSintesiComponent) componenteCertificazioniSintesi!: SelezioneCertificazioniSintesiComponent;
  @ViewChild('scrollConfStampa') scrollConfStampa!: NgScrollbar;
  @ViewChild(CfgStampaComponent) cfgStampaComponent!: CfgStampaComponent;

  public STATO: 'BOZZA' | 'PUBBLICATO' | undefined = undefined;

  public settoriAzienda: Settore[] = [];

  private _sintesi: any = {};

  get sintesi() {
    return this._sintesi;
  }
  public isPermessoInvioMail: boolean = this.utenteService.isPermessoAttivo('INVIO_MAIL');
  public isPermessoSoloRead: boolean = false;

  private numeroDipendetiAzienda: number = 0;
  private numeroFatturatoAzienda: number = 0;

  public mappaAnnoSettore: { [anno: string]: { [settore: string]: boolean } } = {};
  public arrayAnni: string[] = [];
  public guidaDescrizione: string | undefined = undefined;
  public mostraDescrizione: boolean = false;
  public selectedAnalisiMaterialita: string[] = [];
  public arrayAnalisiMaterialita: AnalisiMaterialita[] = []; // step1
  public arrayAnalisiAutovalutazione: AutovalutazioneCompilata[] = [];
  public arrayPolicySostenibilita: PolicySelection[] = [];
  public arrayPianoSostenibilita: PianoSelection[] = [];
  public loadingAnalisiMaterialita = false;
  public loadingAutovalutazione = false;
  public mostraBottoneAssegnazioni: boolean = false;
  public descrizioneErrore: string | undefined = undefined;
  public cfgStampaSintesi: GruppoCfgStampa | undefined;
  public statoToggleTesteCfgStampa: { [chiave: string]: boolean } = {};
  public indicePanelStampa: number | undefined = undefined;
  public isIconaGuida: boolean = false;
  public valueToggleAnni: boolean = false;
  //**step6

  //KPI 
  public kpiNonCompilati: number = 0;
  public kpiCompilati: number = 0;
  //AZIONI
  public azioneCompilati: number = 0;
  public azioneNonCompilati: number = 0;
  //IMPEGNI
  public impegniCompilati: number = 0;
  public impegniNonCompilati: number = 0;

  public objQuestionarioImpegni = [];
  public objQuestionarioAzioniBP = [];
  public objQuestionarioKPI = [];
  public objQuestionarioCertificazioni = [];
  /********************************************************** CREAZIOEN SISTESI SOSTENIBILITA **************************/
  public formCreazioneSostenibilita = new FormGroup({

    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    idAzienda: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    anno: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    nrDipendenti: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(999999999999)],
    }),

    fatturato: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),

    idMaterialita: new FormControl<string[] | AnalisiMaterialita>({ disabled: true, value: [] }, {
      nonNullable: true,
      validators: [],
    }),

    tipoSettore: new FormControl<'GENERALE' | 'SPECIFICO' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settori: new FormControl<Settore | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    sottoSettori: new FormControl<SottoSettore[]>([], {
      nonNullable: true
    }),
    idAutovalutazioneCompilata: new FormControl<string | AutovalutazioneCompilata>('', {
      nonNullable: true,
      validators: [],
    }),
    idPolicySostenibilita: new FormControl<string | PolicySelection>('', {
      nonNullable: true,
      validators: [],
    }),
    idPianoSostenibilita: new FormControl<string | PianoSelection>('', {
      nonNullable: true,
      validators: [],
    }),
    tipo: new FormControl<string>('SINTESI', {
      nonNullable: true,
      validators: [],
    }),
  });


  /*************************************************************** CREAZIONE SURVEY **************************************/
  private _subImpatti: Subscription | undefined;

  public arrayAmbiti: Ambito[] = [];
  public arraySottoSettori: SottoSettore[] = [];
  public settoreGenerale: Settore | undefined = undefined;
  public arrayTematicheDisponibili: { [key: string]: any } = {};
  public loadingTemDisp: { [key: string]: any } = {};


  /******************************************************************** END CRUSCOTTO SURVEY *************************************/

  //******************************************************************* C R U S C O T T O */

  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
    {
      titolo: 'Figura',
      forControlName: 'figura',
      input: 'text',
    },

  ];

  public colonne: { [key: string]: Colonna } = {
    stakeholder: {
      title: 'Figure Aziendali',
      value: 'figura'
    },
    tipologia: {
      title: 'Nome',
      value: 'nome'
    },
    email: {
      title: 'Email',
      value: 'email'
    },
    stato: {
      title: 'Stato',
      value: (record: any) => {
        if (record?.stato === 'ROSSO') { // non compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'GIALLO') { // ha inviato la mail ma non è stato compilato (valore solo per gli stakeholder con modalita compilazione "INVIAMAIL")
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'VERDE') { // compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else {
          return '';
        }
      }
    },
    history: {
      type: 'button',
      buttonIcon: this.isPermessoSoloReadFunx() ? 'visibility' : 'history_edu',
      title: this.isPermessoSoloReadFunx() ? 'Visualizza' : 'Compila',
      buttonId: 'compila',
      buttonMostraSempre: true,
      sortable: false,
    },

    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      nascondiButton: (record) => (record?.stato === 'VERDE' || this.isPermessoSoloReadFunx()),
      buttonMostraSempre: true
    },
    /*  azioni: {
       type: 'button',
       buttonIcon: (record: any) => {
         if (record) {
           if (record.ultimoInvio) {
             return 'notifications_active';
           } else {
             return 'mail';
           }
         } else {
           return '';
         }
       },
       title: (record: any) => {
         if (record) {
           if (record.ultimoInvio) {
             return 'Invia notifica';
           } else {
             return 'Invia mail';
           }
         } else {
           return '';
         }
       },
       buttonId: 'azione',
       buttonMostraSempre: true,
       nascondiButton: (record: any) => !record || record?.modalitaCompilazione === 'INVIAMAIL',
     }, */
  };

  private _subCambioValoriSettoreSottoSettore: Subscription | undefined = undefined;
  private _subValueChangeSettore: Subscription | undefined = undefined;
  private _subValueChangeTipoSettore: Subscription | undefined = undefined;
  private _subValueChangeAnno: Subscription | undefined = undefined;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogCreaSintesiSostenibilitaComponent>,
    private readonly materialitaService: MaterialitaService,
    private readonly utilityService: UtilityService,
    private readonly aziendaService: AziendaService,
    private readonly settoriService: SettoriService,
    private readonly sintesiService: SintesiService,
    private readonly autovalutazioniService: AutovalutazioniService,
    private readonly policyService: PolicyService,
    private readonly importazioniService: ImportazioniService,
    private readonly pianoService: PianoService,
    private readonly utenteService: UtenteService,
    @Inject(MAT_DIALOG_DATA) public data: {
      sintesi: SintesiSostenibilita,
    }
  ) {
    const annoCorrente = new Date().getFullYear();

    // Aggiungi gli anni indietro di 5 anni e in avanti di 1 anno all'array
    for (let i = annoCorrente - 5; i <= annoCorrente + 1; i++) {
      this.arrayAnni.push(i.toString());
    }

  }

  ngOnInit(): void { }

  ngAfterViewInit() {

    setTimeout(async () => {

      await this._caricaSettori();

      // Ad ogni cambio di settore o sottosettore bisogna refreshare materialità e autovalutazioni (assessment).
      // Si usa un merge per evitare che partano chiamate multiple parallele quando cambiano settori e/o sottoSettori.
      this._subCambioValoriSettoreSottoSettore = merge(
        this.formCreazioneSostenibilita.get('settori')!.valueChanges,
        this.formCreazioneSostenibilita.get('sottoSettori')!.valueChanges
      ).pipe(
        debounceTime(500)
      ).subscribe((value) => {
        if (this.formCreazioneSostenibilita.get('settori')?.value && !this.formCreazioneSostenibilita.get('id')?.value) {

          const settore = this.formCreazioneSostenibilita.get('settori')?.value;

          if (settore?.id) {
            this._getAnalisiMaterialita(settore?.id);
            this._getPolicySostenibilita(settore?.id);
            this._getPianoSostenibilita(settore?.id);

            this._getAutovalutazioni();
          }
        }
      });

      this._subValueChangeSettore = this.formCreazioneSostenibilita.get('settori')?.valueChanges.subscribe((value) => {

        if (!this.formCreazioneSostenibilita.get('id')?.value) {
          if (value) {
            this.formCreazioneSostenibilita.get('idMaterialita')!.enable();
            this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')!.enable();
            this.formCreazioneSostenibilita.get('idPolicySostenibilita')!.enable();
            this.formCreazioneSostenibilita.get('idPianoSostenibilita')!.enable();
          } else {
            this.formCreazioneSostenibilita.get('idMaterialita')!.disable();
            this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')!.disable();
            this.formCreazioneSostenibilita.get('idPolicySostenibilita')!.disable();
            this.formCreazioneSostenibilita.get('idPianoSostenibilita')!.disable();
          }
        }


        if (value) {
          this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === value.id)?.sottoSettori || [];

          this._updateSottoSettoriSelezionati();
        }
      });


      this._subValueChangeTipoSettore = this.formCreazioneSostenibilita.get('tipoSettore')?.valueChanges.subscribe((value) => {
        if (value === 'GENERALE') {
          this.formCreazioneSostenibilita.get('settori')?.setValue(this.settoreGenerale);
        } else if (value === 'SPECIFICO') {
          this.formCreazioneSostenibilita.get('settori')?.setValue(this.settoriAzienda?.length ? this.settoriAzienda[0] : undefined);
        }
      });


      this._subValueChangeAnno = this.formCreazioneSostenibilita.get('anno')!.valueChanges.subscribe((value) => {
        if (!this.formCreazioneSostenibilita.get('id')?.value && this.formCreazioneSostenibilita.get('settori')?.value?.id) {
          if (value) {
            this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')!.enable();
          } else {
            this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')!.disable();
          }
        }
      })

      this.init();
    }, 0);

    this.gestioneStepperSintesi();

    this.isPermessoSoloRead = this.isPermessoSoloReadFunx();

    if (this.isPermessoSoloRead) {
      this.formCreazioneSostenibilita.disable();
    }

  }

  isPermessoSoloReadFunx() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_SINTESI');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_SINTESI')
    return (!isEdit && !isCreate)
  }


  ngOnDestroy(): void {
    this._subCambioValoriSettoreSottoSettore?.unsubscribe();
    this._subImpatti?.unsubscribe();
    this._subValueChangeTipoSettore?.unsubscribe();
    this._subValueChangeSettore?.unsubscribe();
    this._subValueChangeAnno?.unsubscribe();
  }

  /**
   * Questionario pubblicato 
   * @returns true === PUBBLICATO
   */
  public isPubblicato(): boolean {
    return (this._sintesi?.stato === 'PUBBLICATO')
  }

  async init() {

    this.spinnerOver.show();

    if (this.data.sintesi) {
      await this._setDatiSintesi(this.data.sintesi);
    } else {

      const azienda = await this.aziendaService.azienda;

      if (azienda) {

        if (this.settoriAzienda?.length) {
          this.formCreazioneSostenibilita.get('tipoSettore')?.setValue('SPECIFICO');
        } else {
          this.formCreazioneSostenibilita.get('tipoSettore')?.setValue('GENERALE');
        }

        this.formCreazioneSostenibilita.get('idAzienda')?.setValue(azienda.id);

        this.aziendaService.getAziendaByIdAzienda(azienda.id).subscribe((risp) => {

          this.numeroDipendetiAzienda = risp.dipendenti;
          this.numeroFatturatoAzienda = risp.fatturato;

          if (!this.data?.sintesi?.id) {
            this.formCreazioneSostenibilita.get('nrDipendenti')?.setValue(this.numeroDipendetiAzienda);
            this.formCreazioneSostenibilita.get('fatturato')?.setValue(this.numeroFatturatoAzienda);
          }

        })
      }

      this.checkSoglieMaterialita();
    }

    if (this.data?.sintesi?.stato === 'PUBBLICATO') {
      this._getStepTematiche();
    }

    this.spinnerOver.hide();

  }

  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  onKeyDownDip(event: KeyboardEvent): void {
    if (event.key === ',' || event.key === '.') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  checkSoglieMaterialita() {

    const fatturatoControl = this.formCreazioneSostenibilita?.get('fatturato');
    const dipendentiControl = this.formCreazioneSostenibilita?.get('nrDipendenti');
    if (fatturatoControl?.value && dipendentiControl?.value) {
      const fatturatoValue = fatturatoControl.value;
      const dipendentiValue = dipendentiControl.value;
      if (dipendentiValue >= this.aziendaService.sogliaNrDipendenti && fatturatoValue >= this.aziendaService.sogliaFatturato) {
        this.guidaDescrizione = "Per proseguire, è consigliato indicare l'Analisi di Materialità di riferimento.";
        this.mostraDescrizione = true;
      } else {
        this.mostraDescrizione = false;
      }
    }

  }

  private _getStepTematiche() {
    const id = this.formCreazioneSostenibilita.get('id')?.value;

    const idSettore = (this.formCreazioneSostenibilita.get('settori')?.value as Settore).id
    this.componenteSurvey.datiInizialiCfgQuestionario(idSettore);

    if (!id) {
      return;
    }

    this.spinnerOver.show();

    this._getQuestionarioSintesi(id).subscribe(
      {
        next: (sintesi: any) => {

          if (sintesi) {


            this._sintesi = sintesi;
            this.completaStep();

          }
          this.btnAvanti.disabled = false;
          this.spinnerOver.hide();
        },
        error: (err: any) => {
          console.error(err);
          this.btnAvanti.disabled = false;

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero della sintesi',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          this.spinnerOver.hide();
        }
      }

    )
  }

  /**
 * Viene disabilitato per non spammare il bottone avanti mentre stanno caricando i dati 
 */
  disabilitaBottoneAvanti() {
    setTimeout(() => {
      this.btnAvanti.disabled = true;
    }, 0);
  }

  cambioStep(event: StepperSelectionEvent) {

    //Creazione sistesi di sostenibilità
    if (event.selectedIndex === 0) {
      this.mostraBottoneAssegnazioni = false;

    } else if (event.selectedIndex === 1) {

      this.mostraBottoneAssegnazioni = true;
      this.disabilitaBottoneAvanti();
      this._getStepTematiche();

    } else if (event.selectedIndex === 2) {

      this.mostraBottoneAssegnazioni = true;
      this.disabilitaBottoneAvanti();

      this._getImpegniSintesi();

    } else if (event.selectedIndex === 3) {
      this.mostraBottoneAssegnazioni = true;

      this.disabilitaBottoneAvanti();

      this._getAzioniBPSintesi();

    } else if (event.selectedIndex === 4) {
      this.mostraBottoneAssegnazioni = true;

      this.disabilitaBottoneAvanti();

      this._getKpiSintesi();


    } else if (event.selectedIndex === 5) {
      this.mostraBottoneAssegnazioni = true;

      this.disabilitaBottoneAvanti();

      this._getCertificazioniSintesi();

    } else if (event.selectedIndex === 6) {
      this.spinnerOver.hide();
      this.tabella.caricaDati();

      const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value
      if (idSintesiSostenibilita) {
        this.caricaTotaliCruscotto(idSintesiSostenibilita);
      }
      this.mostraBottoneAssegnazioni = false;

    } else if (event.selectedIndex === 7) {
      this.mostraBottoneAssegnazioni = false;

      this.spinnerOver.show();

      return this.cfgStampaComponent.caricaStampa();
    }

  }

  indietro() {
    switch (this.stepper.selectedIndex) {
      case 0:
        return true;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        this.stepper.previous();
        return true;
      default:
        return null;
    }
  }



  async avanti() {

    let result: boolean = false;
    this.spinnerOver.show();

    switch (this.stepper.selectedIndex) {
      case 0:
        result = await this._salvaStep1Sintesi();
        break;
      case 1:
        result = await this.salvaStepTematiche();
        break;
      case 2:
        result = await this.salvaStepImpegni();
        break;
      case 3:
        result = await this.salvaStepAzioniBP();
        break;
      case 4:
        result = await this.salvaStepKPI();
        break;
      case 5:
        result = await this.salvaStepCertificazioni();
        break;
      case 6:
        return true;
      default:
        return;
    }

    this.spinnerOver.hide();

    return result;

  }

  public async avantiHTML() {
    if (this.isPermessoSoloRead || await this.avanti()) {
      return this.stepper.next();
    }
  }

  /**
 * Setta nel formGroup i dati della sintesi
 * @param sintesi 
 */
  private async _setDatiSintesi(sintesi: SintesiSostenibilita) {

    let settoreSel: Settore | null = null;

    Object.keys(sintesi).forEach((value) => {

      if (value === 'settori') {
        // Settore si imposta per ultimo, per non far scattare il change quando non è ancora settato l'array dei sottosettori
        settoreSel = (sintesi as any)[value][0];
      } else {
        this.formCreazioneSostenibilita.get(value)?.setValue((sintesi as any)[value]);
      }

    });

    if (settoreSel) {

      if (!this.settoriAzienda.find((set) => set.id === settoreSel!.id)) {
        // Il settore della sintesi non è negli array dei settori azienda.
        // Potrebbe essere stato tolto a posteriori, dopo la creazione della sintesi.
        // Per non lasciare il campo vuoto, si setta nell'array il settore che viene dalla sintesi.
        this.settoriAzienda = [settoreSel];
      }

      this.formCreazioneSostenibilita.get('settori')?.setValue(settoreSel);
    }

    if (this.formCreazioneSostenibilita.get('id')?.value) {
      this.formCreazioneSostenibilita.get('anno')?.disable();
      this.formCreazioneSostenibilita.get('settori')?.disable();
      this.formCreazioneSostenibilita.get('sottoSettori')?.disable();
      this.formCreazioneSostenibilita.get('idMaterialita')?.disable();
      this.formCreazioneSostenibilita.get('idPolicySostenibilita')?.disable();
      this.formCreazioneSostenibilita.get('idPianoSostenibilita')?.disable();
      this.formCreazioneSostenibilita.get('fatturato')?.disable();
      this.formCreazioneSostenibilita.get('nrDipendenti')?.disable();
      this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')?.disable();
    }

    if (sintesi.idMaterialita) {
      await this._getAnalisiMaterialitaById(sintesi.idMaterialita);


      // cerco il nome della materilita in base all id
      const analisiMaterialita = this.arrayAnalisiMaterialita.find(analisi => analisi.idMaterialita === sintesi.idMaterialita)
      if (analisiMaterialita) {
        this.formCreazioneSostenibilita.get('idMaterialita')?.setValue(analisiMaterialita)
        this._cambiaInfoBoxDesc(analisiMaterialita.anno);
      }
    }
    if (sintesi.idAutovalutazioneCompilata) {
      await this._getAutovalutazioneById(sintesi.idAutovalutazioneCompilata);


      // cerco il titolo del autovalutazione  in base all id
      const analisiAutovalutazione = this.arrayAnalisiAutovalutazione.find(auto => auto.id === sintesi.idAutovalutazioneCompilata)
      if (analisiAutovalutazione) {
        this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')?.setValue(analisiAutovalutazione)
      }

    }
    if (sintesi.idPolicySostenibilita) {

      await this._getPolicyById(sintesi.idPolicySostenibilita);

      // cerco il nome della materilita in base all id
      const policySostenibilita = this.arrayPolicySostenibilita.find(policy => policy.idPolicy === sintesi.idPolicySostenibilita)
      if (policySostenibilita) {
        this.formCreazioneSostenibilita.get('idPolicySostenibilita')?.setValue(policySostenibilita)
        this._cambiaInfoBoxDesc(policySostenibilita.anno);
      }
    }

    if (sintesi.idPianoSostenibilita) {
      await this._getPianoById(sintesi.idPianoSostenibilita);
      // cerco il nome della materilita in base all id
      const pianoSostenibilita = this.arrayPianoSostenibilita.find(piano => piano.idPiano === sintesi.idPianoSostenibilita)
      if (pianoSostenibilita) {
        this.formCreazioneSostenibilita.get('idPianoSostenibilita')?.setValue(pianoSostenibilita)
        this._cambiaInfoBoxDesc(pianoSostenibilita.anno);
      }
    }

  }

  async ctrDipendentiFatturato(): Promise<boolean> {
    const id = this.formCreazioneSostenibilita.get('id')?.value;
    const nrDipendenti = this.formCreazioneSostenibilita.get('nrDipendenti')?.value;
    const fatturato = this.formCreazioneSostenibilita.get('fatturato')?.value;
    if (!id && (nrDipendenti !== this.numeroDipendetiAzienda || fatturato !== this.numeroFatturatoAzienda)) {
      /* IMPLEMENTARE LA DIALOG DI VARIAIZONE FATTUARTO E DIPENDENTI */
      const dialog = await this.utilityService.openDialog({
        titolo: 'Variazione Dipendenti e Fatturato',
        descrizione: 'I valori inseriti per il <strong>fatturato</strong> e il <strong>numero di dipendenti</strong> possono essere <strong>aggiornati</strong> anche nell\'<strong>Azienda</strong>.<br>Vuoi procedere con  l\'<strong>aggiornamento</strong>?',
        fontWeight: 'normal',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return false;
      }
      return true;
    }
    return false;
  }

  private async _salvaStep1Sintesi(): Promise<boolean> {

    const idSintesi = this.formCreazioneSostenibilita.get('id')?.value;
    const formCrtSostenibilita = this.formCreazioneSostenibilita.getRawValue();

    if (idSintesi) {
      return true
    }

    if (this.formCreazioneSostenibilita.valid) {

      const aggiornaDatiAzienda = await this.ctrDipendentiFatturato();

      const dialog = await this.utilityService.openDialog({
        titolo: 'Creazione Sintesi di Sostenibilità',
        descrizione: 'Continuando non sarà più possibile modificare queste informazioni.',
        bottoni: [
          {
            nome_btn: 'Annulla',
            id_btn: 'N'
          },
          {
            nome_btn: 'Avanti',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return false;
      }



      if (formCrtSostenibilita.idMaterialita === null) {
        formCrtSostenibilita.idMaterialita = [];
      }



      const nuovaSintesi: SintesiSostenibilita = {
        ...formCrtSostenibilita,
        idMaterialita: (formCrtSostenibilita.idMaterialita as AnalisiMaterialita).idMaterialita,
        idAutovalutazioneCompilata: (formCrtSostenibilita.idAutovalutazioneCompilata as AutovalutazioneCompilata)?.id || '',
        idPolicySostenibilita: (formCrtSostenibilita.idPolicySostenibilita as PolicySelection)?.idPolicy || '',
        idPianoSostenibilita: (formCrtSostenibilita.idPianoSostenibilita as PianoSelection)?.idPiano || '',
        aggiornaDatiAzienda: aggiornaDatiAzienda
      }

      this.spinnerOver.show();

      return firstValueFrom(this.sintesiService.postCreazioneSintesiStep1(nuovaSintesi).pipe(
        map(esito => {
          this.formCreazioneSostenibilita.get('id')?.setValue(esito.id);

          this.formCreazioneSostenibilita.get('anno')?.disable();
          this.formCreazioneSostenibilita.get('settori')?.disable();
          this.formCreazioneSostenibilita.get('idMaterialita')?.disable();
          this.formCreazioneSostenibilita.get('idPolicySostenibilita')?.disable();
          this.formCreazioneSostenibilita.get('idPianoSostenibilita')?.disable();
          this.formCreazioneSostenibilita.get('fatturato')?.disable();
          this.formCreazioneSostenibilita.get('nrDipendenti')?.disable();
          this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')?.disable();
          return true;
        }),
        catchError(err => {
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore nel salvataggio ',
            bottoni: [{ nome_btn: 'Chiudi' }]
          })
          return of(false)

        })
      ))

    } else {
      Object.values(this.formCreazioneSostenibilita.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return false;
  }

  /**
  * Metodo salvataggio STEP  TEMATICHE
  * @returns 
  */
  public salvaStepTematiche(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        resolve(true);
        return;
      }

      this.componenteSurvey.salva().subscribe({
        next: (esito) => {
          resolve(esito);
        }
      });

    });
  }

  /**
  * Metodo salvataggio STEP 6 IMPEGNI
  * @returns 
  */
  public salvaStepImpegni(): Promise<boolean> {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteSelImpegniSintesi.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }

  /**
    * Metodo salvataggio STEP 7 AZIONI E BUONE PRATICHE
    * @returns 
    */
  public salvaStepAzioniBP() {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteSelAzioneBPSintesi.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }

  /**
    * Metodo salvataggio Certificazioni
    * @returns 
    */
  public salvaStepCertificazioni() {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteCertificazioniSintesi.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }

  /**
    * Metodo salvataggio STEP 8 KPI
    * @returns 
    */
  public salvaStepKPI() {

    return new Promise<boolean>(resolve => {
      if (this.componenteSurvey.stato === 'PUBBLICATO') {
        return resolve(true);
      };

      this.componenteSelKPISintesi.salva().subscribe({
        next: (esito => {
          resolve(esito);
        })
      });
    })
  }


  /**
   * Recupera una singola materialità per id
   * 
   * @param idMaterialita 
   * @returns 
   */
  private _getAnalisiMaterialitaById(idMaterialita: string): Promise<void> {

    this.loadingAnalisiMaterialita = true;

    return new Promise((resolve, reject) => {

      this.loadingAnalisiMaterialita = true;

      this.materialitaService.getMaterialitaById(idMaterialita).subscribe({
        next: (materialita: any) => {

          this.arrayAnalisiMaterialita = [{
            idMaterialita: materialita.id,
            nome: 'Analisi Materialità ' + materialita.anno,
            anno: materialita.anno
          }];

          this.loadingAnalisiMaterialita = false;
          resolve();
        },
        error: (err: any) => {
          console.error(err);
          reject(err);
          this.loadingAnalisiMaterialita = false;
          this.spinnerOver.hide();
        }
      });
    });
  }
  /**
   * Recupera una singola POLICY per id
   * 
   * @param idPolicy 
   * @returns 
   */
  private _getPolicyById(idPolicy: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.policyService.getPolicyById(idPolicy).subscribe({
        next: (policy: any) => {

          this.arrayPolicySostenibilita = [{
            idPolicy: policy.id,
            nome: 'Policy di Sostenibilità ' + policy.anno,
            anno: policy.anno
          }];

          resolve();
        },
        error: (err: any) => {
          console.error(err);
          reject(err);

          this.spinnerOver.hide();
        }
      });
    });
  }

  /**
   * Recupera una singola Piano per id
   * 
   * @param idPolicy 
   * @returns 
   */
  private _getPianoById(idPiano: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.pianoService.getPianoById(idPiano).subscribe({
        next: (piano: any) => {

          this.arrayPianoSostenibilita = [{
            idPiano: piano.id,
            nome: 'Piano di Sostenibilità ' + piano.anno,
            anno: piano.anno
          }];

          resolve();
        },
        error: (err: any) => {
          console.error(err);
          reject(err);

          this.spinnerOver.hide();
        }
      });
    });
  }

  /**
   * Recupera una singola autovalutazione per id
   * 
   * @param idAutovalutazione 
   * @returns 
   */
  private _getAutovalutazioneById(idAutovalutazione: string): Promise<void> {

    this.loadingAutovalutazione = true;

    return new Promise((resolve, reject) => {

      this.loadingAutovalutazione = true;

      this.autovalutazioniService.getAutovalutazioniById(idAutovalutazione).subscribe({
        next: (auto: any) => {

          this.arrayAnalisiAutovalutazione = [{
            id: auto.id,
            /*        titolo: 'Autovalutazione ' + auto.anno, */
            titolo: auto.titolo,
            esercizio: auto.esercizio
          }];


          // Controlla il valore già presente nella form. Se non esiste nell'array, si azzera
          const autovalPreSelez = this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')?.value;
          let autovalutazioneTrovata: AutovalutazioneCompilata | undefined = undefined;
          if (autovalPreSelez && typeof autovalPreSelez !== 'string') {
            autovalutazioneTrovata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === autovalPreSelez.id);
          }

          if (!autovalutazioneTrovata) {
            // valore preselezionato non trovato, si azzera
            this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')?.setValue('');
          }

          this.loadingAutovalutazione = false;
          resolve();
        },
        error: (err: any) => {
          console.error(err);
          reject(err);
          this.loadingAutovalutazione = false;
          this.spinnerOver.hide();
        }
      });
    });
  }

  private _getAnalisiMaterialita(idSettore: string): Promise<void> {
    return new Promise((resolve, reject) => {

      this.loadingAnalisiMaterialita = true;

      const sottoSettori = (this.formCreazioneSostenibilita.get('sottoSettori')?.value || []).map(s => s.id).filter(id => id !== undefined) as string[];

      this.materialitaService.getMaterialitaPubblicate(idSettore, sottoSettori).subscribe({
        next: (esito: any) => {
          this.arrayAnalisiMaterialita = esito.map((item: any) => ({
            idMaterialita: item.id,
            nome: 'Analisi Materialità ' + item.anno,
            anno: item.anno
          }));

          this.loadingAnalisiMaterialita = false;
          resolve();
        },
        error: (err: any) => {
          console.error(err);
          reject(err);
          this.loadingAnalisiMaterialita = false;
          this.spinnerOver.hide();
        }
      });
    });
  }

  private _getPolicySostenibilita(idSettore: string): Promise<void> {
    return new Promise((resolve, reject) => {

      const sottoSettori = (this.formCreazioneSostenibilita.get('sottoSettori')?.value || []).map(s => s.id).filter(id => id !== undefined) as string[];

      this.policyService.getPolicyPubblicate(idSettore, sottoSettori).subscribe({
        next: (result) => {
          this.arrayPolicySostenibilita = result.map((item: any) => ({
            idPolicy: item.id,
            nome: 'Policy di Sostenibilita ' + item.anno,
            anno: item.anno
          }));
          resolve();
        },
        error: (err) => {
          console.error(err);
          reject(err);
          this.spinnerOver.hide();
        }

      })

    })
  }

  private _getPianoSostenibilita(idSettore: string): Promise<void> {
    return new Promise((resolve, reject) => {

      const sottoSettori = (this.formCreazioneSostenibilita.get('sottoSettori')?.value || []).map(s => s.id).filter(id => id !== undefined) as string[];

      this.pianoService.getPianiPubblicate(idSettore, sottoSettori).subscribe({
        next: (result) => {
          this.arrayPianoSostenibilita = result.map((item: any) => ({
            idPiano: item.id,
            nome: 'Piano di Sostenibilita ' + item.anno,
            anno: item.anno
          }));
          resolve();
        },
        error: (err) => {
          console.error(err);
          reject(err);
          this.spinnerOver.hide();
        }

      })

    })

  }


  private _getAutovalutazioni(): Promise<void> {

    const idSettore = this.formCreazioneSostenibilita.get('settori')?.value?.id;
    const idSottoSettori: string[] = (this.formCreazioneSostenibilita.get('sottoSettori')?.value?.map(sottoSet => sottoSet.id).filter(val => val !== undefined) || []) as string[];

    return new Promise((resolve, reject) => {

      this.loadingAutovalutazione = true;

      this.autovalutazioniService.getAutovalutazioniCompilatePerSettore(idSettore, idSottoSettori).subscribe({
        next: (esito: any) => {

          this.arrayAnalisiAutovalutazione = esito.map((item: any) => ({
            id: item.id,
            /*        titolo: 'Autovalutazione ' + item.anno, */
            titolo: item.titolo,
            esercizio: item.esercizio
          }));

          // Controlla il valore già presente nella form. Se non esiste nell'array, si azzera
          const autovalPreSelez = this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')?.value;
          let autovalutazioneTrovata: AutovalutazioneCompilata | undefined = undefined;
          if (autovalPreSelez && typeof autovalPreSelez !== 'string') {
            autovalutazioneTrovata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === autovalPreSelez.id);
          }

          if (!autovalutazioneTrovata) {
            // valore preselezionato non trovato, si azzera
            this.formCreazioneSostenibilita.get('idAutovalutazioneCompilata')?.setValue('');
          }

          this.loadingAutovalutazione = false;
          resolve();
        },
        error: (err: any) => {
          console.error(err);
          reject(err);
          this.loadingAutovalutazione = false;
          this.spinnerOver.hide();
        }
      });
    });
  }

  private _cambiaInfoBoxDesc(annoMaterialita: string) {
    /* Controllo se anno della materialità ha anzianità >= 2 anni   */

    const annoControl = this.formCreazioneSostenibilita.get('anno');
    if (annoControl) {

      const annoSintesi = parseInt(annoControl.value, 10);
      const annoMaterialitaNum: number = parseInt(annoMaterialita, 10);

      const diffAnno: number = annoSintesi - annoMaterialitaNum;
      if (diffAnno >= 2) {
        this.guidaDescrizione = "Sono passati 2 anni dall'Analisi di Materialità selezionata";
        this.mostraDescrizione = true;
      } else {
        this.mostraDescrizione = false;
      }
    }
  }

  onSelectionChangeMaterialita(event: MatSelectChange) {
    if (event?.value?.anno) {
      this._cambiaInfoBoxDesc(event?.value?.anno)
    }
  }

  onSelectionChangeAnno() {
    const annoMaterialita = (this.formCreazioneSostenibilita.get('idMaterialita')?.value as AnalisiMaterialita)?.anno;
    if (annoMaterialita) {
      this._cambiaInfoBoxDesc(annoMaterialita)
    }
  }

  onSelectionChangeAutovalutazione(event: MatSelectChange) {
  }

  onSelectionChangePolicy(event: MatSelectChange) {
  }

  onSelectionChangePiano(event: MatSelectChange) {
  }

  async addMaterialita() {

    let idSettore = this.formCreazioneSostenibilita.get('settori')?.value?.id;

    if (idSettore) {
      const dialogCreaAmbito = this.dialog.open(DialogCreaMaterialitaComponent, {
        data: {
          /*    materialita: record, */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaAmbito.afterClosed().subscribe((result) => {


        if (idSettore) {
          this._getAnalisiMaterialita(idSettore);
        }
      });

    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  addPolicy() {
    if (this.formCreazioneSostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formCreazioneSostenibilita.get('settori')?.value?.id;

      const dialogCreaPolicy = this.dialog.open(DialogCreaPolicySostenibilitaComponent, {
        data: {
          /* policy: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaPolicy.afterClosed().subscribe((result) => {
        if (idSettore) {
          this._getPolicySostenibilita(idSettore);
        }
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  addPiano() {
    if (this.formCreazioneSostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formCreazioneSostenibilita.get('settori')?.value?.id;

      const dialogCreaPiano = this.dialog.open(DialogCreaPianoSostenibilitaComponent, {
        data: {
          /* piano: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaPiano.afterClosed().subscribe((result) => {
        if (idSettore) {
          this._getPianoSostenibilita(idSettore);
        }
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /********************************************************* CREAZIONE SURVEY */


  drop(event: CdkDragDrop<{ descrizione: string; chiave: string; id: string }[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }


  compareById(elemento1: any, elemento2: any): boolean {
    return elemento1?.id === elemento2?.id; // Sostituisci "id" con la proprietà univoca dei settori
  }

  compareAnalisiMaterialita(materialita1: AnalisiMaterialita, materialita2: AnalisiMaterialita): boolean {
    return materialita1.idMaterialita === materialita2.idMaterialita; // Sostituisci "id" con la proprietà univoca delle materialità
  }

  compareAnalisiAutovalutazione(autovalutazione1: AutovalutazioneCompilata, autovalutazione2: AutovalutazioneCompilata): boolean {
    return autovalutazione1.id === autovalutazione2.id;
  }

  comparePolicySostenibilita(policy1: PolicySelection, policy2: PolicySelection): boolean {
    return policy1.idPolicy === policy2.idPolicy; // Sostituisci "id" con la proprietà univoca delle policy
  }

  comparePianoSostenibilita(piano1: PianoSelection, piano2: PianoSelection): boolean {
    return piano1.idPiano === piano2.idPiano; // Sostituisci "id" con la proprietà univoca delle piano
  }


  /**
   * Lista di tutte le assegnazioni delle figure 
   * @param stepperIndex index dello stepper in qui sono 
   */
  assegnazioni(stepperIndex: number) {
    this.salvataggioSelezioni(stepperIndex)
      .pipe(
        switchMap(() => {
          const dialogAssegnazioniFigAzz = this.dialog.open(DialogAssegnazioniComponent, {
            data: {
              idContesto: this.formCreazioneSostenibilita.get('id')?.value,
              stato: this.sintesi?.stato,
              contesto: 'sintesi',
              isPermessoSoloRead: this.isPermessoSoloRead
            },
            panelClass: 'dialog-container',
            disableClose: false,
            width: '75%',
            maxHeight: '95%',
            autoFocus: false,
          });

          return dialogAssegnazioniFigAzz.afterClosed();
        })
      )
      .subscribe(() => {
        if (this.isPubblicato()) {
          return;
        }

        switch (stepperIndex) {
          case 2: //Impegni
            this._getImpegniSintesi();
            break;
          case 3: //Azioni e buone pratiche
            this._getAzioniBPSintesi();
            break;
          case 4: //KPI
            this._getKpiSintesi();
            break;
          case 5: //CERTIFICAZIONI
            this._getCertificazioniSintesi();
            break;
          default:
            break;
        }

      });
  }

  salvataggioSelezioni(stepperIndex: number): Observable<boolean> {
    if (this.isPubblicato() || this.isPermessoSoloRead) {
      return of(true);
    }
    switch (stepperIndex) {
      case 2: //Impegni
        this.spinnerOver.show();
        return this.componenteSelImpegniSintesi.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 3: //Azioni e buone pratiche
        this.spinnerOver.show();
        return this.componenteSelAzioneBPSintesi.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 4: //KPI
        this.spinnerOver.show();
        return this.componenteSelKPISintesi.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      case 5: //Certificazione
        this.spinnerOver.show();
        return this.componenteCertificazioniSintesi.salva(true).pipe(finalize(() => this.spinnerOver.hide()));
      default:
        return of();
    }
  }


  /**
   * Get ws impegni sintesi 
   * @returns 
   */
  private _getImpegniSintesi(): Promise<boolean> {
    const id = this.formCreazioneSostenibilita.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();
    return firstValueFrom(this.sintesiService.getImpegniSintesi(id).pipe(
      map(esito => {
        this.objQuestionarioImpegni = esito
        this.componenteSelImpegniSintesi.objQuestionario = esito;
        return true;
      }),
      catchError(err => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero degli impegni',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return of(false);
      }),
      finalize(() => { this.spinnerOver.hide(); this.btnAvanti.disabled = false; })
    ))
  }

  /**
 * Get ws azione e buone pratiche sintesi 
 * @returns 
 */
  private _getAzioniBPSintesi(): Promise<boolean> {
    const id = this.formCreazioneSostenibilita.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();
    return firstValueFrom(this.sintesiService.getAzioneBPSintesi(id).pipe(
      map(esito => {
        this.objQuestionarioAzioniBP = esito
        this.componenteSelAzioneBPSintesi.objQuestionario = esito;
        return true;
      }),
      catchError(err => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle azioni buone pratiche',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return of(false);
      }),
      finalize(() => { this.spinnerOver.hide(); this.btnAvanti.disabled = false; })
    ))

  }


  /**
   * Get ws Kpi sintesi 
   * @returns 
   */
  private _getKpiSintesi(): Promise<boolean> {
    const id = this.formCreazioneSostenibilita.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();
    return firstValueFrom(
      forkJoin({
        anni: this.sintesiService.getAnniPrecKpi(id),
        kpi: this.sintesiService.getKpiSintesi(id)
      }).pipe(
        map(esito => {
          if (esito.kpi) {
            this.objQuestionarioKPI = esito.kpi;
            this.componenteSelKPISintesi.objQuestionario = esito.kpi;
          }

          if (esito.anni) {

            this.valueToggleAnni = esito.anni.includiAnniPrecedenti || false;
          }

          return true
        }),
        catchError(err => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei KPI',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return of(false)
        }),
        finalize(() => { this.spinnerOver.hide(); this.btnAvanti.disabled = false; })
      ))
  }

  /**
 * Get Certificazioni sintesi
 * @returns 
 */
  private _getCertificazioniSintesi(): Promise<boolean> {
    const id = this.formCreazioneSostenibilita.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();
    return firstValueFrom(
      this.sintesiService.getCertificazioniSintesi(id).pipe(
        map(esito => {
          this.objQuestionarioCertificazioni = esito;
          this.componenteCertificazioniSintesi.objQuestionario = esito;
          return true;
        }),
        catchError(err => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero delle certificazioni',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return of(false)
        }),
        finalize(() => { this.spinnerOver.hide(); this.btnAvanti.disabled = false; })
      ))
  }

  cambioToggle(event: any) {


    const id = this.formCreazioneSostenibilita.get('id')?.value;

    if (!id) {
      return;
    }

    this.spinnerOver.show();

    if (event.checked) {

      this.sintesiService.postAnniPrecKpi(id).subscribe({
        next: (esito) => {

          this.valueToggleAnni = true;
          this.spinnerOver.hide();

        },
        error: (err) => {
          this.valueToggleAnni = false;

          this.spinnerOver.hide();
          console.error(err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei KPI',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      });

    } else {

      this.sintesiService.delAnniPrecKpi(id).subscribe({
        next: (esito) => {


          this.valueToggleAnni = false;

          this.spinnerOver.hide();
        },
        error: (err) => {
          this.valueToggleAnni = true;
          this.spinnerOver.hide();
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei KPI',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    }
  }

  //*********************************************** C R U S C O T T O */

  template() {
    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value

    if (idSintesiSostenibilita) {

      const dialogCreaTemplateMail = this.dialog.open(DialogCreaTemplateMailComponent, {
        data: {
          idSintesiSostenibilita,
          isPermessoSoloRead: this.isPermessoSoloRead,
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        height: '95%',
        autoFocus: false,
      });

      dialogCreaTemplateMail.afterClosed().subscribe((result) => {

      });

    }
  }

  async notificaTutti() {

    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value;

    if (idSintesiSostenibilita) {

      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica a tutte le figure che hanno ricevuto la mail',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }
      this.spinnerOver.show();
      this.sintesiService.inviaNotificaMultiple(idSintesiSostenibilita).subscribe({
        next: (value) => {

          this._getQuestionarioSintesi(idSintesiSostenibilita).subscribe();
          this.tabella.caricaDati();
          this.spinnerOver.hide();
          this.utilityService.opneSnackBar('Notifica inviata ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
        },
        error: (err) => {
          console.error(err);
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Non è stato possibile inviare la notifica.',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      })

    }

  }

  async inviaTutti() {
    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value;

    if (idSintesiSostenibilita) {
      if (this._sintesi.stato !== 'PUBBLICATO') {
        const risultatoObservable = await this._pubblicazioneSintesi('con l\'invio mail');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
        }
        this.spinnerOver.hide();
      }

      this.spinnerOver.show();
      this.sintesiService.inviaMailMultiple(idSintesiSostenibilita).subscribe({
        next: (value) => {

          this._getQuestionarioSintesi(idSintesiSostenibilita).subscribe();
          this.tabella.caricaDati();
          this.spinnerOver.hide();
          this.utilityService.opneSnackBar('Mail inviata ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
        },
        error: (err) => {
          console.error(err);
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Non è stato possibile inviare la mail.',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      })

    }
  }

  private async _pubblicazioneSintesi(desc?: string): Promise<Observable<boolean> | undefined> {
    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value;
    let titolo: string = 'Pubblicazione Sintesi di Sostenibilità';
    let descrizione: string = `
      <div>
        <p>
          <strong>Si prega di notare che questa operazione pubblicherà la Sintesi di Sostenibilità.</strong>
        </p>
        <ul>
          <li>La pubblicazione è definitiva e <strong>NON</strong> potrà essere annullata.</li>
          <li>Inoltre non sarà più possibile <strong>aggiungere</strong> o <strong>modificare</strong> le informazioni precedentemente configurate.</li>
        </ul>
        <p><strong>Vuoi procedere ${desc ? desc : ' '}?</strong></p>
      </div>
    `;

    const dialog = await this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      fontWeight: '500',
      bottoni: [
        { nome_btn: 'No', id_btn: 'N' },
        { nome_btn: 'Si', id_btn: 'S' }
      ]
    });

    const valDialog = await firstValueFrom(dialog.beforeClosed());
    if (valDialog === 'N') {
      return of(false);
    }

    // PUBBLICO IL BILANCIO E ASPETTO LA RISPOSTA 
    this.spinnerOver.show();
    await firstValueFrom(this.sintesiService.pubblicaSintesi(idSintesiSostenibilita!));
    return of(true);
  }


  /**
 * Get cruscotto filtrato per figura
 * @param idFigura 
 * @param idSintesiSostenibilita 
 * @returns 
 */
  getFiguraCruscotto(idFigura: string, idSintesiSostenibilita: string) {
    return new Promise((resolve, reject) => {

      let filtroFiguraSelezionata: Filters = {
        values: [idFigura],
        operator: 'eq',
        chiave: 'id'
      };

      this.sintesiService.getCruscotti(idSintesiSostenibilita ?? '', 0, 1000, '', [filtroFiguraSelezionata])
        .subscribe({
          next: data => resolve(data),
          error: err => reject(new Error(err))
        });
    });
  }

  public async eleCompilazioneFigura(elementiCruscotto: ElementiCruscotto[], nome: string, figura: string, idFigura: string) {

    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value;

    if (idSintesiSostenibilita) {

      if (!this.isPubblicato() && !this.isPermessoSoloRead) {

        /* --- Modifica richiesta: Se ci sono elementi precompilati lo posso sapere solamente se pubblico 
            il questionario, una volta pubblicato, il BE calcola le precompilazioni, mi rifaccio la get del cruscotto filtrato
            per idFigura selezionata e mi reperisco gli elementi aggiornati e precompilati. --- */

        const risultatoObservable = await this._pubblicazioneSintesi('con la compilazione');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
          try {
            // CHIAMO LA FIGURA FILTRTA E ASPETTO RISPOSTA 
            const figura: any = await this.getFiguraCruscotto(idFigura!, idSintesiSostenibilita!);
            elementiCruscotto = figura?.content[0]?.elementi;
          } catch (err) {
            console.error(err);
          }
        }
        this.spinnerOver.hide();
      }

      const dialogCreaTemplateMail = this.dialog.open(DialogCompilazioneFigureComponent, {
        data: {
          elementiCruscotto,
          idSintesiSostenibilita,
          nome,
          figura,
          valueToggleAnni: this.valueToggleAnni,
          isPermessoSoloRead: this.isPermessoSoloRead
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '75%',
        maxHeight: '95%',
        autoFocus: false,
      });

      dialogCreaTemplateMail.afterClosed().subscribe((result) => {


        this._getQuestionarioSintesi(idSintesiSostenibilita).subscribe();
        this.tabella.caricaDati();

        this.caricaTotaliCruscotto(idSintesiSostenibilita);

      });

    }
  }


  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value

    if (idSintesiSostenibilita) {
      return this.sintesiService.getCruscotti(idSintesiSostenibilita, page, pageSize, ricerca, filters, sortBy);
    } else {
      return of(false);
    }

  };

  modificaFigura(dati: any) {

    const dialogAssociaFiguraAziendale = this.dialog.open(DialogAssociaFiguraAziendaleComponent, {
      data: {
        figura: { id: dati.id }
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogAssociaFiguraAziendale.afterClosed().subscribe((idFiguraNuova) => {

      const idFiguraPrecedente = dati?.id;



      const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value;

      if (idSintesiSostenibilita && idFiguraPrecedente && idFiguraNuova) {

        this.sintesiService.putCambiaFigura(
          idSintesiSostenibilita,
          idFiguraPrecedente,
          idFiguraNuova
        ).subscribe({
          next: (value) => {

            this.tabella.caricaDati();
          },
          error: (err) => {
            this.tabella.caricaDati();
            console.error(err);
          }
        });
      }

    });
  }


  caricaTotaliCruscotto(idBilancioSostenibilita: string): Promise<boolean> {
    return firstValueFrom(this.sintesiService.getTotaliCruscotto(idBilancioSostenibilita).pipe(
      map((totaliCruscotto: any) => {
        this.kpiCompilati = totaliCruscotto.nrKpiCompilati || 0;
        this.kpiNonCompilati = (totaliCruscotto.nrKpi || 0) - this.kpiCompilati;
        this.azioneCompilati = totaliCruscotto.nrAzioniCompilate || 0;
        this.azioneNonCompilati = (totaliCruscotto.nrAzioni || 0) - this.azioneCompilati;
        this.impegniCompilati = totaliCruscotto.nrImpegniCompilati || 0;
        this.impegniNonCompilati = (totaliCruscotto.nrImpegni || 0) - this.impegniCompilati;
        return true;
      }),
      catchError(err => {
        console.error(err);
        return of(false)
      }),
      finalize(() => this.spinnerOver.hide())

    )
    )
  }

  private async _inviaNotifica(cruscotto: any) {
    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value;

    if (idSintesiSostenibilita) {

      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica alla figura selezionata',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      this.sintesiService.inviaNotifica(idSintesiSostenibilita, cruscotto.id).subscribe({
        next: (value) => {

          this._getQuestionarioSintesi(idSintesiSostenibilita).subscribe();
          this.tabella.caricaDati();

          this.utilityService.opneSnackBar('Notifica inviata ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
        },
        error: (err) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore invio mail',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      })

    }
  }

  private async _inviaMail(cruscotto: any) {

    const idSintesiSostenibilita = this.formCreazioneSostenibilita.get('id')?.value;

    if (idSintesiSostenibilita) {

      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Invia mail ',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      this.sintesiService.inviaMail(idSintesiSostenibilita, cruscotto.id).subscribe({
        next: (value) => {

          this._getQuestionarioSintesi(idSintesiSostenibilita).subscribe();
          this.tabella.caricaDati();

          this.utilityService.opneSnackBar('Mail inviata ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
        },
        error: (err) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore invio mail',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      })

    }
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.modificaFigura(event.data);
        break;
      case 'compila':
        this.eleCompilazioneFigura(event.data.elementi, event.data.nome, event.data.figura, event.data.id);
        break;
      case 'azione':
        if (event.data) {
          if (event.data.ultimoInvio) {

            this._inviaNotifica(event.data);
          } else {

            this._inviaMail(event.data);
          }
        }
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }


  private _getQuestionarioSintesi(id: string) {

    return this.sintesiService.getSintesiSurveyStep2(id).pipe(catchError((err) => {
      console.error(err);
      if (err.status === 404) {
        return of(false);
      } else {
        return throwError(() => err);
      }
    })).pipe(map((sintesi) => {
      if (sintesi) {
        this._sintesi = sintesi;
      }
      return sintesi;
    }));
  }

  private async _caricaSettori() {

    this.spinnerOver.show();

    try {

      const risp = await firstValueFrom(
        forkJoin({
          generale: this.settoriService.getSettoreGenerale().pipe(
            catchError((err) => {
              console.error('ERRORE GET SETTORE GENERALE : ', err);
              // Errore recupero settore generale, potrebbe non esistere. Si torna undefined
              return of(undefined);
            })
          ),
          specifici: this.aziendaService.getSettoriAzienda(),
          sintesiCreate: this.sintesiService.getSintesi(0, 1000, '', []).pipe(
            catchError((err) => {
              console.error('ERRORE GET SINTESI CREATE : ', err);
              return of(undefined);
            })
          ),
        })
      );

      this.settoriAzienda = risp?.specifici || [];
      this.settoreGenerale = risp?.generale;

      for (const sintesi of risp?.sintesiCreate?.content) {
        const anno = sintesi.anno;
        const settore = sintesi.settori[0]?.id;

        if (!this.mappaAnnoSettore[anno]) {
          this.mappaAnnoSettore[anno] = {};
        }

        this.mappaAnnoSettore[anno][settore] = true;
      }


      if (!this.settoriAzienda?.length && !this.settoreGenerale) {
        console.error('NESSUN SETTORE DISPONIBILE, IMPOSSIBILE PROSEGUIRE');
      }

      this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === this.formCreazioneSostenibilita.get('settori')?.value?.id)?.sottoSettori || [];

      this.spinnerOver.hide();

    } catch (error) {

      console.error(error);

      this.spinnerOver.hide();

      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Errore nel recupero dei settori',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      this.dialogRef.close();

    }

  }

  // Gestione cancellazione in mat select 
  hasValue(formControlName: string): boolean {
    const control = this.formCreazioneSostenibilita.get(formControlName);
    const controlValue = control?.value;

    if (formControlName === 'idMaterialita') {
      return !!(this.data.sintesi || !controlValue || (Array.isArray(controlValue) && controlValue.length === 0) || control.status === 'DISABLED');
    } else {
      return !!(this.data.sintesi || !controlValue || control.status === 'DISABLED');
    }
  }

  clearInput(formControlName: string, event?: Event): void {
    const field = this.formCreazioneSostenibilita.get(formControlName);
    if (!field?.disabled) {
      field?.reset();
      field?.markAsDirty();
      field?.markAsTouched();
    }
    if (event) event.stopPropagation();

    const idMaterialitaValue = this.formCreazioneSostenibilita.get('idMaterialita')?.value;
    if (!idMaterialitaValue || !Array.isArray(idMaterialitaValue) || idMaterialitaValue.length === 0) {
      this.mostraDescrizione = false;
    }
  }

  mostraDescrizioneAssessment() {
    return this.arrayAnalisiAutovalutazione.length ? 'Assessment di Sostenibilità' : 'Assessment non selezionato'
  }
  mostraDescrizionMaterialita() {
    return this.arrayAnalisiMaterialita.length ? 'Analisi Materialità' : 'Materialità non selezionata'
  }
  mostraDescrizionPolicy() {
    return this.arrayPolicySostenibilita.length ? 'Policy di Sostenibilità' : 'Policy non selezionata'
  }
  mostraDescrizionPiano() {
    return this.arrayPianoSostenibilita.length ? 'Piano di Sostenibilità' : 'Piano non selezionata'
  }

  private _updateSottoSettoriSelezionati() {
    const sottoSettoriSel = this.formCreazioneSostenibilita.get('sottoSettori')?.value || [];

    const sottoSettoriaggiornati = sottoSettoriSel.filter(
      (sottoSettSel) => {
        return this.arraySottoSettori && sottoSettSel.id && !!this.arraySottoSettori.find(
          (sottoSett) => {
            return sottoSett.id === sottoSettSel.id;
          });
      }
    );

    this.formCreazioneSostenibilita.get('sottoSettori')?.setValue(sottoSettoriaggiornati || []);
  }

  /* ------------------------------ S T A M P A  -------------------------*/

  /**
     * Lancia il salvataggio della configurazione, passando dal componente.
     */
  public salvaCfgStampa() {

    if (!this.cfgStampaComponent.cfgStampa) {
      console.error('NESSUNA CFG STAMPA DA STAMPARE');
      return;
    }

    this.spinnerOver.show();

    this.fnSalvaCfgStampa(this.cfgStampaComponent.cfgStampa).subscribe((esito) => {

      if (!esito) {
        // Salvataggio fallito
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Salvataggio configurazione non riuscito. Verificare le informazioni e riprovare.',
          bottoni: [{ nome_btn: 'Ok' }]
        });
      }

      this.spinnerOver.hide();

    })
  }

  /**
   * Metodo che mi stampa
   */
  public async stampa() {

    const idSintesi = this.formCreazioneSostenibilita.get('id')?.value;

    if (idSintesi) {
      await this.caricaTotaliCruscotto(idSintesi)
    }

    if (this.kpiNonCompilati || this.azioneNonCompilati || this.impegniNonCompilati) {
      let titolo: string = 'Stampa';
      let descrizione: string = `
      <div>
        <p>
          Non sono state <strong>completate</strong> tutte le <strong>compilazioni</strong>.
        </p>
        <p><strong>Vuoi procedere?</strong></p>
      </div>`;

      const dialog = await this.utilityService.openDialog({
        titolo: titolo,
        descrizione: descrizione,
        fontWeight: '500',
        bottoni: [
          { nome_btn: 'No', id_btn: 'N' },
          { nome_btn: 'Si', id_btn: 'S' }
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return of(false);
      }
    }

    if (!this.cfgStampaComponent.cfgStampa) {
      console.error('NESSUNA CFG STAMPA DA SALVARE');
      return;
    }

    this.spinnerOver.show()

    if (idSintesi && this.cfgStampaComponent.cfgStampa) {

      this.fnSalvaCfgStampa(this.cfgStampaComponent.cfgStampa).pipe(
        switchMap((result: any) => {
          if (result) {
            return this.sintesiService.postStampaSintesi(idSintesi);
          } else {
            return of()
          }
        })
      ).subscribe(
        {
          next: (urlStampa) => {
            window.open(urlStampa?.body, '_blank');

            this.spinnerOver.hide();
          },
          error: (err) => {
            console.error(err);

            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Stampa non riuscita. Verificare le configurazioni e riprovare.',
              bottoni: [{ nome_btn: 'Ok' }]
            });
          }
        }
      )
    }
    return
  }

  /**
 * 
 * @returns Funzione di get cfg stampa da impostare sul componente cfgStampaComponent
 */
  public fnGetConfigStampa = () => {
    const idSintesi = this.formCreazioneSostenibilita.get('id')?.value;
    if (idSintesi) {
      return this.sintesiService.getConfigStampa(idSintesi);
    } else {
      return throwError(() => "ID bilancio non valorizzato");
    }
  }

  /**
   * Funzione di salvataggio cfg stampa da impostare sul componente cfgStampaComponent
   * @param grpCfgStampa 
   * @returns 
   */
  public fnSalvaCfgStampa = (grpCfgStampa: GruppoCfgStampa) => {
    const idSintesi = this.formCreazioneSostenibilita.get('id')?.value;

    if (idSintesi) {

      if (this.isPermessoSoloRead) {
        return of(true);
      }

      return this.sintesiService.postSalvaElementiStampa(idSintesi, grpCfgStampa)
    } else {
      return throwError(() => "ID bilancio non valorizzato");
    }
  }

  /**
  * Metodo che mi fa l'importazione del file immagine 
  * @param elemStampa 
  */
  public fnImportaImmagine = (file: File, chiaveCfg: string) => {

    if (!this.formCreazioneSostenibilita.get('idAzienda')?.value ||
      !this.formCreazioneSostenibilita.get('id')?.value) {
      return throwError(() => "DATI INSUFFICIENTI PER ELIMINAZIONE IMMAGINE");
    }

    const listaId = {
      idAzienda: this.formCreazioneSostenibilita.get('idAzienda')!.value,
      idSintesiSostenibilita: this.formCreazioneSostenibilita.get('id')!.value!,
      chiaveCfg
    }

    return this.importazioniService.importImgStampaSintesi(file, {}, listaId);

  }

  /**
 * Metodo per eliminazione immagine in cfg stampa 
 * Viene rimosso da google cloud storage 
 */
  public fnEliminaImg = (chiaveCfg: string) => {

    if (!this.formCreazioneSostenibilita.get('idAzienda')?.value ||
      !this.formCreazioneSostenibilita.get('id')?.value) {
      return throwError(() => "DATI INSUFFICIENTI PER ELIMINAZIONE IMMAGINE");
    }

    const listaId = {
      idAzienda: this.formCreazioneSostenibilita.get('idAzienda')!.value,
      idSintesiSostenibilita: this.formCreazioneSostenibilita.get('id')!.value!,
      chiaveCfg
    }

    return this.importazioniService.deleteImgStampaSintesi(listaId);
  }


  public toggleLoading(mostra: boolean) {
    if (mostra) {
      this.spinnerOver.show();
    } else {
      this.spinnerOver.hide();
    }
  }

  ctrAnnoSintesiUtilizzata(anno: string) {
    return this.isAnnoSettoreValid(anno, this.formCreazioneSostenibilita.get('settori')?.value?.id);
  }

  ctrSettoreSintesiUtilizzata(settore: Settore) {
    const anno = this.formCreazioneSostenibilita.get('anno')?.value;
    return this.isAnnoSettoreValid(anno, settore?.id);
  }

  private isAnnoSettoreValid(anno: string | undefined, settoreId: string | undefined) {
    return anno && settoreId && this.mappaAnnoSettore[anno]?.[settoreId] ? true : false;
  }


  saltaCtrStepper(idx: number) {

    // Se i miei permessi sono solo in read 
    if (this.isPermessoSoloRead) {
      return true;
    }

    // Se pubblicato 
    if ((this.isPubblicato() || this.data?.sintesi?.stato === 'PUBBLICATO')) {
      return true
    }

    // Se torno indietro non faccio ne controlli ne salvataggi
    if (idx < this.stepper.selectedIndex) {
      return true
    }
    return false;
  }


  /**
   * Metodo che gestisce la logica della navigazione sugli stepper 
   */
  public gestioneStepperSintesi() {
    setTimeout(() => {

      this.stepper.steps.forEach((step, idx) => {
        step.select = async () => {

          // Salto controllo stepper 
          if (this.saltaCtrStepper(idx)) {
            return this.stepper.selectedIndex = idx;
          }

          /* Se step selezionato è completed devo controllare gli step in mezzo
           Esempio (sono su STEP 1 seleziono STEP 6 , devo controllare STEP 2-3-4-5) */
          if (this.stepper.steps.get(idx)?.completed) {
            return this.stepper.selectedIndex = await this.ctrStep(idx);
          }


          if (idx === this.stepper.selectedIndex + 1) { // linear
            const isSalvato = await this.avanti();

            if (isSalvato) {
              return this.stepper.selectedIndex = idx;
            }
          }
          return;
        };
      });

    }, 0);
  }

  /**
   * gestione controlli step selezionati
   * @param stepperSelezionato 
   * @returns 
   */
  private async ctrStep(stepperSelezionato: number): Promise<number> {
    const stepperCorrente = this.stepper.selectedIndex;

    if (stepperSelezionato > stepperCorrente) {
      for (let index = stepperCorrente; index < stepperSelezionato; index++) {

        // Salvo step corrente
        if (stepperCorrente === index) {
          const isSalvato = await this.avanti();
          if (!isSalvato) {
            return stepperCorrente;
          } else {
            continue;
          }
        }

        // Controllo step by step e ritorno l'index dove da errore
        if (await this.ritornoControllo(index)) {
          return index;
        }

      }
    }

    return stepperSelezionato;
  }

  /**
  * Controlli degli stepper
  * @param stepperDaControllare 
  * @returns 
  */
  private async ritornoControllo(stepperDaControllare: number): Promise<boolean> {
    let isCtrFallito: boolean = false;;
    switch (stepperDaControllare) {
      case 1:
        isCtrFallito = this.componenteSurvey.ctrSalva();
        break;

      case 2: {
        const esito = await this._getImpegniSintesi();
        if (esito) {
          isCtrFallito = this.componenteSelImpegniSintesi.ctrStepper('impegno');
        }
        break;
      }

      case 3: {
        const esito = await this._getAzioniBPSintesi();
        if (esito) {
          isCtrFallito = this.componenteSelAzioneBPSintesi.ctrStepper('azione o buona pratica');
        }
        break;
      }

      case 4: {
        const esito = await this._getKpiSintesi();
        if (esito) {
          isCtrFallito = this.componenteSelKPISintesi.ctrStepper('kpi');
        }
        break;
      }

      default:
        break;
    }
    return isCtrFallito

  }

  /**
    * Viene richiamato nel momento in cui si caricano le tamatiche (questionario)
    */
  public completaStep() {
    if (this.isPubblicato()) {
      this.stepper.steps.forEach((step, idx) => {
        step.completed = true;
      });
    }
  }
}
