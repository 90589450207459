import { Injectable } from '@angular/core';
import { StandardRendicontazioneWebService } from './standard-rendicontazione-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore } from '../settori/settori.service';
import { Padre } from 'src/app/components/select-custom/select-custom.component';
import { map, of } from 'rxjs';

export interface FigliStdr {
  id: string,
  settore: Settore,
  codice: string,
  descrizione: string,
  selected: boolean,
  target: boolean,
}

export interface STDR {
  id: string,
  generale: boolean,
  compliance: boolean,
  obbligatorio: boolean,
  settoriale: boolean,
  figli: FigliStdr[],
  codice: string,
  descrizione: string,
  selected: boolean,
  target: boolean,
  urlFile?: string
}


@Injectable({
  providedIn: 'root'
})
export class StandardRendicontazioneService {

  constructor(
    private readonly stdrWebService: StandardRendicontazioneWebService
  ) {

  }

  /**
   * GET PAGINATA
   * @param page 
   * @param pageSize 
   * @param ricerca 
   * @param filters 
   * @param sortBy 
   * @returns 
   */
  public getStdr(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.stdrWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  public deleteStdr(idRiga: any) {
    return this.stdrWebService.delete(idRiga);
  }

  public postStdr(stdr: any) {
    return this.stdrWebService.post(stdr);
  }

  public putStdr(id: string, stdr: any) {
    return this.stdrWebService.put(id, stdr);
  }


  public setOggettoSelection(stdr: STDR[], isCompliance: boolean, bilancio?: boolean): Padre[] {
    return stdr
      .filter(padre => padre.compliance === isCompliance)
      .map(padre => {
        const figli = padre.figli.map(figlio => ({
          id: figlio.id,
          nomeFiglio: figlio.codice,
          selected: figlio.selected,
          descrizioneFiglio: figlio.descrizione
        }));

        return {
          id: padre.id,
          nomePadre: padre.codice,
          descrizionePadre: padre.descrizione,
          selected: padre.selected,
          figli: figli,
          disabledPadre: (!padre.generale && padre.settoriale) && !bilancio,
        } as Padre;
      });
  }

  public setOggettoSelectionStdr(stdr: STDR[], bilancio?: boolean): Padre[] {
    return this.setOggettoSelection(stdr, false, bilancio);
  }

  public setOggettoSelectionCompliance(stdr: STDR[], bilancio?: boolean): Padre[] {
    return this.setOggettoSelection(stdr, true, bilancio);
  }

  /**
   * Merge dei valori selezionati dal componente select custom con l'oggetto originale,
   * Prendo l'oggetto originale e cambio il valore della selected con quello del componente custom 
   * @param stdrElaborato oggetto della select-custom
   * @param stdrOriginale oggetto originale 
   * @returns 
   */
  mergeSelectedStdr(stdrElaborato: any[], stdrOriginale: any[], isBilancio?: boolean) {
    return of(stdrOriginale).pipe(
      map(obj2Data =>
        obj2Data.map(padreOriginale => {
          // Trova il corrispondente padre in stdrElaborato
          const padreElaborato = stdrElaborato.find(padreElab => padreElab.id === padreOriginale.id);
          if (padreElaborato) {
            // Aggiorna il valore 'selected' del padre con quello di stdrElaborato
            padreOriginale.selected = padreElaborato.selected;

            // Se entrambi i padri (originale e elaborato) hanno figli, esegui il merge sui figli
            if (padreOriginale.figli && padreOriginale.figli.length > 0 && padreElaborato.figli && padreElaborato.figli.length > 0) {
              padreOriginale.figli = padreOriginale.figli.map((figlioOriginale: any) => {
                // Trova il corrispondente figlio in padreElaborato
                const figlioElaborato = padreElaborato.figli.find((figlio: any) => figlio.id === figlioOriginale.id);
                if (figlioElaborato) {
                  // Aggiorna il valore 'selected' del figlio con quello di stdrElaborato
                  figlioOriginale.selected = figlioElaborato.selected;
                }
                return figlioOriginale; // Ritorna il figlio aggiornato o invariato
              });
            }
          }
          return padreOriginale; // Ritorna il padre aggiornato o invariato
        })
      ),
      map(padri =>
        // Filtra i padri per includere:
        // - Padri che hanno almeno un figlio con 'selected: true'
        // - Padri senza figli ma con 'selected: true'
        padri.filter(padre => {
          if (padre.selected && !isBilancio) {
            // Se il padre è selected è non sono in contesto bilancio, i figli devono essere un array vuoto
            padre.figli = [];
          } else if (padre.figli && padre.figli.length > 0) {
            // Mantieni solo i figli con 'selected: true'
            padre.figli = padre.figli.filter((figlio: any) => figlio.selected);
          }
          // Mantieni il padre se:
          // - Ha almeno un figlio con 'selected: true'
          // - Oppure non ha figli ma il padre stesso ha 'selected: true'
          return (padre.figli && padre.figli.length > 0) || padre.selected;
        })
      )
    );
  }




}
