import { Component } from '@angular/core';

@Component({
    selector: 'app-statistiche',
    templateUrl: './statistiche.component.html',
    styleUrls: ['./statistiche.component.scss'],
    standalone: false
})
export class StatisticheComponent {

}
