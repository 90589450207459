<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>
    Gestione Licenze
</h2>
<div mat-dialog-content class="dialog-center-cont">
    <app-gestione-licenze [tipoLicenza]="this.data.tipo" [isSostenibileGo]="this.data.isUtenteGO"></app-gestione-licenze>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
            Conferma
        </button>
    </div>

</div>