<div class="cont-comp">

    <div appFormElement [larghezza]="1" [riempi]="true">
        <div class="titolo-sezione-dialog">
            Selezione Stakeholder {{tipo === 'INTERNI' ? 'Interni' : 'Esterni'}}
        </div>
        <div class="descrizione-sezione-dialog">
            Seleziona gli stakeholder {{tipo === 'INTERNI' ? 'Interni' : 'Esterni'}} da coinvolgere nell'analisi e
            definiscine il grado di rilevanza attribuendo un peso a ciascuna categoria. Ricorda che il peso complessivo
            dovrà essere pari a 100.
        </div>

    </div>
    <div style="display: flex; width: 100%; column-gap: 15px;">
        <div class="cont-filtri">
            <mat-form-field style="width: 100%;" class="ricerca-input">
                <input matInput placeholder="Inserisci chiave di ricerca" [(ngModel)]="searchValue"
                    (keyup)="applicaFiltroRicerca($event)">
                <mat-icon matSuffix style="color: var(--mat-color-primary);">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="cont-bottoni">
            <div>
                <button mat-flat-button class="primary-button" color="accent" (click)="apriFiltro()"
                    [matBadge]="valFiltri.length" [matBadgeHidden]="valFiltri.length === 0" matBadgeSize="small"
                    matBadgeColor="warn">
                    <mat-icon matSuffix>filter_list</mat-icon>
                    Filtri
                </button>
            </div>
            <div>
                <button mat-flat-button class="primary-button" color="primary" (click)="selezionaTutto()"
                    [disabled]="dataUltimaConferma !== null || isPermessoSoloRead" *ngIf="btnSelezionaTutto === true">
                    Seleziona Tutto
                </button>
                <button mat-flat-button class="primary-button" color="primary" (click)="deselezionaTutto()"
                    [disabled]="dataUltimaConferma !== null || isPermessoSoloRead" *ngIf="btnSelezionaTutto === false">
                    Deseleziona Tutto
                </button>
            </div>
        </div>
    </div>
</div>

<div class="testo-info">
    <div class="titolo-info" (click)="comeDefinireIlPeso()">
        <div style="font-weight: bold;">Come definire il peso degli stakeholder?</div>
    </div>
</div>

<div class="cont-titolo">
    <div>
        <mat-label class="titolo">Stakeholder {{tipo === 'INTERNI' ? 'Interni' : 'Esterni'}}</mat-label>
    </div>

    <div>
        <div class="totPeso">
            Tot. Peso: {{totPeso | async}}
        </div>
    </div>
</div>
<div style="flex: 1;">
    <ng-scrollbar>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let catst of arrayGroupCateStake">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-checkbox [checked]="allComplete[catst.id]" [indeterminate]="someComplete(catst)"
                            (mousedown)="$event.stopPropagation()" (change)="setAll($event.checked , catst)"
                            [disabled]="dataUltimaConferma !== null || isPermessoSoloRead">


                        </mat-checkbox>
                        <span class="nomeStakeholder">{{catst.titolo}}</span>

                        <span class="nrSel">{{nrSelezionati}}/{{catst.stakeholders.length}}</span>
                    </mat-panel-title>
                    <mat-panel-description>

                        <mat-form-field style="width: 80px;height: 50px;" (click)="$event.stopPropagation()"
                            subscriptSizing="dynamic">
                            <mat-label>Peso</mat-label>
                            <input matInput placeholder="Peso" [value]="catst.peso" type="number" appInputNumber min="0"
                                max="100" (input)="cambioPeso($any($event),catst)" (keydown)="onKeyDown($event)"
                                [disabled]="dataUltimaConferma !== null || isPermessoSoloRead" />
                        </mat-form-field>

                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div appFormContainer [larghezza]="2" *ngFor="let st of catst.stakeholders">
                    <div appFormElement [larghezza]="1" [riempi]="true">
                        <mat-checkbox (ngModelChange)="updateAllComplete(catst)" [(ngModel)]="st.selected"
                            [disabled]="dataUltimaConferma !== null || isPermessoSoloRead"
                            (mousedown)="$event.stopPropagation()">
                            <div class="nomeStakeholder">{{st.cognome}} {{st.nome}} {{st.ragSoc}}</div>
                        </mat-checkbox>
                    </div>
                    <div appFormElement [larghezza]="0" [minWidth]="50">
                        <button class="icone-button" mat-icon-button (click)="modifica(st)"
                            [title]=" (dataUltimaConferma !== null || isPermessoSoloRead) ? 'Visualizza': 'Modifica'"
                            [attr.aria-label]="(dataUltimaConferma !== null || isPermessoSoloRead) ? 'Visualizza': 'Modifica'">
                            <mat-icon class="icone">
                                {{(dataUltimaConferma !== null || isPermessoSoloRead) ? 'visibility' : 'edit'}}
                            </mat-icon>
                        </button>
                    </div>

                </div>

                <div>
                    <!--  -->
                    <button mat-stroked-button style="height: 56px;width: 100%;font-weight: bold;"
                        [disabled]="dataUltimaConferma !== null || isPermessoSoloRead" (click)="addStake(catst)">
                        Aggiungi Stakeholder
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-scrollbar>
</div>