import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { ElementiCruscotto } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCompilaSintesiSostenibilitaComponent } from '../dialog-compila-sintesi-sostenibilita/dialog-compila-sintesi-sostenibilita.component';

@Component({
    selector: 'app-dialog-compilazione-figure',
    templateUrl: './dialog-compilazione-figure.component.html',
    styleUrls: ['./dialog-compilazione-figure.component.scss'],
    standalone: false
})
export class DialogCompilazioneFigureComponent implements OnInit, AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [];


  public colonne: { [key: string]: Colonna } | undefined = undefined

  constructor(
    private readonly utilityService: UtilityService,
    public dialogRef: MatDialogRef<DialogCompilazioneFigureComponent>,
    public dialog: MatDialog,
    private readonly cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      elementiCruscotto: ElementiCruscotto[],
      idSintesiSostenibilita: string,
      idBilancioSostenibilita: string,
      idPolicySostenibilita: string,
      idPianoSostenibilita: string,
      nome: string,
      figura: string,
      valueToggleAnni: boolean,
      isPermessoSoloRead?: boolean
    }) {


  }

  ngOnInit(): void {
    let colonnePersonalizzate: any =
    {
      tipo_elemento: {
        title: 'Informazione',
        value: 'descrizioneTipo'
      },
      titolo: {
        title: 'Titolo',
        value: 'nome'
      },
    }

    if (this.data.idPianoSostenibilita) {
      colonnePersonalizzate.obiettivo = {
        title: 'Obiettivo',
        value: 'titoloObiettivo'
      }
    }

    colonnePersonalizzate.valore = {
      title: 'Valore',
      value: (record: any) => (this.visualizzaRecordValore(record)),
      width: '50%'
    },

      colonnePersonalizzate.modifica = {
        type: 'button',
        buttonIcon: (record: any) => this.data?.isPermessoSoloRead ? 'visibility' : (record.compilato === true ? 'edit' : 'history_edu'),
        title: (record: any) => this.data?.isPermessoSoloRead ? 'Visualizza' : (record.compilato === true ? 'Modifica' : 'Compila'),
        buttonId: 'compila',
        buttonMostraSempre: true
      },

      this.colonne = colonnePersonalizzate;

    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {

  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    return this.utilityService.getDatiTableArray(this.data.elementiCruscotto, ['descrizioneTipo', 'nome'], ricerca, page, pageSize);

  };

  /**
   * Metodo che mi valorizza la colonna "valore" della tabella lista elementi per figura
   * @param record 
   * @returns 
   */
  visualizzaRecordValore(record: any) {

    if (record.tipo === 'Kpi') {
      return record?.valoriKpi?.length ? (record?.valoriKpi[0]?.valoreAttribuito ?? '') : '';
    }

    if (record.tipo === 'AzioniEBuonePratiche' && (this.data.idPolicySostenibilita || this.data.idPianoSostenibilita)) {
      return record?.rispostaBreve ? (record?.rispostaBreve ?? '') : '';
    }

    return record?.risposta ? (record?.risposta ?? '') : '';

  }

  compila(dati: ElementiCruscotto) {

    const dialogCompilaSintesiSostenibilita = this.dialog.open(DialogCompilaSintesiSostenibilitaComponent, {
      data: {
        idSintesiSostenibilita: this.data.idSintesiSostenibilita,
        idBilancioSostenibilita: this.data.idBilancioSostenibilita,
        idPolicySostenibilita: this.data.idPolicySostenibilita,
        idPianoSostenibilita: this.data.idPianoSostenibilita,
        idAmbito: dati.idAmbito,
        idTematica: dati.idTematica,
        idElemento: dati.id,
        valueToggleAnni: this.data.valueToggleAnni,
        idObiettivo: dati.idObiettivo,
        isPermessoSoloRead: this.data?.isPermessoSoloRead,
        tipo: dati.tipo
      },
      panelClass: ['dialog-container'],
      disableClose: false,
      width: '77%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCompilaSintesiSostenibilita.afterClosed().subscribe((statoCompilatoInChiusura) => {
      if (statoCompilatoInChiusura?.salvato) {
        //** chiudo la dialog e metto compilato se gli viene passato true */
        dati.compilato = true;

        if (dati.tipo === 'Kpi') {

          dati.valoriKpi = statoCompilatoInChiusura.risposta || null;
        }
        else if (dati.tipo === 'AzioniEBuonePratiche' && (this.data.idPolicySostenibilita || this.data.idPianoSostenibilita)) {
          dati.rispostaBreve = statoCompilatoInChiusura.rispostaBreve || null;;
        }
        else {
          dati.risposta = statoCompilatoInChiusura.risposta || null;
        }

        this.tabella.caricaDati();

      }
    });
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'compila':
        this.compila(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }
}
