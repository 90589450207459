import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { StakeholderService } from 'src/app/services/stakeholder/stakeholder.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaStakeholderComponent } from './dialog/dialog-crea-stakeholder/dialog-crea-stakeholder.component';
import { DialogImportazioneExcelComponent } from './dialog/dialog-importazione-excel/dialog-importazione-excel.component';
import { Subscription } from 'rxjs';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { UtenteService } from 'src/app/services/utente/utente.service';

@Component({
    selector: 'app-stakeholder',
    templateUrl: './stakeholder.component.html',
    styleUrls: ['./stakeholder.component.scss'],
    standalone: false
})
export class StakeholderComponent implements AfterViewInit, OnDestroy {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  private _subAziendaCorrente: Subscription | undefined;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Cognome',
      forControlName: 'cognome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
    {
      titolo: 'Tipologia',
      forControlName: 'tipologia',
      input: 'multiple-option',
      idValueOption: 'id',
      descValueOption: 'descrizione',
      fnDatiOption: () => this.stakeholderService.getTipologia()
    }
  ];

  public colonne: { [key: string]: Colonna } = {
    codice: {
      title: 'Nome/Cognome',
      sortable: true,
      campiSort: ['cognome', 'nome'],
      value: (record: any) => (record?.cognome + ' ' + record?.nome),
    },
    ragSoc: {
      title: 'Ragione Sociale',
      sortable: true,
      value: (record: any) => (record?.ragSoc)
    },
    email: {
      title: 'Email',
      value: 'email',
      sortable: true
    },
    tipologia: {
      title: 'Tipologia',
      sortable: true,
      value: (record: any) => (record?.tipologia === 'INTERNI' ? 'Interno' : 'Esterno')
    },
    categoria: {
      title: 'Categoria',
      sortable: true,
      value: (record: any) => ((record?.categoria?.titolo))
    },
    modalita: {
      title: 'Modalità',
      campiSort: ['modalitaCompilazione'],
      sortable: true,
      value: (record: any) => (record?.modalitaCompilazione === 'INVIAMAIl' ? 'Email' : 'Manuale')
    },
    modifica: {
      type: 'button',
      buttonIcon: this.isPermessoSoloRead() ? 'visibility' : 'edit',
      title: this.isPermessoSoloRead() ? 'Visualizza' : 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      /*  nascondiButton: (!this.utenteService.isPermessoAttivo('EDIT_STAKEHOLDERS')) */
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (!this.utenteService.isPermessoAttivo('DELETE_STAKEHOLDERS')),
      buttonMostraSempre: (!this.utenteService.isPermessoAttivo('DELETE_STAKEHOLDERS'))
    },
  };

  constructor(
    public dialog: MatDialog,
    private readonly utilityService: UtilityService,
    private readonly stakeholderService: StakeholderService,
    private readonly aziendaService: AziendaService,
    public utenteService: UtenteService
  ) { }

  isPermessoSoloRead() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_STAKEHOLDERS');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_STAKEHOLDERS')
    return (!isEdit && !isCreate)
  }


  ngAfterViewInit(): void {
    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this.tabella?.caricaDati();
    });
  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }

  creaNuovo(record?: any) {
    const dialogCreaStakeHolder = this.dialog.open(DialogCreaStakeholderComponent, {
      data: {
        stakeholder: record,
      },
      panelClass: ['dialog-container'],
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaStakeHolder.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.stakeholderService.getStakeholder(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'elimina':
        this._elimina(event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  private _elimina(id: string) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare lo Stakeholder?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI',
          background_color: 'var(--mat-color-primary)',
          handler: () => {
            this.stakeholderService.deleteStakeholder(id).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });
  }

  public importaStakeHolder() {
    const dialogImportaStakeholder = this.dialog.open(DialogImportazioneExcelComponent, {
      data: {
        titoloImportazione: 'Importazione StakeHolder',
        subTitoloImportazione: 'Scarica il file di template excel o fai una nuova importazione degli stakeholder',
        pathTemplateExcel: 'assets/excel/stakeholders.xlsx',
        azienda: true,
        nomeImport: 'stakeholders',
      },
      panelClass: ['dialog-container'],
      disableClose: false,
      maxHeight: '95%',
      maxWidth: '85%',
      autoFocus: false,
    });

    dialogImportaStakeholder.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }
}
