import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map, catchError, of, throwError, Subscription } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { AutovalutazioneCompilata, AutovalutazioneEsterna, AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { TemaService } from 'src/app/services/tema/tema.service';

@Component({
    selector: 'app-autovalutazione-esterno',
    templateUrl: './autovalutazione-esterno.component.html',
    styleUrls: ['./autovalutazione-esterno.component.scss'],
    standalone: false
})
export class AutovalutazioneEsternoComponent implements OnInit, AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public completato: boolean = false;

  public logoPath: string = 'assets/images/logo/logo-sostenibile.png';

  public idComp!: string;
  public temporaryToken!: string;
  public idAzienda?: string;
  public compilazione: any = {};
  public esercizio?: number;
  public idAutovalutazioneCompilata?: string;
  public messaggioErrore: string = '';
  public landingPage: string = '';

  private _subTema: Subscription;

  constructor(
    private route: ActivatedRoute,
    private autovalutazioneService: AutovalutazioniService,
    private temaService: TemaService
  ) {
    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      this.logoPath = temaService.logoPath;
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.spinnerOver.show();

    this.route.queryParams.subscribe(params => {
      // Ora puoi accedere ai parametri come segue

      this.temporaryToken = params['temporaryToken'];

      if (this.temporaryToken) {

        this.autovalutazioneService.getAutovalutazioneEsternaDaCompilare(this.temporaryToken).subscribe({
          next: (autovalutazioneEsterna: AutovalutazioneEsterna) => {
            const comp: AutovalutazioneCompilata = autovalutazioneEsterna.questionarioDaCompilare

            this.landingPage = autovalutazioneEsterna.landing;
            this.compilazione = comp;

            this.esercizio = comp.esercizio?.length ? comp.esercizio[0] : undefined;
            this.idAutovalutazioneCompilata = comp?.id;
            this.idAzienda = comp.aziende?.length ? comp.aziende[0] : undefined;

            if (this.compilazione.stato == 'PUBBLICATO') {
              this.completato = true;
            } else if (this.compilazione.stato !== 'BOZZA') {

              this.messaggioErrore = 'Al momento non è possibile compilare l\'autovalutazione.<br>Per favore, riprova più tardi.';
            }

            this.spinnerOver.hide();

          },
          error: (err) => {
            console.error(err);
            this.messaggioErrore = 'Non è stato possibile recuperare l\'autovalutazione.<br>' + (err?.error?.message || 'Errore sconosciuto') + '<br>Per favore, riprova più tardi o contatta l\'assistenza';

            this.spinnerOver.hide();

          }
        });

      } else {
        // Nessun token, link non valido
        this.messaggioErrore = 'Nessun dato trovato.<br>Il link utilizzato potrebbe essere incompleto o non più valido'
      }

    });

  }

  ngOnDestroy() {
    this._subTema?.unsubscribe();
  }

  salva = (idAutovalutazione: string, compilazione: any): Observable<boolean> => {
    if (compilazione) {

      this.spinnerOver.show();

      return this.autovalutazioneService.postAutovalutazioneEsterniDaCompilare(
        this.temporaryToken,
        compilazione
      ).pipe(
        map(risp => {

          this.spinnerOver.hide();
          return true;
        })
      );

    } else {
      console.error('NESSUNA COMPILAZIONE RILEVATA');
      return throwError(() => "Nessuna compilazione da trasmettere.");
    }


  }


  compilazioneCompletata(esito: boolean) {
    this.completato = esito;
  }


}
