import { UtilityService } from 'src/app/services/utility/utility.service';
import { LicenzeService, PromotoreLicenze } from './../../../../services/licenze/licenze.service';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RuoliList } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { GestioneLicenzeComponent } from '../../gestione-licenze/gestione-licenze.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-dialog-gestione-licenze',
  templateUrl: './dialog-gestione-licenze.component.html',
  styleUrls: ['./dialog-gestione-licenze.component.scss'],
  standalone: false
})
export class DialogGestioneLicenzeComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(GestioneLicenzeComponent) gestioneLicenzeComponent!: GestioneLicenzeComponent;

  constructor(
    public dialogRef: MatDialogRef<DialogGestioneLicenzeComponent>,
    private readonly licenzeService: LicenzeService,
    private readonly utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: {
      tipo: string,
      idUtente: string;
      ruoloUtente: RuoliList,
      codiceLicenza: string;
      record: any,
      dataScadenzaMax: string,
      codicePromotore: string | undefined;
      isUtenteGO: boolean;
      idSettoriPacchettoAttivo: string[];
    }
  ) {


  }

  ngAfterViewInit(): void {
    const formLicenze = this.gestioneLicenzeComponent?.formLicenze
    if (this.data.record) { // Sono in modifica
      formLicenze.disable();
      formLicenze.get('dataScadenza')?.enable();
      formLicenze.get('idSettori')?.enable();

    } else { // Sono in creazione nuova

      let dataAttivazione = new Date();


      if (this.data.tipo === 'rinnovo') {
        let data = new Date(this.data.dataScadenzaMax); // Data scadenza dell'ultimo RINNOVO
        data.setDate(data.getDate() + 1); // Aggiungo un giorno dalla scadenza del pacchetto più 

        dataAttivazione = data;

        formLicenze.get('idSettori')?.setValue(this.data.idSettoriPacchettoAttivo || [])

      }

      formLicenze.get('dataAttivazione')?.setValue(dataAttivazione);

      this.gestioneLicenzeComponent.minDate = dataAttivazione;

      formLicenze.get('dataScadenza')?.setValue(this.gestioneLicenzeComponent.calcolaDataScadenza(dataAttivazione))

    }
  }

  /**
   * Metodo che salva un pacchetto Aggiuntivo o un Rinnovo
   */
  public async salva() {
    const idUtente = this.data?.idUtente;
    const codiceLicenza = this.data?.codiceLicenza;
    const codicePacchetto = this.gestioneLicenzeComponent.formLicenze.get('codicePacchetto')?.value
    const objLicenza = this.gestioneLicenzeComponent.getObjLicenze(idUtente)

    if (idUtente && codiceLicenza) {

      // Controllo che ci sia almeno un valore inserito
      const totLic = (this.gestioneLicenzeComponent.formLicenze.get("nrAutovalutazioni")?.value || 0) +
        (this.gestioneLicenzeComponent.formLicenze.get("nrBilanci")?.value || 0) +
        (this.gestioneLicenzeComponent.formLicenze.get("nrSintesi")?.value || 0) +
        (this.gestioneLicenzeComponent.formLicenze.get("nrMaterialita")?.value || 0) +
        (this.gestioneLicenzeComponent.formLicenze.get("nrPolicy")?.value || 0) +
        (this.gestioneLicenzeComponent.formLicenze.get("nrPiano")?.value || 0) +
        (this.gestioneLicenzeComponent.formLicenze.get("nrMaxAziende")?.value || 0);

      if (totLic === 0) {
        // Nessun valore inserito
        const dialog = await this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Inserire almeno un valore di licenza maggiore di zero per salvare.',
          bottoni: [
            {
              nome_btn: 'Ok'
            },
          ]
        });
        return;
      }

      // Aggiornno Pacchetto (aggiuntivo o rinnovo)
      if (this.data.record && codicePacchetto) {
        await this.gestioneLicenzeComponent.aggiornaLicenzaBase(idUtente, codiceLicenza, codicePacchetto)
        this.dialogRef.close();

      } else {
        // Creo nuovo Pacchetto (aggiuntivo o rinnovo)
        let endpoint: any;

        if (this.data.tipo === 'rinnovo') {
          endpoint = this.licenzeService.rinnovoLicenzaBase(codiceLicenza, idUtente, objLicenza)
        } else if (this.data.tipo === 'aggiuntivo') {
          endpoint = this.licenzeService.aggiungiPacchettoAggiuntivo(codiceLicenza, idUtente, objLicenza)
        }

        this.spinnerOver.show();
        endpoint.pipe(
          finalize(() => this.spinnerOver.hide())
        )
          .subscribe(
            {
              next: (esito: any) => {
                console.log(esito);
                this.dialogRef.close();

              },
              error: (err: any) => {
                console.error(err);
                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: err?.error?.message || 'Impossibile salvare la licenza: errore sconosciuto',
                  bottoni: [
                    {
                      nome_btn: 'Ok'
                    },
                  ]
                });
              }
            }
          )
      }


    }

  }
}
