<mat-form-field appearance="fill">
  <mat-label>{{titolo}}</mat-label>

  <input type="text" matInput [placeholder]="titolo" matInput [matAutocomplete]="auto" (keydown)="onKeyDown($event)"
    [formControl]="selectFormControl">
  <mat-autocomplete #auto="matAutocomplete" [hideSingleSelectionIndicator]="true">

    <ng-container *ngFor="let padre of padri?.controls">
      <!--  SOLO PADRE -->
      <mat-option class="padre-class-select-custom"
        *ngIf="(!$any(padre)?.controls?.figli?.controls?.length || nascondiFigli)">
        <mat-checkbox [formControl]="$any(padre)?.controls?.selected" (click)="$event.stopPropagation()"
          color="primary">
          {{$any(padre)?.controls?.nomePadre?.value}} - <a
            style="font-size: 12px;">{{$any(padre)?.controls?.descrizionePadre?.value}}</a>
        </mat-checkbox>
      </mat-option>

      <!--  PADRE CON FIGLIO -->
      <mat-optgroup *ngIf="($any(padre)?.controls?.figli?.controls?.length && !nascondiFigli)">
        <mat-checkbox [formControl]="$any(padre)?.controls?.selected" (click)="$event.stopPropagation()"
          color="primary">
          {{$any(padre)?.controls?.nomePadre?.value}}
          - <a style="font-size: 12px;">{{$any(padre)?.controls?.descrizionePadre?.value}}</a>

          <!-- Expand -->
          <button *ngIf="$any(padre)?.controls?.figli?.controls?.length && !nascondiFigli" mat-button
            (click)="expandFigli($any(padre)?.value)">
            <mat-icon> {{!isFigliExpand[$any(padre)?.controls?.nomePadre?.value] ? 'expand_less' :
              'expand_more'}}</mat-icon>
          </button>
        </mat-checkbox>

        <!-- FIGLI -->
        <mat-option class="figli-class-select-custom" *ngFor="let figlio of $any(padre)?.controls?.figli?.controls"
          [ngClass]="!isFigliExpand[$any(padre)?.controls?.nomePadre?.value] ? 'list-show' : 'list-hide'">
          <mat-checkbox [formControl]="$any(figlio)?.controls?.selected" (click)="$event.stopPropagation()">
            {{$any(figlio)?.controls?.nomeFiglio?.value}} -<a style="font-size: 12px;">
              {{$any(figlio)?.controls?.descrizioneFiglio?.value}}</a>
          </mat-checkbox>
        </mat-option>

      </mat-optgroup>
    </ng-container>

  </mat-autocomplete>

</mat-form-field>