<!-- ToolBar -->
<mat-toolbar class="toolbar">
  <button mat-icon-button (click)="toolbarMenuButton()">
    <mat-icon>menu</mat-icon>
  </button>

  <img [src]="logoPath" alt="Sostenibile" class="logo-path" />

  <span class="toolbar-spacer"></span>

  <div class="label-azienda" style="padding: 20px 10px 0 0 ">
    <mat-form-field appearance="outline">
      <mat-label>Azienda</mat-label>
      <mat-select #matSelect placeholder="Azienda" [(ngModel)]="azienda" (valueChange)="cambioAzienda($event)"
        [compareWith]="compareAzienda">

        @for (azd of arrayAziende; track azd.id){
        <mat-option class="label-azienda-option" [value]="azd">{{azd.ragioneSociale}}</mat-option>
        }

      </mat-select>
    </mat-form-field>
  </div>

  <button class="user-button" mat-stroked-button color="primary" [matMenuTriggerFor]="userMenu">
    <mat-icon>account_circle</mat-icon>
    <a style="font-weight: 500; color: black;"> {{infoUtente?.userDetails?.username}}</a>
  </button>
  <mat-menu #userMenu="matMenu" [xPosition]="'before'">
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>



<!-- MENU LATERALE -->
<mat-sidenav-container autosize>
  <mat-sidenav #drawer class="mat-drawer" mode="side" [opened]="!isSidenavMini"
    [ngClass]="{'close': isSidenavMini, 'open': !isSidenavMini}">
    <!--  <mat-sidenav #drawer class="drawer" mode="side" [opened]="!isSidenavMini" [class.close]="isSidenavMini">  -->

    <mat-nav-list class="menu">

      @for (voceMenu of vociMenu; track $index;) {

      <!-- Menu principale -->
      @if (!voceMenu.sottoSezione?.length) {
      <mat-list-item matTooltipPosition="right" [matTooltip]=" isSidenavMini ?  voceMenu.sezione : undefined"
        routerLinkActive="active" [disabled]="voceMenu.disabled" (click)="azionaMenu(voceMenu)"
        [ngClass]="{'active': isRouteActive(voceMenu.routerMenu)}">
        <div class="item">
          <mat-icon>{{ voceMenu.icon }}</mat-icon>

          @if (!isSidenavMini) {
          <span style="margin-left: 10px;">{{ voceMenu.sezione }}</span>
          }

        </div>
      </mat-list-item>
      }

      <!-- Menu con sotto-sezioni -->
      @if (voceMenu.sottoSezione?.length) {
      <mat-list-item matTooltipPosition="right" [matTooltip]=" isSidenavMini ?  voceMenu.sezione : undefined"
        [disabled]="voceMenu.disabled" (click)="toggleSubMenu($index, voceMenu)" style="margin-bottom: 0px !important;">
        <div class=" item">
          <mat-icon>{{ voceMenu.icon }}</mat-icon>
          @if (!isSidenavMini) {
          <span style="margin-left: 10px;">{{ voceMenu.sezione }}</span>
          <mat-icon style="margin-left: auto;">{{ voceMenu.isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
          }
        </div>
      </mat-list-item>

      <!--   @if (voceMenu.isExpanded) { -->
      <div class="submenu" [ngClass]="{ 'expanded': voceMenu.isExpanded }">
        @for (voceSottoMenu of voceMenu.sottoSezione; track $index) {
        <mat-list-item routerLinkActive="active" [ngClass]="{'active': isRouteActive(voceSottoMenu.routerMenu)}"
          [disabled]="voceSottoMenu.disabled" (click)="azionaMenu(voceSottoMenu)">
          <div class="item" style="column-gap: 10px; padding-left: 20px;">
            <span style="transform: scale(1.3)">{{ voceSottoMenu.icon }}</span>
            @if (!isSidenavMini) {
            <span>{{ voceSottoMenu.sezione }}</span>
            }
          </div>
        </mat-list-item>
        }
      </div>
      }
      }
      <!--   } -->
    </mat-nav-list>
  </mat-sidenav>

  <!-- CONTENT DELLA PAGINA -->
  <mat-sidenav-content [class.close]="isSidenavMini">
    <ng-scrollbar>
      <router-outlet></router-outlet>
    </ng-scrollbar>
  </mat-sidenav-content>

</mat-sidenav-container>