import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { FigliStdr, StandardRendicontazioneService, STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';
import { catchError, filter, finalize, firstValueFrom, map, of, tap } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ImportazioniService } from 'src/app/services/importazioni/importazioni.service';

@Component({
    selector: 'app-dialog-crea-standard-rendicontazione',
    templateUrl: './dialog-crea-standard-rendicontazione.component.html',
    styleUrls: ['./dialog-crea-standard-rendicontazione.component.scss'],
    standalone: false
})
export class DialogCreaStandardRendicontazioneComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public formStdr = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    codice: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    generale: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settoriale: new FormControl<boolean>(true, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    compliance: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    obbligatorio: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    figli: new FormArray([]),
  });

  public arraySettori: Settore[] = [];
  public arrayFigliStdrSalvati: any[] = [];
  public urlLogo: string | undefined = undefined;
  public imagePreview: string | ArrayBuffer | null = 'assets/images/logo/logo-sostenibile.png';
  public logoDaSalvare: File | undefined = undefined;
  public isIconaGuida: boolean = false;
  public descrizioneErrore: string | undefined = undefined; conaGuida: boolean = false;


  constructor(
    private readonly strstdrService: StandardRendicontazioneService,
    public dialogRef: MatDialogRef<DialogCreaStandardRendicontazioneComponent>,
    private readonly utilityService: UtilityService,
    private readonly settoreService: SettoriService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly importazioniService: ImportazioniService,

    @Inject(MAT_DIALOG_DATA) public data: {
      stdr: STDR
    }
  ) {

  }

  public options = [
    { label: 'Si', value: true },
    { label: 'No', value: false }
  ]

  compareById(settore1: any, settore2: any): boolean {
    return settore1?.id === settore2?.id; // Sostituisci "id" con la proprietà univoca degli ambiti
  }

  ngAfterViewInit(): void {



    this._caricaSettori();

    if (this.data.stdr) {
      this._init(this.data.stdr);

    } else if (this.formStdr.get('settoriale')?.value) {
      // Faccio nascere la creazione con una gestione del sotto figlio di default
      const figliArray = this.formStdr.get('figli') as FormArray;
      figliArray.clear();
      figliArray.push(this.mappaFigliStdr(figliArray?.value));
    }

    setTimeout(() => {
      this.formStdr.get('settoriale')?.valueChanges.subscribe((value: any) => {
        if (value && this.arrayFigliStdrSalvati.length) {
          const figliStdrArray = this.formStdr.get('figli') as FormArray;
          figliStdrArray.patchValue(this.arrayFigliStdrSalvati);
        }
      });
    }, 0);

    this.cdRef.detectChanges(); // Forza il rilevamento delle modifiche

  }

  private _init(stdr: STDR) {
    Object.entries(stdr).forEach(([key, value]) => {

      if (key === 'figli') {
        const figliArray = this.formStdr.get('figli') as FormArray;
        figliArray.clear();

        (value).forEach((figlio: any) => {
          figliArray.push(this.mappaFigliStdr(figlio));
        });
      } else if (key === 'urlFile' && this.data.stdr.urlFile) {
        this.urlLogo = this.data.stdr.urlFile;

      } else {
        this.formStdr.get(key)?.setValue((stdr as any)[key]);
      }
    });

    if (this.data?.stdr?.id) {
      this.formStdr.get('compliance')?.disable();
    }

  }

  /**
   * Metodo di salvataggio stdr
   * @returns 
   */

  public async salva(): Promise<boolean> {
    const isSettoriale = this.formStdr.get('settoriale')?.value;
    const figliStdrControl = this.formStdr.get('figli') as FormArray;

    // Rimuove figli se non settoriale
    if (!isSettoriale) {
      this.arrayFigliStdrSalvati = figliStdrControl?.value;
      figliStdrControl.clear();
    }

    if (this.formStdr.valid) {
      const id = this.formStdr.get('id')?.value;

      const objStdr = {
        ...this.formStdr.getRawValue(),
        urlFile: this.urlLogo,
      };


      try {
        if (id) {
          // Modifica esistente
          await firstValueFrom(this.strstdrService.putStdr(id, objStdr));
        } else {
          // Nuova creazione
          await firstValueFrom(this.strstdrService.postStdr(objStdr).pipe(
            tap(esito => this.formStdr.get('id')?.setValue(esito.body))
          ));
        }

        // Se l'operazione è riuscita
        this.utilityService.opneSnackBar('Salvataggio effettuato', '', {
          duration: 2000,
          panelClass: ['success-snackbar'],
        });
        return true;
      } catch (err: any) {

        if (err?.error?.status === 'NOT_ACCEPTABLE') {
          this.formStdr.get('compliance')?.setValue(this.data?.stdr?.compliance);
        }

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message || 'Errore durante il salvataggio',
          bottoni: [{ nome_btn: 'Chiudi' }],
        });
        return false;
      }
    } else {
      console.log('FORM NON VALIDO');
      // Marcatura dei controlli come "touched"
      Object.values(this.formStdr.controls).forEach((control) => {
        control.markAsTouched();
      });

      if (this.formStdr.get('settoriale')?.value) {
        Object.values(this.formStdr.controls.figli.controls).forEach(
          (formGroup: any) => {
            Object.values(formGroup.controls).forEach((control: any) => {
              control.markAsTouched();
            });
          }
        );
      }
      return false;
    }
  }


  mappaFigliStdr(stdr?: FigliStdr) {
    const figli = new FormGroup({
      id: new FormControl<string | undefined>(stdr?.id),
      codice: new FormControl<string | undefined>(stdr?.codice, Validators.required),
      descrizione: new FormControl<string | undefined>(stdr?.descrizione, Validators.required),
      settore: new FormControl<Settore | undefined>(stdr?.settore, Validators.required)
    });
    return figli
  }

  addFigliStdr(formArray: FormArray) {
    const figli = new FormGroup({
      id: new FormControl<string | undefined>(undefined),
      codice: new FormControl<string | undefined>(undefined, Validators.required),
      descrizione: new FormControl<string | undefined>(undefined, Validators.required),
      settore: new FormControl<Settore | undefined>(undefined, Validators.required)
    });
    formArray.push(figli);
  }

  elimina(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  private _caricaSettori() {

    this.spinnerOver.show();

    this.settoreService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'asc'
    }])
      .pipe(
        finalize(() => this.spinnerOver.hide())
      )
      .subscribe({
        next: (risp) => {
          this.arraySettori = risp?.content.filter((set: any) => !set.generico) || [];
        },
        error: (err) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei settori',
            bottoni: [{ nome_btn: 'Ok' }]
          });

          this.dialogRef.close();

        }
      })
  }

  dropFigliStdr(event: CdkDragDrop<any[]>, formArrayFigli: FormArray) {
    const currentGroup = formArrayFigli.at(event.previousIndex);
    formArrayFigli.removeAt(event.previousIndex, { emitEvent: false });
    formArrayFigli.insert(event.currentIndex, currentGroup, { emitEvent: false });
  }

  slideSettoriale(slideEvent: MatSlideToggleChange) {
    if (slideEvent.checked && !this.formStdr.get('figli')?.value.length) {
      const figliArray = this.formStdr.get('figli') as FormArray;
      figliArray.clear();
      figliArray.push(this.mappaFigliStdr(figliArray?.value));
    }
  }

  apriInfo() {

    const dialogContent: string = `
    <div>
      <h3>Logica di selezione delle Tematiche, Azioni e KPI</h3>
    
      <h4>1. Selezione automatica delle Tematiche</h4>
      <p>
        Dopo il primo step del <strong>Bilancio di Sostenibilità (BdS)</strong>, alcune <strong>tematiche</strong> vengono selezionate automaticamente se sono collegate a uno <strong>Standard di Rendicontazione / Compliance</strong> che coincide con quello del bilancio.
      </p>
      <p><strong>Nello step delle tematiche:</strong></p>
      <ul>
        <li>Se lo <strong>Standard di Rendicontazione / Compliance</strong> coincide con quello del <strong>Bilancio di Sostenibilità</strong>, la tematica sarà pre-selezionata.</li>
        <li>Se inoltre lo <strong>Standard / Compliance</strong> che ha causato la selezione ha il flag <strong>"Obbligatorio con Trigger"</strong> attivo e il <strong>Trigger</strong> è superato, la tematica diventa obbligatoria (indicata con un lucchetto).</li>
      </ul>
    
      <h4>2. Azioni, Buone Pratiche e KPI consigliati e obbligatori</h4>
      <p>
        Il sistema verifica se tra le <strong>ABP</strong> e i <strong>KPI</strong> delle tematiche selezionate ci sono elementi collegati a uno <strong>Standard di Rendicontazione / Compliance</strong> che coincide con quello del BdS.
      </p>
      <ul>
        <li>Questi elementi vengono contrassegnati come <strong>"Consigliati"</strong>.</li>
        <li>Perché una <strong>ABP</strong> o un <strong>KPI</strong> diventi obbligatorio, lo <strong>Standard / Compliance</strong> collegato deve avere il flag <strong>"Obbligatorio con Trigger"</strong> attivo e il <strong>Trigger</strong> deve essere superato.</li>
      </ul>
    
      <h4>3. Compilazione dei Target nei KPI</h4>
      <p>
        Durante la compilazione dei <strong>Target</strong> nei KPI, il sistema verifica se lo <strong>Standard / Compliance</strong> del KPI coincide con quello del BdS.
      </p>
      <p>Il Target diventa obbligatorio se tutte queste condizioni sono soddisfatte:</p>
      <ul>
        <li>Lo <strong>Standard / Compliance</strong> del KPI ha il flag <strong>"Target"</strong> attivo.</li>
        <li>Il <strong>Trigger</strong> è stato superato.</li>
        <li>Lo <strong>Standard / Compliance</strong> che coincide con il BdS ha il flag <strong>"Obbligatorio con Trigger"</strong> attivo.</li>
      </ul>
    </div>
    `;

    this.utilityService.openDialog({
      titolo: 'Obbligatorio con Trigger',
      descrizione: dialogContent,
      fontWeight: 'normal',
      bottoni: [
        {
          nome_btn: 'Ok',
          id_btn: 'ok'
        },

      ]
    }, 1200);
  }

  /**
   * Importazione di un logo
   */
  public async fileSelezionato(file: File[]) {

    // Controllo se il file > 1mb
    if (file[0].size > 1048576) {
      const dialog = await this.utilityService.openDialog({
        titolo: 'Dimensione file eccessiva',
        descrizione: 'La dimensione del file supera 1 Mb, la vuoi ridimensionare',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }
    }

    if (file[0].type === "image/jpeg" || file[0].type === "image/jpg" || file[0].type === "image/png" || file[0].type === "image/gif" || file[0].type === "image/svg+xml") {
      this.isIconaGuida = false;


      this.logoDaSalvare = file[0];
      // Creo un'anteprima dell'immagine
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
        this.urlLogo = undefined;
      };
      reader.readAsDataURL(file[0]);

    } else {
      this.utilityService.opneSnackBar(" Attenzione! Il file d'importazione deve essere un file immagine (.png .jpeg .jpg .gif .svg)", '', {
        duration: 5000,
        panelClass: ['red-snackbar']
      });
      this.descrizioneErrore = " Attenzione! Il file d'importazione deve essere un file immagine (.png, .jpeg, .jpg, .gif, .svg)";
      this.isIconaGuida = true;
    }
  }

  async importaLogo(): Promise<boolean> {

    const idStdr = this.formStdr.get('id')?.value;
    if (idStdr && this.logoDaSalvare) {
      return firstValueFrom(this.importazioniService.importLogoStdr(this.logoDaSalvare, {}, idStdr)
        .pipe(filter((res) => res?.type === 4),
          map((result: any) => {
            console.warn(result);

            if (result) {
              this.urlLogo = result.urlFile;
            }
            return true;
          }),
          catchError(err => {
            console.error(err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || "Errore nell'importazione",
              bottoni: [{ nome_btn: 'Chiudi' }]
            });
            return of(false)
          })))
    } else {
      return false;
    }
  }

  /**
   * Metodo per eliminazione del logo Stdr.
   * Viene rimosso da google cloud storage 
   */
  public eliminaLogo() {

    if (this.logoDaSalvare) {
      this.imagePreview = 'assets/images/logo/logo-sostenibile.png';
      this.logoDaSalvare = undefined;

    } else {
      const idStdr = this.formStdr.get('id')?.value;
      if (idStdr) {
        this.spinnerOver.show();

        this.importazioniService.deleteLogoStdr(idStdr).subscribe({
          next: (esito) => {

            this.spinnerOver.hide();
            this.urlLogo = undefined;
            this.utilityService.opneSnackBar("Logo rimosso", '', {
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          },
          error: (err) => {
            console.error(err);
            this.spinnerOver.hide();
          }
        })
      }
    }
  }

  public async salvaStdr() {
    try {
      this.spinnerOver.show();

      const resultStdr = await this.salva();
      if (!resultStdr) {
        return;
      }
      if (this.logoDaSalvare) {
        const resultLogo = await this.importaLogo();
        if (resultLogo) {
          this.dialogRef.close(this.formStdr.get('id')?.value);
        }
      } else {
        this.dialogRef.close(this.formStdr.get('id')?.value)
      }
    } catch (error) {
      console.error("Errore durante il salvataggio dell'azienda o l'importazione del logo", error);
    } finally {
      this.spinnerOver.hide();
    }
  }

}
