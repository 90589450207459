<div class="contenitore">
    <!-- <div class="titolo-pagina" style="display: flex;justify-content: space-between;padding-right: 30px;">
        <app-titolo-subtitolo [titolo]="'Gestione Licenze per Utente'"
            [subTitolo]="getGeneralitaUtente()"></app-titolo-subtitolo>

        <div style="display: flex;column-gap: 15px;align-items: flex-end;">
            <button mat-flat-button color="primary" style="max-width: 170px !important;" class="primary-button"
                (click)="aggiornaLicenze()"> <mat-icon>update</mat-icon> Aggiorna Licenze</button>

            <button mat-flat-button color="accent" style="max-width: 155px !important;" class="accent-button"
                (click)="goBack()"> <mat-icon>undo</mat-icon> Torna indietro</button>
        </div>

    </div> -->
    <mat-accordion class="accordion" multi #accordion="matAccordion">

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Licenze base
                </mat-panel-title>
                <!-- <mat-panel-description>
                    Rinnova la tua licenza base.
                </mat-panel-description> -->
            </mat-expansion-panel-header>
            <div class="cont-exp">
                <div class="cont-tab">

                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <!-- <div class="titolo-grafico">
                            Lista Rinnovi
                        </div> -->

                        <div style="display: flex; column-gap: 40px;">
                            <div class="contenitore-legenda">
                                <div class="punto-legenda-attiva">
                                </div>
                                <div class="label-legenda">
                                    Licenza Attiva
                                </div>
                            </div>
                            <div class="contenitore-legenda">
                                <div class="punto-legenda-futura">
                                </div>
                                <div class="label-legenda">
                                    Prossimo Rinnovo
                                </div>
                            </div>
                            <div class="contenitore-legenda">
                                <div class="punto-legenda-scaduta">
                                </div>
                                <div class="label-legenda" style="font-style: italic;">
                                    Licenza Scaduta
                                </div>
                            </div>

                            @if(arraySettori.length){

                                <div class="contenitore-legenda">
                                   <!--  <mat-icon [matTooltip]="'Estensioni settoriali per la licenza attiva'" class="material-icons-outlined" style=" transform: scale(0.8);">
                                        info
                                    </mat-icon> -->
                                    <div class="label-legenda estensione-settoriale" matTooltipPosition="right" [matTooltip]="'Estensioni Settoriali per la licenza attiva'"  >
                                        <a *ngFor="let set of arraySettori">{{' • ' + set.titolo}}</a>
                                    </div>
                                </div>
                            }

                        </div>

                        <button mat-flat-button color="primary" (click)="aggiungiRinnovo()" class="primary-button"
                            style="max-width: 200px !important; height: 35px !important"> <mat-icon>add</mat-icon>
                            Aggiungi Rinnovo
                        </button>

                    </div>
                    <app-lista-tabellare #tabellaLicenzaBase [fnCaricamentoDati]="fnCaricamentoDatiLicenzaBase"
                        [colonne]="colonneLicenzaBase ? colonneLicenzaBase : {}" [maxNrButton]="1" [isFiltri]="false"
                        (bottoniListaClick)="bottoniListaClick($event)" [fnCaricamentoStyle]="fnCaricamentoStyle">
                    </app-lista-tabellare>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!isUtenteGo()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Pacchetti Aggiuntivi
                </mat-panel-title>
                <!-- <mat-panel-description>
                    Aggiungi un nuovo pacchetto.
                </mat-panel-description> -->

            </mat-expansion-panel-header>
            <div class="cont-exp">

                <div class="cont-tab">

                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div style="display: flex; column-gap: 50px;">
                            <div class="contenitore-legenda">
                                <div class="punto-legenda-attiva">
                                </div>
                                <div class="label-legenda">
                                    Pacchetto Attivo
                                </div>
                            </div>
                            <div class="contenitore-legenda">
                                <div class="punto-legenda-futura">
                                </div>
                                <div class="label-legenda">
                                    Prossimo Pacchetto
                                </div>
                            </div>
                            <div class="contenitore-legenda">
                                <div class="punto-legenda-scaduta">
                                </div>
                                <div class="label-legenda" style="font-style: italic;">
                                    Pacchetto Scaduto
                                </div>
                            </div>
                        </div>
                        <button mat-flat-button color="primary" (click)="aggiungiPacchettoAggiuntivo()"
                            class="primary-button" style="max-width: 200px !important; height: 35px !important">
                            <mat-icon>add</mat-icon>
                            Aggiungi Pacchetto
                        </button>

                    </div>
                    <app-lista-tabellare #tabellaPackAggiuntivi [fnCaricamentoDati]="fnCaricamentoDatiPackAggiuntivi"
                        [colonne]="colonnePackAggiuntivi" [maxNrButton]="1" [isFiltri]="false"
                        (bottoniListaClick)="bottoniListaClick($event)" [fnCaricamentoStyle]="fnCaricamentoStyle">
                    </app-lista-tabellare>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>