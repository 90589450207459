import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { DialogAlert, DialogAlertComponent } from 'src/app/components/dialog-alert/dialog-alert.component';
import { SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { environment } from 'src/environments/environment';

export const DATE_FORMATS = {
  SHORT: 'dd/MM/yyyy',
  MIDDLE: 'dd/MM/yyyy HH:mm',
  TIME: 'HH:mm',
};

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  /*   private DATE_FORMATS = {
      SHORT: 'd.MMM yyyy',
      MIDDLE: 'd.MMM yyyy, E HH:mm, ',
      TIME: 'HH:mm',
    }; */

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    readonly snackBar: MatSnackBar
  ) {

  }

  //************************************* GESTIONE ALERT  */
  /**
   * Dialog per gestire gli alert
   * @param config 
   * @param maxWidth 
   * @param maxHeight 
   * @returns 
   */
  openDialog(
    config: DialogAlert,
    maxWidth?: number | string,
    maxHeight?: number | string
  ): MatDialogRef<DialogAlertComponent> {
    return this.dialog.open(DialogAlertComponent, {
      data: config,
      disableClose: true,
      maxWidth,
      maxHeight,
      autoFocus: false
    });
  }

  /**
   * Chiudo tutte le dialog
   */
  public closeDialog() {
    this.dialog.closeAll();
  }

  /******************************************** END GESTIONE ALERT  */

  /**
   * Sanificatore di html
   * @param html 
   * @returns 
   */
  public sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  /************************************** GESTIONE TOAST SNACK  */

  /**
   * Gestione del toast
   * @param message 
   * @param action bottone sul toast
   * @param config 
   * @returns 
   */
  public opneSnackBar(message: string, action = '', config?: MatSnackBarConfig) {
    return this.snackBar.open(message, action, config);
  }

  public formatDateInString(date: Date, formatDate: 'SHORT' | 'MIDDLE' | 'TIME') {
    const dtf = DATE_FORMATS[formatDate];
    return format(date, dtf);
  }


  public getDatiTableArray(
    arrayDati: any[],
    searchKey: string[],
    ricerca?: string,
    page?: number,
    pageSize?: number,
    sortBy?: SortBy[]
  ): Observable<any> {
    if (!page) {
      page = 0;
    }
    if (!pageSize) {
      pageSize = 5;
    }

    let array: any[] = [];

    if (ricerca && searchKey.length) {

      array = arrayDati.filter(item => {
        let check = false;
        for (const chiave of searchKey) {

          check = item[chiave].toLowerCase().includes(ricerca?.toLowerCase());
          if (check) {
            break;
          }
        }
        return check;
      });

    } else {
      array = arrayDati;
    }

    if (sortBy?.length) {
      array.sort(
        (record1, record2) => {
          for (let i = 0; i < sortBy.length; i++) {
            const chiave = sortBy[i].chiave;
            const direction = sortBy[i].sort === 'asc' ? 1 : -1;
            let esito = 0;

            let val1: number | string = record1[chiave];
            let val2: number | string = record2[chiave];

            if (typeof val1 !== 'number') {
              val1 = val1.toString().toLowerCase();
            }

            if (typeof val2 !== 'number') {
              val2 = val2.toString().toLowerCase();
            }

            if (val1 && !val2) {
              esito = -1;
            } else if (!val1 && val2) {
              esito = 1;
            } else if (val1 < val2) {
              esito = -1;
            } else if (val1 > val2) {
              esito = 1;
            }

            if (esito !== 0) {
              return esito * direction;
            }
          }

          return 0;
        }
      );
    }

    return of({
      content: array.slice((page * pageSize), ((page + 1) * pageSize)),
      totalElements: array.length
    });
  }



  public static emailValidator(control: any) {
    // Regular expression for a valid email address
    const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return EMAIL_REGEXP.test(control.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  public getBaseUrl() {
    if (location.href.startsWith(environment.urlGo)) {
      return environment.urlGo;
    } else {
      return environment.url;
    }
  }

  public static blankValidator(control: any) {
    const value = control?.value;
    const isWhitespace = typeof value === 'string' && value.trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {
      blankValidator: {
        valid: false
      }
    };
  }

  convertGigabytesToBytes(gigabytes: number | undefined): number {
    if (gigabytes) {
      const bytesInOneGigabyte = 1024 ** 3; // 1 GB = 1024^3 bytes
      return parseFloat((gigabytes * bytesInOneGigabyte).toFixed(1));
    } else {
      return 0;
    }
  }

  convertBytesToGigabytes(bytes: number | undefined): number {
    if (bytes) {
      const bytesInOneGigabyte = 1024 ** 3; // 1 GB = 1024^3 bytes
      const risultato = parseFloat((bytes / bytesInOneGigabyte).toFixed(1));
      return risultato
    } else {
      return 0;
    }
  }



}
