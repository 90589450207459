<app-spinner-overlay></app-spinner-overlay>
<mat-tab-group (selectedTabChange)="onTabChange($event)" #tabGroupAmbiti
    [ngClass]="this.chiaveElementoSelezione === 'targetQualitativi' || this.chiaveElementoSelezione === 'targetQuantitativi' ? 'nascondi-tab' : undefined ">
    <mat-tab [label]="ambito.titoloBreve" *ngFor="let ambito of objQuestionario">
        <div class="bordo">
            <ng-container *ngIf="objQuestionario">
                <ng-scrollbar>
                    <div style="padding-right: 15px;">

                        @if(nomeModulo==='PIANO'){
                        <div class="titolo-sezione-dialog" [matTooltip]="'OBIETTIVO'" [matTooltipPosition]="'left'"
                            style="font-size: 15px;display: flex;align-items: center;column-gap: 5px;">
                            <!-- <a class="targhettina-obiettivo"> {{titoloObiettivo? 'OBIETTIVO - ' : ''}}</a> -->
                            {{titoloObiettivo}}
                        </div>
                        }@else {
                        <div class="titolo-sezione-dialog" style="font-size: 15px;">
                            {{ambito.titolo}}</div>
                        }

                        <div *ngFor="let tematica of ambito.tematiche" class="tematica-group">

                            @if(nomeModulo==='PIANO'){
                            <div [id]="ambito.id + '_' + tematica.id + chiaveElementoSelezione" style="text-transform: none !important;"
                                [matTooltip]="'Descrizione OBIETTIVO'" [matTooltipPosition]="'left'"
                                class="domanda-tematica">
                                {{desObiettivo}}
                            </div>

                            }@else{
                            <!-- TITOLO TEMATICA  -->
                            <div [id]="ambito.id + '_' + tematica.id + chiaveElementoSelezione"
                                [ngClass]="{'domanda-tematica': !ctrTematicaNonCompletata(ambito.id + '_' +  tematica.id), 'domanda-tematica-rossa': ctrTematicaNonCompletata(ambito.id + '_' +  tematica.id)}">
                                Tematica - {{tematica.domanda}}
                            </div>
                            }


                            <!-- INIZIO GESTIONE tematica => checkbox => elemento => aggiungi nuovo -->
                            <div>
                                <div *ngFor="let elementoChk of tematica[chiaveElemento]" style="display: flex;">

                                    <!-- CHECKBOX -->
                                    <mat-checkbox [checked]="elementoChk.selected" #checkboxElemento
                                        class="radio-group checkbox-selezione-elementi"
                                        [disabled]="controlloElementoDisabled(elementoChk) || this.isPermessoSoloRead"
                                        (change)="selezionaCheck(elementoChk,$event.checked,ambito.id, tematica.id);">
                                    </mat-checkbox>

                                    <div style="display: flex;flex-direction: column;">

                                        <mat-label style="display: flex;align-items: center;">

                                            <!-- TITOLO CHECK BOX -->
                                            <a class="titolo-elemento"
                                                (click)="$event.stopPropagation(); $event.preventDefault(); selezionaCheck(elementoChk,!elementoChk.selected,ambito.id, tematica.id)"
                                                [id]="ambito.id + '_' + tematica.id + '_' + elementoChk.id">
                                                {{elementoChk.titolo ? elementoChk.titolo : elementoChk.nomeBreve}}
                                            </a>

                                            <!-- BLOCCATO (lucchetto) -->
                                            <button mat-icon-button *ngIf="isElementoObbligatorio(elementoChk)"
                                                class="material-icons-outlined icone-tem icone-label-chack icona-lucchetto "
                                                [matTooltip]="'Elemento obbligatorio, vedi maggiori informazioni'"
                                                (click)="$event.stopPropagation(); apriPopUpObbligatorio(elementoChk)">
                                                <span class=" material-icons-outlined">
                                                    lock
                                                </span>
                                            </button>

                                            <!-- MODIFICA ELEMENTO -->
                                            <button mat-icon-button class="icona-edit-elem"
                                                (click)="$event.stopPropagation(); editElementoListaChk(ambito.id, tematica.id, elementoChk )"
                                                *ngIf="isElementoEditable(elementoChk)">
                                                <mat-icon class="icone-label-chack">edit</mat-icon>
                                            </button>

                                            <!-- ASSEGNAMENTO FIGURA -->
                                            <ng-container *ngIf="chiaveElementoSelezione !== 'obiettivo'">
                                                <div [matTooltip]="toolTipFigura(elementoChk?.figura)"
                                                    style="display: flex; height: 40px;">
                                                    <div *ngIf="!isImpatto">
                                                        <button *ngIf="elementoChk.selected" mat-icon-button
                                                            aria-label="Associa Figura Aziendale" class="icona-figura"
                                                            (click)="$event.stopPropagation();associaFiguraAziendale(ambito.id, tematica.id, elementoChk.id, elementoChk?.figura)"
                                                            [disabled]="stato === 'PUBBLICATO' || this.isPermessoSoloRead">
                                                            <mat-icon class="material-icons-outlined icone-label-chack"
                                                                [ngClass]="{'blue-icon': elementoChk?.figura?.id, 'default-icon': !elementoChk?.figura?.id}">
                                                                person</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <!-- CONSIGLIATO - Elemento consigliato se STDR e soglie non superate  -->
                                            <button mat-icon-button *ngIf="isElementoConsigliato(elementoChk)"
                                                class="material-icons-outlined icone-tem icona-consigliato"
                                                [matTooltip]="'Elemento consigliato, vedi maggiori informazioni'"
                                                (click)="$event.stopPropagation(); apriPopUpConsigliato(elementoChk)">
                                                <span class=" material-icons-outlined">
                                                    emoji_objects
                                                </span>
                                            </button>

                                        </mat-label>

                                        <!-- DESCRIZIONE ELEMENTO -->
                                        <div style="display: flex;padding-bottom: 10px;">
                                            <p style="color:  var(--grigio);font-size: 13px;margin: 0px;">
                                                {{elementoChk.descrizione}}
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <!-- AGGIUNGI NUOVO ELEMENTO -->
                                <button mat-stroked-button class="aggiungi-nuovo"
                                    (click)="addElementoListaChk(ambito.id, tematica.id )"
                                    [disabled]="stato === 'PUBBLICATO' || this.isPermessoSoloRead">
                                    + Aggiungi Nuovo
                                </button>

                            </div>
                        </div>
                    </div>
                </ng-scrollbar>
            </ng-container>
        </div>
    </mat-tab>
</mat-tab-group>