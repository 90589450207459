<app-spinner-overlay></app-spinner-overlay>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Piano di Sostenibilità"
            subTitolo="Crea il tuo Piano di Sostenibilità, definisci azioni di miglioramento e target quantitativi per tracciare il percorso di miglioramento aziendale.<br><br>
            Collegando il tuo Piano di Sostenibilità agli altri moduli potrai usufruire delle informazioni inserite precedentemente."></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri"
        (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button *ngIf="!isPermessoSoloRead()" mat-flat-button class="primary-button" color="primary"
                (click)="crea()">
                Nuovo
            </button>
            <!-- *ngIf="this.utenteService.isPermessoAttivo('CREATE_PIANO')" -->
        </div>
    </app-lista-tabellare>
</div>