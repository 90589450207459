import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Observable } from 'rxjs';
import { AziendaService } from '../azienda/azienda.service';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore } from '../settori/settori.service';
import { Certificazione } from '../certificazioni/certificazioni.service';
import { AzioneBP } from '../azione-e-buona-pratica/azione-e-buona-pratica.service';
import { Impegno } from '../impegni/impegni.service';
import { Comitati, ComitatiConFigAz } from '../comitati/comitati.service';
import { PerimetriDestinatari, PerimetriDestinatariGrp } from './policy.service';
import { GruppoCfgStampa } from '../config-stampe/config-stampe.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyWebService {
  private nomeWs = 'policy-di-sostenibilita'
  private _idAzienda: string | undefined = undefined;
  constructor(
    private webService: WebService,
    private aziendaService: AziendaService,
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }


  public getDestinatariPolicy(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/destinatari-policy/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del destinatario policy
   * @param data dati da inserire nel MongoDB
   * @returns 
   */
  public postDestinatariPolicy(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/destinatari-policy', data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public deleteDestinatariPolicy(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/destinatari-policy/delete', { id: idRiga })
  }

  public putDestinatariPolicy(id: string, destinatarioPolicy: any): Observable<any> {
    return this.webService.doPut('/api/v1/destinatari-policy/update', destinatarioPolicy, {
      id
    });
  }

  public getPerimetroPolicy(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/perimetro-policy/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del destinatario policy
   * @param data dati da inserire nel MongoDB
   * @returns 
   */
  public postPerimetroPolicy(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/perimetro-policy', data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public deletePerimetroPolicy(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/perimetro-policy/delete', { id: idRiga })
  }

  public putPerimetroPolicy(id: string, destinatarioPolicy: any): Observable<any> {
    return this.webService.doPut('/api/v1/perimetro-policy/update', destinatarioPolicy, {
      id
    });
  }


  /* Policy di Sostenibilità */
  public getPolicySostenibilita(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public deletePolicySostenibilita(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/delete', { id: idRiga })
  }

  /* ---------------- C R E A Z I O N E     P O L I C Y  ---- step1 ------------*/

  /**
   * Creazione step 1 policy
   * @param policySostenibilita oggetto policy di sostenibilita
   * @returns 
   */
  public postCreazionePolicyStep1(policySostenibilita: any) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs,
      policySostenibilita
    );
  }

  /**
   * Aggiornamento step 1 policy
   * @param policySostenibilita id policy di sostenibilita
   * @returns 
   */
  public putAggiornamentoPolicyStep1(idPolicySostenibilita: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita,
      {}
    );
  }

  /* ----------------T E M A T I C H E    P O L I C Y  ---- step2 ------------*/
  public getQuestionarioPolicy(idPolicySostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/questionario-di-policy', {});
  }

  public putQuestionarioPolicy(idPolicySostenibilita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], titolo: string, stato: string, tipo: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/questionario-di-policy', {
      id: idSurvey,
      ambiti,
      settori,
      policy: [idPolicySostenibilita],
      aziende: [this._idAzienda],
      titolo,
      stato,
      tipo
    });
  }


  /* ------------------------- I M P E G N I     step 6 -------------------------------- */
  /**
 * GET IMPEGNI POLICY
 * @param idPolicySostenibilita 
 * @returns obj con lista ambiti - tematiche - impegni
 */
  public getImpegniPolicy(idPolicySostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/impegni', {});
  }

  /**
   * SALVATAGGIO IMPEGNI POLICY SELEZIONATI
   * @param idPolicySostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putImpegniPolicySelezionati(idPolicySostenibilita: string, impegniSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/impegni', impegniSelezionati, {});
  }

  /**
     * ASSEGNA FIGURA AD IMPEGNI POLICY
     * @param idPolicySostenibilita 
     * @param policy
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraImpegnoPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idImpegno: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impegno/' + idImpegno + '/figura/' + idFigura, {});
  }
  public putImpegniEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, impegno: Impegno) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/impegni-effimeri',
      impegno,
      {
        idAzienda: this._idAzienda,
        idPolicySostenibilita
      }
    );
  }
  /* ------------------------- A Z I O N I    B P    step 7 -------------------------------- */

  /**
 * GET AZIONI BP POLICY
 * @param idPolicySostenibilita 
 * @returns obj con lista ambiti - tematiche - azioniBP
 */
  public getAzioniBPPolicy(idPolicySostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/azioni-buone-pratiche', {});
  }

  /**
   * SALVATAGGIO AZIONI BP POLICY SELEZIONATI
   * @param idPolicySostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putAzioniBPPolicySelezionati(idPolicySostenibilita: string, azioniBPSelezionati: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/azioni-buone-pratiche', azioniBPSelezionati, {});
  }

  /**
     * ASSEGNA FIGURA AD AZIONI BP POLICY
     * @param idPolicySostenibilita 
     * @param policy
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraAzioniBPPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idAzioneBP: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/azioni-buone-pratiche/' + idAzioneBP + '/figura/' + idFigura, {});
  }

  public putAzioniBPEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, azioneBP: AzioneBP) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/azioni-buone-pratiche-effimere',
      azioneBP,
      {
        idAzienda: this._idAzienda,
        idPolicySostenibilita
      }
    );
  }

  /* ------------------------- C E R T I F I C A Z I O N I    -------------------------------- */

  /**
 * GET CERTIFICAZIONI POLICY
 * @param idPolicySostenibilita 
 * @returns obj con lista ambiti - tematiche - certificazioni
 */
  public getCertificazioniPolicy(idPolicySostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/certificazioni', {});
  }

  /**
   * SALVATAGGIO CERTIFICAZIONI POLICY SELEZIONATI
   * @param idPolicySostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putCertificazioniPolicySelezionate(idPolicySostenibilita: string, certificazioniSelezionate: any) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/certificazioni', certificazioniSelezionate, {});
  }

  /**
     * ASSEGNA FIGURA AD CERTIFICAZIONI POLICY
     * @param idPolicySostenibilita 
     * @param policy
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraCertificazioniPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idCertificazioni: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/certificazioni/' + idCertificazioni + '/figura/' + idFigura, {});
  }

  public putCertificazioniEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, certificazioni: Certificazione) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/certificazioni-effimere',
      certificazioni,
      {
        idAzienda: this._idAzienda,
        idPolicySostenibilita
      }
    );
  }

  /* -------------------------   C O M I T A T I     step 6 -------------------------------- */
  /**
 * GET COMITATI POLICY
 * @param idPolicySostenibilita 
 * @returns obj con lista ambiti - tematiche - comitati
 */
  public getComitatiPolicy(idPolicySostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/comitati', {});
  }

  public putCompilazioneComitati(idPolicySostenibilita: string, idComitato: string, risposta: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/comitati/' + idComitato, { risposta });
  }

  /**
   * SALVATAGGIO COMITATI POLICY SELEZIONATI
   * @param idPolicySostenibilita 
   * @param objStep3 
   * @returns 
   */
  public putComitatiPolicySelezionati(idPolicySostenibilita: string, objComitati: ComitatiConFigAz[]) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/comitati', objComitati, {});
  }

  /**
     * ASSEGNA FIGURA AD COMITATI POLICY
     * @param idPolicySostenibilita 
     * @param policy
     * @returns <Status 200 (senza body)>
     */
  public putAssegnaFiguraComitatoPolicy(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idComitato: string, idFigura: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/comitato/' + idComitato + '/figura/' + idFigura, {});
  }
  public putComitatiEffimeri(idPolicySostenibilita: string, idAmbito: string, idTematica: string, comitato: Comitati) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/ambito/' + idAmbito + '/tematica/' + idTematica + '/comitati-effimeri',
      comitato,
      {
        idAzienda: this._idAzienda,
        idPolicySostenibilita
      }
    );
  }

  /* --------------------------- FIGURE AZIENDALI - ---------------*/
  public getListaAssegnazioniFigAz(idPolicy: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicy + '/assegnazioni', {});
  }

  /* ------------------------- C R U S C O T T O     STEP 9  -------------------------------- */
  public getCruscotti(idPolicySostenibilita: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {

    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/list-elementi-per-figura',
      {
        page,
        pageSize,
        ricerca,
        filters,
        sortBy
      });
  }

  public getTotaliCruscotto(idPolicySostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/totale-compilazioni', {});
  }

  public putCambiaFigura(idPolicySostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/figura/' + idFiguraPrecedente + '/cambia/' + idFiguraNuova);
  }

  public inviaMailMultiple(idPolicySostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/invio-multiplo', {
      idPolicySostenibilita
    });
  }

  public inviaNotificaMultiple(idPolicySostenibilita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/notifica-multiplo', {
      idPolicySostenibilita
    });
  }

  public putTemplateMail(idPolicySostenibilita: string, templateMail: any): Observable<any> {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/template', templateMail, { idPolicySostenibilita });
  }

  public getTemplateMail(idPolicySostenibilita: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/template', { idPolicySostenibilita });
  }

  public getElementoDaCompilare(idPolicySostenibilita: string, idAmbito: string, idTematica: string, idElemento: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idPolicySostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/elemento/' + idElemento, {

      idAzienda: this._idAzienda,
      idAmbito,
      idTematica,
      idElemento,
      idPolicySostenibilita
    });
  }

  public postElementoDaCompilare(
    idPolicySostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    rispostaBreve: string | number,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string
  ) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' +
      idPolicySostenibilita + '/questionario/ambito/' + idAmbito + '/tematica/' + idTematica + '/elemento/' + idElemento, {
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      rispostaBreve,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento
    })
  }


  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.webService.doGet('/api/v1/compila-policy/get', {
      temporaryToken
    });
  }

  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.webService.doPost('/api/v1/compila-policy/compila',
      objEsterna
      , {
        temporaryToken
      });
  }
  public invioTestMail(messaggio: string, email: string, idPolicySostenibilita: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/test-invio-mail', {
      messaggio,
      email
    });
  }

  public getAnteprimaPolicyCompilato(idPolicySostenibilita: string, idAzienda: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/anteprima-policy-compilata', {});
  }

  /* -------------------------  PERIMETRI E DESTINATARI  STEP 9  -------------------------------- */

  public getPerimetriDestinatari(idPolicy: string): Observable<any> {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicy + '/perimetri-destinatari', {});
  }

  public putPerimetriDestinatari(idPolicySostenibilita: string, perimetriDestinatari: PerimetriDestinatariGrp[]) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/perimetri-destinatari', perimetriDestinatari);
  }

  public putCompilazionePerimetriInPolicy(idPolicySostenibilita: string, idPerimetro: string, descrizione: string, lineaGuidaCompilata: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/perimetri/' + idPerimetro, { descrizione, lineaGuidaCompilata });
  }

  public putCompilazioneDestinatariInPolicy(idPolicySostenibilita: string, idDestinatario: string, descrizione: string, lineaGuidaCompilata: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/destinatari/' + idDestinatario, { descrizione, lineaGuidaCompilata });
  }

  /**
   * Cambio stato a pubblicato della policy
   * @param idPolicySostenibilita 
   * @returns 
   */
  public pubblicaPolicy(idPolicySostenibilita: string) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/pubblica', {});
  }

  /**
  * ritorna policy pubblicate
  * @returns 
  */
  public getPolicyPubblicate(idSettore?: string, idSottoSettori?: string[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/settore/' + idSettore + '/list', {
      idSottoSettori
    });
  }

  /**
  * ritorna materialità corrispondete all'id
  * @param idPolicy 
  * @returns 
  */
  public getPolicyById(id: string) {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + id, {});
  }

  public putDestinatariEffimeri(idPolicySostenibilita: string, destinatario: PerimetriDestinatari) {
    return this.webService.doPut('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicySostenibilita + '/destinatari-effimeri', { ...destinatario });
  }

  /* ------------------------- S T A M P A -------------------------------- */

  /**
  * GET Configurazioni stampa 
  * @param idPolicy 
  * @returns List<GruppoConfigRisultatiPolicyValori>
  */
  public getConfigStampa(idPolicy: string): Observable<GruppoCfgStampa> {
    return this.webService.doGet('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicy + '/cfg-stampa/list', {});
  }

  public postSalvaElementiStampa(idPolicy: string, cfgStampa: GruppoCfgStampa) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicy + '/cfg-stampa', cfgStampa);
  }

  public postStampaPolicy(idPolicy: string) {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/' + this.nomeWs + '/' + idPolicy + '/stampa', {});
  }

}
