import { PolicyService } from 'src/app/services/policy/policy.service';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { finalize } from 'rxjs';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
    selector: 'app-dialog-invia-mail',
    templateUrl: './dialog-invia-mail.component.html',
    styleUrls: ['./dialog-invia-mail.component.scss'],
    standalone: false
})
export class DialogInviaMailComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();

  constructor(
    private utilityService: UtilityService,
    private bilancioService: BilancioService,
    private sintesiService: SintesiService,
    private policyService: PolicyService,
    private pianoService: PianoService,
    private materialitaService: MaterialitaService,

    @Inject(MAT_DIALOG_DATA) public data: {
      idMaterialita?: string,
      idSintesiSostenibilita?: string,
      idBilancioSostenibilita?: string,
      idPolicySostenibilita: string;
      idPianoSostenibilita: string;
      messaggio: string

    }
  ) {

  }

  invia() {
    if (this.emailFormControl.valid && this.emailFormControl.value) {
      let metodoService;

      if (this.data.idMaterialita) {
        metodoService = this.materialitaService.invioTestMail(this.data.messaggio, this.emailFormControl.value);
      } else if (this.data.idSintesiSostenibilita) {
        metodoService = this.sintesiService.invioTestMail(this.data.messaggio, this.emailFormControl.value, this.data.idSintesiSostenibilita);
      } else if (this.data.idBilancioSostenibilita) {
        metodoService = this.bilancioService.invioTestMail(this.data.messaggio, this.emailFormControl.value, this.data.idBilancioSostenibilita);
      } else if (this.data.idPolicySostenibilita) {
        metodoService = this.policyService.invioTestMail(this.data.messaggio, this.emailFormControl.value, this.data.idPolicySostenibilita);
      } else if (this.data.idPianoSostenibilita) {
        metodoService = this.pianoService.invioTestMail(this.data.messaggio, this.emailFormControl.value, this.data.idPianoSostenibilita);
      }

      if (metodoService) {
        this.spinnerOver.show();
        metodoService
          .pipe(
            finalize(() => this.spinnerOver.hide()),
          )
          .subscribe({
            next: (esito) => {
              this.utilityService.opneSnackBar('Mail inviata', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
            },
            error: (err) => {
              this.utilityService.opneSnackBar('Mail non inviata', '', {
                duration: 2000,
                panelClass: ['red-snackbar']
              });
            }
          });
      }
    }
  }
}
