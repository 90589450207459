import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ListaTabellareComponent, Colonna, BottoniListaEvent, Filtri } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { KPI, KPIService } from 'src/app/services/KPI/kpi.service';
import { SettoriService } from 'src/app/services/settori/settori.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaKPIComponent } from './dialog/dialog-crea-kpi/dialog-crea-kpi.component';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { DialogImportazioneExcelComponent } from '../../analisi-materialita/stakeholder/dialog/dialog-importazione-excel/dialog-importazione-excel.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { StandardRendicontazioneService, STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';

@Component({
    selector: 'app-cfg-kpi',
    templateUrl: './cfg-kpi.component.html',
    styleUrls: ['./cfg-kpi.component.scss'],
    standalone: false
})
export class CfgKPIComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public filtri: Filtri[] = [

    {
      titolo: 'Nome Breve',
      forControlName: 'nomeBreve',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
    {
      forControlName: 'settori',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true
    },
    {
      forControlName: 'stdr',
      input: 'multiple-option',
      titolo: 'Standard di rendicontazione',
      fnDatiOption: () => (this.stdrService.getStdr(0, 1000)).pipe(map((value) => value?.content.filter((padre: STDR) => !padre.compliance) || [])),
      descValueOption: 'codice',
      idValueOption: 'codice',
      riempi: true
    },
    {
      forControlName: 'compliance',
      input: 'multiple-option',
      titolo: 'Compliance Normativi',
      fnDatiOption: () => (this.stdrService.getStdr(0, 1000)).pipe(map((value) => value?.content.filter((padre: STDR) => padre.compliance) || [])),
      descValueOption: 'codice',
      idValueOption: 'codice',
      riempi: true
    },
    {
      forControlName: 'idCategoriaUnitaDiMisura',
      input: 'multiple-option',
      titolo: 'Categoria',
      fnDatiOption: () => (this.unitaDiMisuraService.getCategoria()),
      descValueOption: 'nome',
      idValueOption: 'id',
      riempi: true
    },
    {
      forControlName: 'idUnitaDiRiferimento',
      input: 'multiple-option',
      titolo: 'Unità di Misura di Riferimento',
      fnDatiOption: () => (this.unitaDiMisuraService.getUnitaDiMisura(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'nomeBreve',
      idValueOption: 'id',
      riempi: true
    },
    {
      titolo: 'Note',
      forControlName: 'note',
      input: 'text',
      riempi: true
    },
    {
      titolo: 'TAG',
      forControlName: 'tag',
      input: 'multiple-option-chip',
      riempi: true,
      larghezza: 3,
      fnDatiOption: (ricerca: string | undefined) => this.kpiService.getKPITags(0, 1000, ricerca),
      numeroRecOptions: Infinity
    },
  ];

  public colonne: { [key: string]: Colonna } = {

    titolo: {
      title: 'Titolo',
      value: 'nomeBreve',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    /*   duplica: {
        type: 'button',
        buttonIcon: 'content_copy',
        title: 'Duplica',
        buttonId: 'duplica',
      }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };
  constructor(public dialog: MatDialog,
    private readonly kpiService: KPIService,
    private readonly utilityService: UtilityService,
    private readonly settoriService: SettoriService,
    private readonly tematicaService: TematicheService,
    private readonly unitaDiMisuraService: UnitaDiMisuraService,
    public utenteService: UtenteService,
    private readonly stdrService: StandardRendicontazioneService,
  ) { }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  creaNuovoKPI(recordKPI?: KPI) {
    const dialogCreaKPI = this.dialog.open(DialogCreaKPIComponent, {
      data: {
        kpi: recordKPI,
      },
      panelClass: ['dialog-container'],
      disableClose: false,
      width: '80%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaKPI.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'nomeBreve',
        sort: 'asc'
      }];
    }

    return this.kpiService.getKPI(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovoKPI(event.data);
        break;
      case 'elimina':
        this._eliminaKPI(event.data.id);
        break;
      case 'duplica':
        this._duplicaKPI();
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare
   * @param idRiga id della riga da eliminare
   */
  private _eliminaKPI(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'KPI?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.kpiService.deleteKPI(idRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un KPI
   */
  public _duplicaKPI() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }

  public importaKpi() {
    const dialogImportaKpi = this.dialog.open(DialogImportazioneExcelComponent, {
      data: {
        titoloImportazione: 'Importazione Kpi',
        subTitoloImportazione: 'Scarica il file di template excel o fai una nuova importazione dei kpi',
        pathTemplateExcel: 'assets/excel/kpi.xlsx',
        azienda: false,
        nomeImport: 'kpi',
      },
      panelClass: ['dialog-container'],
      disableClose: false,
      maxWidth: '90%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogImportaKpi.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }
}
