import { Injectable } from '@angular/core';
import { KPIWebService } from './kpi-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore } from '../settori/settori.service';


export interface KPI {
  id: string,
  nomeBreve: string,
  descrizione: string,
  note: string,
  settori: Settore[],
  stdr?: Stdr[],
  idCategoriaUnitaDiMisura: string,
  idUnitaDiRiferimento: string, // id dell'unità di misura di riferimento, deve esistere nel gruppo unità di misura selezionato
  /*  evidenziaNote: boolean, */
  tag?: string
  valoreAttribuito?: string
  tipo?: string,
  creatoDaContesto?: boolean;
  valoriKpi?: ValoriKpi[];
  valoriTarget?: ValoriKpi[];
  idObiettivo?: string
}

export interface ValoriKpi {
  valoreAttribuito: number | undefined;
  idUnitaDiRiferimento: string;
  idCategoriaUnitaDiMisura: string;
  anno: number | undefined;
  tipo: string;
  preCompilazioneInfos?: string;
  tipoAzione?: string;
}

export interface Stdr {
  chiave: string;
  descrizione: string;
  target: boolean;
  checked?: boolean
}

export interface KPISintesi extends KPI {
  selected: boolean,
  consigliato: boolean,
  figura: Figura, // intero oggetto, valutare se tenerlo allineato o meno
  creatoDaContesto: boolean,
  compilato: boolean
}

export interface Figura {
  email: string;
  figura: string;
  id: string;
  idAzienda: string;
  nome: string;
}

@Injectable({
  providedIn: 'root'
})
export class KPIService {


  constructor(private kpiWebService: KPIWebService) { }
  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getKPI(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.kpiWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del kpi
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postkpi per la creazione kpi
     */
  public postKPI(kpi: any) {
    return this.kpiWebService.post(kpi);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idKPI id della riga da eliminare
    * @returns 
    */
  public deleteKPI(idKPI: any) {
    return this.kpiWebService.delete(idKPI);
  }

  /**
 *  Modifica del KPI
 * @param id 
 * @param kpi 
 * @returns 
 */
  public putKPI(kpi: any) {
    return this.kpiWebService.put(kpi);
  }

  public putStdr(idKpi: string, arrayStdr: any) {
    return this.kpiWebService.putStdr(idKpi, arrayStdr);
  }

  /**
     * Funzione per selezionare i tag dei kpi
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getKPITags(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.kpiWebService.getKPITags(page, pageSize, ricerca, filters, sortBy);
  }
   
  /**
   * Get lista stdr filtrati per settori selezionati 
   * @param settori 
   * @returns 
   */
   public getStdr(idKpi: string | null, settoriIds: string[], isCreazione: boolean) {
    return this.kpiWebService.getStdr(idKpi, settoriIds, isCreazione);
  }
}
