<app-spinner-overlay></app-spinner-overlay>

<div class="container-titolo">
    <div>
        <div class="titolo">Policy di Sostenibilità
        </div>
    </div>
    <div class="separatore">
    </div>
</div>

<div style="margin-bottom: 15px;"></div>
<ng-container *ngIf="this.data.perimetroDestinatario">
    <div mat-dialog-content class="dialog-center-cont contenitore">
        <div class="descrizione-comitato">{{formPerimDestComp.get('descrizione')?.value}}</div>
        <div class="info">
            <!-- Spiega il ruolo del rispetto alla
            sostenibilità e al documento “policy di sostenibilità” -->
            Definisci e descrivi le modalità di attuazione della Policy di Sostenibilità per il
            {{data.perimetroDestinatario.chiaveElemento}} selezionato
        </div>


        <form [formGroup]="formPerimDestComp" appFormContainer [larghezza]="3">
            <mat-form-field class="descrizione-destinatario" appFormElement [larghezza]="3" [riempi]="true">
                <mat-label> {{data.perimetroDestinatario.chiaveElemento === 'perimetro' ? 'Descrizione Perimetro' :
                    'Titolo Destinatario'}}</mat-label>
                <input formControlName="descrizione" matInput
                    [placeholder]="this.data.perimetroDestinatario.chiaveElemento === 'perimetro' ? 'Descrizione Perimetro' : 'Titolo Destinatario'">
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <div appFormElement [larghezza]="4" [riempi]="true" class="testo-linea-guida" *ngIf="this.data.perimetroDestinatario.chiaveElemento === 'perimetro'">
                <div style="display: flex;">
                    <mat-icon class="material-icons-outlined icone-tem">error</mat-icon>
                </div>
                <div class="titolo-linea-guida" *ngIf="this.data.perimetroDestinatario.chiaveElemento === 'perimetro'">
                    {{data.perimetroDestinatario.lineaGuidaPolicy}}</div>
            </div>


            <mat-form-field class="descrizione-destinatario" appFormElement [larghezza]="3" [riempi]="true">
                <mat-label>Descrizione attuazione</mat-label>
                <textarea formControlName="lineaGuidaCompilata" matInput [placeholder]="'Descrizione attuazione'"
                    rows="4"></textarea>
                <mat-error appFormError></mat-error>
            </mat-form-field>

        </form>

        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
    </div>

    <div mat-dialog-actions class="dialog-footer-cont">
        <div>
            <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
        </div>
        <div *ngIf="!this.data?.isPermessoSoloRead">
            <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
                Conferma
            </button>
        </div>
    </div>

</ng-container>