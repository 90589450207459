<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>
    Configurazione Informativa Aziendale
</h2>
<div mat-dialog-content class="dialog-center-cont">

    <form [formGroup]="formInfoAz">
        <div appFormContainer [larghezza]="3">

            <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                <mat-label>Titolo</mat-label>
                <input matInput placeholder="Nome Breve" formControlName="titolo" />
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
                <mat-label>Descrizione</mat-label>
                <input matInput placeholder="Descrizione" formControlName="descrizione" />
                <mat-error appFormError></mat-error>

            </mat-form-field>
        </div>
        <div style="height: 10px;"></div>
        <!-- TABBE -->
        <ng-container *ngFor="let sezioniArr of $any(formInfoAz.get('sezioni'))?.controls">

            <mat-accordion>

                <div cdkDropList (cdkDropListDropped)="drop($event, sezioniArr)">
                    <mat-expansion-panel [id]="'DOM_' + domandeArr.get('id').value" expanded="true"
                        (opened)="scrollaInView(anchor)"
                        *ngFor="let domandeArr of sezioniArr.get('domande').controls; let idx = index" cdkDrag>
                        <div #anchor></div>
                        <div class="" *cdkDragPlaceholder></div>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <button mat-icon-button title="Sposta" aria-label="Sposta">
                                    <mat-icon> drag_handle </mat-icon>
                                </button>
                                <span class="domanda">
                                    {{domandeArr.get("domanda").value}}
                                </span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <div class="testo-risposta">
                                    <!--    {{domandeArr.get("valore").value}} -->
                                </div>

                                <button mat-icon-button title="Elimina" aria-label="Elimina" color="warn"
                                    (click)="$event.stopPropagation(); elimina(sezioniArr.get('domande'), idx)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <!--form opzioni -->
                        <div appFormContainer [larghezza]="4" [formGroup]="domandeArr" [alignItems]="'flex-start'">

                            <!-- LINEA GUIDA DOMANDA -->
                            <ng-container
                                *ngFor="let lineaGuida of domandeArr?.controls?.metadati?.controls?.lineaGuida?.controls">

                                <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement [larghezza]="3"
                                    [riempi]="true">
                                    <mat-label>Linea Guida</mat-label>
                                    <textarea #inputLineaGuida matInput cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" placeholder="Linea Guida"
                                        [formControl]="lineaGuida" rows="1"></textarea>
                                    <mat-error appFormError></mat-error>
                                </mat-form-field>

                            </ng-container>

                            <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement [larghezza]="3"
                                [riempi]="true">
                                <mat-label>Domanda</mat-label>
                                <textarea #inputDesc matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1" placeholder="Domanda" formControlName="domanda"
                                    rows="1"></textarea>
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement [larghezza]="1"
                                [riempi]="true">
                                <mat-label>Tipologia Risposta</mat-label>
                                <mat-select formControlName="tipo" (valueChange)="cambiaTipologia($event,domandeArr)">
                                    <mat-option [value]="tpRisp.chiave"
                                        *ngFor="let tpRisp of arrayTipologiaRisposta">{{tpRisp.valore}}</mat-option>
                                </mat-select>
                                <mat-error appFormError></mat-error>
                            </mat-form-field>

                            <mat-form-field
                                *ngIf="domandeArr.get('tipo').value==='numero_intero' || domandeArr.get('tipo').value==='numero_decimale' "
                                appFormElement [larghezza]="2" [riempi]="true">
                                <mat-label>Categorie</mat-label>
                                <mat-select [placeholder]="'Categorie'" formControlName="idCategoriaUnitaDiMisura">
                                    <mat-option *ngFor="let cat of arrayCategorie"
                                        [value]="cat.id">{{cat.nome}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appFormElement [larghezza]="2" [riempi]="true"
                                *ngIf="domandeArr.get('idCategoriaUnitaDiMisura').value">
                                <mat-label>Unità Di Misura</mat-label>
                                <mat-select [placeholder]="'Unità Di Misura'" formControlName="idUnitaDiRiferimento">
                                    <mat-option
                                        *ngFor="let um of mappaCategorieUm[domandeArr.get('idCategoriaUnitaDiMisura').value]"
                                        [value]="um.id">{{um.descrizione}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- END appFormContainer -->
                        </div>

                        <ng-container
                            *ngIf="$any(domandeArr.get('tipo'))?.value === 'scelta_singola' || $any(domandeArr.get('tipo'))?.value === 'scelta_multipla'">

                            <div appFormElement [larghezza]="3" [riempi]="true">
                                <app-separatore-label titoloSeparatore="Risposte"
                                    [styleSeparatore]="'small'"></app-separatore-label>
                            </div>

                            <div style="height: 25px;"></div>

                            <!-- Risposte -->
                            <mat-accordion appFormElement [larghezza]="3">

                                <div cdkDropList (cdkDropListDropped)="dropSotto($event,domandeArr.get('opzioni'))">

                                    <mat-expansion-panel expanded="true"
                                        *ngFor="let opzioni of $any(domandeArr.get('opzioni'))?.controls; let idx2 = index"
                                        cdkDrag>

                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <button mat-icon-button title="Sposta" aria-label="Sposta">
                                                    <mat-icon> drag_handle </mat-icon>
                                                </button>
                                                {{opzioni.get("valore").value}}
                                            </mat-panel-title>
                                            <mat-panel-description>
                                                <div class="testo-risposta">
                                                    <!-- {{opzioni.get("valore").value}} -->
                                                </div>
                                                <button mat-icon-button title="Elimina Sottorisposta"
                                                    aria-label="Elimina Sottorisposta" color="warn"
                                                    (click)="elimina( $any(domandeArr.get('opzioni')), idx2)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <div appFormContainer [larghezza]="3" [formGroup]="opzioni">

                                            <!-- <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement
                                                [larghezza]="3" [riempi]="true">
                                                <mat-label>Descrizione</mat-label>
                                                <input matInput placeholder="Descrizione" formControlName="valore" />
                                                <mat-error appFormError></mat-error>
                                            </mat-form-field> -->
                                            <!--  -->
                                            <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement
                                                [larghezza]="3" [riempi]="true">
                                                <mat-label>Descrizione</mat-label>
                                                <textarea #inputDesc matInput cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                                    placeholder="Descrizione" formControlName="valore"
                                                    rows="1"></textarea>
                                                <mat-error appFormError></mat-error>
                                            </mat-form-field>
                                        </div>

                                    </mat-expansion-panel>

                                </div>
                            </mat-accordion>

                            <div style="height: 25px;"></div>

                            <div appFormElement [larghezza]="3" [riempi]="true">
                                <button mat-stroked-button (click)="addOpzioni($any(domandeArr.get('opzioni')))"
                                    style="height: 56px;width: 100%;font-weight: bold;">
                                    Aggiungi Risposte
                                </button>
                            </div>

                        </ng-container>

                    </mat-expansion-panel>

                </div>
            </mat-accordion>

            <div style="height: 25px;"></div>

            <button mat-stroked-button (click)="addDomanda(sezioniArr.get('domande').controls)"
                style="height: 56px;width: 100%;font-weight: bold;">
                Aggiungi Domanda
            </button>
        </ng-container>
    </form>
    <div style="height: 10px;"></div>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salvaInfoAz()" class="primary-button">
            Salva
        </button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="pubblica()" class="primary-button">
            Pubblica
        </button>
    </div>
</div>