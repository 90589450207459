import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-dialog-pdf-viewer',
  imports: [NgxExtendedPdfViewerModule],
  templateUrl: './dialog-pdf-viewer.component.html',
  styleUrl: './dialog-pdf-viewer.component.scss'
})
export class DialogPdfViewerComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      pdfPath: string
    }
  ) {

  }

}
