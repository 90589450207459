import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { TemaService } from 'src/app/services/tema/tema.service';

@Component({
    selector: 'app-sintesi-sostenibilita-esterna',
    templateUrl: './sintesi-sostenibilita-esterna.component.html',
    styleUrls: ['./sintesi-sostenibilita-esterna.component.scss'],
    standalone: false
})
export class SintesiSostenibilitaEsternaComponent implements OnInit, AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public logoPath: string = 'assets/images/logo/logo-sostenibile.png';
  public completato: boolean = false;
  public temporaryToken!: string;
  public descrizione: string = "";
  public messaggio: string = '';

  public compilazione: any;
  public idSintesiSostenibilita: string = "";
  private idAzienda: string = "";
  public landingPage: string = "";
  public isAnteprima: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private sintesiService: SintesiService,
    private temaService: TemaService
  ) { 
    this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      this.logoPath = temaService.logoPath;
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.parametriDaURL();
  }

  /**
 * Metodo che mi prende il token dal URL
 */
  public parametriDaURL() {
    // Sottoscrivi all'observable paramMap per ottenere i parametri dall'URL
    this.spinnerOver.show();
    this.route.queryParams.subscribe(params => {
      // Ora puoi accedere ai parametri come segue
      this.temporaryToken = params['temporaryToken'];
      this.idSintesiSostenibilita = params['id'];
      this.idAzienda = params['idA'];


      if (this.temporaryToken) {
        this._getSurveyByTemporaryToken();
      } else if (this.idSintesiSostenibilita && this.idAzienda) {
        this._anteprimaSintesi();
      }
    });
  }

  private _anteprimaSintesi() {
    this.isAnteprima = true;
    this.spinnerOver.show();
    this.sintesiService.getAnteprimaSintesiCompilata(this.idSintesiSostenibilita, this.idAzienda).subscribe({
      next: (esito) => {
         
        this.spinnerOver.hide();
        this.compilazione = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }

  /**
 * Metodo che mi prende i dati della survey dal token
 */
  private _getSurveyByTemporaryToken() {
    this.isAnteprima = false;
    this.sintesiService.getElementiEsterniDaCompilare(
      this.temporaryToken
    ).subscribe({
      next: (esito) => {
        this.compilazione = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.messaggio = err?.error?.message || "Nessun dato trovato";
        this.completato = true;
        this.spinnerOver.hide();
      }
    })
  }

  loading = (loading: boolean) => {
    if (loading) {
      this.spinnerOver.show();
    } else {
      this.spinnerOver.hide();
    }
  }

  salva = (compilazione: any, isImportazione?: boolean): Observable<any> => {
    if (compilazione) {

      let arrayRequest: any[] = [];

      (this.compilazione.ambiti as Ambito[]).forEach((ambito) => {
        ambito.tematiche.forEach((tematica) => {

          const objCall = {
            idAmbito: ambito.id,
            idTematica: tematica.id,
            idElemento: '',
            idUnitaDiRiferimento: '',
            idCategoriaUnitaDiMisura: '',
            risposta: ''
          }

          if (tematica.impegni?.length) {
            tematica.impegni.forEach((impegno) => {
              arrayRequest.push({
                ...objCall, idElemento: impegno.id,
                risposta: impegno.risposta
              });
            })
          }


          if (tematica.kpis?.length) {

            tematica.kpis.forEach((kpi) => {
              const valoriKpi = kpi.valoriKpi || [];
              const valoriTarget = kpi.valoriTarget || [];

              const risposta = [
                ...valoriKpi,
                ...valoriTarget
              ];
              arrayRequest.push({
                ...objCall, idElemento: kpi.id,
                idUnitaDiRiferimento: kpi.idUnitaDiRiferimento,
                idCategoriaUnitaDiMisura: kpi.idCategoriaUnitaDiMisura,
                risposta: risposta
              });

            })
          }

          if (tematica.impatti?.length) {
            tematica.impatti.forEach((impatti) => {
              arrayRequest.push({
                ...objCall, idElemento: impatti.id,
                risposta: impatti.valoreAttribuito
              });
            })
          }

          if (tematica.azioni?.length) {
            tematica.azioni.forEach((azione) => {
              arrayRequest.push({
                ...objCall, idElemento: azione.id,
                risposta: azione.risposta
              });
            })
          }

          if (tematica.certificazioni?.length) {
            tematica.certificazioni.forEach((certif) => {
              arrayRequest.push({
                ...objCall, idElemento: certif.id,
                risposta: certif.risposta
              });
            })
          }

        })
      })

      const arrayRequestFiltered = arrayRequest.filter((elem) => {
        if (elem.risposta !== null && elem.risposta !== undefined) {
          // Se elem.risposta è un array, controlla i valori interni
          if (Array.isArray(elem.risposta)) {
            return elem.risposta.some((risp: any) =>
              risp?.valoreAttribuito !== null && risp?.valoreAttribuito !== undefined
            );
          }
          // Se non è un array, ritorna true perché risposta è valida (non null o undefined)
          return true;
        }
        // escludo se elem.risposta è null o undefined
        return false;
      });

      return this.sintesiService.postElementiEsterniDaCompilare(
        this.temporaryToken,
        arrayRequestFiltered
      ).pipe(
        map(risp => {
        
          if (!isImportazione) {
            this.messaggio = 'Le tue risposte sono state salvate. Grazie.'
            this.completato = true;
          }
        }),
        catchError(err => {
          console.error(err);
          return throwError(() => err);
        })
      )
    }
    return of()

  }
}
