import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-crea-cfg-autovalutazione',
    templateUrl: './dialog-crea-cfg-autovalutazione.component.html',
    styleUrls: ['./dialog-crea-cfg-autovalutazione.component.scss'],
    standalone: false
})
export class DialogCreaCfgAutovalutazioneComponent implements AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCreaCfgAutovalutazioneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cfgAutovalutazione: any }
  ) { }

  ngAfterViewInit(): void { }

  pubblicato(){
    this.dialogRef.close();
  }

}
