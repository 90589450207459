import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
 
@Injectable({
  providedIn: 'root'
})
export class DashboardWebService {

  constructor(
    private webService: WebService
  ) { }

  public getInfoLicenze(idUtente: string): Observable<any> {
    return this.webService.doGet('/api/v1/dashboard/utente/' + idUtente + '/chart-licenze', {});
  }

  public getDatiDoughnutChart(idUtente: string): Observable<any> {
    return this.webService.doGet('/api/v1/dashboard/utente/' + idUtente + '/chart-storage', {});
  }
  public aggiornaDatiDoughnutChart(idUtente: string): Observable<any> {
    return this.webService.doGet('/api/v1/dashboard/utente/' + idUtente + '/update-chart-storage', {});
  }

  /**
 * lista riepilogo generale 
 * @param page 
 * @param pageSize 
 * @param ricerca 
 * @param filters 
 * @param sortBy 
 * @returns 
 */
  public getRiepilogoGenerale(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/dashboard/riepilogo-generale', { page, pageSize, ricerca, filters, sortBy });
  }
}
