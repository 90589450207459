import { Component } from '@angular/core';

@Component({
    selector: 'app-dashboard-azienda',
    templateUrl: './dashboard-azienda.component.html',
    styleUrls: ['./dashboard-azienda.component.scss'],
    standalone: false
})
export class DashboardAziendaComponent {
  public titolo = ['KPIs', 'Valutazione di Sostenibilità', 'Rating Totale'];
  public value = [90, 40, 80];
  public maxValue = [0, 100, 100];
  public coloriLineChart = ['#2C8945','#892F2C','#2C3289'];
  public valoreFreccia = [20.2, 30.4, 50.4];
}
