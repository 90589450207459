import { Injectable } from '@angular/core';
import { AzioneEBuonaPraticaWebService } from './azione-e-buona-pratica-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Settore } from '../settori/settori.service';

export interface AzioneBP {
  id: string,
  titolo: string,
  descrizione: string,
  settori: Settore[],
  stdr: string[],
  note: string,
  tag?: string,
  creatoDaContesto?: boolean;
  risposta?: string;
  rispostaBreve?: string;
  tipo?: string;
  preCompilazioneInfos?: string
  anno?: string
  titoloObiettivo?: string,
  idObiettivo?: string
}

@Injectable({
  providedIn: 'root'
})
export class AzioneEBuonaPraticaService {
  constructor(private azioneBPWebService: AzioneEBuonaPraticaWebService) { }
  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getAzioneBP(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.azioneBPWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del AzioneBP
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postAzioneBP per la creazione AzioneBP
     */
  public postAzioneBP(data: any) {
    return this.azioneBPWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteAzioneBP(idRiga: any) {
    return this.azioneBPWebService.delete(idRiga);
  }

  /**
 * AzioneBP
 * @param id 
 * @param AzioneBP 
 * @returns 
 */
  public putAzioneBP(id: string, AzioneBP: any) {
    return this.azioneBPWebService.put(id, AzioneBP);
  }
  /**
     * Funzione per selezionare i tag degli impegni
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getAzioneBPTags(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.azioneBPWebService.getAzioneBPTags(page, pageSize, ricerca, filters, sortBy);
  }

  /**
   * Get lista stdr filtrati per settori selezionati 
   * @param settori 
   * @returns 
   */
  public getStdrBySettore(idAzione: string | null, settoriIds: string[], isCreazione: boolean) {
    return this.azioneBPWebService.getStdrBySettore(idAzione, settoriIds, isCreazione);
  }
}
