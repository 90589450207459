import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Stdr } from './kpi.service';

@Injectable({
  providedIn: 'root'
})
export class KPIWebService {
  private nomeWs = 'kpi';

  constructor(private webService: WebService) { }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del kpi
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione post per la creazione kpi
   */
  public post(kpi: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs, kpi);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idKPI id della riga da eliminare
   * @returns 
   */
  public delete(idKPI: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs, { id: idKPI })
  }

  /**
   * Modifica del KPI
   * @param id 
   * @param kpi 
   * @returns 
   */
  public put(kpi: any): Observable<any> {
    const id: any = kpi?.id;
    return this.webService.doPut('/api/v1/' + this.nomeWs, kpi, {
      id
    });
  }

  /**
*Funzione per selezionare i tag dei kpi
* Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
* @param page pagina
* @param pageSize record per pagina
* @param ricerca risultato per ricerca 
* @param filters valori da filtrare 
* @param sortBy sort asc desc
* @returns lista di righe
*/
  public getKPITags(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/tags', { page, pageSize, ricerca, filters, sortBy });
  }



  public putStdr(idKpi: string, arrayStdr: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/' + idKpi + '/update-stdr', {
      ...arrayStdr
    });
  }

  /**
   * Get lista stdr per settore
   * @param idAzione 
   * @param settori  
   * @returns 
   */
  public getStdr(idKpi: string | null, settoriIds: string[], isCreazione: boolean) {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/' + idKpi + '/list-stdr', { settoriIds, isCreazione })
  }


}
