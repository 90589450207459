import { Injectable } from '@angular/core';
import { BilancioWebService } from './bilancio-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore, SottoSettore } from '../settori/settori.service';
import { InformativaAziendale } from '../informativa-aziendale/informativa-aziendale.service';
import { CfgStampa, GruppoCfgStampa } from '../config-stampe/config-stampe.service';
import { STDR } from '../standard-rendicontazione/standard-rendicontazione.service';

export interface BilancioSostenibilita {
  id?: string;
  idAzienda?: string;
  anno?: string;
  fatturato?: number;
  nrDipendenti?: number;
  stdr?: string[];
  idMaterialita?: string;
  idSintesiSostenibilita?: string;
  idAutovalutazioneCompilata?: string;
  idPolicySostenibilita?: string;
  idPianoSostenibilita?: string;
  idQuestionarioInformativa?: string;
  idQuestionario?: string;
  settori?: Settore[];
  sottoSettori?: SottoSettore[];
  nome?: string;
  stato?: string;
  /* impatti: any; 
  impegni: Record<string, any>; 
  azioniEBuonePratiche: Record<string, any>;
  kpi: Record<string, any>;  */
}


interface ControlloCompilazioneKpi {
  soglieSuperate: boolean;
  stdrBilancio: STDR[];
  complianceBilancio: STDR[];
}

export interface ConfigBilanci {
  nrMaxBilanci: number,
  nrMaxAmbiti: number,
  nrMaxTematiche: number
  settore: Settore,
}

@Injectable({
  providedIn: 'root'
})
export class BilancioService {


  controlloCompilazioneKpi: ControlloCompilazioneKpi = {
    soglieSuperate: false,
    stdrBilancio: [],
    complianceBilancio: [],
  };

  constructor(private bilancioWebService: BilancioWebService) { }



  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getBilancio(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.bilancioWebService.get(page, pageSize, ricerca, filters, sortBy);
  }


  /**
    * Funzione per eliminare una riga da una lista 
  * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteBilancio(idRiga: any) {
    return this.bilancioWebService.delete(idRiga);
  }

  public getAnteprimaBilancioCompilato(idBilancio: string, idAzienda: string) {
    return this.bilancioWebService.getAnteprimaBilancioCompilato(idBilancio, idAzienda);
  }

  /* --------------------------- FIGURE AZIENDALI --- ---------------*/

  public getListaAssegnazioniFigAz(idBilancio: string) {
    return this.bilancioWebService.getListaAssegnazioniFigAz(idBilancio);
  }


  /* ---------------- C R E A Z I O N E   B I L A N C I O ---- step1 ------------*/

  /**
   * Creazione step 1 bilancio
   * @param bilancioSostenibilita oggetto bilancio di sostenibilita
   * @returns 
   */
  public postCreazioneBilancioStep1(bilancioSostenibilita: any) {
    return this.bilancioWebService.postCreazioneBilancioStep1(bilancioSostenibilita);
  }

  /**
   * Aggiornamento step 1 bilancio
   * @param bilancioSostenibilita id bilancio di sostenibilita
   * @returns 
   */
  public putAggiornamentoBilancioStep1(idBilancioSostenibilita: any, fatturato: number, nrDipendenti: number) {
    return this.bilancioWebService.putAggiornamentoBilancioStep1(idBilancioSostenibilita, fatturato, nrDipendenti);
  }

  /* ---------------- C R E A Z I O N E  S T D R ---- step2 ------------*/
  /**
   * Aggiornamento step 2 bilancio
   * @param bilancioSostenibilita id bilancio di sostenibilita
   * @returns 
   */
  public putBilancioStep2(idBilancioSostenibilita: any, bilancioStep2: any) {
    return this.bilancioWebService.putBilancioStep2(idBilancioSostenibilita, bilancioStep2);
  }

  /**
 * Funzione per selezionare le righe da far vedere nella grid
 * @param page pagina
 * @param pageSize record per pagina
 * @param ricerca risultato per ricerca 
 * @param filters valori da filtrare 
 * @param sortBy sort asc desc
 * @returns lista di righe
 */
  public getInformativaList(
    idBilancioSostenibilita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.bilancioWebService.getInformativaList(idBilancioSostenibilita, page, pageSize, ricerca, filters, sortBy);
  }


  /* -------------- I N F O R M A T I V A   A Z I E N D A L E  step3 -----*/

  putSalvaCompilazioneInformativa(idBilancioSostenibilita: string, infoAz: InformativaAziendale) {
    return this.bilancioWebService.putSalvaCompilazioneInformativa(idBilancioSostenibilita, infoAz);
  }
  putSalvaCompilazioneInformativaBozza(idBilancioSostenibilita: string, infoAz: InformativaAziendale) {
    return this.bilancioWebService.putSalvaCompilazioneInformativaBozza(idBilancioSostenibilita, infoAz);
  }

  getCompilazioneInformativa(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getCompilazioneInformativa(idBilancioSostenibilita);
  }

  getInformativa(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getInformativa(idBilancioSostenibilita);
  }

  putCambiaFiguraInformativa(idBilancioSostenibilita: string, idFigura: string) {
    return this.bilancioWebService.putCambiaFiguraInformativa(idBilancioSostenibilita, idFigura);
  }

  inviaMailInformativa(idBilancioSostenibilita: string) {
    return this.bilancioWebService.inviaMailInformativa(idBilancioSostenibilita);
  }

  inviaNotificaInformativa(idBilancioSostenibilita: string) {
    return this.bilancioWebService.inviaNotificaInformativa(idBilancioSostenibilita);
  }

  /* ------------------------- T E M A T I C H E step 4 -------------------------------- */


  public getQuestionarioBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getQuestionarioBilancio(idBilancioSostenibilita);
  }

  /**
 * 
 * @param 
 * @returns 
 */
  public putQuestionarioBilancio(idBilancioSostenibilita: string, idSurvey: string, ambiti: Ambito[], settori: Settore[], titolo: string, stato: string, tipo: string) {
    return this.bilancioWebService.putQuestionarioBilancio(idBilancioSostenibilita, idSurvey, ambiti, settori, titolo, stato, tipo);
  }

  /* ------------------------- C H E C K   I M P A T T I   step 5 -------------------- */
  /**
 * GET IMPATTI BILANCIO
 * @param idBilancioSostenibilita 
 * @returns obj con lista ambiti - tematiche - impatti
 */
  public getImpattiBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getImpattiBilancio(idBilancioSostenibilita);
  }

  /**
  * SALVATAGGIO IMPATTI BILANCIO SELEZIONATI
  * @param idBilancioSostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putImpattiBilancioSelezionati(idBilancioSostenibilita: string, impattiSelezionati: any) {
    return this.bilancioWebService.putImpattiBilancioSelezionati(idBilancioSostenibilita, impattiSelezionati);
  }

  /**
  * ASSEGNA FIGURA AD IMPATTO BILANCIO
  * @param idBilancioSostenibilita 
  * @param bilancio
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraImpattoBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idBilancio: string, idFigura: string) {
    return this.bilancioWebService.putAssegnaFiguraImpattoBilancio(idBilancioSostenibilita, idAmbito, idTematica, idBilancio, idFigura);
  }


  public putImpattiEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, impatto: any) {
    return this.bilancioWebService.putImpattiEffimeri(idBilancioSostenibilita, idAmbito, idTematica, impatto);
  }

  /* ------------------------- I M P E G N I     step 6 -------------------------------- */
  /**
  * GET IMPEGNI BILANCIO
  * @param idBilancioSostenibilita 
  * @returns obj con lista ambiti - tematiche - impegni
  */
  public getImpegniBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getImpegniBilancio(idBilancioSostenibilita);
  }

  /**
  * SALVATAGGIO IMPEGNI BILANCIO SELEZIONATI
  * @param idBilancioSostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putImpegniBilancioSelezionati(idBilancioSostenibilita: string, impegniSelezionati: any) {
    return this.bilancioWebService.putImpegniBilancioSelezionati(idBilancioSostenibilita, impegniSelezionati);
  }

  /**
  * ASSEGNA FIGURA AD IMPEGNO BILANCIO
  * @param idBilancioSostenibilita 
  * @param bilancio
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraImpegnoBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idBilancio: string, idFigura: string) {
    return this.bilancioWebService.putAssegnaFiguraImpegnoBilancio(idBilancioSostenibilita, idAmbito, idTematica, idBilancio, idFigura);
  }

  public putImpegniEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, impegno: any) {
    return this.bilancioWebService.putImpegniEffimeri(idBilancioSostenibilita, idAmbito, idTematica, impegno);
  }

  /* ------------------------- A Z I O N I    B P    step 7 -------------------------------- */
  /**
  * GET AZIONI BP BILANCIO
  * @param idBilancioSostenibilita 
  * @returns obj con lista ambiti - tematiche - azioniBP
  */
  public getAzioniBPBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getAzioniBPBilancio(idBilancioSostenibilita);
  }

  /**
  * SALVATAGGIO AZIONI BP BILANCIO SELEZIONATI
  * @param idBilancioSostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putAzioniBPBilancioSelezionati(idBilancioSostenibilita: string, azioniBPSelezionati: any) {
    return this.bilancioWebService.putAzioniBPBilancioSelezionati(idBilancioSostenibilita, azioniBPSelezionati);
  }

  /**
  * ASSEGNA FIGURA AD AZIONI BP BILANCIO
  * @param idBilancioSostenibilita 
  * @param bilancio
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraAzioniBPBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idBilancio: string, idFigura: string) {
    return this.bilancioWebService.putAssegnaFiguraAzioniBPBilancio(idBilancioSostenibilita, idAmbito, idTematica, idBilancio, idFigura);
  }

  public putAzioniBPEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, azioniBP: any) {
    return this.bilancioWebService.putAzioniBPEffimeri(idBilancioSostenibilita, idAmbito, idTematica, azioniBP);
  }

  /* ------------------------- K P I step8-------------------------------- */
  /**
   * GET KPI BILANCIO
   * @param idBilancioSostenibilita 
   * @returns obj con lista ambiti - tematiche - kpi
   */
  public getKpiBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getKpiBilancio(idBilancioSostenibilita);
  }

  /**
   * SALVATAGGIO KPI BILANCIO SELEZIONATI
   * @param idBilancioSostenibilita  
   * @param objStep3 
   * @returns 
   */
  public putKpiBilancioSelezionati(idBilancioSostenibilita: string, kpiSelezionate: any) {
    return this.bilancioWebService.putKpiBilancioSelezionati(idBilancioSostenibilita, kpiSelezionate);
  }

  /**
  * ASSEGNA FIGURA AD KPI BILANCIO
  * @param idBilancioSostenibilita 
  * @param kpi
  * @returns <Status 200 (senza body)>
  */

  public putAssegnaFiguraKpiBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idKpi: string, idFigura: string) {
    return this.bilancioWebService.putAssegnaFiguraKpiBilancio(idBilancioSostenibilita, idAmbito, idTematica, idKpi, idFigura);
  }
  /**
  * GET ASSEGNAZIONI KPI BILANCIO (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
  * @param idBilancioSostenibilita 
  * @returns 
  */
  public getAssegnazioniKpi(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getAssegnazioniKpi(idBilancioSostenibilita);
  }

  public putKpiEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, kpi: any) {
    return this.bilancioWebService.putKpiEffimeri(idBilancioSostenibilita, idAmbito, idTematica, kpi);
  }

  public getAnniPrecKpi(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getAnniPrecKpi(idBilancioSostenibilita);
  }

  public postAnniPrecKpi(idBilancioSostenibilita: string) {
    return this.bilancioWebService.postAnniPrecKpi(idBilancioSostenibilita);
  }
  public delAnniPrecKpi(idBilancioSostenibilita: string) {
    return this.bilancioWebService.delAnniPrecKpi(idBilancioSostenibilita);
  }
  /* ------------------------- C R U S C O T T O     STEP 9  -------------------------------- */
  public getTotaliCruscotto(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getTotaliCruscotto(idBilancioSostenibilita);
  }


  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */

  public getCruscotti(
    idBilancioSostenibilita: string,
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.bilancioWebService.getCruscotti(idBilancioSostenibilita, page, pageSize, ricerca, filters, sortBy);
  }

  /**
 * Cambio figura
 * @param idBilancioSostenibilita 
 * @param idFiguraPrecedente 
 * @param idFiguraNuova 
 * @returns 
 */
  putCambiaFigura(idBilancioSostenibilita: string, idFiguraPrecedente: string, idFiguraNuova: string) {
    return this.bilancioWebService.putCambiaFigura(idBilancioSostenibilita, idFiguraPrecedente, idFiguraNuova);
  }

  public inviaMailMultiple(idBilancioSostenibilita: string) {
    return this.bilancioWebService.inviaMailMultiple(idBilancioSostenibilita);
  }

  public inviaMail(idBilancioSostenibilita: string, idFigura: string) {
    return this.bilancioWebService.inviaMail(idBilancioSostenibilita, idFigura);
  }

  public inviaNotificaMultiple(idBilancioSostenibilita: string) {
    return this.bilancioWebService.inviaNotificaMultiple(idBilancioSostenibilita);
  }

  public inviaNotifica(idBilancioSostenibilita: string, idFigura: string) {
    return this.bilancioWebService.inviaNotifica(idBilancioSostenibilita, idFigura);
  }
  public putTemplateMail(idBilancioSostenibilita: string, templateMail: any) {
    return this.bilancioWebService.putTemplateMail(idBilancioSostenibilita, templateMail);
  }


  public getTemplateMail(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getTemplateMail(idBilancioSostenibilita);
  }


  /* ------------------------- C E R T I F I C A Z I O N I  */
  /**
  * GET CERTIFICAZIONI BILANCIO
  * @param idBilancioSostenibilita 
  * @returns obj con lista ambiti - tematiche - certificazioni
  */
  public getCertificazioniBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getCertificazioniBilancio(idBilancioSostenibilita);
  }

  /**
  * SALVATAGGIO CERTIFICAZIONI BILANCIO SELEZIONATI
  * @param idBilancioSostenibilita 
  * @param objStep3 
  * @returns 
  */
  public putCertificazioniBilancioSelezionate(idBilancioSostenibilita: string, certificazioniSelezionate: any) {
    return this.bilancioWebService.putCertificazioniBilancioSelezionate(idBilancioSostenibilita, certificazioniSelezionate);
  }

  /**
  * ASSEGNA FIGURA AD CERTIFICAZIONI BILANCIO
  * @param idBilancioSostenibilita 
  * @param bilancio
  * @returns <Status 200 (senza body)>
  */
  public putAssegnaFiguraCertificazioniBilancio(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idCertificazioni: string, idFigura: string) {
    return this.bilancioWebService.putAssegnaFiguraCertificazioniBilancio(idBilancioSostenibilita, idAmbito, idTematica, idCertificazioni, idFigura);
  }

  public putCertificazioniEffimeri(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, certificazioni: any) {
    return this.bilancioWebService.putCertificazioniEffimeri(idBilancioSostenibilita, idAmbito, idTematica, certificazioni);
  }



  /* ------------------------- S T A M P A   STEP 10  -------------------------------- */

  /**
  * GET Configurazioni stampa SINTESI (set intero, il filtro lo facciamo in frontend - raggruppare per tematica)
  * @param idBilancioSostenibilita 
  * @returns List<GruppoConfigRisultatiSintesiValori>
  */
  public getConfigStampa(idBilancioSostenibilita: string) {
    return this.bilancioWebService.getConfigStampa(idBilancioSostenibilita);
  }
  public postSalvaElementiStampa(idBilancioSostenibilita: string, cfgStampaBilancio: GruppoCfgStampa) {
    return this.bilancioWebService.postSalvaElementiStampa(idBilancioSostenibilita, cfgStampaBilancio);
  }
  public postStampaBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.postStampaBilancio(idBilancioSostenibilita);
  }

  public getElementoDaCompilare(idBilancioSostenibilita: string, idAmbito: string, idTematica: string, idElemento: string) {
    return this.bilancioWebService.getElementoDaCompilare(idBilancioSostenibilita, idAmbito, idTematica, idElemento);
  }

  public postElementoDaCompilare(
    idBilancioSostenibilita: string,
    idAmbito: string,
    idTematica: string,
    idElemento: string,
    risposta: string | number,
    idCategoriaUnitaDiMisura?: string,
    idUnitaDiRiferimento?: string
  ) {
    return this.bilancioWebService.postElementoDaCompilare(
      idBilancioSostenibilita,
      idAmbito,
      idTematica,
      idElemento,
      risposta,
      idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento);
  }

  public getElementiEsterniDaCompilare(temporaryToken: string) {
    return this.bilancioWebService.getElementiEsterniDaCompilare(temporaryToken);
  }

  public postElementiEsterniDaCompilare(temporaryToken: string, objEsterna: any[]) {
    return this.bilancioWebService.postElementiEsterniDaCompilare(temporaryToken, objEsterna);
  }

  public invioTestMail(messaggio: string, email: string, idBilancioSostenibilita: string) {
    return this.bilancioWebService.invioTestMail(messaggio, email, idBilancioSostenibilita);
  }

  /**
 *  ritorna bilanci pubblicati
 * @returns 
 */
  public getBilanciPubblicati(idSettore?: string, idSottoSettori?: string[]) {
    return this.bilancioWebService.getBilanciPubblicati(idSettore, idSottoSettori);
  }

  public pubblicaBilancio(idBilancioSostenibilita: string) {
    return this.bilancioWebService.pubblicaBilancio(idBilancioSostenibilita);
  }

  public getConfigBilanci() {
    return this.bilancioWebService.getConfigBilanci();
  }

  public getStdr(idBilancio: string | null, idSettore: string, isCreazione: boolean) {
    return this.bilancioWebService.getStdr(idBilancio, idSettore, isCreazione);
  }

}

