<div class="imgLoginPage" [style.background-image]="'url('+bgPath+')'">
</div>
<div class="destra">

    <div class="loginForm">

        <img [src]="logoPath">

        <div class="overlay" *ngIf="loading">
            <mat-spinner diameter="50"></mat-spinner>
        </div>

        <div class="msg-errore" *ngIf="!loading && msgError">
            {{msgError}}
        </div>

        <ng-container *ngIf="!loading && !msgError && idUtente && !completato">
            <div class="bentornato">
                Inserisci la nuova password nei campi sottostanti
            </div>

            <div class="formContainer">
                <form [formGroup]="formPassword">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" />
                            <mat-error appFormError></mat-error>
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" tabindex="-1">
                                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Conferma password</mat-label>
                            <input matInput formControlName="confirm_password"
                                [type]="hideConfirm ? 'password' : 'text'" />
                            <mat-error appFormError></mat-error>
                            <button type="button" mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm" tabindex="-1">
                                <mat-icon>{{ hideConfirm ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="errore-req" *ngIf="formPassword.errors && formPassword.errors['password']">
                        Le password non coincidono
                    </div>
                    <div class="errore-req" *ngIf="(!formPassword.errors || !formPassword.errors['password']) && requestError">{{requestError}}</div>

                    <!-- <div class="errore-req" *ngIf="formPassword.errors && formPassword.errors['password']">Le password non coincidono</div> -->

                    <div class="bottoniLogin">
                        <button type="submit" mat-raised-button color="primary" class="btnAccedi"
                            [disabled]="formPassword.invalid || loadingUpdate" (click)="eseguiUpdatePwd()">

                            <mat-spinner *ngIf="loadingUpdate" class="spinner-btn" diameter="20"></mat-spinner>
                            Aggiorna Password

                        </button>
                    </div>

                    <div class="legenda">
                        La password deve:
                        <ul>
                            <li *ngFor="let criterio of criteri">{{criterio}}</li>
                        </ul>
                    </div>

                </form>
            </div>
        </ng-container>


        <div class="operazione-completata" *ngIf="!loading && !loadingUpdate">

            <div class="ope-comp" *ngIf="!requestError && completato">
                Operazione completata.
            </div>
            <button type="submit" mat-button color="accent" class="btnAccedi" routerLink="/login">
                Torna alla pagina di accesso
            </button>
        </div>

    </div>
    <div class="policy">
    </div>
</div>