<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>Impegni</h2>
<div mat-dialog-content class="dialog-center-cont">
    <form [formGroup]="formImpegni" appFormContainer [larghezza]="4">

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Titolo</mat-label>
            <input matInput placeholder="Titolo" formControlName="titolo" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <div appFormElement [larghezza]="4" [riempi]="true" class="testo-linea-guida"
            *ngIf="(this.data.contesto === 'sintesi' || this.data.contesto === 'bilancio' || this.data.contesto === 'policy' ) && data?.impegno?.note">
            <div style="display: flex;">
                <mat-icon class="material-icons-outlined icone-tem">error</mat-icon>
            </div>
            <div class="titolo-linea-guida">
                <div style="font-weight: bold;">Linea guida per la stesura dell’impegno aziendale</div>
                {{data.impegno.note}}
            </div>
        </div>

        <mat-form-field class="input-form" appFormElement [larghezza]="2" [riempi]="true"
            *ngIf="this.data.contesto !== 'sintesi' && this.data.contesto !== 'bilancio' && this.data.contesto !== 'policy' ">
            <mat-label>Settori</mat-label>
            <mat-select formControlName="settori" placeholder="Settori" [(value)]="selectedSettori" multiple
                [compareWith]="compareSettori">
                <mat-option *ngFor="let set of arraySettori" [value]="set">{{set.titolo}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="4" [riempi]="true"
            *ngIf="this.data.contesto === 'sintesi' || this.data.contesto === 'bilancio' || this.data.contesto === 'policy'">
            <mat-label>Descrizione</mat-label>
            <textarea matInput placeholder="Descrizione" formControlName="descrizione" rows="4"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="4" [riempi]="true"
            *ngIf="this.data.contesto !== 'sintesi' && this.data.contesto !== 'bilancio' && this.data.contesto !== 'policy'">
            <mat-label>Guida alla scrittura</mat-label>
            <textarea matInput placeholder="Guida alla scrittura" formControlName="note" rows="4"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="4" [riempi]="true"
            *ngIf="this.data.contesto !== 'sintesi' && this.data.contesto !== 'bilancio' && this.data.contesto !== 'policy'">
            <mat-label>TAG</mat-label>
            <input matInput placeholder="TAG" formControlName="tag" />
            <mat-error appFormError></mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
