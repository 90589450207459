<app-spinner-overlay></app-spinner-overlay>

<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo titolo="Configurazione linee guida stampe e Template mail"
            subTitolo="Definizione delle linee guida mostrate nelle configurazioni di stampa. Configurazione template mail"></app-titolo-subtitolo>
    </div>

    <mat-tab-group>

        <mat-tab *ngFor="let contesto of contesti" [label]="contesto.label">

            <mat-tab-group>
                <mat-tab label="CONFIGURAZIONE STAMPE" *ngIf="contesto.tipo !== 'AUTOVALUTAZIONE'">
                    <ng-scrollbar #scrollConfStampa>
                        <div class="contenuto" scrollViewport>

                            <mat-accordion dropListScroller cdkDropList #selList="cdkDropList"
                                [cdkDropListData]="contesto.config" (cdkDropListDropped)="dropCfgStampa($event)">

                                <mat-expansion-panel class="cfg-stampa" #expCfgStampa (opened)="openGroup(elemStampa)"
                                    *ngFor="let elemStampa of contesto.config; let idx = index" cdkDrag cdkDragDisabled>

                                    <mat-expansion-panel-header>
                                        <mat-panel-title>

                                            <span class="nome-grp-cfg">{{elemStampa.titolo}}</span>
                                        </mat-panel-title>

                                    </mat-expansion-panel-header>
                                    <ng-container>

                                        <div class="paragrafo">
                                            <div class="editor-cont">
                                                <div>Linea guida</div>


                                                <div class="editor-cont">
                                                    <app-editor [(ngModel)]="elemStampa.descrizione"
                                                        [removePlugins]="removePlugins"></app-editor>
                                                </div>
                                                <!-- <textarea class="descrizione" matInput
                                                    [(ngModel)]="elemStampa.descrizione" rows="4"></textarea> -->
                                            </div>
                                        </div>

                                        <div class="btn-container">
                                            <button mat-flat-button color="primary"
                                                (click)="salva(contesto.tipo, elemStampa)" class="primary-button salva"
                                                [disabled]="salvataggioInCorso[elemStampa.chiave]">
                                                Salva
                                            </button>

                                            <mat-spinner *ngIf="salvataggioInCorso[elemStampa.chiave]"
                                                diameter="20"></mat-spinner>
                                        </div>
                                    </ng-container>
                                </mat-expansion-panel>
                            </mat-accordion>

                        </div>
                    </ng-scrollbar>
                </mat-tab>




                <mat-tab label="CONFIGURAZIONE TEMPLATE MAIL">
                    <div class="container-template-mail">
                        <div class="contenuto-template-mail">
                            <ng-scrollbar #scrollConfMail>
                                <div appFormContainer [larghezza]="3" [rowGap]="20">
                                    <!-- ------------------------------- M A I L - I N V I O ---------------------- -->
                                    <div class="mail-invio">
                                        <div style="padding: 10px 15px 0 15px; display: flex; flex-direction: row;">
                                            <div class="desc-bott">

                                                <div class="contenitore-titolo-subtitolo">
                                                    <div class="titolo-sezione-dialog">
                                                        Testo Mail Invio {{contesto.label}}
                                                    </div>
                                                    <div class="descrizione-sezione-dialog">
                                                        Questo testo personalizzato verrà inserito all'interno della
                                                        prima mail
                                                        inviata.
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <button mat-flat-button color="primary" [matMenuTriggerFor]="menu"
                                                    class="primary-button tags">
                                                    Tags
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <div class="legenda-info">
                                                        <p>
                                                            Clicca per copiare il tag negli appunti ed incollalo nel
                                                            testo del template.
                                                        </p>
                                                        <p>
                                                            I tag aggiunti al testo saranno sostituiti con il contenuto
                                                            relativo durante l'elaborazione.
                                                        </p>
                                                    </div>
                                                    <button *ngFor="let tag of tags" (click)="copiaTag(tag.tag)"
                                                        mat-menu-item>
                                                        {{tag.nome}}
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </div>

                                        <div class="editor-cont">
                                            <app-editor [(ngModel)]="contesto.mailInvio"></app-editor>
                                        </div>
                                    </div>
                                    <!-- ------------------------------- M A I L - N O T I F I C A ---------------------- -->
                                    <div class="mail-notifica">
                                        <div style="padding: 10px 15px 0 15px; display: flex; flex-direction: row;">
                                            <div class="desc-bott">
                                                <div class="contenitore-titolo-subtitolo">
                                                    <div class="titolo-sezione-dialog">
                                                        Testo Mail Notifica Promemoria {{contesto.label}}
                                                    </div>
                                                    <div class="descrizione-sezione-dialog">
                                                        Questo testo personalizzato verrà inserito all'interno della
                                                        mail di
                                                        promemoria
                                                        inviata.

                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <button mat-flat-button color="primary" [matMenuTriggerFor]="menu"
                                                    class="primary-button tags">
                                                    Tags
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <div class="legenda-info">
                                                        <p>
                                                            Clicca per copiare il tag negli appunti ed incollalo nel
                                                            testo del template.
                                                        </p>
                                                        <p>
                                                            I tag aggiunti al testo saranno sostituiti con il contenuto
                                                            relativo durante l'elaborazione.
                                                        </p>
                                                    </div>
                                                    <button *ngFor="let tag of tags" (click)="copiaTag(tag.tag)"
                                                        mat-menu-item>
                                                        {{tag.nome}}
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </div>
                                        <div class="editor-cont">
                                            <app-editor [(ngModel)]="contesto.mailNotifica"></app-editor>
                                        </div>
                                    </div>
                                    <!-- ------------------------------- L A N D I N G - P A G E ---------------------- -->
                                    <div class="landing-page">
                                        <div style="padding: 10px 15px 0 15px; display: flex; flex-direction: row;">
                                            <div class="desc-bott">
                                                <div class="contenitore-titolo-subtitolo">
                                                    <div class="titolo-sezione-dialog">
                                                        Testo Landing Page compilazione {{contesto.label}}
                                                    </div>
                                                    <div class="descrizione-sezione-dialog">
                                                        Questo testo verrà inserito in testata della landing page di
                                                        compilazione
                                                        {{contesto.label}}
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <button mat-flat-button color="primary" [matMenuTriggerFor]="menu"
                                                    class="primary-button tags">
                                                    Tags
                                                </button>
                                                <mat-menu #menu="matMenu" class="asdasdasd">
                                                    <div class="legenda-info">
                                                        <p>
                                                            Clicca per copiare il tag negli appunti ed incollalo nel
                                                            testo del template.
                                                        </p>
                                                        <p>
                                                            I tag aggiunti al testo saranno sostituiti con il contenuto
                                                            relativo durante l'elaborazione.
                                                        </p>
                                                    </div>
                                                    <button *ngFor="let tag of tags" (click)="copiaTag(tag.tag)"
                                                        mat-menu-item>
                                                        {{tag.nome}}
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </div>
                                        <div class="editor-cont">
                                            <app-editor [(ngModel)]="contesto.landingPage"></app-editor>
                                        </div>
                                    </div>
                                </div>
                            </ng-scrollbar>
                        </div>
                        <div class="btn-container">
                            <button mat-flat-button color="primary"
                                (click)="salvaCfgMail(contesto.tipo, contesto.mailNotifica, contesto.mailInvio, contesto.landingPage)"
                                class="primary-button salva">
                                Salva
                            </button>
                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>



        </mat-tab>
    </mat-tab-group>

</div>