<h2 mat-dialog-title>
  Azienda
</h2>

<app-spinner-overlay></app-spinner-overlay>
<div mat-dialog-content class="dialog-center-cont">

  <form [formGroup]="formAzienda" appFormContainer [larghezza]="6" style="padding-right: 10px;">

    <div style="display: flex;
    flex-direction: column;
    flex: 1; padding-right: 20px;" appFormElement [larghezza]="4" [riempi]="true">
      
      <ng-container *ngIf="this.utenteService.infoUtente?.utente?.ruolo?.roleName==='configuratore' &&
        !this.data?.azienda && !this.data?.utente?.id">

        <app-autocomplete appFormElement [larghezza]="2" [riempi]="true" formControlName="idUtente" [label]="'Utente'"
          [loadDataFn]="getUtentiAutoComplete" [displayFn]="displayFnUtente" [compare]="compareById" [required]="true"
          [chiaveId]="'id'" [spinnerLoading]="loadingUtente" [numeroRecOptions]="10">
        </app-autocomplete>

      </ng-container>

      <mat-form-field appFormElement [larghezza]="4" [riempi]="true">
        <mat-label>Nome Impresa</mat-label>
        <input matInput placeholder="Nome Impresa" formControlName="ragioneSociale" />
        <mat-error appFormError></mat-error>
      </mat-form-field>

      <mat-form-field appFormElement [larghezza]="4" [riempi]="true">
        <mat-label>P.IVA</mat-label>
        <input matInput placeholder="P.IVA" formControlName="piva" minlength="11" maxlength="11" />
        <mat-error appFormError></mat-error>
      </mat-form-field>

  

      <ng-container *ngIf="canSelectTipoInstallazione">

        <div class="separatore" appFormElement [larghezza]="4" *ngIf="!isModifica">
          <app-separatore-label titoloSeparatore="Tipo installazione"></app-separatore-label>
        </div>

        <mat-form-field appFormElement [larghezza]="3" *ngIf="!isModifica">
          <mat-label>Tipo installazione</mat-label>
          <mat-select [placeholder]="'Tipo installazione'" formControlName="tipoInstallazione"
            (selectionChange)="onSelectionChangeTipoInstallazione($event)">
            <mat-option *ngFor="let tipo of listaTipiInstallazione"
              [value]="tipo.chiave">{{tipo.descrizione}}</mat-option>
          </mat-select>
        </mat-form-field>

      </ng-container>

    </div>

    <div style="z-index: 1;">
      <div style="  border: 1px solid #ececec; padding: 5px; z-index: 1;">
        <div class="image-container">
          <div class="immagine-cont" [ngStyle]="{'background-image': 'url(' + (urlLogo ? urlLogo : imagePreview)+')'}">
            <div class="overlay">
              <div class="text">Importa un nuovo Logo</div>
            </div>
          </div>
          <input [disabled]="this.isPermessoSoloRead" id="fileInput" type="file"
            (change)="fileSelezionato($any($event.target)?.files)" class="select-profile-picture" />
        </div>
      </div>
      <div *ngIf="urlLogo || logoDaSalvare">
        <button [disabled]="this.isPermessoSoloRead" class="bottone-elimina" mat-flat-button color="warn"
          (click)="eliminaLogo()">Rimuovi</button>
      </div>


      <div *ngIf="!urlLogo && !logoDaSalvare">
        <div class="fileinput">

          <button class="bottone-elimina" mat-flat-button color="accent">
            Importa
            <mat-icon>
              file_upload
            </mat-icon>
          </button>
          <input #fileInput [disabled]="this.isPermessoSoloRead" type="file" name="fileDaCaricare"
            (change)="fileSelezionato($any($event.target)?.files)" (click)="$any($event.target).value=null" />

        </div>
      </div>
    </div>

    <mat-form-field appFormElement [larghezza]="4" [riempi]="true"
      *ngIf="((this.formAzienda.get('tipoInstallazione')?.value !== 'SOSTENIBILE_GO' && !isUtenteGo) || (this.utenteService.infoUtente?.utente?.ruolo?.roleName === 'configuratore' && this.formAzienda.get('tipoInstallazione')?.value !== 'SOSTENIBILE_GO'))">
      <mat-label>Cod. Ateco</mat-label>
      <input type="text" matInput formControlName="codiciAteco" [matAutocomplete]="ateco">
      <mat-autocomplete #ateco="matAutocomplete" [displayWith]="displayFnAteco">
        <mat-option *ngFor="let ateco of filteredAteco | async | slice : 0 : 20" [value]="ateco">
          <b>{{ ateco._id }}</b> - {{ateco.desc}}
        </mat-option>
        <div *ngIf="this.showAltriRecordDisponibili" style="text-align: center; color: #807f7f;
      margin: 0 10px 0 10px;"> Numero record limitati.
          Modifica il filtro per restringere la ricerca
        </div>
      </mat-autocomplete>
    </mat-form-field>

    <div class="separatore" appFormElement [larghezza]="6" *ngIf="!isModifica">
      <app-separatore-label titoloSeparatore="Referente"></app-separatore-label>
    </div>

    <mat-form-field appFormElement [larghezza]="3" [riempi]="true" *ngIf="!isModifica">
      <mat-label>Nome e Cognome</mat-label>
      <input matInput placeholder="Nome " formControlName="nome" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="3" [riempi]="true" *ngIf="!isModifica">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email" formControlName="email" type="email" />
      <mat-error appFormError></mat-error>
    </mat-form-field>


    <div class="separatore" appFormElement [larghezza]="6">
      <app-separatore-label titoloSeparatore="Anagrafica"></app-separatore-label>
    </div>


    <app-autocomplete appFormElement [larghezza]="3" [riempi]="true" formControlName="regione" [label]="'Regione'"
      [loadDataFn]="getRegioni" [displayFn]="displayFn" [compare]="compareLocalita"
      (recordChange)="getProvinciaAutoComplete($event)" [chiaveId]="'id'" [numeroRecOptions]="30">
    </app-autocomplete>

    <app-autocomplete appFormElement [larghezza]="3" [riempi]="true" formControlName="provincia" [label]="'Provincia'"
      [loadDataFn]="getProvince" [displayFn]="displayFn" [compare]="compareLocalita"
      (recordChange)="getComuneAutoComplete($event)" [chiaveId]="'id'" [numeroRecOptions]="20">
    </app-autocomplete>

    <app-autocomplete appFormElement [larghezza]="3" [riempi]="true" formControlName="comune" [label]="'Comune'"
      [loadDataFn]="getComune" [displayFn]="displayFn" [compare]="compareLocalita" [chiaveId]="'id'">
    </app-autocomplete>


    <mat-form-field appFormElement [larghezza]="1">
      <mat-label>C.A.P</mat-label>
      <input matInput placeholder="C.A.P" formControlName="cap" minlength="5" maxlength="5" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="4" [riempi]="true">
      <mat-label>Indirizzo</mat-label>
      <input matInput placeholder="Indirizzo" formControlName="indirizzo" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
      <mat-label>Sito Web</mat-label>
      <input matInput placeholder="Sito Web" formControlName="sitoWeb" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="2">
      <mat-label>Fatturato</mat-label>
      <input matInput placeholder="Euro" formControlName="fatturato" type="number" appInputNumber
        inputmode="numeric" min="0" max="999999999999" (keydown)="onKeyDown($event)" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
      <mat-label>Dipendenti</mat-label>
      <input matInput placeholder="Numero di dipendenti" formControlName="dipendenti" type="number" appInputNumber
        inputmode="numeric" min="0" (keydown)="onKeyDownDip($event)" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="6">
      <mat-label>Descrizione</mat-label>
      <textarea matInput placeholder="Descrizione" formControlName="descrizione"></textarea>
      <mat-error appFormError></mat-error>
    </mat-form-field>


  </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
  <div>
    <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
  </div>
  <div>
    <button *ngIf="!this.isPermessoSoloRead" mat-flat-button color="primary" class="primary-button"
      (click)="salvaAzienda()">
      Salva
    </button>
  </div>
</div>