<div class="imgLoginPage" [style.background-image]="'url('+bgPath+')'">
</div>
<div class="destra">
    <div class="loginForm">
        <img [src]="logoPath">
        <div *ngIf="!completato" class="bentornato">
            Inserisci il tuo indirizzo email per richiedere il reset della password.
        </div>
        <div class="formContainer">
            <form [formGroup]="formLogin" *ngIf="!completato">
                <div>
                    <!-- Username -->
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput formControlName="username" required />
                        <mat-error appFormError></mat-error>
                        <mat-icon matSuffix>account_circle</mat-icon>
                    </mat-form-field>
                </div>

                <div class="error" *ngIf="requestError">{{requestError}}</div>

                <div class="bottoniLogin">
                    <button type="submit" mat-raised-button color="primary" class="btnAccedi"
                        [disabled]="formLogin.invalid || loading" (click)="avviaReset()">
                        <mat-spinner *ngIf="loading" class="spinner-btn" diameter="20"></mat-spinner>
                        Recupera Password
                    </button>
                </div>
            </form>
            <div class="operazione-completata" *ngIf="completato">
                Operazione completata. Controlla la tua casella email.
            </div>
            <div class="btn-accesso" *ngIf="!loading">
                <button type="submit" mat-button color="accent" class="btnAccedi" routerLink="/login">
                    Torna alla pagina di accesso
                </button>
            </div>
        </div>
    </div>
    <div class="policy">
        <!-- <p>Term of use. Privacy Policy</p> -->
    </div>
</div>