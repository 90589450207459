<app-spinner-overlay></app-spinner-overlay>
<div class="cont-comp">

    <div appFormElement [larghezza]="1" [riempi]="true">
        <div class="titolo-sezione-dialog">
            Selezione delle figure aziendali
        </div>
        <div class="descrizione-sezione-dialog">
            Seleziona le figure aziendali responsabili delle strategie di sostenibilità. Una volta selezionata una
            figura, come il Comitato ESG, clicca sull'icona <mat-icon class="icone">edit</mat-icon> per accedere ad una scheda dedicata e definire ruoli e
            responsabilità.
        </div>

    </div>
    <div style="display: flex; width: 100%; column-gap: 15px;">
        <div class="cont-filtri">
            <mat-form-field style="width: 100%;" class="ricerca-input">
                <input matInput placeholder="Inserisci chiave di ricerca" [(ngModel)]="searchValue"
                    (keyup)="applicaFiltroRicerca($event)">
                <mat-icon matSuffix style="color: var(--mat-color-primary);">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="cont-bottoni">
            <div>
                <button mat-flat-button class="primary-button" color="accent" (click)="apriFiltro()"
                    [matBadge]="valFiltri.length" [matBadgeHidden]="valFiltri.length === 0" matBadgeSize="small"
                    matBadgeColor="warn">
                    <mat-icon matSuffix>filter_list</mat-icon>
                    Filtri
                </button>
            </div>
            <div>
                <button [disabled]="isPermessoSoloRead" mat-flat-button class="primary-button" color="primary"
                    (click)="selezionaTutto()" *ngIf="btnSelezionaTutto === true">
                    Seleziona Tutto
                </button>
                <button [disabled]="isPermessoSoloRead" mat-flat-button class="primary-button" color="primary"
                    (click)="deselezionaTutto()" *ngIf="btnSelezionaTutto === false">
                    Deseleziona Tutto
                </button>
            </div>
        </div>
    </div>
</div>

<div class="cont-titolo">
    <div>
        <mat-label class="titolo">Comitati</mat-label>
    </div>
</div>
<div style="flex: 1;">
    <ng-scrollbar>
        <mat-accordion>
            <mat-expansion-panel [id]="comitato.id" *ngFor="let comitato of arrayGroupComitati">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-checkbox [disabled]="isPermessoSoloRead" [checked]="allComplete[comitato.id]"
                            [indeterminate]="someComplete(comitato)" (mousedown)="$event.stopPropagation()"
                            (change)="setAll($event.checked , comitato)">


                        </mat-checkbox>
                        <span class="nome-figura">{{comitato.descrizione}}</span>

                        <span class="nrSel">{{nrSelezionati}}/{{comitato.figureAziendali.length}}</span>
                    </mat-panel-title>
                    <mat-panel-description>

                        <ng-container *ngIf="isComitatoSelected(comitato.figureAziendali)">
                            <div appFormElement [larghezza]="0" [minWidth]="50">
                                <button class="icone-button" mat-icon-button
                                    (click)="compilaComitato(comitato); $event.stopPropagation();"
                                    [title]="comitato.risposta ? 'Modifica' : 'Compila'"
                                    [attr.aria-label]="comitato.risposta ? 'Modifica' : 'Compila'">
                                    <mat-icon class="icone">
                                        {{comitato.risposta ? 'edit' : 'history_edu'}}
                                    </mat-icon>
                                </button>
                            </div>
                        </ng-container>

                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div appFormContainer [larghezza]="2" *ngFor="let figura of comitato.figureAziendali">
                    <div appFormElement [larghezza]="1" [riempi]="true">
                        <mat-checkbox [disabled]="isPermessoSoloRead" (ngModelChange)="updateAllComplete(comitato)"
                            [(ngModel)]="figura.selected" (mousedown)="$event.stopPropagation()" color="primary">
                            <div class="nome-figura">{{figura.nome}}</div>
                        </mat-checkbox>
                    </div>
                    <!-- <div appFormElement [larghezza]="0" [minWidth]="50">
                        <button class="icone-button" mat-icon-button (click)="insUpdFiguraAziendale(comitato, figura)" title="Modifica"
                            [attr.aria-label]="'Modifica'">
                            <mat-icon class="icone">
                                edit
                            </mat-icon>
                        </button>
                    </div> -->

                </div>

                <div>
                    <!--  -->
                    <button [disabled]="isPermessoSoloRead" mat-stroked-button
                        style="height: 56px;width: 100%;font-weight: bold;" (click)="insUpdFiguraAziendale(comitato)">
                        + Aggiungi Figura Aziendale
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-scrollbar>
</div>