import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { CompilaSintesiSostenibilitaComponent } from 'src/app/components/compila-sintesi-sostenibilita/compila-sintesi-sostenibilita.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { TemaService } from 'src/app/services/tema/tema.service';

@Component({
    selector: 'app-piano-sostenibilita-esterna',
    templateUrl: './piano-sostenibilita-esterna.component.html',
    styleUrls: ['./piano-sostenibilita-esterna.component.scss'],
    standalone: false
})
export class PianoSostenibilitaEsternaComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(CompilaSintesiSostenibilitaComponent) compilaSintesiSostenibilita!: CompilaSintesiSostenibilitaComponent;

  public logoPath: string = 'assets/images/logo/logo-sostenibile.png';
  public completato: boolean = false;
  public temporaryToken!: string;
  public descrizione: string = "";
  public messaggio: string = '';

  public compilazione: any;
  public idPianoSostenibilita: string = "";
  private idAzienda: string = "";
  public landingPage: string = "";
  public isAnteprima: boolean = false;

  private _subTema: Subscription;

  constructor(
    private route: ActivatedRoute,
    private pianoService: PianoService,
    private temaService: TemaService
  ) {
    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      this.logoPath = temaService.logoPath;
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.parametriDaURL();
  }

  ngOnDestroy(){
    this._subTema?.unsubscribe();
  }

  /**
 * Metodo che mi prende il token dal URL
 */
  public parametriDaURL() {
    // Sottoscrivi all'observable paramMap per ottenere i parametri dall'URL
    this.spinnerOver.show();
    this.route.queryParams.subscribe(params => {
      // Ora puoi accedere ai parametri come segue
      this.temporaryToken = params['temporaryToken'];
      this.idAzienda = params['idA'];
      this.idPianoSostenibilita = params['idP'];
      if (this.temporaryToken) {
        this._getSurveyByTemporaryToken();
      } else if (this.idPianoSostenibilita && this.idAzienda) {
        this._anteprimaPiano();
      }
    });
  }

  private _anteprimaPiano() {
    this.isAnteprima = true;
    this.spinnerOver.show();
    this.pianoService.getAnteprimaPianoCompilato(this.idPianoSostenibilita, this.idAzienda).subscribe({
      next: (esito) => {

        this.spinnerOver.hide();
        this.compilazione = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }


  /**
 * Metodo che mi prende i dati della survey dal token
 */
  private _getSurveyByTemporaryToken() {
    this.isAnteprima = false;
    this.pianoService.getElementiEsterniDaCompilare(
      this.temporaryToken
    ).subscribe({
      next: (esito) => {
        this.compilazione = esito.questionarioDaCompilare;
        this.landingPage = esito.landing;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.messaggio = err?.error?.message || "Nessun dato trovato";
        this.completato = true;
        this.spinnerOver.hide();
      }
    })
  }

  loading = (loading: boolean) => {
    if (loading) {
      this.spinnerOver.show();
    } else {
      this.spinnerOver.hide();
    }
  }

  salva = (compilazione: any, isImportazione?: boolean): Observable<any> => {
    if (compilazione) {
      this.spinnerOver.show();

      let arrayRequest: any[] = [];

      (this.compilazione.ambiti as Ambito[]).forEach((ambito) => {
        ambito.tematiche.forEach((tematica) => {

          const objCall = {
            idAmbito: ambito.id,
            idTematica: tematica.id,
            idElemento: '',
            idUnitaDiRiferimento: '',
            idCategoriaUnitaDiMisura: '',
            risposta: ''
          }

          if (tematica.kpis?.length) {
            tematica.kpis.forEach((kpi) => {
              const valoriKpi = kpi.valoriKpi || [];
              const valoriTarget = kpi.valoriTarget || [];

              const risposta = [
                ...valoriKpi,
                ...valoriTarget
              ];
              arrayRequest.push({
                ...objCall, idElemento: kpi.id,
                idUnitaDiRiferimento: kpi.idUnitaDiRiferimento,
                idCategoriaUnitaDiMisura: kpi.idCategoriaUnitaDiMisura,
                risposta: risposta,
                idObiettivo: kpi.idObiettivo,
              });

            })
          }

          if (tematica.azioni?.length) {
            tematica.azioni.forEach((azione) => {
              arrayRequest.push({
                ...objCall, idElemento: azione.id,
                rispostaBreve: azione.rispostaBreve,
                idObiettivo: azione.idObiettivo,
                anno: azione.anno,
              });
            })
          }

        })
      })

      const arrayRequestFiltered = arrayRequest.filter((elem) => {
        if (elem.risposta !== null && elem.risposta !== undefined) {
          // Se elem.risposta è un array, controlla i valori interni
          if (Array.isArray(elem.risposta)) {
            return elem.risposta.some((risp: any) =>
              risp?.valoreAttribuito !== null && risp?.valoreAttribuito !== undefined
            );
          }
          // Se non è un array, ritorna true perché risposta è valida (non null o undefined)
          return true;
        }
        // escludo se elem.risposta è null o undefined
        return false;
      });

      return this.pianoService.postElementiEsterniDaCompilare(
        this.temporaryToken,
        arrayRequestFiltered
      ).pipe(
        map(risp => {

          this.spinnerOver.hide();
          if (!isImportazione) {
            this.messaggio = 'Le tue risposte sono state salvate. Grazie.'
            this.completato = true;
          }
        }),
        catchError(err => {
          this.spinnerOver.hide();
          console.error(err);
          return of();
        })
      )
    }


    return of();
  }
}
