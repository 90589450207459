<div class="contenitore">
    <mat-accordion class="accordion">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Cruscotto Generale
                </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="cont-exp">
                <div style="display: flex; column-gap: 20px; flex-wrap: wrap; row-gap: 20px;">

                    <!-- GRAFICO A BARRE -->
                    <div class="cont-graf-barre">
                        <div class="titolo-grafico">
                            <div class="cont-titolo"> Disponibilità Pacchetti </div>
                        </div>
                        <div class="bar-chart">
                            <app-chart-host chartType="bar">
                                <canvas #barChartCanvas baseChart height="1" width="4" [data]="barChartData"
                                    [options]="barChartOptions" [type]="barChartType"
                                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                                </canvas>
                            </app-chart-host>
                        </div>
                    </div>

                    <!-- GRAFICO A DoughnutChart -->
                    <div class="cont-graf-torta">
                        <div class="spinner-container">
                            <div class="blur-background" *ngIf="spinnerDoughnutChart"></div>
                            <mat-spinner [diameter]="50" *ngIf="spinnerDoughnutChart"></mat-spinner>

                            <div class="cont-titolo-icon">

                                <div class="titolo-grafico">
                                    Spazio di Archiviazione
                                </div>
                                <button class="icone-button" mat-icon-button (click)="aggiornaDoughnutChart()"
                                    [matTooltip]="'Aggiorna'" matTooltipPosition="above">
                                    <mat-icon>sync</mat-icon>
                                </button>
                            </div>

                            <div class="container-doughnutChart">
                                <app-chart-host chartType="doughnut">
                                    <canvas #doughnutChartCanvas baseChart [data]="doughnutChartData"
                                        [type]="doughnutChartType" [plugins]="doughnutChartPlugins"
                                        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                                    </canvas>
                                </app-chart-host>
                                <div class="dati-doughnutChart">

                                    <div class="legenda-sotto-grafico">
                                        <div class="cont-titolo-pallino">
                                            <span class="pallino"
                                                [ngStyle]="{ 'background-color': datiDoughnutChart.availableUsage.colore }"></span>
                                            {{datiDoughnutChart.availableUsage.titolo}}
                                        </div>
                                        <div>{{this.formatBytes(datiDoughnutChart.availableUsage.valore)}}</div>
                                    </div>

                                  

                                    <div class="legenda-sotto-grafico">
                                        <div class="cont-titolo-pallino">
                                            <span class="pallino"
                                                [ngStyle]="{ 'background-color': datiDoughnutChart.storageUsage.colore }"></span>
                                            {{datiDoughnutChart.storageUsage.titolo}}
                                        </div>
                                        <div>{{this.formatBytes(datiDoughnutChart.storageUsage.valore)}}</div>
                                    </div>

                                    <div class="legenda-sotto-grafico">
                                        <div class="cont-titolo-pallino">
                                            <span class="pallino"
                                                [ngStyle]="{ 'background-color': datiDoughnutChart.databaseUsage.colore }"></span>
                                            {{datiDoughnutChart.databaseUsage.titolo}}
                                        </div>
                                        <div>{{this.formatBytes(datiDoughnutChart.databaseUsage.valore)}}</div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="cont-tab">

                    <div style="display: flex; justify-content: space-between; align-items: center;"
                        class="cont-titolo">
                        <div class="titolo-grafico">
                            Riepilogo Generale
                        </div>
                        <div style="display: flex; column-gap: 10px; transform: scale(0.8);">
                            <button class="icone-button" mat-icon-button>
                                <mat-icon>download</mat-icon>
                            </button>
                            <button class="icone-button" mat-icon-button>
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <button class="icone-button" mat-icon-button>
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                    <app-lista-tabellare #tabellaRiepilogo [fnCaricamentoDati]="fnCaricamentoDati"
                        [colonne]="colonneRiepilogo ? colonneRiepilogo : {} " [maxNrButton]="1" [isFiltri]="false">
                    </app-lista-tabellare>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Pianificazione Attività
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cont-exp">
                <div class="cont-tab">
                      Tabella 2 
                </div>
            </div>
        </mat-expansion-panel> -->
    </mat-accordion>
</div>