import { Component, NgModule } from '@angular/core';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent {
  
  query: string = '';

  constructor() {}

  ngOnInit() {}

  onSearch() {
    
  }
}
