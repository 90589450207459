import { Injectable } from '@angular/core';
import { ImportazioniWebService } from './importazioni-web.service';

@Injectable({
  providedIn: 'root'
})
export class ImportazioniService {

  constructor(
    public importazioniWebService: ImportazioniWebService
  ) { }

  public importFile(file: File, data: { [key: string]: string }, azienda: boolean, nomeImport: string) {
    return this.importazioniWebService.uploadFile(file, data, azienda, nomeImport);
  }

  //LOGO AZIENDA SU CREAZIONE AZIENDA 
  public importLogo(file: File, data: { [key: string]: string }, idAzienda: string) {
    return this.importazioniWebService.uploadLogo(file, data, idAzienda);
  }

  public deleteLogoAzienda(idAzienda: string) {
    return this.importazioniWebService.deleteLogoAzienda(idAzienda);
  }

  // Immagine su compilazione della certificazione bilancio
  public importImgCertificazioneBilancio(file: File, data: { [key: string]: string }, listaID: any) {
    return this.importazioniWebService.importImgCertificazioneBilancio(file, data, listaID);
  }

  // Immagine su compilazione della certificazione bilancio da esterna
  public importImgCertificazioneBilancioDaEsterna(file: File, data: { [key: string]: string }, listaID: any) {
    return this.importazioniWebService.importImgCertificazioneBilancioDaEsterna(file, data, listaID);
  }

  public deleteImgCertificazioneBilancio(listaID: any) {
    return this.importazioniWebService.deleteImgCertificazioneBilancio(listaID);
  }

  public deleteImgCertificazioneBilancioDaEsterna(listaID: any, temporaryToken: string) {
    return this.importazioniWebService.deleteImgCertificazioneBilancioDaEsterna(listaID, temporaryToken);
  }

  // Immagine su compilazione della certificazione sintesi
  public importImgCertificazioneSintesi(file: File, data: { [key: string]: string }, listaID: any) {
    return this.importazioniWebService.importImgCertificazioneSintesi(file, data, listaID);
  }

  // Immagine su compilazione della certificazione sintesi
  public importImgCertificazioneSintesiDaEsterna(file: File, data: { [key: string]: string }, listaID: any) {
    return this.importazioniWebService.importImgCertificazioneSintesiDaEsterna(file, data, listaID);
  }

  public deleteImgCertificazioneSintesi(listaID: any) {
    return this.importazioniWebService.deleteImgCertificazioneSintesi(listaID);
  }

  public deleteImgCertificazioneSintesiDaEsterna(listaID: any, temporaryToken: string) {
    return this.importazioniWebService.deleteImgCertificazioneSintesiDaEsterna(listaID, temporaryToken);
  }

  // Immagine su compilazione della certificazione policy
  public importImgCertificazionePolicy(file: File, data: { [key: string]: string }, listaID: any) {
    return this.importazioniWebService.importImgCertificazionePolicy(file, data, listaID);
  }

  // Immagine su compilazione della certificazione policy
  public importImgCertificazionePolicyDaEsterna(file: File, data: { [key: string]: string }, listaID: any) {
    return this.importazioniWebService.importImgCertificazionePolicyDaEsterna(file, data, listaID);
  }

  public deleteImgCertificazionePolicy(listaID: any) {
    return this.importazioniWebService.deleteImgCertificazionePolicy(listaID);
  }

  public deleteImgCertificazionePolicyDaEsterna(listaID: any, temporaryToken: string) {
    return this.importazioniWebService.deleteImgCertificazionePolicyDaEsterna(listaID, temporaryToken);
  }

  // Immagine su cfg stampa 
  public importImgStampaBilancio(file: File, data: { [key: string]: string }, listaID: {
    idAzienda: string;
    idBilancioSostenibilita: string;
    chiaveCfg: string;
  }) {
    return this.importazioniWebService.importImgStampa(file, data, listaID);
  }

  public deleteImgStampaBilancio(listaID: {
    idAzienda: string;
    idBilancioSostenibilita: string;
    chiaveCfg: string;
  }) {
    return this.importazioniWebService.deleteImgStampa(listaID);
  }
  // Immagine su cfg stampa Sintesi
  public importImgStampaSintesi(file: File, data: { [key: string]: string }, listaID: {
    idAzienda: string;
    idSintesiSostenibilita: string;
    chiaveCfg: string;
  }) {
    return this.importazioniWebService.importImgStampaSintesi(file, data, listaID);
  }

  public deleteImgStampaSintesi(listaID: {
    idAzienda: string;
    idSintesiSostenibilita: string;
    chiaveCfg: string;
  }) {
    return this.importazioniWebService.deleteImgStampaSintesi(listaID);
  }

  public importImgStampaPolicy(file: File, data: { [key: string]: string }, listaID: {
    idAzienda: string;
    idBilancioSostenibilita: string;
    chiaveCfg: string;
  }) {
    return this.importazioniWebService.importImgStampaPolicy(file, data, listaID);
  }

  public deleteImgStampaPolicy(listaID: {
    idAzienda: string;
    idBilancioSostenibilita: string;
    chiaveCfg: string;
  }) {
    return this.importazioniWebService.deleteImgStampaPolicy(listaID);
  }

  
   public importLogoStdr(file: File, data: { [key: string]: string }, idAzienda: string) {
    return this.importazioniWebService.uploadLogoStdr(file, data, idAzienda);
  }

  public deleteLogoStdr(idStdr: string) {
    return this.importazioniWebService.deleteLogoStdr(idStdr);
  }

}
