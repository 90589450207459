import { Component, Inject, OnInit, Input, ElementRef, ViewChild, inject } from '@angular/core';
import { Filtri } from '../../lista-tabellare.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Filters } from '../../classes/lista-tabellare-data-source';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, debounceTime, startWith, switchMap } from 'rxjs';
import { FiltriService } from 'src/app/services/filtri/filtri.service';

@Component({
    selector: 'app-dialog-filtro-tabellare',
    templateUrl: './dialog-filtro-tabellare.component.html',
    styleUrls: ['./dialog-filtro-tabellare.component.scss'],
    standalone: false
})
export class DialogFiltroTabellareComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];

  formControlCampiMultiSelect: { [key: string]: FormControl } = {};
  filteredItemsCampiMultiSelect: { [key: string]: Observable<any[]> } = {};

  @ViewChild('itemInput') itemInput!: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);

  private _larghezza = 3;

  get larghezza() {
    return this._larghezza;
  }


  public arrayDatiFiltri: { [key: string]: any } = {};
  /**
   * FormGroup per la gestione dei filtri
   */
  public formFiltri: FormGroup = new FormGroup({});


  public filtriPerCampi: Filtri[] = [];


  constructor(
    public dialogRef: MatDialogRef<DialogFiltroTabellareComponent>,
    private filtriService: FiltriService,
    @Inject(MAT_DIALOG_DATA) public data: {
      larghezza: number,
      filtri: Filtri[],
      valoriFiltro: Filters[]
    }
  ) {
    this._larghezza = data.larghezza || 3;
  }

  ngOnInit(): void {

    let idFiltroChip: any[] | undefined = undefined;

    if (this.data?.filtri) {
      // se sono impostati i filtri li ciclo e creo il FormControl
      this.data.filtri.forEach((value: Filtri, index, array) => {

        if (value?.forControlName && value.input === 'date') {

          this.formFiltri.addControl(
            value.forControlName,
            new FormArray([new FormControl(), new FormControl()])
          );

        } else if (value?.forControlName && value.input === 'multiple-option') {
          this.formFiltri.addControl(
            value.forControlName,
            new FormControl([])
          );

        } else if (value?.forControlName && value.input !== 'multiple-option' && value.input !== 'multiple-option-chip') {
          this.formFiltri.addControl(
            value.forControlName,
            new FormControl(value.value || null)
          );
        } else if (value?.forControlName && value.input === 'multiple-option-chip') {

          // Aggiungi il FormControl a formControlCampiMultiSelect ( viene usato per gestire l'autocomplete )
          this.formControlCampiMultiSelect[value.forControlName] = new FormControl();

          if (value.fnDatiOption) {

            // Crea l'Observable per filteredItemsCampiMultiSelect
            this.filteredItemsCampiMultiSelect[value.forControlName] = this.formControlCampiMultiSelect[value.forControlName]?.valueChanges.pipe(
              debounceTime(250),
              startWith(undefined),
              switchMap((ricerca: string | undefined) => value.fnDatiOption!(ricerca)),
            );

            this.formFiltri.addControl( //Form control dove ci sono i valori selezionati, i chip
              value.forControlName as string,
              new FormControl(undefined)
            );

            if ((value.value as any[])?.length) {
              idFiltroChip = value.value as any[];
            }

          }
        }

        if (value.input !== 'multiple-option-chip') {
          // metodo da fare dentro la funzione multipla
          if (value?.fnDatiOption && value?.forControlName) {
            value.fnDatiOption(
            ).subscribe((risp) => {
              this.arrayDatiFiltri[value?.forControlName || ''] = risp;
            });
          }
        }

        const valFiltro = this.data?.valoriFiltro.find((valFil) => {
          return valFil.chiave === value?.forControlName;
        })

        if (valFiltro) {
          if (value?.forControlName && (value.input === 'multiple-option' || value.input === 'multiple-option-chip' || value.input === 'date')) {

            if (value.fnDatiOption && value.input === 'multiple-option-chip' && (valFiltro.values)?.length) {

              idFiltroChip = valFiltro.values;

            } else {
              this.formFiltri.get(valFiltro.chiave)?.setValue(valFiltro.values);
            }

          } else {
            this.formFiltri.get(valFiltro.chiave)?.setValue(valFiltro.values[0]);
          }

          if (idFiltroChip) {
            value.fnDatiOption!(undefined, idFiltroChip).subscribe({
              next: (esito => {
                console.log(esito);
                this.formFiltri.get(valFiltro.chiave)?.setValue(esito);
              }),
              error: (err => {
                console.error(err);
              })
            })
          }
        }

      });



      this.filtriPerCampi = this.data.filtri.filter((filtro) => {

        if (typeof filtro.nascondi === 'boolean') {
          return !filtro.nascondi;
        } else if (typeof filtro.nascondi === 'function') {
          return !filtro.nascondi(filtro);
        }

        return true;

      });
    }
  }

  applicaFiltri() {

    const datiFiltri = this.filtriService.costruisciJsonFiltri(this.formFiltri.getRawValue(), this.data.filtri || []);

    this.dialogRef.close({
      filtri: datiFiltri,
    });
  }

  cancellaFiltri() {
    this.formFiltri.reset();
  }

  /* ----------------  MULTIPLE-OPTION-CHIP -----------------*/

  add(event: MatChipInputEvent, formCtrName: string | undefined): void {
    console.log(event.value);
    
    const value = (event.value || '').trim();

    // Add nuovo elemento selezionato
    if (value) {
      if (formCtrName) {
        const arrayChip = this.formFiltri.get(formCtrName)?.value || [];
        arrayChip.push(value);
        this.formFiltri.get(formCtrName)?.setValue(arrayChip);
      }
    }

    // Clear the input value
    event.chipInput!.clear();

    if (formCtrName) {

      this.formControlCampiMultiSelect[formCtrName].setValue(null);

    }
  }

  remove(elemSel: string, formCtrName: string | undefined): void {
    if (formCtrName) {

      const arrayChip = this.formFiltri.get(formCtrName)?.value || [];

      const index = arrayChip.indexOf(elemSel);
      if (index >= 0) {
        arrayChip.splice(index, 1);
        this.announcer.announce(`Removed ${elemSel}`);
        this.formFiltri.get(formCtrName)?.setValue(arrayChip);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent, formCtrName: string | undefined): void {
    if (formCtrName) {

      const arrayChip = this.formFiltri.get(formCtrName)?.value || [];
      arrayChip.push(event.option.value);
      console.log(event.option.value);

      this.itemInput.nativeElement.value = '';

      this.formControlCampiMultiSelect[formCtrName].setValue(null);

      this.formFiltri.get(formCtrName)?.setValue(arrayChip);
    }
  }

}
