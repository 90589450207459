<app-spinner-overlay></app-spinner-overlay>
<div class="cont-comp">
    <div appFormElement [larghezza]="1" [riempi]="true">
        <div class="titolo-sezione-dialog">
            Selezione del Perimetro e attuazione della Policy
        </div>
        <div class="descrizione-sezione-dialog">
            La Policy di Sostenibilità si applica a specifici destinatari interni ed esterni, definendo il Perimetro.
            Seleziona i destinatari della Policy e specifica come la Policy trova applicazione con ciascun
            gruppo.
        </div>

    </div>
</div>

<div class="cont-titolo">
    <div>
        <mat-label class="titolo">Seleziona e compila gli elementi</mat-label>
    </div>
</div>
<div style="flex: 1;">
    <ng-scrollbar>
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === idx" (opened)="setStep(idx)"
                *ngFor="let elem of arrayPerimetriDestinatari; index as idx" exp>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="titolo-panel">{{elem.descrizione}}</span>
                        <span
                            class="nrSel">{{numeroSelezionatiCount(elem)}}/{{elem?.perimetriDestinatari?.length}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div appFormContainer [larghezza]="3" *ngFor="let elemento of elem.perimetriDestinatari">
                    <div appFormElement [larghezza]="0.7" [riempi]="true">
                        <mat-checkbox [disabled]="isPermessoSoloRead" [(ngModel)]="elemento.selected"
                            (change)="onChangeCheck($event, elemento, idx)" (mousedown)="$event.stopPropagation()"
                            color="primary">

                            <div class="nome-figura">{{elemento.descrizione}}</div>
                        </mat-checkbox>
                    </div>

                    <div appFormElement [larghezza]="1.3" [riempi]="true" style="display: flex; align-items: center;">
                        <div class="nome-figura">
                            {{elemento.lineaGuidaCompilata}}</div>
                    </div>
                    <ng-container *ngIf="elemento.selected">
                        <button class="icone-button" mat-icon-button
                            (click)="compilaPerimetro(elemento, idx); $event.stopPropagation();"
                            [title]="(isPermessoSoloRead && elemento.lineaGuidaCompilata) ? 'Visualizza' :  (elemento.lineaGuidaCompilata ? 'Modifica' : 'Compila')"
                            [attr.aria-label]="(isPermessoSoloRead && elemento.lineaGuidaCompilata) ? 'Visualizza' :  (elemento.lineaGuidaCompilata ? 'Modifica' : 'Compila')">
                            <mat-icon class="icone">
                                {{(isPermessoSoloRead && elemento.lineaGuidaCompilata) ? 'visibility' :
                                (elemento.lineaGuidaCompilata ? 'edit' : 'history_edu') }}
                            </mat-icon>
                        </button>
                    </ng-container>
                    <div [ngStyle]="!elemento.selected ? {'padding-right': '45px'} : {'padding-right': '0px'}"></div>

                </div>
                <div appFormElement [larghezza]="1" [riempi]="true"
                    *ngIf="elem.descrizione === 'Destinatari'" style="padding-bottom: 10px;">
                    <button mat-stroked-button [disabled]="isPermessoSoloRead ? true : false"
                        (click)="creaNuovoDestinatario()"
                        style="height: 40px;width: 100%;font-weight: bold; background-color: #F5F5F5;">
                        + Aggiungi Nuovo Destinatario
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-scrollbar>
</div>