import { Component, Input, OnInit, ViewChild, HostBinding, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DialogAssociaFiguraAziendaleComponent } from './dialog/dialog-associa-figura-aziendale/dialog-associa-figura-aziendale.component';
import { MatDialog } from '@angular/material/dialog';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { FormControl, Validators } from '@angular/forms';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { Settore } from 'src/app/services/settori/settori.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetInfoElementiComponent, InfoElemento } from './bottom-sheet-info-elementi/bottom-sheet-info-elementi.component';

@Component({
  selector: 'app-selezione-elementi-questionario',
  templateUrl: './selezione-elementi-questionario.component.html',
  styleUrls: ['./selezione-elementi-questionario.component.scss'],
  standalone: false
})

export class SelezioneElementiQuestionarioComponent implements OnInit {
  @HostBinding('class.selezione-elementi-questionario') cssClass = true;

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild('tabGroupAmbiti') tabGroupAmbiti!: MatTabGroup;

  @Output()
  public salvato = new EventEmitter<void>();

  private _objQuestionario: any;
  private _chiaveElementoSelezione: string = '';
  private _chiaveElemento: string = '';
  private _idContest: string = '';
  private _idAzienda: string | undefined = undefined;
  public referente: string = '';
  public _stato: string | undefined = undefined;
  public _isMaterialitaSelezionata: boolean = false; //solo per Bilancio e Sintesi ( se la materialità è stata selezionata nella cfg)
  public _isImpatto: boolean = false;
  public _isSoglieSuperate: boolean = false;
  private _settore: Settore[] = [];
  public isProfessionista: boolean = false;
  private arrayTematicheNonCompletata: string[] = [];

  public _titoloObiettivo: string | undefined;
  public _desObiettivo: string | undefined;
  public _nomeModulo: string | undefined;

  @Input()
  set objQuestionario(objQuestionario: any) {
    this._objQuestionario = objQuestionario;
    this.updateFormStatus();
  }
  get objQuestionario() {
    return this._objQuestionario;
  }

  @Input()
  set chiaveElemento(chiaveElemento: any) {
    this._chiaveElemento = chiaveElemento;
  }
  get chiaveElemento() {
    return this._chiaveElemento;
  }
  @Input()
  set chiaveElementoSelezione(chiaveElementoSelezione: any) {
    this._chiaveElementoSelezione = chiaveElementoSelezione;
  }
  get chiaveElementoSelezione() {
    return this._chiaveElementoSelezione;
  }
  @Input()
  set idContest(idContest: any) {
    this._idContest = idContest;
  }
  get idContest() {
    return this._idContest;
  }
  @Input()
  set isSoglieSuperate(isSoglieSuperate: boolean) {
    this._isSoglieSuperate = isSoglieSuperate;
  }
  get isSoglieSuperate() {
    return this._isSoglieSuperate;
  }

  @Input()
  set stato(stato: string | undefined) {
    this._stato = stato;
  }
  get stato() {
    return this._stato;
  }

  // Solo per il PIANO!!!!
  @Input()
  set titoloObiettivo(titoloObiettivo: string | undefined) {
    this._titoloObiettivo = titoloObiettivo;
  }
  get titoloObiettivo() {
    return this._titoloObiettivo;
  }

  // Solo per il PIANO!!!!
  @Input()
  set desObiettivo(desObiettivo: string | undefined) {
    this._desObiettivo = desObiettivo;
  }
  get desObiettivo() {
    return this._desObiettivo;
  }

  @Input()
  set nomeModulo(nomeModulo: string | undefined) {
    this._nomeModulo = nomeModulo;
  }
  get nomeModulo() {
    return this._nomeModulo;
  }

  @Input()
  set settore(settore: Settore[]) {
    this._settore = settore;
  }

  get settore() {
    return this._settore;
  }

  @Input()
  set isImpatto(isImpatto: boolean) {
    this._isImpatto = isImpatto;
  }

  get isImpatto() {
    return this._isImpatto;
  }


  private _isPermessoSoloRead = false;
  @Input()
  set isPermessoSoloRead(isPermessoSoloRead: boolean) {
    this._isPermessoSoloRead = isPermessoSoloRead;
  }
  get isPermessoSoloRead() {
    return this._isPermessoSoloRead;
  }

  formValido = new FormControl<boolean>(false, {
    validators: [Validators.requiredTrue]
  })

  constructor(
    public materialitaService: MaterialitaService,
    public bilancioService: BilancioService,
    public sintesiService: SintesiService,
    public dialog: MatDialog,
    public utilityService: UtilityService,
    private readonly aziendaService: AziendaService,
    private readonly utenteService: UtenteService,
    public policyService: PolicyService,
    public pianoService: PianoService,
    private readonly _bottomSheet: MatBottomSheet
  ) {


    this.aziendaService.azienda.then((azienda) => {
      this._idAzienda = azienda?.id;
    });

    if (!this.utenteService.isPermessoAttivo('EDIT_UNITA_MISURA')) {
      this.isProfessionista = true;
    }

  }

  ngOnInit(): void {
    this._listaFigureAz()
  }

  onTabChange(event: MatTabChangeEvent) {

  }

  /**
 * Controllo se la tematica passata che abbia almeno un elemento checkato
 * se è vero allora rimuovo dall arrayTematicheNonCompletata la tematica con l'elemento checkato
 * altrimento lo aggiunge per un futuro controllo (almeno 1 elemento per tematica) 
 * @param idAmbito  
 * @param idTematica 
 */
  gestioneArrayTematicheNonCompletata(idAmbito: string, idTematica: string) {
    const ambito = this.objQuestionario.find((ambito: any) => ambito.id === idAmbito);

    if (!ambito) return;

    const tematica = ambito.tematiche.find((tematica: any) => tematica.id === idTematica);

    if (!tematica) return;

    const elementiSelezionati = tematica.elementi.filter((elemento: any) => elemento.selected);
    const tematicaKey = `${idAmbito}_${idTematica}`;
    const indiceTematicaNonCompleta = this.arrayTematicheNonCompletata.indexOf(tematicaKey);

    if (elementiSelezionati.length === 0) {
      // Aggiungi la tematica alla lista se non è già presente
      if (indiceTematicaNonCompleta < 0) {
        this.arrayTematicheNonCompletata.push(tematicaKey);
      }
    } else {
      // Rimuovi la tematica dalla lista se è stata completata
      if (indiceTematicaNonCompleta >= 0) {
        this.arrayTematicheNonCompletata.splice(indiceTematicaNonCompleta, 1);
      }
    }
  }

  scrollToElement(idAmbito: string, idTematica: string, idElemento: string) {

    let elementiSelezionati = this.oggettoElementiSelezionati();

    /* Controllo tutte le tematiche cosi non mi rimane in rosso una tematica già selezionata, perchè ora
    controllava solamente la tematica dove si azionava. 
    */
    for (let elem of elementiSelezionati) {
      this.gestioneArrayTematicheNonCompletata(elem.idAmbito, elem.idTematica);
    }

    let elTematica = document.getElementById(idAmbito + '_' + idTematica + this.chiaveElementoSelezione);
    let el = document.getElementById(idAmbito + '_' + idTematica + '_' + idElemento);
    setTimeout(() => {
      if (idElemento) {
        el?.scrollIntoView({ behavior: 'smooth' });
      } else {
        elTematica?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }

  editElementoListaChk(idAmbito: string, idTematica: string, elementoDaModificare: any): void { }

  addElementoListaChk(idAmbito: string, idTematica: string): void { }

  associaFiguraAziendale(idAmbito: string, idTematica: string, idElemChk: string, figura: any): void {

    const dialogAssociaFiguraAziendale = this.dialog.open(DialogAssociaFiguraAziendaleComponent, {
      data: {
        figura: figura
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });
    dialogAssociaFiguraAziendale.afterClosed().subscribe((result) => {
      // ritorna id della figura aziendale 
      if (result) {
        this.salvaFigAz(idAmbito, idTematica, idElemChk, result)
      }
    });

  }

  salvaFigAz(idAmbito: string, idTematica: string, idElemChk: string, idFigAziendale: string): void { }

  /**
   * Metodo che mi controlla se lo step è valido per andare avanti
   * @param msgChiave 
   * @returns 
   */
  ctrStepper(msgChiave: string): boolean {
    let elementiSelezionati = this.oggettoElementiSelezionati();
    //Controllo se è stato selezionato almeno un impatto per tematica 
    return this.ctrElemChecked(elementiSelezionati, msgChiave);
  }

  /**
   * Funzione che mi genera un oggetto per passare come parametro al salvataggio 
   * dello step 3
   * @returns ogggetto per salvattagio step 3
   */
  oggettoElementiSelezionati() {
    let nuovoOggetto: any = [];

    this.objQuestionario.forEach((ambito: any) => {
      ambito.tematiche.forEach((tematica: any) => {
        nuovoOggetto.push({
          "idAmbito": ambito.id,
          "idTematica": tematica.id,
          "idElementi": tematica.elementi
            .filter((elemento: any) => elemento.selected)
            .map((elemento: any) => elemento.id)
        });
      });
    });
    return nuovoOggetto;
  }

  /**
  * Metodo che mi contorlla se selezionato almeno un elemento per tematica 
  * @param ambiti 
  * @returns [true] no elementi checked per tematica  [false] almeno 1 elemento per tematica checked.  
  */
  ctrElemChecked(oggetto: any, nominativoElemento?: string): boolean {

    this.arrayTematicheNonCompletata = [];

    if (!nominativoElemento) {
      nominativoElemento = 'elemento'
    }

    for (let elem of oggetto) {
      if (elem.idElementi <= 0) {
        this.arrayTematicheNonCompletata.push(elem?.idAmbito + '_' + elem?.idTematica)
      }
    }

    return this.cambioTabEvidenzioElemento(nominativoElemento);
  }

  /**
   * Metodo che mi evidenzia la tematica non selezionata e cambia tab se questa tematica si trova in una tab diversa 
   * @param nominativoElemento 
   */
  cambioTabEvidenzioElemento(nominativoElemento?: string): boolean {
    if (this.arrayTematicheNonCompletata.length) {

      this.cambioTab();

      setTimeout(() => {
        let el = document.getElementById(this.arrayTematicheNonCompletata[0] + this.chiaveElementoSelezione);
        el?.scrollIntoView({ behavior: 'smooth' });
        this.utilityService.opneSnackBar('Selezionare almeno un ' + nominativoElemento + ' per tematica ', '', {
          duration: 2000,
          panelClass: ['red-snackbar']
        });
      }, 0);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Cambio tab se ho elementi obbligatori da checkare in un altra tab
   */
  cambioTab() {
    const idxTab = (this.objQuestionario as Ambito[]).findIndex((amb: Ambito) => {
      return amb.id === this.arrayTematicheNonCompletata[0].split("_")[0];
    })
    this.tabGroupAmbiti.selectedIndex = idxTab;
  }

  updateFormStatus() {
    const elSenzaSel = this.objQuestionario.find((ambito: any) => {
      return ambito.tematiche.find((tematica: any) => {
        return !tematica.elementi
          .find((impegno: any) => impegno.selected);
      });
    });

    this.formValido.setValue(elSenzaSel ? false : true);

    this.ordinaAmbiti();

  }

  /**
   * Metodo che ordina le tab ambiti per index. 
   */
  ordinaAmbiti() {
    this._objQuestionario = this._objQuestionario
      .sort((a: any, b: any) => {
        let indexA = a.index == null ? Infinity : a.index;
        let indexB = b.index == null ? Infinity : b.index;
        return indexA - indexB;
      });
  }

  /**
   * Funzione che mi prende la figura referente
   */
  private _listaFigureAz() {
    if (this._idAzienda) {

      this.aziendaService.getFigureAz(this._idAzienda, 0, 1000).subscribe({
        next: (esito) => {
          this.referente = esito.content
            .filter((item: any) => item.figura.toLowerCase() === 'referente')
            .map((item: any) => item.figura + ' : ' + item.nome);
        },
        error: (err) => {
          console.error(err);
        }
      })

    }
  }

  /**
   * Metodo che viene lanciato nel momento del check elemento.
   * @param elementoChk
   * @param checked 
   * @param idAmbito 
   * @param idTematica 
   * @returns 
   */
  selezionaCheck(elementoChk: any, checked: boolean, idAmbito: string, idTematica: string) {
    if (this.isPermessoSoloRead) {
      return;
    }
    if (!this.controlloElementoDisabled(elementoChk)) {
      elementoChk.selected = checked;
      this.updateFormStatus();

      /* Controllo che una volta checkato, i campi obbligatori dentro l'elemento non siano vuoti 
         Viene utilizzato per impatti e impegni per adesso. L'implementazione si trova nelle proprie classi  */
      this.ctrCheck(idAmbito, idTematica, elementoChk, checked);
    }
  }

  ctrCheck(idAmbito: string, idTematica: string, elementoChk: any, checked: boolean) { }

  /**
   * Metodo che mi valorizza il tooltip della della figura
   * @param figura figura
   */
  toolTipFigura(figura: any): string {

    let tooltip: string | undefined = undefined;
    if (figura) {
      tooltip = figura?.figura + ' : ' + figura?.nome;
    } else {
      tooltip = this.referente;
    }

    return tooltip;
  }

  controlloElementoDisabled(elementoChk: any): boolean {
    if (this.stato === 'PUBBLICATO' || this.isElementoObbligatorio(elementoChk)) {
      return true;
    }
    return false;
  }

  isElementoEditable(elementoChk: any) {

    if (this.isPermessoSoloRead) {
      return false;
    }

    if (!this.controlloElementoDisabled(elementoChk)) {
      return true;
    }
    return false;
  }

  /**
   * Elemento obbligatorio 
   * Non obbligatorio per impegni e certificazioni
   * @param elementoChk 
   * @returns 
   */
  isElementoObbligatorio(elementoChk: any) {
    const isPreSelected = elementoChk?.preSelected;
    const isImpatto = elementoChk?.tipo === 'Impatto';

    // Controllo se azioni e kpi sono obbligaotri per soglie superata e stdr obbligatori
    if (elementoChk.tipo === 'AzioniEBuonePratiche' || elementoChk.tipo === 'Kpi') {
      if (elementoChk?.infoConsigliatoObbl?.stdrObbligatori?.length && this.isSoglieSuperate) {
        return true;
      } else {
        return false
      }
    }

    // PER IMPATTI
    if (isPreSelected && isImpatto && elementoChk?.materiale && elementoChk?.preSelectedInfos?.length) {
      return true;
    }


    return false
  }

  /**
     * Elemento consigliato
     * @param elementoChk 
     * @returns 
     */
  isElementoConsigliato(elementoChk: any) {

    // Controllo se AZIONI e KPI sono consigliati per soglie non superate e stdr consigliati
    if (elementoChk.tipo === 'AzioniEBuonePratiche' || elementoChk.tipo === 'Kpi' && elementoChk?.preSelectedInfos?.length) {

      if (elementoChk?.infoConsigliatoObbl?.stdrConsigliati?.length && !this.isSoglieSuperate) {
        return true;
      } else {
        return false
      };
    }
    // Controllo consigliato per IMPEGNO e CERTIFICAZIONI 
    if ((elementoChk.tipo === 'Impegno' || elementoChk.tipo === 'Certificazione') && elementoChk?.preSelectedInfos?.length) {
      return true;
    }
    // Controllo consigliato per IMPATTO
    if (elementoChk.tipo === 'Impatto' && elementoChk?.consigliato) {
      return true
    }

    return false
  }

  /**
       * Mostro icona del obbligatorio
       * @param elementoChk 
       * @returns 
       */
  /*   showLockIcon(elementoChk: any): boolean {
      const isObbligatorio = this.isElementoObbligatorio(elementoChk);
      const isPreSelected = elementoChk?.preSelected;
      const isImpatto = elementoChk?.tipo === 'Impatto';
  
      // PER ELEMENTI KPI E AZIONI NEL CONTESTO BILANCIO
      if (isObbligatorio) {
        return true;
      }
  
      // PER IMPATTI
      if (isPreSelected) {
        if (isImpatto && elementoChk?.materiale) {
          return true;
        }
      }
      return false;
    } */

  tooltipInfo(elementoChk?: any): string[] {

    const infoList: string[] = [];
    if (elementoChk?.preSelectedInfos?.length) {

      elementoChk?.preSelectedInfos?.forEach((info: string) => {

        const [codice, anno] = info.split("_");

        switch (codice) {

          case 'MA':
            infoList.push('Impatto definito nell’Analisi di Materialità dell’' + anno + ' associata al Bilancio')
            break;

          case 'PA':
            infoList.push('Policy di Sostenibilità ' + anno);
            break;

          case 'SA':
            infoList.push('Sintesi di Sostenibilità ' + anno);
            break;

          case 'AA':
            infoList.push('Assessment di Sostenibilità ' + anno);
            break;

          case 'BA':
            infoList.push('Bilancio di Sostenibilità ' + anno);
            break;
          default:
        }
      });

    }
    return infoList;
  }

  /**
   * arrayTematicheNonCompletata ha come valori una lista di elementi [idAmbito_Tematica], quindi tematiche
   * che non hanno nessun elemento checkato.
   * @param idAmbito_Tematica 
   * @returns 
   */
  ctrTematicaNonCompletata(idAmbito_Tematica?: string) {

    if (idAmbito_Tematica && this.arrayTematicheNonCompletata.includes(idAmbito_Tematica)) {
      return true;
    }
    return false
  }

  apriPopUpConsigliato(elementoChk: any) {

    const infoElementoObj: InfoElemento = {
      preSelectedInfos: this.tooltipInfo(elementoChk),
      stdr: elementoChk?.infoConsigliatoObbl?.stdrConsigliati,
      titoloElemento: elementoChk?.titolo,
      obbligatorio: false

    }

    this._bottomSheet.open(BottomSheetInfoElementiComponent, { data: infoElementoObj });
  }

  apriPopUpObbligatorio(elementoChk: any) {

    const infoElementoObj: InfoElemento = {
      preSelectedInfos: this.tooltipInfo(elementoChk),
      stdr: elementoChk?.infoConsigliatoObbl?.stdrObbligatori,
      titoloElemento: elementoChk?.titolo,
      obbligatorio: true
    }

    this._bottomSheet.open(BottomSheetInfoElementiComponent, { data: infoElementoObj });
  }

}

