import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
/* import { ChatWidgetComponent } from '../chat/chat-widget/chat-widget.component'; */
import { MatDialog } from '@angular/material/dialog';
/* import { UserDialogComponent } from '../auth/user-dialog/user-dialog.component';
import { AuthService, User } from '../auth/auth-service/auth-service.service'; */
import { AsyncPipe } from '@angular/common';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { TemaService } from 'src/app/services/tema/tema.service';
import { InfoUtente } from 'src/app/services/utente/utente-web.service';
import { Subscription } from 'rxjs';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
/* import { CompanySelectorDialogComponent } from '../common/company-selector-dialog/company-selector-dialog.component';
import { SessionService } from '../common/session-service/session.service'; */

/**
 * Struttura del menu sidenav
 * la struttura può avere anche sottovoci(children)
 */
interface VociMenu {
  sezione: string;
  routerMenu: string;
  icon: string;
  sottoSezione?: VociMenu[];
  disabled?: boolean;
  mostrato?: boolean
}


@Component({
  selector: 'app-main-page',
  standalone: true, // !METTERE FALSE
  imports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatDividerModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.scss'
})
export class MainPageComponent {
  @ViewChild(MatSelect) matSelect!: MatSelect;

  isSidenavMini = false;
  public vociMenu: any;

  public infoUtente: InfoUtente | undefined;
  public isMenuOpen: boolean = true;

  public logoPath: string = '';

  public isActive: boolean = false;

  private readonly _subAziendaCorrente: Subscription | undefined;
  private _isUtenteGo: boolean = false;

  public azienda: Azienda | undefined;
  public arrayAziende: Azienda[] = [];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private readonly aziendaService: AziendaService,
    private readonly utilityService: UtilityService,
    private readonly utenteService: UtenteService,
    private readonly temaService: TemaService,
    private readonly matDialog: MatDialog,
    /* public authService: AuthService, */
    /*     public sessionService: SessionService, */
  ) {
    this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      this.logoPath = temaService.logoPath;
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this._isUtenteGo = true;
      } else {
        this._isUtenteGo = false;
      }
      this._getMenu(this.azienda?.id)
      console.log(this.vociMenu)
    })

    this.infoUtente = this.utenteService.infoUtente;

    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda: Azienda | undefined) => {

      this.azienda = azienda;
      this._getMenu(azienda?.id);
      console.log(this.vociMenu)

    });

    this.aziendaService.listaAziende.subscribe((listaAziende) => {
      this.arrayAziende = listaAziende || [];

      this.aziendaService.azienda.then((val) => {
        this.azienda = val;
      });
    });

    if (this.azienda?.id) {
      this.aziendaService.setSoglia(this.azienda?.id);
    }
  }

  azionaMenu(pagina: any) {


    if (pagina?.disabled === true) {

      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Seleziona un azienda',
        bottoni: [{
          nome_btn: 'Ok', handler: () => {
            this.matSelect.open();
          }
        }]
      })

    } else {
      this.router.navigate([pagina.routerMenu], {
        relativeTo: this.activatedRoute,
      });
    }
  }
  showUserDialog() {
    /*  this.matDialog.open(UserDialogComponent, { minWidth: '350px', data: user }); */
  }

  showCompanyDialog() {
    /* this.matDialog.open(CompanySelectorDialogComponent, { minWidth: '350px', data: user }); */
  }
  public compareAzienda(azienda1: Azienda, azienda2: Azienda): boolean {
    return azienda1?.id === azienda2?.id; // Sostituisci "id" con la proprietà univoca degli ambiti
  }


  public cambioAzienda(azienda: Azienda) {

    this.aziendaService.azienda = azienda;
  }

  private _getMenu(idAzienda?: string) {
    this.vociMenu = [
      {
        sezione: 'Dashboard',
        routerMenu: 'dashboard',
        icon: 'dashboard',
        sottoSezione: [],
        mostrato: true
      },
      {
        sezione: 'Assessment di Sostenibilità',
        routerMenu: 'assessment',
        icon: 'star_border',
        sottoSezione: [],
        disabled: (!idAzienda),
        mostrato: this.utenteService.isPermessoAttivo('READ_AUTOVALUTAZIONI'),
      },
      {
        sezione: 'Policy di Sostenibilità',
        routerMenu: 'policy-sostenibilita',
        icon: 'policy',
        sottoSezione: [],
        disabled: (!idAzienda),
        mostrato: this.utenteService.isPermessoAttivo('READ_POLICY'),
      },
      {
        sezione: 'Piano di Sostenibilità',
        routerMenu: 'piano-sostenibilita',
        icon: 'event_note',
        sottoSezione: [],
        disabled: (!idAzienda),
        mostrato: this.utenteService.isPermessoAttivo('READ_PIANO'),
      },
      {
        sezione: 'Analisi Materialità',
        routerMenu: 'analisi-materialità',
        icon: 'text_snippet',
        mostrato: this.utenteService.isPermessoAttivo('READ_STAKEHOLDERS') || this.utenteService.isPermessoAttivo('READ_MATERIALITA'),
        isExpanded: false,
        sottoSezione: [
          {
            sezione: 'Stakeholder',
            routerMenu: 'stakeholder',
            icon: '•',
            disabled: (!idAzienda),
            mostrato: this.utenteService.isPermessoAttivo('READ_STAKEHOLDERS'),
          },
          {
            sezione: 'Materialità',
            routerMenu: 'materialita',
            icon: '•',
            disabled: (!idAzienda),
            mostrato: this.utenteService.isPermessoAttivo('READ_MATERIALITA'),
          },
        ],
      },
      {
        sezione: 'Sintesi di Sostenibilità',
        routerMenu: 'sostenibilita',
        icon: 'spa',
        disabled: (!idAzienda),
        mostrato: this.utenteService.isPermessoAttivo('READ_SINTESI'),
      },
      {
        sezione: this._isUtenteGo ? 'Report Tematico' : 'Bilancio di Sostenibilità',
        routerMenu: 'bilanci',
        icon: 'balance',
        disabled: (!idAzienda),
        mostrato: this.utenteService.isPermessoAttivo('READ_BILANCIO'),
      },
      {
        sezione: 'Aziende',
        routerMenu: 'aziende',
        icon: 'apartment',
        mostrato: this.utenteService.isPermessoAttivo('READ_AZIENDA') || this.utenteService.isPermessoAttivo('READ_FIGURA_AZ'),
        isExpanded: false,
        sottoSezione: [
          {
            sezione: 'Aziende',
            routerMenu: 'aziende',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_AZIENDA')
          },
          {
            sezione: 'Figure Aziendali',
            routerMenu: 'figure-aziendali',
            icon: '•',
            disabled: (!idAzienda),
            mostrato: this.utenteService.isPermessoAttivo('READ_FIGURA_AZ'),
          },
          {
            sezione: 'Comitati',
            routerMenu: 'comitati',
            icon: '•',
            disabled: (!idAzienda),
            mostrato: this.utenteService.isPermessoAttivo('READ_COMITATI') && !this._isUtenteGo,
          }
        ],
      },
      {
        sezione: 'Utenti',
        routerMenu: 'utenti',
        icon: 'group',
        sottoSezione: [],
        mostrato: this.utenteService.isPermessoAttivo('READ_UTENTI'),
      },
      {
        sezione: 'Configurazioni',
        routerMenu: 'configurazioni',
        icon: 'settings_application',
        mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI'),
        isExpanded: false,
        sottoSezione: [
          {
            sezione: 'Ambiti',
            routerMenu: 'configurazioni-ambiti',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Tematiche',
            routerMenu: 'configurazioni-tematiche',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Impatti',
            routerMenu: 'configurazioni-impatti',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Impegni',
            routerMenu: 'configurazioni-impegni',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Azioni e Buone Pratiche',
            routerMenu: 'configurazioni-azione-e-buona-pratica',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'KPI',
            routerMenu: 'configurazioni-KPI',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Standard di Rendicontazione',
            routerMenu: 'configurazioni-standard-rendicontazione',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Assessment di Sostenibilità',
            routerMenu: 'configurazioni-autovalutazioni',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Categorie Stakeholder',
            routerMenu: 'configurazioni-cate-stakeholder',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Guide Scrittura Impatto',
            routerMenu: 'configurazioni-scrittura-impatto',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Informativa Aziendale',
            routerMenu: 'configurazioni-informativa-aziendale',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Settori',
            routerMenu: 'configurazioni-settori',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Unità di misura',
            routerMenu: 'configurazioni-unita-di-misura',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Certificazioni',
            routerMenu: 'configurazioni-certificazioni',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Comitati',
            routerMenu: 'configurazioni-comitati',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          /*    {
               sezione: 'Destinatari Policy',
               routerMenu: 'configurazioni-destinatari-policy',
               icon: '•',
               mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
             }, */
          {
            sezione: 'Perimetro di Applicazione Policy',
            routerMenu: 'configurazioni-perimetro-app-policy',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Linee guida stampe e Mail',
            routerMenu: 'configurazioni-linee-guida-stampe',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Obiettivi',
            routerMenu: 'configurazioni-obiettivi-piano',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Obiettivi Aziendali',
            routerMenu: 'configurazioni-obiettivi-aziendali-piano',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Modelli Sviluppo Piano',
            routerMenu: 'configurazioni-modelli-sviluppo-piano',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
        ],
      },
    ]/* .map((voce) => {
      (voce.sottoSezione as VociMenu[]) = (voce.sottoSezione as VociMenu[] || []).filter((sottoVoce: VociMenu) => sottoVoce.mostrato);
      return voce;
    }).filter((voce) => voce.mostrato); */

  }

  toggleSubMenu(index: number): void {
    this.vociMenu[index].isExpanded = !this.vociMenu[index].isExpanded;
  }

}
