<div mat-dialog-title>Analisi di Materialità</div>

<div mat-dialog-content class="dialog-center-cont" style="padding: 0px !important;">
    <app-spinner-overlay></app-spinner-overlay>
    <mat-stepper [linear]="true" #stepper (selectionChange)="cambioStep($event)">

        <mat-step [stepControl]="formMaterialitaCheckId" [editable]="true">

            <ng-template matStepLabel>
                Analisi di materialità
            </ng-template>
            <ng-scrollbar>
                <form [formGroup]="formMaterialita" appFormContainer [larghezza]="6" [rowGap]="10"
                    style="align-items: center;">

                    <div appFormElement [larghezza]="6" [riempi]="true">

                        <div class="titolo-sezione-dialog">
                            Creazione Analisi di Materialità
                        </div>

                        <div class="descrizione-sezione-dialog">
                            Inserisci il titolo e l'anno di riferimento e scegli tra l'approccio generico e quello settoriale.  <br>
                            Se selezioni l'approccio settoriale il sistema elaborerà un framework basato sulle specificità del settore di appartenenza. La scelta del sotto-settore aumenterà l'accuratezza dell'approccio selezionato. 
                        </div>
                    </div>

                    <mat-form-field appFormElement [larghezza]="2" class="input-form">
                        <mat-label>Titolo</mat-label>
                        <input matInput placeholder="Titolo" formControlName="titolo" />
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appFormElement [larghezza]="2" [riempi]="true" [minWidth]="150">
                        <mat-label>Anno</mat-label>
                        <mat-select formControlName="anno" placeholder="Anno">
                            <mat-option *ngFor="let aa of arrayAnni" [value]="aa"
                                [disabled]="ctrAnnoMaterialitaUtilizzata(aa)">{{aa}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <div appFormElement [larghezza]="2" style="display:flex; flex-direction: column">
                        <mat-radio-group class="radio-tipo-sett" style="justify-content: space-around;"
                            aria-label="Tipo settore" formControlName="tipoSettore">
                            <mat-radio-button aria-label="Settore Generico" value="GENERALE"
                                [disabled]="this.formMaterialita.get('id')?.value || !this.settoreGenerale">Settore
                                Generico</mat-radio-button>
                            <mat-radio-button aria-label="Settore Specifico" value="SPECIFICO"
                                [disabled]="this.formMaterialita.get('id')?.value || !(this.settoriAzienda.length)">Settore
                                Specifico</mat-radio-button>
                        </mat-radio-group>
                    </div> -->

                    
                    <!-- <div appFormElement [larghezza]="4" style="display:flex; flex-direction: column">
                        <mat-radio-group class="radio-tipo-sett" style="justify-content: space-around;"
                            aria-label="Tipo settore" formControlName="tipoSettore">
                            <mat-radio-button aria-label="Settore Generico" value="GENERALE"
                                [disabled]="this.formMaterialita.get('id')?.value || !this.settoreGenerale">
                                Settore Generico
                            </mat-radio-button>
                            <mat-radio-button aria-label="Settore Specifico" value="SPECIFICO"
                                [disabled]="this.formMaterialita.get('id')?.value || !(this.settoriAzienda.length)">
                                Settore Specifico
                            </mat-radio-button>
                        </mat-radio-group>
                    </div> -->

                    <div appFormElement [larghezza]="2" style="display:flex; flex-direction: column">
                        <mat-label>Tipo Settore</mat-label>
                        <mat-radio-group class="radio-tipo-sett" aria-label="Tipo settore"
                            formControlName="tipoSettore">
                            <mat-radio-button aria-label="Generico" value="GENERALE"
                                [disabled]="this.formMaterialita.get('id')?.value || !this.settoreGenerale">
                                <div class="eti-check">
                                    Generico
                                    <mat-icon matTooltip="Adatto ad aziende di ogni settore">info_outline</mat-icon>
                                </div>
                            </mat-radio-button>
                            <mat-radio-button aria-label="Specifico" value="SPECIFICO"
                                [disabled]="this.formMaterialita.get('id')?.value || !(this.settoriAzienda.length)">
                                <div class="eti-check">
                                    Specifico
                                    <mat-icon
                                        matTooltip="Sviluppato ad hoc per il settore di appartenenza dell'azienda">info_outline</mat-icon>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>


                    <mat-form-field appFormElement [larghezza]="3" class="input-form"
                        *ngIf="this.formMaterialita.get('tipoSettore')?.value === 'SPECIFICO'">
                        <mat-label>Settore</mat-label>
                        <mat-select formControlName="settori" placeholder="Settore" [compareWith]="compareById">
                            <mat-option *ngFor="let set of settoriAzienda" [value]="set"
                                [disabled]="ctrSettoreMaterialitaUtilizzata(set)">{{set.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appFormElement [larghezza]="3" class="input-form"
                        *ngIf="this.formMaterialita.get('tipoSettore')?.value === 'SPECIFICO' && arraySottoSettori?.length">
                        <mat-label>Sotto-settori</mat-label>
                        <mat-select formControlName="sottoSettori" placeholder="Sotto-settori"
                            [compareWith]="compareById" multiple>
                            <mat-option *ngFor="let sottoSet of arraySottoSettori"
                                [value]="sottoSet">{{sottoSet.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div appFormElement [larghezza]="6">
                        <app-separatore-label [styleSeparatore]="'small'" [spazioDestra]="0"
                            [spazioSinistra]="0"></app-separatore-label>
                    </div>

                    <div appFormElement [larghezza]="6" [riempi]="true">
                        <div class="titolo-sezione-dialog">
                            Inserisci dati sul fatturato e il numero di dipendenti
                        </div>

                        <div class="descrizione-sezione-dialog">
                            Completa i campi su fatturato e numero di dipendenti per continuare l'analisi.
                        </div>
                    </div>

                    <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Fatturato</mat-label>
                        <input matInput placeholder="Fatturato" formControlName="fatturato" type="number" appInputNumber
                            inputmode="numeric" min="0" max="999999999999" (keydown)="onKeyDown($event)" />
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                    <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                        <mat-label>Nr. Dipendenti</mat-label>
                        <input matInput placeholder="Nr. Dipendenti" formControlName="nrDipendenti" type="number"
                            appInputNumber min="0" max="999999999999" (keydown)="onKeyDownDip($event)"
                            inputmode="numeric" />
                        <mat-error appFormError></mat-error>
                    </mat-form-field>

                </form>
            </ng-scrollbar>
        </mat-step>
        <!--  ---------------------- I M P A T T I ---------- -->
        <mat-step>

            <ng-template matStepLabel>
                Impatti
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">

                    <div class="titolo-sezione-dialog">
                        Selezione degli Impatti
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Gli impatti descrivono sia le conseguenze che un tema, problema o fattore di sostenibilità hanno sull'azienda stessa, sia l'effetto che l'azienda genera sull'ambiente e sugli stakeholder.<br>
                        Per ogni tematica scelta, è necessario selezionare almeno un impatto.
                    </div>

                </div>
            </div>

            <app-selezione-impatti-materialita [isPermessoSoloRead]="isPermessoSoloRead"
                [objQuestionario]="objSurveyImpattiChk" chiaveElemento="elementi"
                [idContest]="this.formMaterialita.get('id')?.value" [stato]="survey?.stato" [isImpatto]="true"
                chiaveElementoSelezione="impatto">

            </app-selezione-impatti-materialita>

        </mat-step>

        <!-- ? CREAZIONE STAKEHOLDER INTERNI -->
        <mat-step [errorMessage]="'Peso diverso da 100'" [stepControl]="formStepInterni">
            <!-- Stakeholder selezionati '+ formStepInterni.get('nrSel')?.value -->
            <ng-template matStepLabel>
                Stakeholder
                Interni
            </ng-template>

            <div style="height: 10px;"></div>
            <app-seleziona-stakeholders [tipo]="'INTERNI'" #stInterni
                [idMaterialita]="this.formMaterialita.get('id')?.value"
                [dataUltimaConferma]="this.formMaterialita.get('dataUltimaConferma')?.value"></app-seleziona-stakeholders>
        </mat-step>

        <!-- ? CREAZIONE STAKEHOLDER ESTERNI -->
        <mat-step [errorMessage]="'Peso diverso da 100'" [stepControl]="formStepEsterni">
            <!-- , Stakeholder selezionati '+ formStepEsterni.get('nrSel')?.value -->
            <ng-template matStepLabel>
                Stakeholder Esterni
            </ng-template>
            <div style="height: 10px;"></div>
            <app-seleziona-stakeholders [tipo]="'ESTERNI'" #stEsterni
                [idMaterialita]="this.formMaterialita.get('id')?.value"
                [dataUltimaConferma]="this.formMaterialita.get('dataUltimaConferma')?.value"></app-seleziona-stakeholders>
        </mat-step>

        <!-- ? CRUSCOTTO SURVEY -->
        <mat-step [stepControl]="ctrCruscottoStepControl()">
            <ng-template matStepLabel>
                Cruscotto Survey
            </ng-template>

            <form appFormContainer [larghezza]="4" [formGroup]="formImpatti">

                <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                    <mat-label>Tipologia Punteggio</mat-label>
                    <mat-select formControlName="tipoPunteggio" placeholder="Tipologia Punteggio" value="BASE_5">
                        <mat-option value="BASE_5">Base 5</mat-option>
                        <mat-option value="BASE_10">Base 10</mat-option>
                    </mat-select>
                    <mat-error appFormError></mat-error>
                </mat-form-field>

                <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                    <mat-label>Data di Scadenza Survey</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="scadenzaSurvey">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error appFormError></mat-error>
                </mat-form-field>

            </form>


            <div class="barra-titolo-cruscotto">

                <div style="display: flex;flex-direction: column;flex: 1;">
                    <div class="titolo-sezione-dialog">Cruscotto Survey</div>

                    <div class="descrizione-sezione-dialog ">
                        Visualizza e gestisci ogni aspetto relativo alla compilazione: avanzamento, configurazione dei testi mail, invio e notifiche.
                    </div>
                </div>

                <div class="contatori">
                    <div style="text-align: center;">
                        <div class="titolo-cont">MANUALI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{manualiCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #C7A045;">{{manualiNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">MAIL</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{MailDaInviare}}</div>
                                <div class="label">da inviare</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{MailInviate}}</div>
                                <div class="label">inviate</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #C7A045;">{{Mailcompilate}}</div>
                                <div class="label">compilate</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">TOTALI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: var(--mat-color-accent);">
                                    {{totaliManuali}}</div>
                                <div class="label">manuali</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: var(--mat-color-accent);">
                                    {{totaliMail}}</div>
                                <div class="label">mail</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <ng-scrollbar>
                <div style="padding-right: 15px;">
                    <app-lista-tabellare #tabella [defaultPageSize]="4" [fnCaricamentoDati]="fnCaricamentoDati"
                        [colonne]="colonne" [maxNrButton]="1" [filtri]="filtri"
                        (bottoniListaClick)="bottoniListaClick($event)" *ngIf="this.formMaterialita.get('id')?.value">
                        <ng-container btnEnd>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="template()">
                                <mat-icon matSuffix>settings</mat-icon>
                                Config. Testi
                            </button>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="inviaTutti()"
                                [disabled]="this.formMaterialita.get('dataUltimaConferma')?.value">
                                <mat-icon matSuffix>mail</mat-icon>
                                Invia a Tutti
                            </button>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="notificaTutti()"
                                [disabled]="this.formMaterialita.get('dataUltimaConferma')?.value">
                                <mat-icon matSuffix>notifications_active</mat-icon>
                                Notifica Tutti
                            </button>

                        </ng-container>
                    </app-lista-tabellare>
                </div>
            </ng-scrollbar>
        </mat-step>

        <!-- ? ANALISI RISULTATI -->
        <mat-step>
            <ng-template matStepLabel>
                Analisi Risultati
            </ng-template>
            <ng-container>
                <!-- <ng-scrollbar> -->
                    <app-analisi-risultati
                        [idMaterialita]="this.formMaterialita.get('id')?.value"></app-analisi-risultati>
              <!--   </ng-scrollbar> -->
            </ng-container>
        </mat-step>
    </mat-stepper>

</div>

<div mat-dialog-actions class="dialog-footer-cont" *ngIf="stepper.selectedIndex !== 5">
    <div>
        <button mat-flat-button color="accent" [disabled]="stepper.selectedIndex === 0" (click)="indietro()"
            class="accent-button">Indietro</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="avantiHTML()" class="primary-button" #btnAvanti>
            Avanti
        </button>
    </div>
    <ng-container *ngIf="stepper.selectedIndex === 2 || stepper.selectedIndex === 3 ">
        <button mat-flat-button color="accent" (click)="creaCategoriaSh(stepper.selectedIndex)"
            [disabled]="formMaterialita.get('dataUltimaConferma')?.value ||isPermessoSoloRead"
            class="accent-button">Nuova
            categoria</button>
    </ng-container>
</div>

<div mat-dialog-actions class="dialog-footer-cont" *ngIf="stepper.selectedIndex === 5">
    <div>
        <button mat-flat-button color="accent" (click)="stampa()" class="accent-button">
            <mat-icon>print</mat-icon>
            Stampa</button>
    </div>
    <div>
        <button mat-flat-button color="accent" (click)="indietro()" class="accent-button">Indietro</button>
    </div>
    <div *ngIf="isPermessoSoloRead">
        <button matDialogClose mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div *ngIf="!isPermessoSoloRead">
        <button mat-flat-button mat-dialog-close color="primary" (click)="conferma()" class="primary-button" #btnAvanti>
            Conferma
        </button>
    </div>
    <div style="font-size: 14px;" *ngIf="formMaterialita.get('dataUltimaConferma')?.value">Ultima conferma :
        {{formMaterialita.get('dataUltimaConferma')?.value}}</div>
</div>