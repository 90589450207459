import { PolicyService } from './../../../../../services/policy/policy.service';
import { Component, Inject, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
    selector: 'app-dialog-crea-perimetro-app-policy',
    templateUrl: './dialog-crea-perimetro-app-policy.component.html',
    styleUrls: ['./dialog-crea-perimetro-app-policy.component.scss'],
    standalone: false
})
export class DialogCreaPerimetroAppPolicyComponent implements AfterViewInit {

  public formPerimetroPolicy = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    lineaGuidaPolicy: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaPerimetroAppPolicyComponent>,
    private policyService: PolicyService,
    private utilityService: UtilityService,

    @Inject(MAT_DIALOG_DATA) public data: {
      perimetroPolicy: any,
    }
  ) { }

  ngAfterViewInit(): void {
    if (this.data?.perimetroPolicy) {
      Object.keys(this.data.perimetroPolicy).forEach((value, index, array) => {
        this.formPerimetroPolicy.get(value)?.setValue((this.data.perimetroPolicy)[value]);
      })
    }
  }

  public salva() {

    if (this.formPerimetroPolicy.valid) {

      const id = this.formPerimetroPolicy.get('id')?.value;

      if (id) {
        return this.policyService.putPerimetroPolicy(id, this.formPerimetroPolicy.getRawValue()).subscribe(
          {
            next: () => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err.error.message,
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }
          }
        );
      } else {
        return this.policyService.postPerimetroPolicy(this.formPerimetroPolicy.getRawValue()).subscribe(
          {
            next: () => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: () => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formPerimetroPolicy.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }
}
