import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Filters, FnCaricamentoDati, FnCaricamentoStyle, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { DialogGestioneLicenzeComponent } from '../dialog/dialog-gestione-licenze/dialog-gestione-licenze.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, Location } from '@angular/common';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';
import { finalize, firstValueFrom, tap } from 'rxjs';
import { MatAccordion } from '@angular/material/expansion';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Utente } from 'src/app/services/utente/utente.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
const datePipe = new DatePipe('it-IT');
@Component({
  selector: 'app-riepilogo-licenze',
  templateUrl: './riepilogo-licenze.component.html',
  styleUrls: ['./riepilogo-licenze.component.scss'],
  standalone: false
})
export class RiepilogoLicenzeComponent implements OnInit, AfterViewInit {
  @ViewChild('tabellaPackAggiuntivi') tabellaPackAggiuntivi!: ListaTabellareComponent;
  @ViewChild('tabellaLicenzaBase') tabellaLicenzaBase!: ListaTabellareComponent;
  @ViewChild('accordion') accordion!: MatAccordion;

  private _utente: Utente | undefined;

  @Input()
  set utente(ute: Utente | undefined) {
    this._utente = ute;
  }

  get utente() {
    return this._utente;
  }
  
  public arraySettori: Settore[] = [];

  private _objDataScadenzaMax: any;
  private _codicePromotore: string | undefined;

  public colonneLicenzaBase: { [key: string]: Colonna } | undefined = undefined;

  public idSettoriPacchettoAttivo: string[] = []

  constructor(
    public dialog: MatDialog,
    private readonly location: Location,
    public licenzeService: LicenzeService,
    private readonly utilityService: UtilityService,
    private readonly settoriService: SettoriService,
  ) {
    this.isUtenteGo();
  }

  public filtriLicenzaBase: Filtri[] = [
  ];

  public colonnePackAggiuntivi: { [key: string]: Colonna } = {
    dataAttivazione: {
      title: 'Data Attivazione',
      value: (record: any) => new Date(record.dataAttivazione).toLocaleDateString('it-IT'),
      sortable: true
    },
    dataScadenza: {
      title: 'Data Scadenza',
      value: (record: any) => new Date(record.dataScadenza).toLocaleDateString('it-IT'),
      sortable: true
    },
    bilancio: {
      title: 'Bilacio',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.BILANCIO, record.acquisti?.BILANCIO)
    },
    assessment: {
      title: 'Assessment',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.AUTOVALUTAZIONE, record.acquisti?.AUTOVALUTAZIONE)
    },
    sintesi: {
      title: 'Sintesi',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.SINTESI, record.acquisti?.SINTESI)
    },
    materialita: {
      title: 'Materialita',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.MATERIALITA, record.acquisti?.MATERIALITA)
    },
    policy: {
      title: 'Policy',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.POLICY, record.acquisti?.POLICY)
    },
    piano: {
      title: 'Piano',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.PIANO, record.acquisti?.PIANO)
    },

    aziende: {
      title: 'Azienda',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.AZIENDA, record.acquisti?.AZIENDA)
    },
    storage: {
      title: 'Storage',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(this.utilityService.convertBytesToGigabytes(record.contatori?.STORAGE), this.utilityService.convertBytesToGigabytes(record.acquisti?.STORAGE)) + ' Gb'
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modificaAggiuntivo',
      buttonMostraSempre: true,
      sortable: false,
      nascondiButton: (record: any) => (record.stato === 'S')
    }
  };

  ngAfterViewInit(): void {
    // this.accordion.openAll();
  }

  ngOnInit(): void {

    let colonne: any = {

      dataAttivazione: {
        title: 'Data Attivazione',
        value: (record: any) => new Date(record.dataAttivazione).toLocaleDateString('it-IT'),
        sortable: true
      },
      dataScadenza: {
        title: 'Data Scadenza',
        value: (record: any) => new Date(record.dataScadenza).toLocaleDateString('it-IT'),
        sortable: true
      },
      bilancio: {
        title: this.isUtenteGo() ? 'Report Tematico' : 'Bilancio',
        sortable: true,
        value: (record: any) => this.getValoreLicenze(record.contatori?.BILANCIO, record.acquisti?.BILANCIO)
      },
      assessment: {
        title: 'Assessment',
        sortable: true,
        value: (record: any) => this.getValoreLicenze(record.contatori?.AUTOVALUTAZIONE, record.acquisti?.AUTOVALUTAZIONE)
      },
    }

    if (!this.isUtenteGo()) {

      colonne.sintesi = {
        title: 'Sintesi',
        sortable: true,
        value: (record: any) => this.getValoreLicenze(record.contatori?.SINTESI, record.acquisti?.SINTESI)
      }
      colonne.materialita = {
        title: 'Materialita',
        sortable: true,
        value: (record: any) => this.getValoreLicenze(record.contatori?.MATERIALITA, record.acquisti?.MATERIALITA)
      }
      colonne.policy = {
        title: 'Policy',
        sortable: true,
        value: (record: any) => this.getValoreLicenze(record.contatori?.POLICY, record.acquisti?.POLICY)
      }
      colonne.piano = {
        title: 'Piano',
        sortable: true,
        value: (record: any) => this.getValoreLicenze(record.contatori?.PIANO, record.acquisti?.PIANO)
      }
    }

    colonne.aziende = {
      title: 'Azienda',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(record.contatori?.AZIENDA, record.acquisti?.AZIENDA)
    };
    colonne.storage = {
      title: 'Storage',
      sortable: true,
      value: (record: any) => this.getValoreLicenze(this.utilityService.convertBytesToGigabytes(record.contatori?.STORAGE), this.utilityService.convertBytesToGigabytes(record.acquisti?.STORAGE)) + ' Gb'
    }
    colonne.modifica = {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modificaRinnovo',
      buttonMostraSempre: true,
      sortable: false,
      nascondiButton: (record: any) => (record.stato === 'S')
    };

    colonne.altro = {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'eliminaRinnovo',
      sortable: false,
      nascondiButton: (record: any) => (record.stato === 'S' || record.stato === 'A')
    };

    this.colonneLicenzaBase = colonne;
  }


  /**
 * Gestico lo stile della singola riga della tabella 
 * @param record riga 
 * @returns style 
 */
  public fnCaricamentoStyle: FnCaricamentoStyle = (record: any) => {

    let colore: string = 'BLACK';
    let fontStyle: string = 'normal'

    switch (record?.stato) {
      case 'S':
        colore = 'grey'
        fontStyle = 'italic'
        break;
      case 'R':
        colore = 'var(--mat-color-accent)';
        fontStyle = 'bold'
        break;
      case 'A':
        colore = 'var(--mat-color-primary)';
        fontStyle = 'bold'
        break;

      default:
        break;
    }

    return {
      color: colore,
      fontWeight: fontStyle,
      fontStyle: (record?.stato === 'S' ? 'italic' : 'normal'),
    }
  }

  getValoreLicenze(contatori?: number, acquisti?: number): string {
    const contatore = contatori ?? 0;
    const acquistato = acquisti ?? 0;

    const differenza = acquistato - contatore;

    // Arrotonda il risultato a due cifre decimali
    const differenzaArrotondata = Number(differenza.toFixed(1));
    const acquistatoArrotondato = Number(acquistato.toFixed(1));

    return `${differenzaArrotondata}/${acquistatoArrotondato}`;
  }
  getDataScadenzaMax(listaPacchetti: any) {
    if (listaPacchetti.length) {
      this._objDataScadenzaMax = listaPacchetti.reduce((a: any, b: any) => {
        const dateA = new Date(a.dataScadenza);  // Usa a.dataScadenza
        const dateB = new Date(b.dataScadenza);  // Usa b.dataScadenza
        return dateA.getTime() > dateB.getTime() ? a : b; // Restituisci l'oggetto intero
      });
    }
  }


  public fnCaricamentoDatiLicenzaBase: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.licenzeService.getPacchettiBase(page, pageSize, ricerca, filters, sortBy, this.utente?.codiceLicenza, this.utente?.id)
      .pipe(
        tap((esito: any) => {
          this.getDataScadenzaMax(esito.content);
          this._codicePromotore = esito.content[0]?.codicePromotore;

          this.idSettoriPacchettoAttivo = esito.content.find((pacchetto: any) => pacchetto.stato === 'A')?.idSettori;

          this._caricaSettori();

        })
      )

  };

  public fnCaricamentoDatiPackAggiuntivi: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.licenzeService.getPacchettiAggiuntivi(page, pageSize, ricerca, filters, sortBy, this.utente?.codiceLicenza, this.utente?.id);
  };

  aggiungiPacchettoAggiuntivo() {
    this.apriDialogCreaLicenza('aggiuntivo')
  }

  aggiungiRinnovo(record?: any) {
    this.apriDialogCreaLicenza('rinnovo', record)
  }

  private apriDialogCreaLicenza(tipo: string, record?: any) {

    if (!this.utente?.codiceLicenza || !this.utente?.id) {
      console.error('ERRORE - CODICE LICENZA O ID UTENTE MANCANTI');
      return;
    }

    let data: {
      tipo: string,
      idUtente: string,
      codiceLicenza: string,
      record: any;
      dataScadenzaMax: string,
      codicePromotore: string | undefined
      isUtenteGO: boolean;
      idSettoriPacchettoAttivo: string[];
    };


    data = {
      codiceLicenza: this.utente?.codiceLicenza,
      idUtente: this.utente?.id,
      tipo: tipo,
      record: record,
      dataScadenzaMax: this._objDataScadenzaMax?.dataScadenza,
      codicePromotore: this._codicePromotore,
      isUtenteGO: this.isUtenteGo(),
      idSettoriPacchettoAttivo: this.idSettoriPacchettoAttivo
    }

    const dialogCreaLicenza = this.dialog.open(DialogGestioneLicenzeComponent, {
      data: data,
      panelClass: 'dialog-container',
      disableClose: false,
      width: 'auto',
      height: 'auto',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogCreaLicenza.afterClosed().subscribe((result) => {

      if (tipo === 'rinnovo') {
        this.tabellaLicenzaBase.caricaDati();

      } else {
        this.tabellaPackAggiuntivi.caricaDati();
      }
    });
  }

  goBack() {
    this.location.back();
  }


  public bottoniListaClick(event: BottoniListaEvent) {
    if (event.id === 'modificaRinnovo') {
      this.apriDialogCreaLicenza('rinnovo', event.data);
    } else if (event.id === 'eliminaRinnovo') {
      this.eliminaLicenza(event.data);
    } else if (event.id === 'modificaAggiuntivo') {
      this.apriDialogCreaLicenza('aggiuntivo', event.data);
    } else {
      console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }

  }

  async eliminaLicenza(record: any) {

    if (!this.utente?.id) {
      console.error('ERRORE - ID UTENTE NON TROVATO');
      return;
    }

    const dialog = await this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Il pacchetto base sarà eliminato. Continuare?',
      bottoni: [
        {
          nome_btn: 'Annulla',
          id_btn: 'N'
        },
        {
          nome_btn: 'Ok',
          id_btn: 'S'
        },
      ]
    });

    const valDialog = await firstValueFrom(dialog.beforeClosed());

    if (valDialog === 'N') {
      return;
    }

    try {

      await firstValueFrom(this.licenzeService.deleteLicenzaBase(this.utente?.id, record.codiceLicenza, record.codicePacchetto));

      this.tabellaLicenzaBase.caricaDati();

    } catch (error: any) {
      console.error(error);

      await this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Non è stato possibile eliminare la licenza.<br>' + (error?.error?.message || 'Errore sconosciuto'),
        bottoni: [
          {
            nome_btn: 'Ok'
          },
        ]
      });


    }

  }



  isUtenteGo() {
    if (this.utente?.ruolo?.roleName) {
      const ruoliGo = ['collaboratore_go', 'utente_read_only_go', 'utente_premium_go']

      if (ruoliGo.includes(this.utente?.ruolo?.roleName)) {
        return true;
      }
    }
    return false;
  }

  aggiornaLicenze() {
    this.tabellaLicenzaBase.caricaDati();
    this.tabellaPackAggiuntivi.caricaDati();
  }
  private _caricaSettori() {


    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'asc'
    }]).subscribe({
      next: (risp) => {
        this.arraySettori = risp?.content?.filter((settore: any) =>
          this.idSettoriPacchettoAttivo.includes(settore.id)
        );
console.log(this.arraySettori);

      },
      error: (err) => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei settori',
          bottoni: [{ nome_btn: 'Ok' }]
        });

      }
    })
  }

  

}
