import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Injectable({
  providedIn: 'root'
})
export class StandardRendicontazioneWebService {

  private readonly nomeWs = 'stdr';

  constructor(
    private readonly webService: WebService
  ) { }

  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/delete', { id: idRiga })
  }

  public post(stdr: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/create', stdr);
  }

  public put(id: string, stdr: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/update', stdr, {
      id
    });
  }

}
