import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { TemaService } from 'src/app/services/tema/tema.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: false
})
export class ResetPasswordComponent implements OnDestroy {

  public hide = true;
  public hideConfirm = true;
  public logoPath: string = '';
  public bgPath: string = '';

  public criteri: string[] = [];

  private _loading = true;

  get loading() {
    return this._loading;
  }

  private _loadingUpdate = false;

  get loadingUpdate() {
    return this._loadingUpdate;
  }

  private _msgError: string | undefined;

  get msgError() {
    return this._msgError;
  }

  private _idUtente: string | undefined;

  get idUtente() {
    return this._idUtente;
  }

  private _completato = false;

  get completato() {
    return this._completato;
  }

  private _requestError?: string;

  get requestError() {
    return this._requestError;
  }

  private _token?: string;

  get token() {
    return this._token;
  }

  public formPassword = new FormGroup({
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required/* , this._validatorePwd() */]
    }),
    confirm_password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required/* , this._validatorePwd() */]
    })
  },[this._validatorePwd()])

  private _validatorePwd(): ValidatorFn {
    return (control: AbstractControl) => {
      const password = (control as FormGroup)?.controls['password']?.value;
      const confirm_password = (control as FormGroup)?.controls['confirm_password']?.value;
      if (password && confirm_password && password != confirm_password) {
        return { password: true };
      }
      return null;
    };
  }

  private _passwordSub?: Subscription;
  private _confirmPasswordSub?: Subscription;

  constructor(
    private readonly temaService: TemaService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly utenteService: UtenteService
  ) {

    this.temaService.temaUpdated.subscribe(() => {
      this.logoPath = temaService.logoPath;
      this.bgPath = temaService.bgPath;
    });

    this._passwordSub = this.formPassword.get('password')?.valueChanges.subscribe((val) => {
      this._requestError = undefined;
    });

    this._confirmPasswordSub = this.formPassword.get('confirm_password')?.valueChanges.subscribe((val) => {
      this._requestError = undefined;
    });


    this.route.queryParamMap.subscribe((queryParamMap) => {

      this._msgError = undefined;

      this._token = queryParamMap.get('tk') || undefined;

      if (!this._token) {
        this._setErrore("Richiesta non valida");
        return;
      }

      forkJoin({
        criteri: this.utenteService.getCriteriPassword(),
        check: this.utenteService.checkResetPasswordToken(this._token)
      }).subscribe({
        next: (result) => {

          console.log('CRITERI : ', result.criteri.criteri);

          this.criteri = (result.criteri.criteri || []).sort();

          console.log('CHECK TOKEN : ', result.check);

          if (result.check.idUtente) {
            this._idUtente = result.check.idUtente;
          } else {
            this._setErrore("Richiesta non valida");
          }
          this._loading = false;



        },
        error: (err) => {
          console.error('ERRORE CHECK TOKEN : ', err);
          this._setErrore(err?.error?.message || 'Errore sconosciuto. Riprovare più tardi.');
        }
      })


    });
  }

  ngOnDestroy(): void {
    console.log('DISTRUGGO');
    this._passwordSub?.unsubscribe();
    this._confirmPasswordSub?.unsubscribe();
  }

  eseguiUpdatePwd() {
    console.log(this.formPassword.getRawValue());

    if (!this._token) {
      console.error('NESSUN TOKEN');
      return;
    }

    this._requestError = '';
    this._loadingUpdate = true;

    this.utenteService.updatePassword(this._token, this.formPassword.get('password')!.value).subscribe({
      next: (result) => {
        console.log('UPD PASSWORD : ', result);

        this._loadingUpdate = false;

        this._completato = true;

      },
      error: (err: any) => {
        console.error('ERRORE UPD PASSWORD : ', err);
        this._requestError = (err?.error?.message || 'Errore sconosciuto. Riprovare più tardi.');
        this._loadingUpdate = false;
      }
    })

  }

  private _setErrore(errore: string) {
    this._loading = false;
    this._msgError = errore;
  }
}
