import { Component, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import Annotation from 'chartjs-plugin-annotation';

enum Mesi {
  Gennaio = 'Gen',
  Febbraio = 'Feb',
  Marzo = 'Mar',
  Aprile = 'Apr',
  Maggio = 'Mag',
  Giugno = 'Giu',
  Luglio = 'Lug',
  Agosto = 'Ago',
  Settembre = 'Set',
  Ottobre = 'Ott',
  Novembre = 'Nov',
  Dicembre = 'Dic',
}

interface filtroPeriodo {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
    standalone: false
})
export class LineChartComponent {
  private newLabel? = 'New label';
  public periodoSelezionato: string = '';

  constructor() {
    Chart.register(Annotation);
  }

  periodi: filtroPeriodo[] = [
    { value: '2', viewValue: 'Ultimi 2 mesi' },
    { value: '3', viewValue: 'Ultimi 3 mesi' },
    { value: '6', viewValue: 'Ultimi 6 mesi' },
    { value: '12', viewValue: 'Ultimo anno' },
  ];

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [15, 10, 14, 19, 15, 24, 18, 8, 2, 5, 17, 10],
        label: 'Energia Rinovabile',
        backgroundColor: 'rgba(0, 116, 154,0.1)',
        
        borderColor: 'rgba(0, 116, 154,1)',
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(0, 116, 154,1)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(0, 116, 154,1)',
        pointRadius: 5,
        pointBorderWidth: 2,
        fill: 'origin',
      },
      {
        data: [18, 8, 16, 13, 18, 10, 15, 13, 17, 19, 16, 24],
        label: 'Riduzione delle Emissioni',
        backgroundColor: 'rgba(120,173,43,0.3)',
        borderColor: 'rgba(120,173,43,1)',
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(120,173,43,1)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(120,173,43,0.8)',
        pointRadius: 5,
        pointBorderWidth: 2,
        fill: 'origin',
      },
    ],
    /* labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug',], */
    labels: Object.values(Mesi).slice(0, 6),
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y: {
        position: 'left',
        grid: {
          color: 'rgba(255,0,0,0.05)',
        },
        ticks: {
          color: 'grey',
        },
      },
      x: {
        grid: {
          color: 'rgba(255,0,0,0.05)',
        },
        ticks: {
          color: 'grey',
        },
      },
    },

    plugins: {
      legend: { display: true },
    },
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    
  }

  getMonths(intervaloMesi: string): void {
    let cvtIntervaloMesi: number = parseInt(intervaloMesi);
    const mesi: string[] = Object.values(Mesi);

    cvtIntervaloMesi = Math.min(cvtIntervaloMesi, mesi.length);
    const mesiSelezionati = mesi.slice(0, cvtIntervaloMesi);

    this.lineChartData.labels = mesiSelezionati;
    this.chart?.update();
  }
}
