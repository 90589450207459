import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ListaTabellareComponent, Filtri, Colonna, BottoniListaEvent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { BilancioService, ConfigBilanci } from 'src/app/services/bilancio/bilancio.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaBilancioSostenibilitaComponent } from './dialog/dialog-crea-bilancio-sostenibilita/dialog-crea-bilancio-sostenibilita.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { map, Subscription, tap } from 'rxjs';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';
import { TemaService } from 'src/app/services/tema/tema.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { SettoriService } from 'src/app/services/settori/settori.service';

@Component({
    selector: 'app-bilancio-sostebilita',
    templateUrl: './bilancio-sostebilita.component.html',
    styleUrls: ['./bilancio-sostebilita.component.scss'],
    standalone: false
})
export class BilancioSostebilitaComponent implements AfterViewInit {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  private azienda: Azienda | undefined = undefined;
  public isUtenteGo: boolean = false;
  private configBilanci: ConfigBilanci | undefined = undefined;
  private nrBilanciAzienda: number = 0;
  public disableNuovo = true;
  public filtri: Filtri[] = [
    {
      titolo: 'Anno',
      forControlName: 'anno',
      input: 'text',
    },
    {
      forControlName: 'settori',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true,
      larghezza: 1
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    anno: {
      title: 'Anno',
      value: 'anno'
    },
    settore: {
      title: 'Settore',
      value: (record: any) => (record?.settori ? record?.settori[0]?.titolo : ''),
    },
    risultatiImpegni: {
      title: 'Impegni',
      value: (record: any) => (record?.compilazioni?.impegni?.compilati || 0) + '/' + (record?.compilazioni?.impegni?.totali || 0)
    },
    risultatiAzioni: {
      title: 'Azioni',
      value: (record: any) => (record?.compilazioni?.azioni?.compilati || 0) + '/' + (record?.compilazioni?.azioni?.totali || 0)
    },
    risultatiKpi: {
      title: 'Kpi',
      value: (record: any) => (record?.compilazioni?.kpi?.compilati || 0) + '/' + (record?.compilazioni?.kpi?.totali || 0)
    },
    modifica: {
      type: 'button',
      buttonIcon: this.isPermessoSoloRead() ? 'visibility' : 'edit',
      title: this.isPermessoSoloRead() ? 'Visualizza' : 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      sortable: false,
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => this.nascondiBottoneElimina(record),
      buttonMostraSempre: true

    },
  };

  private _subAziendaCorrente: Subscription | undefined;
  private _subTema: Subscription | undefined;

  constructor(
    public dialog: MatDialog,
    private readonly utilityService: UtilityService,
    private readonly aziendaService: AziendaService,
    private readonly bilancioService: BilancioService,
    public utenteService: UtenteService,
    private readonly licenzeService: LicenzeService,
    private readonly temaService: TemaService,
    private readonly settoriService: SettoriService,

  ) { }


  nascondiBottoneElimina(record: any) {
    if (this.utenteService.infoUtente?.isDeveloper) {
      return false; //non nascondere
    }
    if ((record.stato === 'PUBBLICATO' || !this.utenteService.isPermessoAttivo('DELETE_BILANCIO'))) {
      return true //nascondere
    }
    return false
  }

  isPermessoSoloRead() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_BILANCIO');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_BILANCIO')
    return (!isEdit && !isCreate)
  }

  ngAfterViewInit() {
    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {

      let cambiato = false;

      if (this.azienda && azienda && this.azienda.id !== azienda.id) {
        cambiato = true;
      }

      this.azienda = azienda;

      if (cambiato) {
        this.tabella?.caricaDati();
      }
    });

    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
      } else {
        this.isUtenteGo = false;
      }
    })

    this.bilancioService.getConfigBilanci().subscribe({
      next: (esito) => {
        this.configBilanci = esito;
        console.log(this.configBilanci)
      },
      error: (er) => {
        console.error(er)
      }
    })
  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
    this._subTema?.unsubscribe();
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    return this.bilancioService.getBilancio(page, pageSize, ricerca, filters, sortBy).pipe(
      tap(result => {
        this.nrBilanciAzienda = result?.content?.length;
        if (this.nrBilanciAzienda >= 1 && this.isUtenteGo) {
          this.disableNuovo = true;
        } else {
          this.disableNuovo = false;
        }

      })

    );
  };

  /**
  * Metodo di modifica o creazione nuova bilancio 
  * @param record 
  */
  crea(record?: any) {
    if (!record) {
      this.spinnerOver.show();



      this.licenzeService.getUserHasLicense(this.isUtenteGo ? 'Report' : 'Bilancio').subscribe({
        next: (esito) => {
          this.spinnerOver.hide();
          this.apriDialogCreaBilancio(record);
        },
        error: (err) => {

          this.spinnerOver.hide();

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message,
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    } else {
      this.apriDialogCreaBilancio(record);
    }
  }

  private apriDialogCreaBilancio(record: any) {
    const dialogCreaBilancio = this.dialog.open(DialogCreaBilancioSostenibilitaComponent, {
      data: {
        bilancio: record,
        configBilancio: this.configBilanci
      },
      panelClass: ['dialog-container'],
      disableClose: false,
      width: '80%',
      height: '99%',
      autoFocus: false,
    });

    dialogCreaBilancio.afterClosed().subscribe((result) => {
      // Ricarica i dati della tabella dopo la chiusura del dialogo
      this.tabella.caricaDati();
    });
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'elimina':
        this._elimina(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
  * Funzione per eliminare una riga da una lista tabellare 
  * @param idRiga id della riga da eliminare 
  */
  private _elimina(bilancio: any) {
    let descrizioneElimina: string;
    let titolo: string;
    descrizioneElimina = '<div> <p><strong> Si prega di notare che questa operazione eliminerà anche tutte le compilazioni raccolte associate.</strong></p> <ul> <li>L’eliminazione è definitiva e <strong>NON può essere annullata</strong>.</li> <li>Tutte le informazioni e le compilazioni raccolte saranno perse <strong>permanentemente</strong>.</li></ul><p><strong>Sei sicuro di voler continuare?</strong></p>'
    titolo = 'Eliminazione Dati Irreversibile'


    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizioneElimina,
      fontWeight: '500',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.bilancioService.deleteBilancio(bilancio?.id).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                  this.spinnerOver.hide()
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: 'Impossibile eliminare: ' + (err?.error?.message || 'Errore sconosciuto'),
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                  this.spinnerOver.hide()
                }
              }
            );

          }
        }]
    });

  }

}
