import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogInviaMailComponent } from './dialog-invia-mail/dialog-invia-mail.component';
import { Router } from "@angular/router";
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { catchError, finalize, firstValueFrom, Observable, of, switchMap, take } from 'rxjs';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { Utente, UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
    selector: 'app-dialog-crea-template-mail',
    templateUrl: './dialog-crea-template-mail.component.html',
    styleUrls: ['./dialog-crea-template-mail.component.scss'],
    standalone: false
})
export class DialogCreaTemplateMailComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public isInvioMail: boolean = false;
  public formTemplateMail = new FormGroup({

    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    mailInvio: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    mailNotifica: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    landingPage: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaTemplateMailComponent>,
    private readonly materialitaService: MaterialitaService,
    private readonly sintesiService: SintesiService,
    private readonly utilityService: UtilityService,
    private readonly bilancioService: BilancioService,
    private readonly policyService: PolicyService,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly aziendaService: AziendaService,
    private readonly pianoService: PianoService,
    private readonly utenteService: UtenteService,
    @Inject(MAT_DIALOG_DATA) public data: {
      idMaterialita?: string,
      idSintesiSostenibilita?: string,
      idBilancioSostenibilita?: string,
      idPolicySostenibilita?: string,
      idPianoSostenibilita?: string,
      idSurvey?: string,
      isPermessoSoloRead?: boolean,

    }) { }


  ngAfterViewInit(): void {
    this.datiIniziali();

    if (this.data?.isPermessoSoloRead) {
      this.formTemplateMail.disable();
    }

    this.isInvioMail = this.utenteService.isPermessoAttivo('INVIO_MAIL')
  }


  /**
   *  Metodo di salvattaggio da html form
   */
  public salvaFromHTML() {
    this.spinnerOver.show()
    this._salva()
      .pipe(
        finalize(() => this.spinnerOver.hide()))
      .subscribe(
        (esito) => {
          if (esito) {
            this.dialogRef.close();
          }
        }
      )
  }

  /**
   *  Metodo che salva i campi dell'oggetto DialogCreaTemplateMailComponent
   * @returns  Observable<boolean>
   */
  private _salva(): Observable<boolean> {

    if (!this.formTemplateMail.valid) {

      Object.values(this.formTemplateMail.controls).forEach(control => {
        control.markAsTouched();
      });
      this.utilityService.opneSnackBar('Compila tutti i campi prima di eseguire un\'azione', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });

      return of(false);
    }

    // Determino il servizio da utilizzare 
    let serviceCall: Observable<any> = of();

    if (this.data.idMaterialita) {
      serviceCall = this.materialitaService.putTemplateMail(this.data.idMaterialita, this.formTemplateMail.getRawValue());
    } else if (this.data.idSintesiSostenibilita) {
      serviceCall = this.sintesiService.putTemplateMail(this.data.idSintesiSostenibilita, this.formTemplateMail.getRawValue());
    } else if (this.data.idBilancioSostenibilita) {
      serviceCall = this.bilancioService.putTemplateMail(this.data.idBilancioSostenibilita, this.formTemplateMail.getRawValue());
    } else if (this.data.idPolicySostenibilita) {
      serviceCall = this.policyService.putTemplateMail(this.data.idPolicySostenibilita, this.formTemplateMail.getRawValue());
    } else if (this.data.idPianoSostenibilita) {
      serviceCall = this.pianoService.putTemplateMail(this.data.idPianoSostenibilita, this.formTemplateMail.getRawValue());
    }

    // Effettuao la chiamata al servizio 
    if (serviceCall) {
      return serviceCall.pipe(
        switchMap(() => {
          // Se la chiamata ha successo, mostra una notifica, chiude il dialogo e restituisce true
          this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
          return of(true);
        }),
        catchError((err) => {
          // Se si verifica un errore, mostra un dialogo di avviso e restituisce false
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio del template',
            bottoni: [{ nome_btn: 'Chiudi' }]
          });
          return of(false);
        })
      );
    }
    return of(false);
  }

  /**
   * Restituisce un oggetto di tipo DialogInviaMailComponent
   */
  public async testMailInvio() {

    try {
      if (this.data?.isPermessoSoloRead) {
        return this._dialogInviaMail(this.formTemplateMail.controls.mailInvio.value);
      }

      const salvato = await firstValueFrom(this._salva())
      if (salvato) {
        this._dialogInviaMail(this.formTemplateMail.controls.mailInvio.value);
      };

    } catch (error) {
      console.error(error);

    }
  }

  /**
   *  Restituisce un oggetto di tipo
   */
  public async testMailNotifica() {
    try {

      if (this.data?.isPermessoSoloRead) {
        return this._dialogInviaMail(this.formTemplateMail.controls.mailNotifica.value);
      }

      const salvato = await firstValueFrom(this._salva())
      if (salvato) {
        this._dialogInviaMail(this.formTemplateMail.controls.mailNotifica.value);
      };

    } catch (error) {
      console.error(error);

    }
  }

  /**
   *  Se il salvataggio è andato a buon fine apre una nuova tab con l'anteprima
   */
  public async anteprima() {

    try {
      if (this.data?.isPermessoSoloRead) {
        return this._openNewTab();
      }

      const salvato = await firstValueFrom(this._salva())
      if (salvato) {
        this._openNewTab();
      }
    } catch (error) {
      console.error(error);

    }
  }

  /**
   *  Dialog inserimento mail invio
   */
  private _dialogInviaMail(messaggio: string) {
    const dialogInviaMail = this.dialog.open(DialogInviaMailComponent, {
      data: {
        idMaterialita: this.data.idMaterialita,
        idSintesiSostenibilita: this.data.idSintesiSostenibilita,
        idBilancioSostenibilita: this.data.idBilancioSostenibilita,
        idPolicySostenibilita: this.data.idPolicySostenibilita,
        idPianoSostenibilita: this.data.idPianoSostenibilita,
        messaggio: messaggio,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '35%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogInviaMail.afterClosed().subscribe((result) => {

    })
  }

  /**
   *  Apri una nuova tab con l'anteprima
   */
  private _openNewTab() {

    this.aziendaService.azienda.then((azienda) => {
      const idAzienda = azienda?.id;
      let url: string = "";
      if (this.data?.idMaterialita && this.data?.idSurvey) {
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/compilazione-survey'], { queryParams: { id: this.data?.idMaterialita, idA: idAzienda, idS: this.data?.idSurvey } })
        );
      } else if (this.data.idSintesiSostenibilita) {
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/compilazione-sintesi-sostenibilita'], { queryParams: { id: this.data?.idSintesiSostenibilita, idA: idAzienda } })
        );
      } else if (this.data.idBilancioSostenibilita) {
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/compilazione-bilancio-sostenibilita'], { queryParams: { idB: this.data?.idBilancioSostenibilita, idA: idAzienda } })
        );
      } else if (this.data.idPolicySostenibilita) {
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/compilazione-policy-sostenibilita'], { queryParams: { idP: this.data?.idPolicySostenibilita, idA: idAzienda } })
        );
      } else if (this.data.idPianoSostenibilita) {
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/compilazione-piano-sostenibilita'], { queryParams: { idP: this.data?.idPianoSostenibilita, idA: idAzienda } })
        );
      }

      window.open(url, '_blank');
    });

  };

  /**
    * Funzione che prende i dati dal service e mappa sui campi in input 
    */
  private datiIniziali() {
    this.spinnerOver.show();
    if (this.data.idMaterialita) {

      this.materialitaService.getTemplateMail(this.data.idMaterialita)
        .pipe(finalize(() => this.spinnerOver.hide()))
        .subscribe(
          {
            next: (template) => {

              Object.keys(template).forEach((value, index, array) => {
                this.formTemplateMail.get(value)?.setValue((template as any)[value]);
              })
            },
            error: (err: any) => {
            }
          }
        );

    } else if (this.data.idSintesiSostenibilita) {
      this.sintesiService.getTemplateMail(this.data.idSintesiSostenibilita)
        .pipe(finalize(() => this.spinnerOver.hide()))
        .subscribe(
          {
            next: (template) => {

              Object.keys(template).forEach((value, index, array) => {
                this.formTemplateMail.get(value)?.setValue((template as any)[value]);
              })
            },
            error: (err) => {
            }
          }
        )
    } else if (this.data.idBilancioSostenibilita) {
      this.bilancioService.getTemplateMail(this.data.idBilancioSostenibilita)
        .pipe(finalize(() => this.spinnerOver.hide()))
        .subscribe(
          {
            next: (template) => {

              Object.keys(template).forEach((value, index, array) => {
                this.formTemplateMail.get(value)?.setValue((template as any)[value]);
              })
            },
            error: (err) => {
            }
          }
        )
    } else if (this.data.idPolicySostenibilita) {
      this.policyService.getTemplateMail(this.data.idPolicySostenibilita)
        .pipe(finalize(() => this.spinnerOver.hide()))
        .subscribe(
          {
            next: (template) => {

              Object.keys(template).forEach((value) => {
                this.formTemplateMail.get(value)?.setValue((template as any)[value]);
              })
            },
            error: (err) => {
            }
          }
        )
    } else if (this.data.idPianoSostenibilita) {
      this.pianoService.getTemplateMail(this.data.idPianoSostenibilita)
        .pipe(finalize(() => this.spinnerOver.hide()))
        .subscribe(
          {
            next: (template) => {

              Object.keys(template).forEach((value) => {
                this.formTemplateMail.get(value)?.setValue((template as any)[value]);
              })
            },
            error: (err) => {
            }
          }
        )
    } else {
      this.spinnerOver.hide();
    }
  }


  get testoTemplate() {
    if (this.data.idMaterialita) {
      return 'Survey';
    } else if (this.data.idSintesiSostenibilita) {
      return 'Sintesi';
    } else if (this.data.idBilancioSostenibilita) {
      return 'Bilancio';
    } else if (this.data.idPolicySostenibilita) {
      return 'Policy';
    } else if (this.data.idPianoSostenibilita) {
      return 'Piano';
    } else {
      return '';
    }
  }

}
