import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TemaService } from 'src/app/services/tema/tema.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
    selector: 'app-password-dimenticata',
    templateUrl: './password-dimenticata.component.html',
    styleUrls: ['./password-dimenticata.component.scss'],
    standalone: false
})
export class PasswordDimenticataComponent {

  public logoPath: string = '';
  public bgPath: string = '';

  public loading = false;
  public completato = false;

  public requestError: string | undefined = undefined;

  public formLogin = new FormGroup({
    username: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator]
    })
  });

  constructor(
    private utenteService: UtenteService,
    private temaService: TemaService
  ) {
    this.temaService.temaUpdated.subscribe(() => {
      this.logoPath = temaService.logoPath;
      this.bgPath = temaService.bgPath;
    })

  }

  avviaReset() {

    this.requestError = undefined;
    const username = this.formLogin.get('username')?.value;

    this.formLogin.disable();

    if (username) {

      this.loading = true;

      this.utenteService.richiediResetPassword(username).subscribe({
        next: (result) => {

          this.loading = false;
          this.completato = true;
        },
        error: (err) => {
          this.loading = false;
          console.error(err);

          if (err?.status === 404) {
            this.requestError = 'Questo account non esiste nei nostri sistemi. Controlla che lo username sia corretto e riprova.';
          } else {
            this.requestError = 'Si è verificato un errore. Riprova più tardi.';
          }

          this.formLogin.enable();
        }
      })
    }
  }

}
