import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickEvent, HoverRatingChangeEvent, RatingChangeEvent } from 'angular-star-rating'
import { NgStarRatingService, RatingTematica } from 'src/app/services/ng-star-rating/ng-star-rating.service';

@Component({
    selector: 'app-my-rating-star',
    templateUrl: './my-rating-star.component.html',
    styleUrls: ['./my-rating-star.component.scss'],
    standalone: false
})
export class MyRatingStarComponent {
  private _numeroStelle: number = 0;
  private _idRating: string = '';
  public ratingSelezionato: RatingTematica[] = [];
  public colorePunteggio: string = 'var(--mat-color-accent)';
  public coloreRatingChange: string = 'var(--mat-color-accent)';
  public punteggio: number = 0;
  public _valoreAtribuito: number = 0;
  public punteggioChange: number = 0;
  public _idSurveyCompilato: string = '';

  @Input()
  set valoreAtribuito(valoreAtribuito: number) {
    this._valoreAtribuito = valoreAtribuito;
  }
  get valoreAtribuito() {
    return this._valoreAtribuito;
  }
  @Input()
  set numeroStelle(numeroStelle: number) {
    this._numeroStelle = numeroStelle;
  }
  get numeroStelle() {
    return this._numeroStelle;
  }

  @Input()
  set idRating(idRating: string) {
    this._idRating = idRating;
  }
  get idRating() {
    return this._idRating;
  }

  @Input()
  set idSurveyCompilato(idSurveyCompilato: string) {
    this._idSurveyCompilato = idSurveyCompilato;
  }
  get idSurveyCompilato() {
    return this._idSurveyCompilato;
  }


  @Output()
  valueChangePunteggio = new EventEmitter<number>();

  onClickResult: ClickEvent | undefined;
  onHoverRatingChangeResult: HoverRatingChangeEvent | undefined;
  onRatingChangeResult: RatingChangeEvent | undefined;

  constructor(public ngStarRating: NgStarRatingService) {

  }

  onClick = ($event: ClickEvent) => {
    this.onClickResult = $event;
    this.popolaArrayRating($event)
  };

  onRatingChange = ($event: RatingChangeEvent) => {
    this.onRatingChangeResult = $event;
    this.coloreRatingChange = this._colorePunteggio(this.onRatingChangeResult.rating);
    this.colorePunteggio = this._colorePunteggio(this.onRatingChangeResult.rating);
    this.punteggio = this.onRatingChangeResult.rating;
    this.punteggioChange = this.onRatingChangeResult.rating;
    this.valueChangePunteggio.emit(this.punteggioChange ? this.punteggioChange : 0);
  };

  onHoverRatingChange = ($event: HoverRatingChangeEvent) => {
    this.onHoverRatingChangeResult = $event;
    this.colorePunteggio = this._colorePunteggio(this.onHoverRatingChangeResult.hoverRating);
    this.punteggio = this.onHoverRatingChangeResult.hoverRating;
  };

  /**
   * Popolo l'array con i punteggi 
   * @param $event 
   */
  public popolaArrayRating($event: ClickEvent) {
    let found = false;
    this.ratingSelezionato = this.ngStarRating.getRatingSelezionato();

    //controllo se l'id tematica è stato già inserito cosichè aggiorno solamente il valore rating
    for (let elem of this.ratingSelezionato) {
      if (elem.id === this._idRating) {
        // Aggiorna l'elemento
        elem.rating = $event.rating;
        found = true;
        break;
      }
    }

    // Se l'id tematica non esiste, aggiungo un nuovo elemento
    if (!found) {
      this.ratingSelezionato?.push({
        id: this._idRating,
        rating: $event.rating
      });
    }

    // Aggiorna il rating selezionato
    if (this.ratingSelezionato) {
      this.ngStarRating.setRatingSelezionato(this.ratingSelezionato);
    }
  }

  // Funzione per calcolare il colore in base al rating
  private _colorePunteggio(rating: number): string {
    if (this.numeroStelle === 10) {

      if (rating > 6) {
        return "#7ed321"; //verde
      } else if (rating > 3) {
        return "#ffc058"; //giallo
      } else {
        return "#f03c56"; //rosso
      }
    } else {

      if (rating > 3) {
        return "#7ed321";
      } else if (rating > 1) {
        return "#ffc058";
      } else {
        return "#f03c56";
      }
    }
  }
  // Quando il mouse esce dall'area di hover, ripristina il colore del rating change
  onHoverRatingLeave = () => {
    if ((this.onClickResult?.rating && this.onClickResult?.rating > 0) || this.valoreAtribuito) {
      this.colorePunteggio = this.coloreRatingChange;
    } else {
      this.colorePunteggio = 'var(--mat-color-accent)';
    }
    this.punteggio = this.punteggioChange;

  };
}
