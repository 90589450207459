import { Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip/custom-tooltip.component';
import { BehaviorSubject, Subscription } from 'rxjs';

@Directive({
    selector: '[mytooltip],[mytooltipText]',
    standalone: false
})
export class CustomTooltipDirective {
  private _posizioneSubject = new BehaviorSubject<string>('right');
  private _offsetSubject = new BehaviorSubject<number>(10);
  private _sizeContainerSubject = new BehaviorSubject<number>(400);
  private _sizeContainerSubscription: Subscription | undefined;
  private _posizioneSubscription: Subscription | undefined;
  private _offsetSubscription: Subscription | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private cfr: ViewContainerRef
  ) {
  }

  ngOnDestroy() {
    this._posizioneSubscription?.unsubscribe();
    this._sizeContainerSubscription?.unsubscribe();
    this._offsetSubscription?.unsubscribe();

  }

  @Input()
  set mytooltip(val: TemplateRef<any>) {
    this.createTooltipComponent(val);
  }

  @Input()
  set mytooltipText(text: string) {
    this.createTooltipComponent(text);
  }

  @Input()
  set mytooltipPosizione(posizione: string) {
    this._posizioneSubject.next(posizione);
  }

  @Input()
  set mytooltipOffset(offset: number) {
    this._offsetSubject.next(offset);
  }

  @Input()
  set mytooltipSizeContainer(sizeContainer: number) {
    this._sizeContainerSubject.next(sizeContainer);
  }

  private createTooltipComponent(contents: string | TemplateRef<any>) {

    const compRef = this.cfr.createComponent(CustomTooltipComponent)
    //posizione
    this._posizioneSubscription = this._posizioneSubject.subscribe((posizione) => {
      compRef.setInput('posizione', posizione);
    });
    //OFFSET
    this._offsetSubscription = this._offsetSubject.subscribe((offset) => {
      compRef.setInput('offsetTooltip', offset)
    })
    //dimensione Container
    this._sizeContainerSubscription = this._sizeContainerSubject.subscribe((sizeContainer) => {
      compRef.setInput('sizeContainer', sizeContainer)
    })

    compRef.instance.trigger = this.templateRef;

    if (contents instanceof TemplateRef) {
      compRef.instance.contentTmpl = contents;
    } else {
      compRef.instance.contentText = contents;
    }

  }
}
