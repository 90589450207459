import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Injectable({
  providedIn: 'root'
})
export class InformativaAziendaleWebService {

  private nomeWs = 'informativa-aziendale';
  private lang = 'it-IT';

  constructor(private webService: WebService) { }

  /**
    * Funzione per selezionare le righe da far vedere nella grid
    * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
    * @param page pagina
    * @param pageSize record per pagina
    * @param ricerca risultato per ricerca 
    * @param filters valori da filtrare 
    * @param sortBy sort asc desc
    * @returns lista di righe
    */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/lang/' + this.lang + '/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public getBozza() {
    return this.webService.doGet('/api/v1/lang/' + this.lang + '/' + this.nomeWs + '/bozza', {});
  }

  /**
   * Funzine ws per la creazione del Ambito
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione postAmbiti per la creazione Ambito
   */
  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/lang/' + this.lang + '/' + this.nomeWs, data);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(idRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/lang/' + this.lang + '/' + this.nomeWs + '/delete', { id: idRiga })
  }

  /**
 * 
 * @param id 
 * @param ambito 
 * @returns 
 */
  public put(id: string, informazioni: any): Observable<any> {
    return this.webService.doPut('/api/v1/lang/' + this.lang + '/' + this.nomeWs + '/aggiorna', informazioni, {
      id
    });
  }

  public putPubblica(id: string): Observable<any> {
    return this.webService.doPut('/api/v1/lang/' + this.lang + '/' + this.nomeWs + '/pubblica', {}, {
      id
    });
  }

  ///api/v1/compila-informativa/lang/{codiceLingua}/get
  public getInformativaAziendaleEsterna(temporaryToken: string) {
    return this.webService.doGet('/api/v1/compila-informativa/lang/' + this.lang + '/get', {
      temporaryToken
    });
  }


  ///api/v1/compila-informativa/lang/{codiceLingua}/compila
  public putCompilaInformativaAziendaleEsterna(temporaryToken: string, informazioni: any): Observable<any> {
    return this.webService.doPut('/api/v1/compila-informativa/lang/' + this.lang + '/compila', informazioni, {
      temporaryToken
    });
  }

  public putCompilaInformativaAziendaleEsternaBozza(temporaryToken: string, informazioni: any): Observable<any> {
    return this.webService.doPut('/api/v1/compila-informativa/lang/' + this.lang + '/salva-bozza', informazioni, {
      temporaryToken
    });
  }
}
