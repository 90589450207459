import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import {
  BottoniListaEvent,
  Colonna,
  Filtri,
  ListaTabellareComponent,
} from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Utente, UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { map, of, tap } from 'rxjs';
import { DialogCreaUtentiComponent } from 'src/app/page/utenti/dialog/dialog-crea-utenti/dialog-crea-utenti.component';
import { DialogVisAziendeComponent } from 'src/app/page/utenti/dialog/dialog-vis-aziende/dialog-vis-aziende.component';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';
import { DialogRiepilogoLicenzeComponent } from 'src/app/page/utenti/dialog/dialog-riepilogo-licenze/dialog-riepilogo-licenze.component';

@Component({
  selector: 'app-lista-utenti',
  templateUrl: './lista-utenti.component.html',
  styleUrls: ['./lista-utenti.component.scss'],
  standalone: false
})
export class ListaUtentiComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public isPermessoSoloRead: boolean = (!this.utenteService.isPermessoAttivo('EDIT_UTENTI') && !this.utenteService.isPermessoAttivo('CREATE_UTENTI'))
  private _id_azienda: string | undefined = undefined;
  private listaUntentiPerAzienda: Utente[] = []

  @Input()
  get id_azienda() {
    return this._id_azienda;
  }

  set id_azienda(id_azienda: string | undefined) {
    this._id_azienda = id_azienda || undefined;
  }

  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome',
      forControlName: 'firstName',
      input: 'text',
    },
    {
      titolo: 'Cognome',
      forControlName: 'lastName',
      input: 'text',
    },
    {
      forControlName: 'roleName',
      input: 'option',
      titolo: 'Ruoli Utente',
      fnDatiOption: () => (this.utenteService.getRuoli()).pipe(map((value) => value || [])),
      descValueOption: 'descrizione',
      idValueOption: 'roleName',
      riempi: true,
    },
    {
      titolo: 'Stato',
      forControlName: 'attivo',
      input: 'option',
      descValueOption: 'descrizione',
      idValueOption: 'valore',
      value: true, // per default mostra solo utenti attivi
      fnDatiOption: () => of(this.filtroAttivoNonAttivo())
    },
    {
      titolo: 'Assegnazione Aziende',
      input: 'separatore',
      larghezza: 3,
      riempi: true,
    },
    {
      forControlName: 'idAziende',
      input: 'multiple-option',
      titolo: 'Aziende',
      fnDatiOption: () => (this.aziendaService.getAziende(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'ragioneSociale',
      idValueOption: 'id',
      riempi: true,
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    utenti: {
      title: 'Utenti',
      sortable: true,
      campiSort: ['lastName', 'firstName'],
      value: (record: Utente) => (record?.lastName + ' ' + record?.firstName),
    },
    email: {
      title: 'Email/Username',
      sortable: true,
      value: (record: Utente) => (record?.email || ''),
    },
    ruolo: {
      title: 'Ruolo',
      sortable: true,
      value: (record: any) => (record?.ruolo?.descrizione || ''),
    },
    /* idAziende: {
      title: 'Aziende Associate',
      value: (record: any) => (record?.idAziende?.length || 0),
    }, */
    modifica: {
      type: 'button',
      buttonIcon: (record: any) => this.isRecordEditable(record) ? 'edit' : 'visibility',
      title: (record: any) => this.isRecordEditable(record) ? 'Modifica' : 'Visualizza',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      sortable: false,
    },
    business: {
      type: 'button',
      buttonIcon: 'business',
      title: 'Aziende',
      buttonId: 'aziendeAssoc',
      buttonMostraSempre: true,
      sortable: false,
      nascondiButton: this.utenteService.getNrChiamataDialogUsr() >= 1
    },
    resetPassword: {
      type: 'button',
      buttonIcon: 'key',
      title: 'Reset password',
      buttonId: 'reset-pwd',
      sortable: false,
      nascondiButton: (record: any) => !this.utenteService.isPermessoAttivo('RESET_PASSWORD_ALL') && this.utenteService?.infoUtente?.utente?.keyId !== record?.keyId
    },
    /*  duplica: {
       type: 'button',
       buttonIcon: 'content_copy',
       title: 'Duplica',
       buttonId: 'duplica',
     }, */
    simula: {
      type: 'button',
      buttonIcon: 'swap_horiz',
      title: 'Simula utente',
      buttonId: 'simula',
      sortable: false,
      nascondiButton: (record: any) => !this.utenteService.isPermessoAttivo('SIMULA_UTENTI') || !record.attivo || record?.keyId === this.utenteService.infoUtente?.utente?.keyId
    },
    attiva: {
      type: 'button',
      buttonIcon: 'how_to_reg',
      title: 'Attiva',
      buttonId: 'attiva',
      sortable: false,
      nascondiButton: (record: any) => this._ctrDisattivaAttiva(record, 'attiva')
    },
    disattiva: {
      type: 'button',
      buttonIcon: 'person_off',
      title: 'Disattiva',
      buttonId: 'disattiva',
      sortable: false,
      nascondiButton: (record: any) => this._ctrDisattivaAttiva(record, 'disattiva'),
    },
    licenze: {
      type: 'button',
      buttonIcon: 'assignment_ind',
      title: 'Gestione Licenze',
      buttonId: 'licenze',
      sortable: false,
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (this.noscondiButtonLicenze(record))
    }
  };

  public noscondiButtonLicenze(record: any): boolean {

    if (this.utenteService.infoUtente?.utente.ruolo.roleName !== 'configuratore') {
      return true;
    }

    const ruoli = [
      "utente_read_only",
      "utente_read_only_go",
      "collaboratore",
      "collaboratore_go",
    ]

    if (ruoli.includes(record.ruolo.roleName)) {
      return true;
    }

    return false;

  }

  constructor(
    public dialog: MatDialog,
    public utenteService: UtenteService,
    private readonly utilityService: UtilityService,
    private readonly aziendaService: AziendaService,
    private readonly router: Router,
    public activatedRoute: ActivatedRoute,
    public licenzeService: LicenzeService,
  ) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.id_azienda) {
        this.filtri = this.filtri.filter((val) => val.forControlName !== 'aziende');
        this.filtri = this.filtri.filter((val) => val.input !== 'separatore');
      }
    }, 0)
  }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  crea(recordUtente?: Utente) {
    const dialogCreaUtente = this.dialog.open(DialogCreaUtentiComponent, {
      data: {
        utente: recordUtente,
        idAzienda: this.id_azienda, // 
        listaUntentiPerAzienda: this.listaUntentiPerAzienda,
      },
      panelClass: ['dialog-container'],
      disableClose: false,
      width: '80%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaUtente.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    const utentiSortBy: SortBy = {
      chiave: 'lastName',
      sort: 'asc'
    };

    let actualSortBy: SortBy[] = [];

    if (sortBy) {
      // Ordinamento ricevuto, si controlla se presente un ordinamento per lastName
      const ordinatoPerLastName = sortBy.find((sort) => sort.chiave === 'lastName') ? true : false;

      if (ordinatoPerLastName) {
        // C'è già un ordinamento per lastName, si lascia invariato
        actualSortBy = sortBy;
      } else {
        // Non c'è un ordinamento per lastName, si accoda
        actualSortBy = sortBy?.concat(utentiSortBy);
      }
    } else {
      // Nessun ordinamento ricevuto, si ordina per lastName
      actualSortBy = [utentiSortBy];
    }

    // Filtro gli utenti per id azienda (SONO IN LISTA UTENTI DA AZIENDA)
    if (this.id_azienda) {
      return this.utenteService.getUtentiByIdAzienda(this.id_azienda, page, pageSize, ricerca, filters, actualSortBy).pipe(
        tap(esito => {
          if (esito.content) {
            this.listaUntentiPerAzienda = esito.content
          }
        })
      );
    } else {
      return this.utenteService.getUtenti(page, pageSize, ricerca, filters, actualSortBy);
    }
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'simula':
        this._simula(event.data.id);
        break;
      case 'disattiva':
        this._disattivaUtente(event.data.id);
        break;
      case 'attiva':
        this._attivaUtente(event.data.id);
        break;
      case 'duplica':
        this._duplicaUtente();
        break;
      case 'reset-pwd':
        this._resetPassword(event.data.id);
        break;
      case 'aziendeAssoc':
        this._aziendeAssoc(event.data);
        break;
      case 'licenze':
        this._apriGestioneLicenze(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Disattiva l'utente indicato.
   * 
   * @param idUtente id dell'utente da disattivare 
   */
  private _disattivaUtente(idUtente: any) {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Confermi di disattivare l\'utente?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.utenteService.disattivaUtente(idUtente).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err?.error?.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
  /**
   * Attiva l'utente indicato
   * 
   * @param idUtente id dell'utente da disattivare 
   */
  private _attivaUtente(idUtente: any) {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Confermi di attivare l\'utente?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.utenteService.attivaUtente(idUtente).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                },
                error: (err: any) => {
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }

  /**
   * Metodo per la duplicazione di un utente
   */
  public _duplicaUtente() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }

  /**
   * Simula l'utente selezionato.
   * Disponibile solo per i configuratori con permesso SIMULA_UTENTI.
   * 
   * NOTA: Serve anche un permesso keycloak, assegnato tramite gruppo "impersonate"
   * 
   * @param recordUtente 
   */
  private _simula(idUtente?: string) {

    if (!idUtente) {
      console.error('NESSUN ID UTENTE RILEVATO');
      return;
    }

    this.spinnerOver.show();

    this.utenteService.simulaUtente(idUtente, this.utilityService.getBaseUrl()).subscribe({
      next: (result) => {
        location.reload();
      },
      error: (err) => {
        console.error(err);

        this.spinnerOver.hide();

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Simulazione non riuscita. Riprovare più tardi.',
          bottoni: [
            {
              nome_btn: 'Chiudi', handler: () => {
                this.tabella.caricaDati();
              }
            }]
        });
      }
    });


  }


  /**
   * Invia la mail di reset password
   */
  public _resetPassword(idUtente: string) {

    this.spinnerOver.show();

    this.utenteService.resetPassword(idUtente).subscribe({
      next: (result) => {
        this.spinnerOver.hide();
        this.utilityService.opneSnackBar('Mail inviata', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
      },
      error: (err) => {
        console.error(err);

        this.spinnerOver.hide();

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Invio reset password non riuscito. Verificare l\'indirizzo mail e riprovare più tardi.',
          bottoni: [
            {
              nome_btn: 'Chiudi', handler: () => {
                this.tabella.caricaDati();
              }
            }]
        });
      }
    });

  }

  private _aziendeAssoc(utente: Utente) {
    if (utente.idAziende) {
      const dialogVisAzienda = this.dialog.open(DialogVisAziendeComponent, {
        data: {
          idAzienda: utente.idAziende,
          utente: utente
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '95%',
        maxHeight: '95%',
        autoFocus: false,
      });

      dialogVisAzienda.afterClosed().subscribe((result) => {

        this.tabella.caricaDati();
        this.utenteService.setNrChiamataDialogUsr(0);
        this.aziendaService.setNrChiamataDialogAzd(0);
      });
    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Nessun\'azienda associata',
        bottoni: [
          {
            nome_btn: 'Chiudi', handler: () => {
              /*  this.tabella.caricaDati(); */
            }
          }]
      });
    };
  };

  private _apriGestioneLicenze(utente: Utente) {

    // this.router.navigate(['home/riepilogo-licenze']);

    const dialogRiepilogoLicenze = this.dialog.open(DialogRiepilogoLicenzeComponent, {
      data: {
        idUtente: utente.id
      },
      panelClass: ['dialog-container', 'maxwidth'],
      disableClose: false,
      width: '95%',
      height: '95%',
      maxWidth: '95%',
      autoFocus: false,
    });
    dialogRiepilogoLicenze.afterClosed()
  }

  /**
   * false = visibility
   * true = edit
   */
  isRecordEditable(record: any) {
    const ruoloLogin = this.utenteService?.infoUtente?.utente?.ruolo?.roleName; // Ruolo dell'utente loggato
    const ruoloRecord = record?.ruolo?.roleName; // Ruolo dell'utente nel record
    const keyIdUtente = this.utenteService?.infoUtente?.utente?.keyId; // ID dell'utente loggato
    const keyIdRecord = record?.keyId; // ID del record utente

    if (ruoloLogin === 'configuratore') {
      return true
    }

    // Posso modificare me stesso (sempre)
    if (keyIdUtente === keyIdRecord) {
      return true;
    }

    if (this.isPermessoSoloRead) {
      return false
    }


    if (ruoloRecord === 'collaboratore_go' && this.utenteService.isPermessoAttivo('EDIT_COLLABORATOREGO')) {
      return true;
    }


    if (ruoloRecord === 'collaboratore' && this.utenteService.isPermessoAttivo('EDIT_COLLABORATORE')) {
      return true;
    }

    if (ruoloRecord === 'utente_read_only_go' && this.utenteService.isPermessoAttivo('EDIT_READONLYGO')) {
      return true
    }

    if (ruoloRecord === 'utente_read_only' && this.utenteService.isPermessoAttivo('EDIT_READONLY')) {
      return true
    }

    if (ruoloRecord === 'utente_premium' && this.utenteService.isPermessoAttivo('EDIT_PREMIUM')) {
      return true
    }

    if (ruoloRecord === 'utente_premium_go' && this.utenteService.isPermessoAttivo('EDIT_PREMIUMGO')) {
      return true
    }

    if (ruoloRecord === 'professionista' && this.utenteService.isPermessoAttivo('EDIT_PROFESSIONISTA')) {
      return true
    }

    return false
  }

  /** KS
   * @param record selezionato dalla lista
   * @returns true = nascondi
   */
  private _ctrDisattivaAttiva(record: any, buttonId?: string): boolean {
    const ruoloLogin = this.utenteService?.infoUtente?.utente?.ruolo?.roleName; // Ruolo dell'utente loggato
    const keyIdUtente = this.utenteService?.infoUtente?.utente?.keyId; // ID dell'utente loggato
    const keyIdRecord = record?.keyId; // ID del record utente
    const permessoAttivo = this.utenteService.isPermessoAttivo('DELETE_UTENTI'); // Controlla se l'utente ha il permesso di eliminare utenti
    const recordAttivo = record?.attivo; // Stato attivo/disattivo del record utente
    const ruoloRecord = record?.ruolo?.roleName; // Ruolo dell'utente nel record


    // Nascondi il bottone se l'utente tenta di disattivare sé stesso
    if (keyIdUtente === keyIdRecord) {
      return true;
    }

    // Nascondi il bottone se l'utente tenta di disattivare un altro utente con lo stesso ruolo, a meno che non sia un 'configuratore'
    if (ruoloLogin === ruoloRecord && ruoloRecord !== 'configuratore') {
      return true;
    }


    if (ruoloRecord === 'collaboratore_go' && !this.utenteService.isPermessoAttivo('DELETE_COLLABORATOREGO')) {
      return true;
    }

    if (ruoloRecord === 'collaboratore' && !this.utenteService.isPermessoAttivo('DELETE_COLLABORATORE')) {
      return true;
    }

    if (ruoloRecord === 'utente_read_only_go' && !this.utenteService.isPermessoAttivo('DELETE_READONLYGO')) {
      return true
    }

    if (ruoloRecord === 'utente_read_only' && !this.utenteService.isPermessoAttivo('DELETE_READONLY')) {
      return true
    }

    if (ruoloRecord === 'utente_premium' && !this.utenteService.isPermessoAttivo('DELETE_PREMIUM')) {
      return true
    }

    if (ruoloRecord === 'utente_premium_go' && !this.utenteService.isPermessoAttivo('DELETE_PREMIUMGO')) {
      return true
    }
    if (ruoloRecord === 'professionista' && !this.utenteService.isPermessoAttivo('DELETE_PROFESSIONISTA')) {
      return true
    }

    if (ruoloRecord === 'professionista_go' && !this.utenteService.isPermessoAttivo('DELETE_PROFESSIONISTAGO')) {
      return true
    }

    if (buttonId === 'disattiva') {
      // Nascondi il bottone se l'utente non ha il permesso o il record è già disattivato
      if (!permessoAttivo || !recordAttivo) {
        return true;
      }
    }

    else if (buttonId === 'attiva') {
      // Nascondi il bottone se l'utente non ha il permesso o il record è già attivo
      if (!permessoAttivo || recordAttivo) {
        return true;
      }
    }

    // Mostra il bottone 
    return false;
  }

  public isCollaboratoreOrReadOnly(): boolean {
    const ruoloLogin = this.utenteService?.infoUtente?.utente?.ruolo?.roleName

    return !!(ruoloLogin && ['collaboratore', 'collaboratore_go', 'utente_read_only', 'utente_read_only_go'].includes(ruoloLogin))

  }

  filtroAttivoNonAttivo() {

    const filtro = [{ valore: true, descrizione: 'Attivo' }];
    if (this.utenteService.infoUtente?.utente.ruolo.roleName === 'configuratore') {
      filtro.push({ valore: false, descrizione: 'Non attivo' })
    }
    return filtro;
  }

}
