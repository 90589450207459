<!-- ToolBar -->
<mat-toolbar class="toolbar">

    <button mat-icon-button (click)="isSidenavMini = !isSidenavMini" [class.sidenav-mini]="isSidenavMini"
        disableRipple="true">
        <mat-icon>menu</mat-icon>
    </button>

    <img [src]="logoPath" alt="Sostenibile" class="logo-path" />

    <span class="toolbar-spacer"></span>

</mat-toolbar>


<!-- MENU LATERALE -->

<mat-sidenav-container class="sidenav-container">

    <mat-sidenav #sidenav mode="side" [opened]="true" class="sidenav" [class.mini]="isSidenavMini">

        <mat-nav-list class="menu">

            @for (voceMenu of vociMenu; track $index;) {

            <!-- Menu principale -->
            @if (!voceMenu.sottoSezione?.length) {
            <mat-list-item [routerLink]="voceMenu.routerMenu" routerLinkActive="active" [disabled]="voceMenu.disabled">
                <div class="menu-item">
                    <mat-icon>{{ voceMenu.icon }}</mat-icon>
                    <span>{{ voceMenu.sezione }}</span>
                </div>
            </mat-list-item>
            }

            <!-- Menu con sotto-sezioni -->
            @if (voceMenu.sottoSezione?.length) {
            <mat-list-item [disabled]="voceMenu.disabled" (click)="toggleSubMenu($index)">
                <div class=" menu-item" style="color: red; font-weight: bold;">
                    <mat-icon>{{ voceMenu.icon }}</mat-icon>
                    <span>{{ voceMenu.sezione }}</span>
                    <mat-icon>{{ voceMenu.isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
                </div>
            </mat-list-item>

            @if (voceMenu.isExpanded) {
            <div class="submenu">
                @for (voceSottoMenu of voceMenu.sottoSezione; track $index) {
                <mat-list-item [routerLink]="voceSottoMenu.routerMenu" routerLinkActive="active"
                    [disabled]="voceSottoMenu.disabled">
                    <div class="menu-item" style="color: pink; padding-left: 35px;">
                        <span>{{ voceSottoMenu.icon }}</span>
                        <span>{{ voceSottoMenu.sezione }}</span>
                    </div>
                </mat-list-item>
                }
            </div>
            }
            }
            }
        </mat-nav-list>
    
    </mat-sidenav>

</mat-sidenav-container>