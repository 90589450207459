import { Injectable } from '@angular/core';
import { StakeholderWebService } from './stakeholder-web.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Observable, of } from 'rxjs';

export interface ModalitaCompilazione {
  id: 'INVIAMAIL' | 'MANUALE',
  descrizione: 'Email' | 'Manuale'
}

export interface Tipologie {
  id: 'ESTERNI' | 'INTERNI',
  descrizione: 'Esterno' | 'Interno'
}

export interface Categorie {
  descrizione: string;
  id: string;
  idAzienda?: string;
  tipologia: string;
  titolo: string;
}

export interface CategoriaStakeholders extends Categorie {
  stakeholders: Stakeholder[],
  peso?: number
  idAzienda?: string,
}

export interface Stakeholder {
  id: string;
  nome: string;
  cognome: string;
  modalitaCompilazione: string,
  email: string;
  tipologia: string;
  categoria: Categorie,
  idAzienda: string;
  selected?: boolean;
  ragSoc?:string;
}


@Injectable({
  providedIn: 'root'
})
export class StakeholderService {


  constructor(private readonly stakeholderWebService: StakeholderWebService) { }

  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getStakeholder(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.stakeholderWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
    * Funzine ws per la creazione del Tematica
    * @param data dati da inserire nel MongoDB
    * @returns chiama la funzione post Stakeholder per la creazione Tematica
    */
  public postStakeholder(data: any) {
    return this.stakeholderWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteStakeholder(idRiga: any) {
    return this.stakeholderWebService.delete(idRiga);
  }

  /**
   * Stakeholder
   * @param id 
   * @param stakeholder 
   * @returns 
   */
  public putStakeholder(id: string, stakeholder: any) {
    return this.stakeholderWebService.put(id, stakeholder);
  }

  public getStakeMate(id: string, tipologia: 'ESTERNI' | 'INTERNI') {
    return this.stakeholderWebService.getStakeMate(id, tipologia);
  }

  /************ CATEGORIE ***********/

  /**
     * Funzione per selezionare le righe da far vedere nella grid
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getCategorieStake(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.stakeholderWebService.getCate(page, pageSize, ricerca, filters, sortBy);
  }
  /**
     * Funzione per selezionare le righe da far vedere nella grid Cfg Categorie
     * @param page pagina
     * @param pageSize record per pagina
     * @param ricerca risultato per ricerca 
     * @param filters valori da filtrare 
     * @param sortBy sort asc desc
     * @returns lista di righe
     */
  public getCategorieStakeCfg(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.stakeholderWebService.getCateCfg(page, pageSize, ricerca, filters, sortBy);
  }

  /**
    * Funzine ws per la creazione del Tematica
    * @param data dati da inserire nel MongoDB
    * @returns chiama la funzione post Stakeholder per la creazione Tematica
    */
  public postCategorieStake(data: any) {
    return this.stakeholderWebService.postCate(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteCategorieStake(idRiga: any) {
    return this.stakeholderWebService.deleteCate(idRiga);
  }

  /**
   * Stakeholder
   * @param id 
   * @param stakeholder 
   * @returns 
   */
  public putCategorieStake(id: string, stakeholder: any) {
    return this.stakeholderWebService.putCate(id, stakeholder);
  }

  public getTipologia(): Observable<Tipologie[]> {
    return of(
      [
        {
          id: 'INTERNI',
          descrizione: 'Interno'
        },
        {
          id: 'ESTERNI',
          descrizione: 'Esterno'
        },
      ])
  }

  public getStatus(): Observable<any[]> {
    return of(
      [
        {
          id: 'INV',
          descrizione: 'Inviate'
        },
        {
          id: 'NONINV',
          descrizione: 'Non Inviate'
        },
        {
          id: 'NONCOMP',
          descrizione: 'Non Compilate'
        },
        {
          id: 'COMP',
          descrizione: 'Compilate'
        },
      ])
  }

  /************************************************ STAKEHOLDER MATERIALITA */

  public getModalitaCompilazione(): Observable<ModalitaCompilazione[]> {
    return of(
      [
        {
          id: 'INVIAMAIL',
          descrizione: 'Email'
        },
        {
          id: 'MANUALE',
          descrizione: 'Manuale'
        },
      ])
  }
}
