import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { CategorieUm, UnitaDiMisura, UnitaDiMisuraDTO, UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
    selector: 'app-dialog-crea-unita-di-misura',
    templateUrl: './dialog-crea-unita-di-misura.component.html',
    styleUrls: ['./dialog-crea-unita-di-misura.component.scss'],
    standalone: false
})
export class DialogCreaUnitaDiMisuraComponent implements AfterViewInit {
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public selectedCategorieUm: string[] = [];
  public categoriaDaAggiornare: CategorieUm | undefined = undefined;
  public arrayCategorieUm: CategorieUm[] = [];
  public mostraCampoCategoria: boolean = false;
  /* Form Controls */
  public formUnitaDiMisura = new FormGroup({


    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    nomeBreve: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    idCategoriaUnitaDiMisura: new FormControl<string>('', {
      nonNullable: true,
    }),
  });


  public controlDesCatUnitaMisura = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, UtilityService.blankValidator]
  })

  constructor(
    public dialogRef: MatDialogRef<DialogCreaUnitaDiMisuraComponent>,
    public unitaDiMisuraService: UnitaDiMisuraService,
    private utilityService: UtilityService,

    @Inject(MAT_DIALOG_DATA) public data: {
      unitaDiMisura: UnitaDiMisura
    }
  ) {
    if (this.data?.unitaDiMisura) {
      Object.keys(this.data.unitaDiMisura).forEach((value, index, array) => {
        this.formUnitaDiMisura.get(value)?.setValue((this.data.unitaDiMisura as any)[value]);
      })
    }
    this._getCategorieUm();
  }

  ngAfterViewInit() {

  }

  scrollToBottom(): void {
    let el = document.getElementById('aggiungiCategoriaBottone');
    el?.scrollIntoView({ behavior: 'smooth' });
  }
  /**
   * Funzione di salvataggio di un nuovo unitaDiMisura
   * @returns 
   */
  compareCategorie(settore1: Settore, settore2: Settore): boolean {
    return settore1.id === settore2.id; // Sostituisci "id" con la proprietà univoca degli unitaDiMisura
  }

  public salva() {


    if (this.formUnitaDiMisura.valid) {

      const id = this.formUnitaDiMisura.get('id')?.value;

      const nuovaUnitaDiMisura: UnitaDiMisuraDTO = {
        ...this.formUnitaDiMisura.getRawValue(),
      }

      if (id) {
        return this.unitaDiMisuraService.putUnitaDiMisura(id, this.formUnitaDiMisura.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio dell\'unitaDiMisura:', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio dell\'unitaDiMisura',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {
        return this.unitaDiMisuraService.postUnitaDiMisura(nuovaUnitaDiMisura).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio degli unitaDiMisura:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio degli unitaDiMisura',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formUnitaDiMisura.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  /**
   * Get della lista di categorie
   */
  private _getCategorieUm() {

    return new Promise<void>((resolve, reject) => {
      this.unitaDiMisuraService.getCategoria().subscribe({
        next: (result: any) => {

          this.arrayCategorieUm = result || [];
          resolve();
        },
        error: (err: any) => {
          console.error('Errore durante il caricamento delle categorie:', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero delle categorie',
            bottoni: [{ nome_btn: 'Ok' }]
          });

          reject();
        }
      });
    })
  }


  visualizzaBottone() {
    this.mostraCampoCategoria = true;
    this.controlDesCatUnitaMisura.setValue('')
  }

  /**
     * Modifica categoria
     * @returns 
     */
  editCategoria(categoriaUnitaDiMisura: CategorieUm): void {
    this.mostraCampoCategoria = true;
    if (categoriaUnitaDiMisura.id) {
      this.categoriaDaAggiornare = categoriaUnitaDiMisura;
      this.controlDesCatUnitaMisura?.setValue(categoriaUnitaDiMisura.nome || '');
    }

  }

  /**
   * Annulla inserimento categoria.
   * @returns
   */
  chiudiInsCategoria(idCategoriaUnitaDiMisura?: string) {

    setTimeout(() => {
      if (idCategoriaUnitaDiMisura) {
        let el = document.getElementById(idCategoriaUnitaDiMisura);
        el?.scrollIntoView({ behavior: 'smooth' });
      }

      this.mostraCampoCategoria = false;
      this.categoriaDaAggiornare = {};
    }, 0);

  }

  /**
    * Aggiungi nuova categoria
    * @returns 
    */
  addCategoria() {

    this.spinnerOver.show();

    let obsSalvataggio: Observable<any>;

    if (this.categoriaDaAggiornare?.id) {
      this.categoriaDaAggiornare.nome = this.controlDesCatUnitaMisura?.value;

      obsSalvataggio = this.unitaDiMisuraService.putCategoria(this.categoriaDaAggiornare).pipe(map((res) => res.id));
    } else {

      const nuovaCategoria: CategorieUm = {
        nome: this.controlDesCatUnitaMisura?.value,
        unitaDiMisura: []
      }

      obsSalvataggio = this.unitaDiMisuraService.postCategoria(nuovaCategoria).pipe(map((res) => res.idCategoriaUnitaDiMisura));
    }

    obsSalvataggio.subscribe(
      {
        next: async (idCat: any) => {

          this.utilityService.opneSnackBar('Salvataggio effettuato', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });

          await this._getCategorieUm();

          this.chiudiInsCategoria(idCat);

          this.spinnerOver.hide();
        },
        error: (err: any) => {
          console.error(err);
          this.spinnerOver.hide();
          this.utilityService.opneSnackBar(err?.error?.message || 'Errore nel salvataggio', '', {
            duration: 2000,
            panelClass: ['red-snackbar']
          });
        }
      }
    );
  }

}