import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-aziende',
    templateUrl: './aziende.component.html',
    styleUrls: ['./aziende.component.scss'],
    standalone: false
})
export class AziendeComponent {
  constructor(
    public dialog: MatDialog,
  ) { }

}

