import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, finalize, map } from 'rxjs';
import { CompilaAutovalutazioneComponent } from 'src/app/components/compila-autovalutazione/compila-autovalutazione.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { AziendaService } from 'src/app/services/azienda/azienda.service';

@Component({
    selector: 'app-dialog-compila-autovalutazione',
    templateUrl: './dialog-compila-autovalutazione.component.html',
    styleUrls: ['./dialog-compila-autovalutazione.component.scss'],
    standalone: false
})
export class DialogCompilaAutovalutazioneComponent {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(CompilaAutovalutazioneComponent) compilaAutovalutazione!: CompilaAutovalutazioneComponent;

  private _idAzienda?: string;

  get idAzienda() {
    return this._idAzienda;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogCompilaAutovalutazioneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      comp: any,
      esercizio: number,
      idAutovalutazioneCompilata: string
    },
    private readonly aziendaService: AziendaService,
    private readonly autovalutazioneService: AutovalutazioniService
  ) { }

  ngOnInit() {
    this.aziendaService.azienda.then((azienda) => {
      this._idAzienda = azienda?.id;
    });
  }

  finitaCompilazione(value: boolean) {
    if (value) {
      this.dialogRef.close();
    }
  }

  salva = (idAutovalutazioneCompilata: string, compilazione: any): Observable<boolean> => {

    this.spinnerOver.show();

    return this.autovalutazioneService.salvaCompilazione(
      idAutovalutazioneCompilata,
      compilazione
    ).pipe(
      map((result) => {
        // tutto ok

        this.spinnerOver.hide();

        return true;
      })
    );
  }


}
