import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { PianoService } from 'src/app/services/piano/piano.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
    selector: 'app-dialog-crea-obiettivi-piano',
    templateUrl: './dialog-crea-obiettivi-piano.component.html',
    styleUrls: ['./dialog-crea-obiettivi-piano.component.scss'],
    standalone: false
})
export class DialogCreaObiettiviPianoComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public formObiettivoPiano = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaObiettiviPianoComponent>,
    private pianoService: PianoService,
    private utilityService: UtilityService,

    @Inject(MAT_DIALOG_DATA) public data: {
      obiettivoPiano: any,
      idContest: string,
      idAmbito: string,
      idTematica: string,
      contesto: string,
    }

  ) { }

  ngAfterViewInit(): void {
    if (this.data?.obiettivoPiano) {
      Object.keys(this.data.obiettivoPiano).forEach((value, index, array) => {
        this.formObiettivoPiano.get(value)?.setValue((this.data.obiettivoPiano)[value]);
      })
    }
  }

  public salva() {

    if (this.formObiettivoPiano.valid) {

      if (this.data.idContest) {
        return this.salvaInContesto();
      }

      const id = this.formObiettivoPiano.get('id')?.value;

      if (id) {
        return this.pianoService.putObiettiviPiano(id, this.formObiettivoPiano.getRawValue()).subscribe(
          {
            next: () => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err.error.message,
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }
          }
        );
      } else {
        return this.pianoService.postObiettiviPiano(this.formObiettivoPiano.getRawValue()).subscribe(
          {
            next: () => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: () => {
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formObiettivoPiano.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }
  /**
     * Metodo che mi salva un nuovo obiettivo effimero in piano di sostenibilità
     * @returns 
     */
  public salvaInContesto() {

    if (!this.data.idAmbito) {
      this.mostraMessaggio('Attenzione', 'Ambito non trovato o non riconosciuto');
      return;
    } else if (!this.data.idTematica) {
      this.mostraMessaggio('Attenzione', 'Tematica non trovata o non riconosciuta');
      return;
    }

    if (!this.formObiettivoPiano.get('id')?.value) {
      this.formObiettivoPiano.get('creatoDaContesto')?.setValue(true);
    }

    let serviceCall;

    if (this.data.contesto == 'piano') {
      serviceCall = this.pianoService.putObiettiviEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formObiettivoPiano.getRawValue());
    }

    if (serviceCall) {
      serviceCall.subscribe({
        next: (result: any) => {
          this.spinnerOver.hide();
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
        },
        error: (err: any) => {
          this.spinnerOver.hide();
          console.error('Errore durante il salvataggio dell\'obiettivo:', err);
          this.mostraMessaggio('Attenzione', 'Errore nel salvataggio dell\'obiettivo');
        }
      });
    }
  }
  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }
}
