import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { AziendaService } from '../azienda/azienda.service';
import { CategorieUm, UnitaDiMisuraDTO } from './unita-di-misura.service';

@Injectable({
  providedIn: 'root'
})
export class UnitaDiMisuraWebService {

  private nomeWs = 'unita-di-misura';
  private _idAzienda: string | undefined = undefined;
  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }
  public getUnitaDiMisuraPiano(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/azienda/' + this._idAzienda + '/piano-di-sostenibilita/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Esegue la POST della lista unità di misura usando un temporary token
   * @param page 
   * @param pageSize 
   * @param ricerca 
   * @param filters 
   * @param sortBy 
   * @returns 
   */
  public getTempToken(temporaryToken: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list-temp-token/' + temporaryToken, { page, pageSize, ricerca, filters, sortBy });
  }
  public getTempTokenPiano(temporaryToken: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list-temp-token/' + temporaryToken, { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del unitaDiMisura
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione post per la creazione unitaDiMisura
   */
  public post(unitaDiMisura: UnitaDiMisuraDTO): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs, unitaDiMisura);
  }

  /**
   * Funzione per eliminare una riga da una lista 
   * @param idRiga id della riga da eliminare
   * @returns 
   */
  public delete(datiRiga: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/categoria/' + datiRiga.idCategoriaUnitaDiMisura + '/' + datiRiga.id, {})
  }

  /**
   * 
   * @param id 
   * @param unitaDiMisura 
   * @returns 
   */
  public put(idUnitaDiMisura: string, unitaDiMisura: UnitaDiMisuraDTO): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/' + idUnitaDiMisura, unitaDiMisura, {});
  }
  /**
   * Metodo che modifica la categoria dentro l'unità di misura 
   * @param idCategoriaUnitaDiMisura 
   * @returns 
   */
  public putCategoria(categoriaUnitaDiMisura: CategorieUm): Observable<any> {
    const idCategoria: any = categoriaUnitaDiMisura.id;

    return this.webService.doPut('/api/v1/' + this.nomeWs + '/categorie/' + idCategoria, categoriaUnitaDiMisura, {
    });
  }

  /**
  * Metodo che crea la categoria dentro l'unità di misura 
  * @param idCategoriaUnitaDiMisura 
  * @returns 
  */
  public postCategoria(categoriaUnitaDiMisura: CategorieUm): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/categorie', categoriaUnitaDiMisura, {});
  }

  public getCategorie(): Observable<any> {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/categorie', {});
  }
}