import { map } from 'rxjs';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImpattoService, ScritturaImpatto } from 'src/app/services/impatto/impatto.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
    selector: 'app-cfg-scrittura-impatto',
    templateUrl: './cfg-scrittura-impatto.component.html',
    styleUrls: ['./cfg-scrittura-impatto.component.scss'],
    standalone: false
})
export class CfgScritturaImpattoComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  constructor(
    private utilityService: UtilityService,
    private impattoService: ImpattoService) {
  }

  public formScritturaImpatto = new FormGroup({

    PotenzialePositivo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    PotenzialeNegativo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    PotenzialePositivoNegativo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    PotenzialeEffettivoPositivo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    PotenzialeEffettivoNegativo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    PotenzialeEffettivoPositivoNegativo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    EffettivoPositivo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    EffettivoNegativo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    EffettivoPositivoNegativo: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),

  });

  ngAfterViewInit(): void {
    this.datiIniziali();
  }

  public salva() {
    const valuesArray = Object.values(this.formScritturaImpatto.getRawValue());
    const keysArray = Object.keys(this.formScritturaImpatto.getRawValue());

    const scritturaImpattoArray: ScritturaImpatto[] = keysArray.map((key, index) => ({
      codice: key,
      descrizione: valuesArray[index],
    }));

    
    if (this.formScritturaImpatto.valid) {

      return this.impattoService.postScritturaImpatto(scritturaImpattoArray).subscribe(
        {
          next: (result: any) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar'],
            });
          },
          error: (err: any) => {
            console.error('Errore durante il salvataggio dell\' impatto:', err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio dell\' impatto',
              bottoni: [{ nome_btn: 'Chiudi' }]
            })
          }

        }
      );
    } else {
      Object.values(this.formScritturaImpatto.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  /**
   * Funzione che prende i dati dal service e mappa sui campi in input 
   */
  private datiIniziali() {
    setTimeout(() => {
      this.spinnerOver.show();

      this.impattoService.getScritturaImpatti().subscribe(
        {
          next: (impattoList: ScritturaImpatto[]) => {

            if (Array.isArray(impattoList) && impattoList.length > 0) {
              

              for (let impatto of impattoList) {
                //Controllo se il codice del ws corrisponde al nome del campo FormControl
                const formImpatto = this.formScritturaImpatto.get(impatto.codice);

                if (formImpatto) {
                  // Se il codice corrisponde al nome allora setto come valore la descrizione del WS
                  formImpatto.setValue(impatto.descrizione);
                } else {
                  console.error('Il campo non esiste nel FormGroup.');
                }
              }
            }

            this.spinnerOver.hide();
          },
          error: (err: any) => {
            console.error('Errore durante la chiamata al servizio', err);
            this.spinnerOver.hide();
          }
        }
      );
    });
  }

}
