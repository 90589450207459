import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogCreaCfgImpattoComponent } from 'src/app/page/configurazioni/cfg-impatti/dialog/dialog-crea-cfg-impatto/dialog-crea-cfg-impatto.component';
import { Figura } from 'src/app/services/KPI/kpi.service';
import { AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { SottoSettore } from 'src/app/services/settori/settori.service';
import { TemaService } from 'src/app/services/tema/tema.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-autovalutazione',
  templateUrl: './dialog-crea-autovalutazione.component.html',
  styleUrls: ['./dialog-crea-autovalutazione.component.scss'],
  standalone: false
})
export class DialogCreaAutovalutazioneComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinner!: SpinnerOverlayComponent;

  public arrayAnni: string[] = [];
  public arraySottoSettori: SottoSettore[] = [];
  public arrayAutovalutazioni: any[] = [];
  public arrayFigure: Figura[] = [];
  public isUtenteGo: boolean = false;
  public sottoSettore: FormControl<string> = new FormControl('', { nonNullable: true });
  private _subTema: Subscription | undefined;

  public formCreaAutovalutazione = new FormGroup({
    id: new FormControl<string>("", {
      nonNullable: true,
      validators: [],
    }),
    annoDiRiferimento: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    idAutovalutazione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    idFigura: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  })

  constructor(
    private utilityService: UtilityService,
    private autovalutazioneService: AutovalutazioniService,
    private aziendaService: AziendaService,
    public dialogRef: MatDialogRef<DialogCreaCfgImpattoComponent>,
    public temaService: TemaService,

    @Inject(MAT_DIALOG_DATA) public data: {
    }
  ) {

    this._subTema = this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
      } else {
        this.isUtenteGo = false;
      }
    })

  }

  ngAfterViewInit(): void {
    const annoCorrente = new Date().getFullYear();
    // Aggiungi gli anni indietro di 5 anni e in avanti di 1 anno all'array
    for (let i = annoCorrente - 5; i <= annoCorrente + 1; i++) {
      this.arrayAnni.push(i.toString());
    }

    this._caricaAutovalutazioni();

    if (!this.isUtenteGo) {
      this._caricaSottoSettori();

      this.sottoSettore.valueChanges.subscribe((value) => {
        this._caricaAutovalutazioni();
      });
    }

    this._caricaFigure();


  }

  ngOnDestroy(): void {
    this._subTema?.unsubscribe();
  }


  salva() {

    if (this.formCreaAutovalutazione.valid) {

      this.spinner.show();

      const annoDiRiferimento = this.formCreaAutovalutazione.get('annoDiRiferimento')?.value;
      const idAutovalutazione = this.formCreaAutovalutazione.get('idAutovalutazione')?.value;
      const idFigura = this.formCreaAutovalutazione.get('idFigura')?.value;

      if (annoDiRiferimento && idAutovalutazione && idFigura) {

        return this.autovalutazioneService.creaAutovalutazioneCompilate(
          annoDiRiferimento,
          idAutovalutazione,
          idFigura
        ).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio :', err);

              this.spinner.hide();

              // 424 -> L'assessment è stato creato ma non è stata inviata la mail 
              if (err?.status === 424) {
                this.dialogRef.close();
              }

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err?.error?.message || 'Errore nel salvataggio ',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );

      }
    } else {
      Object.values(this.formCreaAutovalutazione.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }


  private _caricaAutovalutazioni() {

    this.aziendaService.azienda.then((azienda) => {

      if (azienda) {

        const filter: Filters[] = [];

        if (this.sottoSettore.value && !this.isUtenteGo) {
          filter.push({
            chiave: 'sottoSettore',
            operator: 'eq',
            values: [this.sottoSettore.value]
          });
        }

        this.autovalutazioneService.getAutovalutazioneCodiceAteco(azienda?.codiciAteco[0], 0, 1000, "", filter, []).subscribe((autovalutazioni) => {
          this.arrayAutovalutazioni = autovalutazioni.content;

          if (this.arrayAutovalutazioni.findIndex((auto) => auto.id === this.formCreaAutovalutazione.get('idAutovalutazione')?.value) <= -1) {
            // L'autovalutazione selezionata non è tra quelle estratte, si azzera il valore
            this.formCreaAutovalutazione.get('idAutovalutazione')?.setValue('');
          }

        });

      }

    });

  }


  private _caricaSottoSettori() {

    this.aziendaService.azienda.then((azienda) => {
      if (azienda) {

        this.aziendaService.getSettoriAzienda().subscribe((settori) => {

          this.arraySottoSettori = (settori || []).map((settore => settore.sottoSettori || [])).flat();

        });

      }

    });

  }


  private _caricaFigure() {

    this.aziendaService.azienda.then((azienda) => {
      if (azienda) {

        this.aziendaService.getFigureAz(azienda.id, 0, 1000, "", [], []).subscribe((figura) => {
          this.arrayFigure = figura.content;

          const referente = this.arrayFigure.find((fig) => {
            return fig.figura.toLowerCase() === 'referente';
          });

          if (referente) {
            this.formCreaAutovalutazione.get('idFigura')?.setValue(referente.id);
          }
        });

      }

    });

  }


}
