<div class="landing-page" *ngIf="landingPage" [innerHtml]="landingPage"></div>
<div style="align-items: center;display: flex;justify-content: space-between;width: 100%;height: 50px;">
    <div>
        <div style="font-size: 32px;color: var(--mat-color-accent);font-family: Abel;">
            {{compilazione.titolo}} {{esercizio}}
        </div>
    </div>
    <div style="height: 1px;background-color: #707070;flex:1;margin-right: 20px;margin-left: 20px;">
    </div>

    <div style="font-size: 32px;color: var(--mat-color-accent);font-family: Abel;">
        {{(currentSlideIndex + 1)}}/{{totTematiche}}
    </div>

</div>
<div mat-dialog-content class="dialog-center-compilazione">
    <swiper-container [simulateTouch]="false" auto-height="true" #swiperAmbiti (swiperslidechange)="onSlideChange()">

        <swiper-slide class="ambito" *ngFor="let amb of ambiti; let idxAmb = index">
          
            <div class="titolo">
                Ambito - {{amb.titolo}} - {{amb.descrizione}}
            </div>

            <swiper-container [simulateTouch]="false" auto-height="true" #swiperTematiche>

                <swiper-slide *ngFor="let tema of amb.tematiche; let idxTem = index">

                    <div class="tematiche">

                        <div *ngIf="tema.domanda" class="domanda">
                            Tematica - {{tema.domanda}}
                        </div>

                        <div *ngIf="tema.descrizione" class="descrizione">
                            {{tema.descrizione}}
                        </div>

                        <ng-container *ngIf="tema.tipo === 'scelta_singola'">

                            <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group"
                                [value]="tema?.risposte?.length ? tema.risposte![0] : null"
                                (change)="onRadioChange($event,idxAmb,idxTem)" #radioGroup="matRadioGroup">

                                <ng-container *ngFor="let opz of tema.opzioni; let idxOpz = index">

                                    <mat-radio-button [value]="opz.chiave">
                                        {{opz.valore}}
                                    </mat-radio-button>

                                    <div class="sotto-opzioni"
                                        *ngIf="opz.sottoOpzioni?.length && radioGroup.value === opz.chiave">

                                        <div *ngFor="let opzSec of opz.sottoOpzioni" class="radio-group">

                                            <ng-container *ngIf="opzSec.tipo === 'checkbox'">

                                                <mat-checkbox [value]="opzSec.chiave" [checked]="opzSec.checked"
                                                    (change)="cambioCheckSottoOpzione($event,opzSec)">
                                                    {{opzSec.valore}}
                                                </mat-checkbox>

                                            </ng-container>

                                            <ng-container *ngIf="opzSec.tipo === 'azione'">

                                                <mat-label class="descrizione"
                                                    style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>

                                                <mat-form-field>
                                                    <mat-label>Risposta</mat-label>
                                                    <textarea matInput [placeholder]="'Risposta'" rows="4"
                                                        [(ngModel)]="opzSec.risposta"></textarea>
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>

                                            </ng-container>

                                            <ng-container *ngIf="opzSec.tipo === 'kpi'">

                                                <mat-label class="descrizione"
                                                    style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>

                                                <div appFormContainer [larghezza]="3">

                                                    <mat-form-field appFormElement [larghezza]="1">
                                                        <mat-label>Unità di Misura</mat-label>
                                                        <mat-select [placeholder]="'Unità di Misura'"
                                                            [(ngModel)]="opzSec.valoriKpi[0].idUnitaDiRiferimento">
                                                            <mat-option
                                                                *ngFor="let um of arrayUm[opzSec.valoriKpi[0].idCategoriaUnitaDiMisura]"
                                                                [value]="um.id">{{um.descrizione}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>

                                                    <mat-form-field appFormElement [larghezza]="1">
                                                        <mat-label>Risposta</mat-label>
                                                        <input matInput [placeholder]="'Risposta'"
                                                            [(ngModel)]="opzSec.valoriKpi[0].valoreAttribuito"
                                                            type="number" appInputNumber step="0.01"
                                                            inputmode="numeric" />
                                                        <mat-error appFormError></mat-error>
                                                    </mat-form-field>

                                                </div>

                                            </ng-container>

                                        </div>

                                    </div>

                                </ng-container>

                            </mat-radio-group>

                        </ng-container>

                        <ng-container *ngIf="tema.tipo === 'scelta_multipla'">

                            <div class="checkbox-group" *ngFor="let opz of tema.opzioni; let idxOpz = index">

                                <mat-checkbox #checkboxOpz [value]="opz.chiave"
                                    [checked]="(tema?.risposte || []).indexOf(opz.chiave) > -1"
                                    (change)="cambioCheckOpzione($event, tema, opz)">
                                    {{opz.valore}}
                                </mat-checkbox>


                                <div class="sotto-opzioni" *ngIf="opz.sottoOpzioni?.length && checkboxOpz.checked">

                                    <div *ngFor="let opzSec of opz.sottoOpzioni" class="radio-group">

                                        <ng-container *ngIf="opzSec.tipo === 'checkbox'">

                                            <mat-checkbox [value]="opzSec.chiave" [checked]="opzSec.checked"
                                                (change)="cambioCheckSottoOpzione($event,opzSec)">
                                                {{opzSec.valore}}
                                            </mat-checkbox>

                                        </ng-container>

                                        <ng-container *ngIf="opzSec.tipo === 'azione'">

                                            <mat-checkbox #checkSottoOpzioneAttiva [checked]="opzSec.checked"
                                                (change)="sottoOpzAzioneKpiChecked($event,opzSec)">
                                                <mat-label class="descrizione"
                                                    style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>
                                            </mat-checkbox>

                                            <ng-container *ngIf="checkSottoOpzioneAttiva.checked">

                                                <mat-form-field>
                                                    <mat-label>Risposta</mat-label>
                                                    <textarea matInput [placeholder]="'Risposta'" rows="4"
                                                        [(ngModel)]="opzSec.risposta"></textarea>
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>
                                            </ng-container>

                                        </ng-container>

                                        <ng-container *ngIf="opzSec.tipo === 'kpi'">

                                            <mat-checkbox #checkSottoOpzioneAttiva [checked]="opzSec.checked"
                                                (change)="sottoOpzAzioneKpiChecked($event,opzSec)">
                                                <mat-label class="descrizione"
                                                    style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>
                                            </mat-checkbox>

                                            <div appFormContainer [larghezza]="3"
                                                *ngIf="checkSottoOpzioneAttiva.checked">

                                                <mat-form-field appFormElement [larghezza]="1">
                                                    <mat-label>Unità di Misura</mat-label>
                                                    <mat-select [placeholder]="'Unità di Misura'"
                                                        [(ngModel)]="opzSec.valoriKpi[0].idUnitaDiRiferimento">
                                                        <mat-option
                                                            *ngFor="let um of arrayUm[opzSec.valoriKpi[0].idCategoriaUnitaDiMisura]"
                                                            [value]="um.id">{{um.descrizione}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appFormElement [larghezza]="1">
                                                    <mat-label>Risposta</mat-label>
                                                    <input matInput [placeholder]="'Risposta'"
                                                        [(ngModel)]="opzSec.valoriKpi[0].valoreAttribuito" type="number"
                                                        appInputNumber step="0.01" inputmode="numeric" />
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>

                                            </div>

                                        </ng-container>

                                    </div>

                                </div>

                            </div>

                        </ng-container>

                    </div>

                </swiper-slide>

            </swiper-container>

        </swiper-slide>

    </swiper-container>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-flat-button color="accent" (click)="indietro()" class="accent-button">
            {{currentSlideIndex === 0 ? 'Chiudi' : 'Indietro'}}
        </button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="avanti()" class="primary-button" #btnAvanti
            [disabled]="!ambiti[indexAmbitoCorrente].tematiche[indexTematicaCorrente].risposte?.length">
            {{(currentSlideIndex + 1) === totTematiche ? 'Fine' : 'Avanti'}}
        </button>
    </div>
</div>