import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.scss'],
    standalone: false
})
export class SpinnerOverlayComponent {

  @HostBinding('class.hidden')
  private _spinnerHide = true;

  public show() {
    setTimeout(() => {
      this._spinnerHide = false;
    },0);
  }

  public hide() {
    setTimeout(() => {
      this._spinnerHide = true;
    },0);
  }
}
