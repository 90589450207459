import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appInputNumber]',
    standalone: false
})
export class InputNumberDirective {

  constructor() { }

  @HostListener('keypress', ['$event'])
  checkIfNumber(event: KeyboardEvent): boolean {
    const key = event.key;

    // Controlla se il carattere non è un numero (i caratteri numerici vanno da '0' a '9') o una virgola
    if (!/^[0-9,]$/.test(key)) {
      event.preventDefault(); // Impedisce l'inserimento di caratteri non numerici o virgola
      return false;
    }

    return true;
  }

}
