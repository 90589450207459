import { Injectable } from '@angular/core';
import { LicenzeWebService } from './licenze-web.service';
import { Utente } from '../utente/utente.service';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

export interface PromotoreLicenze {
  codice: string,
  descrizione: string,
  id: string,
}
interface LicenzaContesti {
  bilancio: number;
  sintesi: number;
  materialita: number;
  piano: number;
  policy: number;
  assessment: number;
  azienda: number
}
interface Utilizzo {
  tipo: string;
  dataUtilizzo: string;
  idAzienda: string;
  idUtenteUtilizzatore: string;
  idRisorsa: string;
}

interface Pacchetto {
  codicePacchetto: string;
  idUtenteAcquisto: string;
  acquisti: LicenzaContesti
  contatori: LicenzaContesti
  utilizzi: Utilizzo[];
}

interface PacchettoUtenza {
  codicePacchetto: string;
  dataAttivazione: string;
  dataScadenza: string;
  idUtenteAttivatore: string;
  acquisti: LicenzaContesti
  contatori: LicenzaContesti
  utilizzi: Utilizzo[];
}

interface PacchettiAcquisti {
  dataAttivazione: string;
  dataScadenza: string;
  pacchetti: Pacchetto[];
}

export interface Licenza {
  tipo: 'UTENZA' | 'AGGIUNTIVO'
  codiceLicenza: string;
  idUtente: string;
  codicePromotore: string;
  freeze: boolean;
  pacchettoUtenza: PacchettoUtenza;
  pacchettiAcquisti: PacchettiAcquisti;
}




@Injectable({
  providedIn: 'root'
})


export class LicenzeService {

  private codiceLingua = 'it-IT'
  constructor(
    public licenzeWebService: LicenzeWebService
  ) { }


  public getLicenzeByUtente(idUtente: string, codiceLicenza: string) {
    return this.licenzeWebService.getLicenzeByUtente(idUtente, codiceLicenza);
  }

  /* public putLicenzeByUtente(idUtente: string, codiceLicenza: string, licenza: any) {
    return this.licenzeWebService.putLicenzeByUtente(idUtente, codiceLicenza, licenza);
  }
 */
  public postAbilitaLicenza(idUtente: string, licenza: any) {
    return this.licenzeWebService.postAbilitaLicenza(idUtente, licenza);
  }

  public putLicenzaBase(idUtente: string, codiceLicenza: string, codicePacchetto: string, licenza: any) {
    return this.licenzeWebService.putLicenzaBase(idUtente, codiceLicenza, codicePacchetto, licenza);
  }

  public deleteLicenzaBase(idUtente: string, codiceLicenza: string, codicePacchetto: string) {
    return this.licenzeWebService.deleteLicenzaBase(idUtente, codiceLicenza, codicePacchetto);
  }

  public postLicenzeList() {
    return this.licenzeWebService.postLicenzeList();
  }

  public postLicenzeCreate() {
    return this.licenzeWebService.postLicenzeCreate();
  }

  public getPromotoriList() {
    return this.licenzeWebService.getPromotoriList();
  }
  public postPromotori() {
    return this.licenzeWebService.postPromotori();
  }

  public getUserHasLicense(nomeModulo: string) {
    return this.licenzeWebService.getUserHasLicense(nomeModulo);
  }
  public getUserHasLicenseIdUtente(idUtente: string, nomeModulo: string) {
    return this.licenzeWebService.getUserHasLicenseIdUtente(idUtente, nomeModulo);
  }

  public getPacchettiBase(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[],
    codiceLicenza?: string,
    idUtente?: string
  ) {
    return this.licenzeWebService.getPacchettiBase(page, pageSize, ricerca, filters, sortBy, codiceLicenza, idUtente);
  }
  public getPacchettiAggiuntivi(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[],
    codiceLicenza?: string,
    idUtente?: string
  ) {
    return this.licenzeWebService.getPacchettiAggiuntivi(page, pageSize, ricerca, filters, sortBy, codiceLicenza, idUtente);
  }

  public rinnovoLicenzaBase(codiceLicenza: string, idUtente: string, licenza: any) {
    return this.licenzeWebService.rinnovoLicenzaBase(codiceLicenza, idUtente, licenza);
  }

  public aggiungiPacchettoAggiuntivo(codiceLicenza: string, idUtente: string, licenza: any) {
    return this.licenzeWebService.aggiungiPacchettoAggiuntivo(codiceLicenza, idUtente, licenza);
  }

}