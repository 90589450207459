import { Component } from '@angular/core';
import { SelezioneElementiQuestionarioComponent } from '../../selezione-elementi-questionario.component';
import { DialogCreaKPIComponent } from 'src/app/page/configurazioni/cfg-kpi/dialog/dialog-crea-kpi/dialog-crea-kpi.component';
import { of, Observable, map, catchError, tap, firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-selezione-kpi-sintesi',
    templateUrl: '../../selezione-elementi-questionario.component.html',
    styleUrls: ['../../selezione-elementi-questionario.component.scss'],
    standalone: false
})
export class SelezioneKPISintesiComponent extends SelezioneElementiQuestionarioComponent {
  override editElementoListaChk(idAmbito: string, idTematica: string, kpi?: any): void {
    const dialogCreaKPI = this.dialog.open(DialogCreaKPIComponent, {
      data: {
        kpi: kpi,
        idContest: this.idContest,
        idAmbito: idAmbito,
        idTematica: idTematica,
        contesto: 'sintesi'
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '75%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaKPI.afterClosed().subscribe((result) => {
       

      if (result) {
        this.spinnerOver.show();
        this.salvataggio(idAmbito, idTematica, kpi?.id);
      }

    });
  }

  async salvataggio(idAmbito: string, idTematica: string, idKpi: string) {
    try {
      // Salvo i dati modificati
      await firstValueFrom(this.salva(true));
      // Get dei dati aggiornati 
      await firstValueFrom(this._getKpiSintesi());

      // Scroll all'elemento modificato 
      setTimeout(() => {
        this.scrollToElement(idAmbito, idTematica, idKpi);
      }, 0);
    } catch (error) {
      console.error(error)
    }

  }


  override addElementoListaChk(idAmbito: string, idTematica: string): void {
    // posso passare la stessa funzione poichè la gestione è identica, Cambia solameanete il passaggio del kpi 
    // a vuoto nella creazione.
    this.editElementoListaChk(idAmbito, idTematica);
  }

  override salvaFigAz(idAmbito: string, idTematica: string, idKpi: string, idFigAziendale: string): void {
    this.spinnerOver.show();
    this.sintesiService.putAssegnaFiguraKpiSintesi(this.idContest, idAmbito, idTematica, idKpi, idFigAziendale).subscribe({
      next: (esito) => {
        this.salvataggio(idAmbito, idTematica, idKpi);

      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      }
    })
  }

  private _getKpiSintesi(): Observable<any> {
    this.spinnerOver.show();

    return this.sintesiService.getKpiSintesi(this.idContest).pipe(
      tap((esito) => {
        this.objQuestionario = esito;
      }),
      catchError(() => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei kpi',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of()
      }),
      tap({
        finalize: () => this.spinnerOver.hide()
      }
      )
    )
  }

  /**
   * 
   * @param noCtr controlla check selezionati
   * @returns 
   */
  salva(noCtr?: boolean) {

    let kpiSelezionati = this.oggettoElementiSelezionati();


    if (!noCtr) {
      //Controllo se è stato selezionato almeno un impatto per tematica 
      if (this.ctrElemChecked(kpiSelezionati, 'kpi')) {
        return of(false);
      }
    }

    return this._salvaSurveyMaterialita(kpiSelezionati);
  }


  private _salvaSurveyMaterialita(kpiSelezionati: any): Observable<boolean> {

    if (this.idContest) {

      return this.sintesiService.putKpiSintesiSelezionati(
        this.idContest, kpiSelezionati).pipe(
          map((result: any) => {
             

            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();

            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore salvataggio (sconosciuto)',
              bottoni: [{ nome_btn: 'Ok' }]
            });
            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }


}
