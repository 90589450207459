import { AutovalutazioniService } from './../../../../../services/autovalutazioni/autovalutazioni.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { UnitaDiMisura, UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
    selector: 'app-dialog-anteprima',
    templateUrl: './dialog-anteprima.component.html',
    styleUrls: ['./dialog-anteprima.component.scss'],
    standalone: false
})
export class DialogAnteprimaComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public dataCompilazione: string = '';
  public ambiti: Ambito[] = [];

  public arrayUm: { [idKpi: string]: UnitaDiMisura[] } = {};
  public mappaPunteggioAmbiti: { [key: string]: any } = {};
  constructor(
    public dialogRef: MatDialogRef<DialogAnteprimaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ambito: Ambito[], compilate: boolean, autovalutazione: any },
    private readonly utilityService: UtilityService,
    private readonly dialog: MatDialog,
    private readonly unitaDiMisuraService: UnitaDiMisuraService,
    private readonly autovalutazioniService: AutovalutazioniService
  ) {

    this.initAnteprima();

  }

  /**
   * Metodo che mi stampa
   */
  public stampa() {

    this.spinnerOver.show()
    const idAutovalutazione = this.data.autovalutazione.id;

    if (idAutovalutazione) {
      this.autovalutazioniService.stampaAutovalutazione(idAutovalutazione).subscribe({
        next: (result) => {

          this.spinnerOver.hide();
          window.open(result?.body, '_blank');
        },
        error: (errore) => {
          console.error(errore);

          this.spinnerOver.hide();

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Stampa non riuscita. Verificare le configurazioni e riprovare.',
            bottoni: [{ nome_btn: 'Ok' }]
          });

        }
      })
    }
  }

  initAnteprima() {
    if (this.data?.autovalutazione?.dataCompilazione) {
      // Creare un oggetto Date dal formato fornito
      const dataCompilazione: Date = new Date(this.data.autovalutazione?.dataCompilazione);

      // Ottenere i componenti della data
      const giorno: string = String(dataCompilazione.getDate()).padStart(2, '0');
      const mese: string = String(dataCompilazione.getMonth() + 1).padStart(2, '0'); // Mese inizia da 0
      const anno: number = dataCompilazione.getFullYear();

      // Formattare la data nel tuo formato desiderato
      this.dataCompilazione = `${giorno}/${mese}/${anno}`;
    }

    for (let idxAmb = 0; idxAmb < this.data.ambito.length; idxAmb++) {

      this.mappaPunteggioAmbiti[this.data.ambito[idxAmb].id] = this.data.ambito[idxAmb].punteggioPercentuale;

      if (this.data.ambito[idxAmb].tematiche.length) {

        for (let idxTema = 0; idxTema < this.data.ambito[idxAmb].tematiche.length; idxTema++) {

          if (this.data.ambito[idxAmb].tematiche[idxTema].opzioni?.length) {

            for (let idxOpz = 0; idxOpz < this.data.ambito[idxAmb].tematiche[idxTema].opzioni.length; idxOpz++) {

              if (this.data.ambito[idxAmb].tematiche[idxTema].opzioni[idxOpz].sottoOpzioni?.length) {

                this.data.ambito[idxAmb].tematiche[idxTema].opzioni[idxOpz].sottoOpzioni!.forEach((sotOpz) => {

                  if (sotOpz.tipo === 'kpi') {
                    if (!sotOpz.valoriKpi?.length) {
                      // Probabilmente siamo in anteprima da compilare,
                      // si mette un valore fittizio per non far scoppiare il template
                      sotOpz.valoriKpi = [{
                        valoreAttribuito: undefined,
                        idCategoriaUnitaDiMisura: sotOpz.idCategoriaUnitaDiMisura,
                        idUnitaDiRiferimento: sotOpz.idUnitaDiRiferimento,
                        anno: (new Date()).getFullYear(),
                        tipo: "valore_kpi"
                      }]
                    }

                    if (sotOpz.idCategoriaUnitaDiMisura && !this.arrayUm[sotOpz.idCategoriaUnitaDiMisura]) {

                      this.arrayUm[sotOpz.idCategoriaUnitaDiMisura] = []; // si mette intanto array vuoto per evitare di chiamare il ws più volte per una singola categoria

                      this.unitaDiMisuraService.getUnitaDiMisura(0, 1000, '', [{
                        chiave: 'idCategoriaUnitaDiMisura',
                        operator: 'eq',
                        values: [sotOpz.idCategoriaUnitaDiMisura]
                      }]).subscribe((um) => {
                        this.arrayUm[sotOpz.idCategoriaUnitaDiMisura] = um?.content || [];
                      });
                    }
                  }
                });
              }
            }
          }

        }
      }
    }

    this.ambiti = this.data.ambito || [];
  }

}