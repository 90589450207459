<app-spinner-overlay></app-spinner-overlay>

<form [formGroup]="formCreaCfgQuestionario">

    <div class="top-container">
        <div appFormContainer [larghezza]="6" class="campi" [alignItems]="'flex-start'">

            <mat-form-field appFormElement [larghezza]="2" class="input-form" *ngIf="!nascondiTitolo">
                <mat-label>Titolo</mat-label>
                <input matInput placeholder="Titolo" formControlName="titolo" />
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <div appFormElement [larghezza]="2" *ngIf="tipoQuestionario === 'AUTOVALUTAZIONE'"
                style="display:flex; flex-direction: column">
                <label style="padding-left: 20px;">Tipo assessment</label>
                <mat-radio-group aria-label="Select an option" formControlName="tipoAutovalutazione"
                    style="padding-left: 10px;" (change)="onSelectionChangeTipo($event)">
                    <mat-radio-button aria-label="Sito vetrina" value="GENERICO">Sito vetrina</mat-radio-button>
                    <mat-radio-button aria-label="Per settore" value="SETTORE">Per settore</mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="tipoQuestionario === 'AUTOVALUTAZIONE'" style="width: 100%; flex: 1;" appFormContainer
                [larghezza]="3" [alignItems]="'flex-start'" [alignContent]="'flex-start'" [rowGap]="22">
                <button mat-flat-button color="accent" style="height: 56px; flex: 1;" (click)="salvaInterno()">Salva
                    Bozza</button>

                <button mat-flat-button color="accent" style="height: 56px; flex: 1;"
                    (click)="anteprimaAutovalutazione()">Anteprima</button>

                <button mat-flat-button color="primary" style="height: 56px; flex: 1;"
                    (click)="pubblicaAutovalutazione()">Pubblica</button>
            </div>

            <mat-form-field appFormElement [larghezza]="2" class="input-form"
                *ngIf="tipoQuestionario === 'SURVEY' || formCreaCfgQuestionario.get('tipoAutovalutazione')?.value === 'SETTORE'">
                <mat-label>Settori*</mat-label>
                <mat-select formControlName="settori" placeholder="Settori" [compareWith]="compareById"
                    (selectionChange)="onSelectionChangeSettore($event)">
                    <mat-option *ngFor="let set of arraySettori" [value]="set">{{set.titolo}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appFormElement [larghezza]="2" class="input-form"
                *ngIf="(tipoQuestionario === 'SURVEY' || formCreaCfgQuestionario.get('tipoAutovalutazione')?.value === 'SETTORE') && arraySottoSettori?.length">
                <mat-label>Sotto-settori</mat-label>
                <mat-select formControlName="sottoSettori" placeholder="Sotto-settori" [compareWith]="compareById"
                    multiple>
                    <mat-option *ngFor="let sottoSet of arraySottoSettori"
                        [value]="sottoSet">{{sottoSet.titolo}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                *ngIf="this.tipoQuestionario === 'AUTOVALUTAZIONE' && (this.formCreaCfgQuestionario.get('settori')?.value || formCreaCfgQuestionario.get('tipoAutovalutazione')?.value === 'GENERICO')"
                appFormElement [larghezza]="2" class="input-form" [riempi]="true">
                <mat-label>Ambiti</mat-label>
                <mat-select formControlName="ambiti" placeholder="Ambiti" multiple [compareWith]="compareById">
                    <mat-option *ngFor="let amb of arrayAmbiti" [value]="amb"
                        [disabled]="ambitiLocked[amb.id]">{{amb.titoloBreve}}</mat-option>
                </mat-select>
            </mat-form-field>


        </div>


    </div>

</form>

<ng-container *ngIf="isUtenteGo && tipoQuestionario ==='BILANCIO'">
    <div class="titolo-sezione-dialog">Scegli la tematica su cui si baserà il tuo report</div>

    <div class="descrizione-sezione-dialog">
        Potrai trattare un'unica tematica.
    </div>

</ng-container>

<ng-container *ngIf="!this.ambitiSelezionati.length">
    <div class="msg-no-ambito">NESSUN AMBITO DISPONIBILE</div>
</ng-container>

<!-- TAB DOVE CI SONO GLI AMBITI -->
<mat-tab-group *ngIf="this.ambitiSelezionati.length">
    <ng-container *ngIf="this.ambitiSelezionati?.length">
        <!-- TAB POPOLATA DAGLI AMBITI -->
        <mat-tab [label]="amb.titoloBreve" *ngFor="let amb of this.ambitiSelezionati">
            <!-- CONTENUTO DELLA TAB -->

            <div class="tematiche" appFormContainer [larghezza]="2">
                <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                    <div class="cont-titolo">
                        <mat-label class="titolo">Tematiche Selezionate</mat-label>
                    </div>
                    <ng-scrollbar #scrollSelezionate [id]="amb.id+'_sel'">
                        <div scrollViewport dropListScroller cdkDropList #selList="cdkDropList"
                            [cdkDropListData]="arrayTematicheSel[amb.id]" [cdkDropListConnectedTo]="[dispList]"
                            class="list" (cdkDropListDropped)="drop($event, amb.id, 'SELEZIONATI')">
                            <div class="box" *ngFor="let sel of arrayTematicheSel[amb.id]" cdkDrag
                                [cdkDragDisabled]="qstDisabilitato || sel?.locked" [matTooltip]="sel.descrizione"
                                matTooltipPosition="left">
                                <div class="box-sx">
                                    <button mat-icon-button title="Sposta" aria-label="Sposta"
                                        [disabled]="qstDisabilitato  || sel?.locked">
                                        <mat-icon> drag_handle </mat-icon>
                                    </button>
                                    <mat-label class="truncate">{{sel.domanda}}</mat-label>
                                </div>

                                <!-- CONSIGLIATO - Tematica consigliata -->
                                <button mat-icon-button
                                    *ngIf="((sel?.preSelectedInfos && sel?.preSelectedInfos?.length > 0) || (sel?.infoConsigliatoObbl?.stdrConsigliati)) && !sel?.locked"
                                    class="material-icons-outlined icone-tem icona-consigliato"
                                    [matTooltip]="'Tematica consigliata, vedi maggiori informazioni'"
                                    (click)="$event.stopPropagation(); apriPopUpConsigliato(sel)">
                                    <span class=" material-icons-outlined">
                                        emoji_objects
                                    </span>
                                </button>

                                <!-- BLOCCATO (lucchetto) -->
                                <button mat-icon-button
                                    *ngIf="((sel?.preSelectedInfos && sel?.preSelectedInfos?.length > 0) || (sel?.infoConsigliatoObbl?.stdrConsigliati)) && sel?.locked"
                                    class="material-icons-outlined icone-tem icone-label-chack icona-lucchetto "
                                    [matTooltip]="'Tematica obbligatoria, vedi maggiori informazioni'"
                                    (click)="$event.stopPropagation(); apriPopUpObbligatorio(sel)">
                                    <span class=" material-icons-outlined">
                                        lock
                                    </span>
                                </button>

                            </div>
                        </div>
                    </ng-scrollbar>
                </div>
                <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                    <div class="cont-titolo">
                        <mat-label class="titolo">Tematiche Disponibili</mat-label>
                    </div>
                    <ng-scrollbar #scrollDisponibili [id]="amb.id+'_disp'">
                        <div cdkDropList scrollViewport dropListScroller #dispList="cdkDropList"
                            [cdkDropListData]="arrayTematicheDisponibili[amb.id]" [cdkDropListConnectedTo]="[selList]"
                            class="list" style="background-color: #f5f5f5;"
                            (cdkDropListDropped)="drop($event, amb.id, 'DISPONIBILI')">

                            <div class="tematica-disp" *ngIf="loadingTemDisp[amb.id]">
                                <mat-spinner style="height: 50px;"></mat-spinner>
                            </div>

                            <div class="nessuna-tematica"
                                *ngIf="!arrayTematicheDisponibili[amb.id]?.length && !loadingTemDisp[amb.id]">
                                <p>Nessuna Tematica Disponibile</p>
                            </div>

                            <div class="box" style="background-color: #f5f5f5;"
                                *ngFor="let disp of arrayTematicheDisponibili[amb.id]" cdkDrag
                                [cdkDragDisabled]="(qstDisabilitato) " [matTooltip]="disp.descrizione"
                                matTooltipPosition="left">
                                <div class="box-sx">
                                    <button mat-icon-button title="Sposta" aria-label="Sposta"
                                        [disabled]="(qstDisabilitato) ">
                                        <mat-icon> drag_handle </mat-icon>
                                    </button>
                                    <mat-label class="truncate">{{disp.domanda}}</mat-label>
                                </div>

                                <!-- CONSIGLIATO - Tematica consigliata -->
                                <button mat-icon-button
                                    *ngIf="(disp?.preSelectedInfos && disp?.preSelectedInfos?.length > 0) || (disp.infoConsigliatoObbl?.stdrConsigliati)"
                                    class="material-icons-outlined icone-tem icona-consigliato"
                                    [matTooltip]="'Tematica consigliata, vedi maggiori informazioni'"
                                    (click)="$event.stopPropagation(); apriPopUpConsigliato(disp)">
                                    <span class=" material-icons-outlined">
                                        emoji_objects
                                    </span>
                                </button>

                            </div>
                        </div>
                    </ng-scrollbar>
                </div>
            </div>
        </mat-tab>
    </ng-container>
</mat-tab-group>