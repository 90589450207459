import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';

export interface InfoElemento {
  preSelectedInfos: string[]
  stdr: STDR[]
  titoloElemento: string,
  obbligatorio: boolean,
}


@Component({
    selector: 'app-bottom-sheet-info-elementi',
    templateUrl: './bottom-sheet-info-elementi.component.html',
    styleUrls: ['./bottom-sheet-info-elementi.component.scss'],
    standalone: false
})
export class BottomSheetInfoElementiComponent {
  public infoElemento: InfoElemento;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.infoElemento = data;
  }


  public isStdr(): boolean {
    return this.infoElemento?.stdr.some(elem => !elem.compliance) || false;
  }

  public isCompliance(): boolean {
    return this.infoElemento?.stdr.some(elem => elem.compliance) || false;
  }
}
