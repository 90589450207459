<app-spinner-overlay></app-spinner-overlay>
<div class="contenitore">
    <div class="titolo-pagina">
        <app-titolo-subtitolo [titolo]="isUtenteGo ? 'Report Tematico':'Bilancio di Sostenibilità'"
            subTitolo="Redigi il Bilancio di Sostenibilità selezionando standard di reportistica e normative di sostenibilità al quale allineare le informazioni da trattare nel documento. <br>
            <br>
            Compila le tue informazioni aziendali per capire gli obblighi aziendali sul tema della reportistica di sostenibilità e sfrutta le funzionalità di Sostenibile.cloud per raccogliere tutte le informazioni di sostenibilità dell’azienda: impegni, buone pratiche, Key Performance Indicators (KPIs), certificazioni e target futuri. <br>
            <br>
            Collegando il tuo Bilancio di Sostenibilità agli altri moduli potrai usufruire delle informazioni inserite precedentemente.
            "></app-titolo-subtitolo>
    </div>
    <app-lista-tabellare #tabella [fnCaricamentoDati]="fnCaricamentoDati" [colonne]="colonne" [filtri]="filtri"
        (bottoniListaClick)="bottoniListaClick($event)">
        <div btnEnd>
            <button [disabled]="disableNuovo" mat-flat-button class="primary-button" color="primary" (click)="crea()"
                *ngIf="this.utenteService.isPermessoAttivo('CREATE_BILANCIO')">
                Nuovo
            </button>
        </div>
    </app-lista-tabellare>
</div>