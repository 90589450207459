<div>
    <div class="titolo-e-targa">
        <div class="titolo-elemento">{{infoElemento.titoloElemento}}</div>
        <!-- OBBLIGATORI -->
        <div *ngIf="infoElemento?.obbligatorio" class="targa" style="color: var(--grigio);">
            <span class=" material-icons-outlined icone">
                lock
            </span>
            <div> Obbligatorio</div>
        </div>

        <!-- CONSIGLIATI -->
        <div *ngIf="!infoElemento?.obbligatorio" class="targa">
            <span class=" material-icons-outlined icone">
                emoji_objects
            </span>
            Consigliato
        </div>
    </div>
    <div class="informazioni">
        <ng-container *ngIf="infoElemento?.stdr?.length">
            <!-- STDR -->
            <ng-container *ngIf="isStdr()">
                <div style="font-weight: bold; padding-bottom: 5px; font-size: 12px;">Standard di rendicontazione:</div>
                <div *ngFor="let stdr of infoElemento?.stdr">
                    <div style="padding-left: 20px;">
                        <div *ngIf="!stdr.compliance">
                            <div style="display: flex; align-items: center; column-gap: 10px;">
                                <div class="image-container">
                                    <div class="immagine-cont"
                                        [ngStyle]="{'background-image': 'url(' + (stdr?.urlFile)+')'}">
                                    </div>
                                </div>
                                {{stdr.codice}}
                            </div>
                        </div>
                    </div>

                </div>
                <br>
            </ng-container>
            <!-- COMPLIANCE -->
            <ng-container *ngIf="isCompliance()">
                <div style="font-weight: bold; padding-bottom: 5px; font-size: 12px;">Compliance Normativi:</div>
                <div *ngFor="let stdr of infoElemento?.stdr">

                    <div style="padding-left: 20px;">

                        <div *ngIf="stdr.compliance">
                            <div style="display: flex; align-items: kscenter; column-gap: 10px;">
                                <div class="image-container">
                                    <div class="immagine-cont"
                                        [ngStyle]="{'background-image': 'url(' + (stdr?.urlFile)+')'}">
                                    </div>
                                </div>
                                {{stdr.codice}}
                            </div>
                        </div>

                    </div>
                </div>
                <br>
            </ng-container>
        </ng-container>

        <!-- INCLUSIONI -->
        <ng-container *ngIf="infoElemento?.preSelectedInfos?.length">
            <div style="font-weight: bold;padding-bottom: 5px;">Moduli associati:</div>
            <div *ngFor="let infoElem of infoElemento?.preSelectedInfos">
                <ul>
                    <li style="padding-right: 30px;">{{infoElem}}</li>
                </ul>
            </div>
        </ng-container>
    </div>
</div>